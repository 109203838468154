import React from "react";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { IDialogProps } from "./index";

const DialogFullScreen = (props: IDialogProps) => {
  return (
    <>
      <AppBar
        sx={{
          position: "relative",
        }}
      >
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
            <ArrowBackIcon fontSize="large" />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" />
          {props.title && <Typography variant="h3">{props.title}</Typography>}
        </Toolbar>
      </AppBar>
      <Container maxWidth="md">
        <Box padding="2px 0">{props.body}</Box>
      </Container>
    </>
  );
};

export default DialogFullScreen;
