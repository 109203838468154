import { ApolloProvider } from "@apollo/client";
import { client } from "./apolloClient";
import AuthProvider from "context/Auth";
import { ModalDialogProvider } from "context/DialogProvider";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface AppWrapperProps {
  children: JSX.Element;
}

const AppWrapper = (props: AppWrapperProps) => {
  return (
    <ApolloProvider client={client}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AuthProvider>
          <ModalDialogProvider>{props.children}</ModalDialogProvider>
        </AuthProvider>
      </LocalizationProvider>
    </ApolloProvider>
  );
};

export default AppWrapper;
