// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "./QuestionsTable";
import CategoriesTable from "./CategoriesTable";
import { IconButton } from "@mui/material";
import { AddCommentOutlined } from "@mui/icons-material";
import { useState } from "react";
import AddQuestion from "./AddQuestion";

function List(): JSX.Element {
  const [isAddQuestionOpen, setAddQuestionOpen] = useState(false);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox p={3} lineHeight={1}>
          <MDTypography variant="h5" fontWeight="medium">
            Catégories de questions matricielles
          </MDTypography>
          <CategoriesTable />
        </MDBox>
      </Card>

      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Questions matricielles
            </MDTypography>
          </MDBox>{" "}
          <IconButton color="secondary" onClick={() => setAddQuestionOpen(true)}>
            <AddCommentOutlined></AddCommentOutlined>
          </IconButton>
          <DataTable />
        </Card>
      </MDBox>
      <AddQuestion open={isAddQuestionOpen} onClose={() => setAddQuestionOpen(false)}></AddQuestion>
      <Footer />
    </DashboardLayout>
  );
}

export default List;
