import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useAddMatricQuestionMutation, useGetMatricQuestionCategoriesQuery } from "services/api";

export default function Form({ open, onClose }: any) {
  const { data: dataResopnse } = useGetMatricQuestionCategoriesQuery();
  const [addQuestion] = useAddMatricQuestionMutation();

  const matricQuestionCategories = useMemo(() => {
    return dataResopnse?.matric_question_categories || [];
  }, [dataResopnse]);

  const [selectedCategory, setSelectedCategory] = useState(matricQuestionCategories?.[0]?.id);
  const [contentEn, setContentEn] = useState("");
  const [contentFr, setContentFr] = useState("");

  const handleSubmit = async () => {
    await addQuestion({
      variables: {
        category_id: selectedCategory,
        contentEn: contentEn,
        contentFr: contentFr,
      },
    });
    setContentEn("");
    setContentFr("");
    onClose();
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Add Question</DialogTitle>
      <DialogContent>
        <form style={{ padding: "5px" }}>
          <Select
            defaultValue={matricQuestionCategories?.[0]?.id}
            fullWidth
            onChange={(e) => setSelectedCategory(e.target.value as number)}
          >
            {matricQuestionCategories.map((category) => (
              <MenuItem value={category.id}>
                {category.content.translations?.find((e) => e.language_code === "en")?.translation}
              </MenuItem>
            ))}
          </Select>
          <br />
          <br />
          <TextField
            label="Anglaise"
            variant="outlined"
            fullWidth
            value={contentEn}
            onChange={(e) => setContentEn(e.target.value)}
          />
          <br />
          <br />
          <TextField
            label="Français"
            variant="outlined"
            fullWidth
            value={contentFr}
            onChange={(e) => setContentFr(e.target.value)}
          />
          <br />
          <br />
        </form>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
        <Button onClick={() => onClose()} color="primary">
          Fermerr
        </Button>
      </DialogActions>
    </Dialog>
  );
}
