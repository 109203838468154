import { useMemo, useEffect, useCallback } from "react";
import { useTable, usePagination, useSortBy, ColumnWithLooseAccessor } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Profiler Connextion components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Profiler Connextion examples components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {
  GetUsersQueryVariables,
  useGetOnboardingLinkLazyQuery,
  useGetUsersLazyQuery,
} from "services/api";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import MDBadge from "components/MDBadge";
import { useDialog } from "hooks/useDialog";
import dayjs from "dayjs";

// Declaring props types for DataTable
interface Props {
  entriesPerPage?: {
    defaultValue: number;
    entries: number[];
  };
  canSearch?: boolean;
  showTotalEntries?: boolean;
  pagination?: {
    variant: "contained" | "gradient";
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  };
  isSorted?: boolean;
  noEndBorder?: boolean;
  pageCount?: number;
  fetchData?: (options: { limit: number; offset: number }) => void;
}

function DataTable({ showTotalEntries, pagination, isSorted, noEndBorder }: Props): JSX.Element {
  const navigate = useNavigate();
  const dialog = useDialog();

  const entriesPerPage = useMemo(() => ({ defaultValue: 10, entries: [10] }), []);

  const baseQuery: GetUsersQueryVariables = useMemo(
    () => ({
      limit: entriesPerPage.defaultValue,
      offset: 0,
      where: {
        role: {
          _eq: "COACH",
        },
      },
    }),
    []
  );

  const [query, { data: userResult }] = useGetUsersLazyQuery({
    variables: {
      ...baseQuery,
    },
  });

  const [getOnboardingLink, { loading: onboardingLoading }] = useGetOnboardingLinkLazyQuery({
    fetchPolicy: "no-cache",
  });

  const totalCount = useMemo(() => {
    if (!userResult?.users_aggregate) return 0;

    return userResult.users_aggregate.aggregate.count;
  }, [userResult?.users_aggregate]);

  const openOnboardLink = (link: string) => {
    dialog.open({
      title: "Stripe account onboarding link",
      body: <div>{link}</div>,
      actions: [
        {
          label: "Fermer",
          onClick: () => dialog.close(),
          type: "CANCEL",
        },
      ],
    });
  };

  const columns: ColumnWithLooseAccessor[] = useMemo(
    () => [
      { Header: "Nom complet", accessor: "full_name" }, // Full name
      { Header: "E-mail", accessor: "email" }, // Email
      {
        Header: "Créé le", // Created at
        accessor: "created_at",
        Cell: (e) => <span>{dayjs(e.value).toString()}</span>,
      },
      {
        Header: "Activé", // Activated
        accessor: "is_active",
        Cell: (e) => (
          <MDBadge
            variant="contained"
            size="xs"
            badgeContent={e.value ? "Vérifié" : "Non vérifié"} // Verified / Not Verified
            container
            color={e.value ? "success" : "error"}
            fontWeight="medium"
          />
        ),
      },
      {
        Header: "Compte Stripe", // Stripe Account
        accessor: "is_stripe_account_ready",
        align: "center",
        Cell: (e) => {
          if (e.value) {
            return (
              <MDBadge
                variant="contained"
                size="xs"
                badgeContent={"Vérifié"} // Verified
                container
                color="success"
                fontWeight="medium"
              />
            );
          }

          return (
            <MDButton
              variant="gradient"
              size="small"
              color="info"
              disabled={onboardingLoading}
              onClick={() =>
                getOnboardingLink({
                  variables: {
                    input: {
                      user_id: e.cell.row.values?.id,
                    },
                  },
                }).then((d) => openOnboardLink(d.data.getStripeAccountLink.link))
              }
            >
              Intégration
            </MDButton>
          );
        },
      },
      {
        Header: "Disponibilité", // Availability
        accessor: "id",
        Cell: (e) => (
          <MDButton
            variant="gradient"
            size="small"
            color="info"
            onClick={() => navigate(`/dashboard/coaches/availability/${e.value}`)}
          >
            Disponibilité
          </MDButton>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => userResult?.users || [], [userResult]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    pageCount,
  } = useTable(
    {
      autoResetPage: false,
      autoResetExpanded: false,
      columns,
      data,
      manualPagination: true,
      pageCount: Math.ceil(totalCount / entriesPerPage.defaultValue),
      initialState: { pageIndex: 0, pageSize: entriesPerPage.defaultValue },
    },
    useSortBy,
    usePagination
  );

  const fetchData = useCallback((options: { limit: number; offset: number }) => {
    const { limit, offset } = options;

    query({
      variables: {
        ...baseQuery,
        limit,
        offset,
      },
    });
  }, []);

  useEffect(() => {
    fetchData({
      limit: pageSize,
      offset: pageIndex * pageSize,
    });
  }, [pageIndex]);

  const handleGotoPage = (currentPage: number) => {
    gotoPage(currentPage);
  };

  const handleInputPagination = ({ target: { value } }: any) => {
    const pageTarget = value > pageOptions.length || value < 0 ? 0 : Number(value);
    handleGotoPage(pageTarget);
  };

  const customizedPageOptions = pageOptions.map((option: any) => option + 1);

  const handleInputPaginationValue = ({ target: value }: any) =>
    handleGotoPage(Number(value.value - 1));

  const renderPagination = useMemo(() => {
    return pageOptions.map((option: any) => (
      <MDPagination
        item
        key={option}
        onClick={() => handleGotoPage(Number(option))}
        active={pageIndex === option}
      >
        {option + 1}
      </MDPagination>
    ));
  }, [pageOptions, pageIndex]);

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        {entriesPerPage && (
          <MDBox display="flex" alignItems="center">
            <Autocomplete
              disableClearable
              value={pageSize.toString()}
              options={entriesPerPage.entries.map((e) => e.toString())}
              onChange={(event, newValue) => {
                setPageSize(parseInt(newValue, 10));
                fetchData({
                  limit: parseInt(newValue, 10),
                  offset: 0,
                });
              }}
              size="small"
              sx={{ width: "5rem" }}
              renderInput={(params) => <MDInput {...params} />}
            />
            <MDTypography variant="caption" color="secondary">
              &nbsp;&nbsp;Entrées par page
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup: any, key: any) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, key: any) => (
                <DataTableHeadCell
                  key={key}
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row: any, key: any) => {
            prepareRow(row);
            return (
              <TableRow key={key} {...row.getRowProps()}>
                {row.cells.map((cell: any, key: any) => (
                  <DataTableBodyCell
                    key={key}
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Page {pageIndex + 1} sur {pageCount}
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination
                item
                onClick={() => {
                  previousPage();
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(event: any) => {
                    handleInputPagination(event);
                    handleInputPaginationValue(event);
                  }}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination
                item
                onClick={() => {
                  nextPage();
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

// Declaring default props for DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: ["5", "10", "15", "20", "25"] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

export default DataTable;
