// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Profiler Connextion components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Autocomplete, FilterOptionsState, createFilterOptions } from "@mui/material";
import MDTypography from "components/MDTypography";

// Profiler Connextion examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser layout schemas for form and form feilds
import FormField from "modules/users/NewUser/components/FormField";

import form from "modules/coaches/NewCoach/schemas/from";
import initialValues from "modules/coaches/NewCoach/schemas/initialValues";
import validations from "modules/coaches/NewCoach/schemas/validations";
import { useMemo } from "react";
import { useCreateCoachMutation, useGetGendersQuery } from "services/api";

const filter = createFilterOptions<string>();

export function NewCoach(): JSX.Element {
  const [createCoach] = useCreateCoachMutation();
  const { data: srcGenderData } = useGetGendersQuery();

  const srcGenders = useMemo(() => {
    return srcGenderData?.genders || [];
  }, [srcGenderData]);

  const { formId, formField } = useMemo(() => form, []);
  const { fullName, country, email, password, skills, gender, birthday, hourly } = useMemo(
    () => formField,
    [formField]
  );

  const submitForm = async (values: any, actions: any) => {
    console.log("values", values);

    await createCoach({
      variables: {
        input: {
          email: values[email.name],
          full_name: values[fullName.name],
          gender_id: srcGenders.find((e) => e.code === values[gender.name])?.id,
          date_of_birth: values[birthday.name],
          hourly_rate: values[hourly.name],
          skills: values[skills.name],
          password: values[password.name],
        },
      },
    });

    alert("Created coach successfully!!!");

    actions.setSubmitting(false);
    actions.resetForm();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={validations}
              onSubmit={submitForm}
            >
              {({ values, errors, touched, setFieldValue, isSubmitting }) => (
                <Card sx={{ height: "100%" }}>
                  <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    p={3}
                    mb={1}
                    textAlign="center"
                  >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                      Créer un nouvel entraîneur
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                      Entrez les informations pour créer
                    </MDTypography>
                  </MDBox>
                  <Form id={formId} autoComplete="off">
                    <MDBox mt={1.625} p={3}>
                      <Grid container spacing={3} mb={3}>
                        <Grid item xs={12} sm={6}>
                          <MDBox>
                            <FormField
                              type={fullName.type}
                              label={fullName.label}
                              name={fullName.name}
                              value={values[fullName.name]}
                              error={errors.fullName && touched.fullName}
                              success={values[fullName.name].length > 0 && !errors.fullName}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDBox>
                            <Autocomplete
                              options={srcGenders.map((e) => e.code)}
                              onChange={(e, value) => setFieldValue(gender.name, value)}
                              defaultValue={
                                srcGenders?.[0]?.content?.translations?.find(
                                  (e) => e.language_code === "fr"
                                )?.translation || ""
                              }
                              renderInput={(params) => (
                                <FormField
                                  {...params}
                                  label={gender.label}
                                  name={gender.name}
                                  value={values[gender.name] || "MALE"}
                                  error={errors.gender && touched.gender}
                                  success={values[gender.name]?.length > 0 && !errors?.gender}
                                />
                              )}
                            />
                          </MDBox>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} mb={3}>
                        <Grid item xs={12} sm={6}>
                          <MDBox>
                            <FormField
                              type={birthday.type}
                              label={birthday.label}
                              name={birthday.name}
                              value={values[birthday.name]}
                              error={errors.birthday && touched.birthday}
                              success={values[birthday.name]?.length > 0 && !errors.birthday}
                              InputLabelProps={{ shrink: true }}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDBox>
                            <FormField
                              type={country.type}
                              label={country.label}
                              name={country.name}
                              value={values[country.name]}
                              error={errors.country && touched.country}
                              success={values[country.name].length > 0 && !errors.country}
                            />
                          </MDBox>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} mb={2}>
                        <Grid item xs={12} sm={12}>
                          <MDBox>
                            <FormField
                              type={email.type}
                              label={email.label}
                              name={email.name}
                              value={values[email.name]}
                              error={errors.email && touched.email}
                              success={values[email.name].length > 0 && !errors.email}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <MDBox>
                            <FormField
                              type={password.type}
                              label={password.label}
                              name={password.name}
                              value={values[password.name]}
                              error={errors.password && touched.password}
                              success={values[password.name].length > 0 && !errors.password}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Autocomplete
                            multiple
                            defaultValue={[]}
                            options={[
                              "Communication",
                              "Goal setting",
                              "Leadership",
                              "Motivation",
                              "Resolution",
                            ]}
                            filterOptions={(
                              options: string[],
                              params: FilterOptionsState<string>
                            ) => {
                              const filtered = filter(options, params);

                              const { inputValue } = params;
                              // Suggest the creation of a new value
                              const isExisting = options.some((option) => inputValue === option);
                              if (inputValue !== "" && !isExisting) {
                                filtered.push(`${inputValue}`);
                              }

                              return filtered;
                            }}
                            onChange={(_, values) => setFieldValue(skills.name, values)}
                            renderInput={(params) => (
                              <FormField
                                {...params}
                                label={skills.label}
                                name={skills.name}
                                value={values[skills.name]}
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <MDBox>
                            <FormField
                              type={hourly.type}
                              placeholder="Eg. 10"
                              label={hourly.label}
                              name={hourly.name}
                              value={values[hourly.name]}
                              error={errors.hourly && touched.hourly}
                              success={Number(values[hourly.name]) > 0 && !errors.hourly}
                            />
                          </MDBox>
                        </Grid>
                      </Grid>
                      <MDBox
                        width="100%"
                        height={{ xs: "auto", md: "100%" }}
                        display="flex"
                        justifyContent={{ xs: "flex-start", md: "flex-end" }}
                        alignItems="flex-end"
                        mt={{ xs: 2, md: 0 }}
                      >
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="dark"
                          disabled={isSubmitting}
                        >
                          Create
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Form>
                </Card>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
