/* eslint-disable @typescript-eslint/no-empty-function */
import { Users, useLoginMutation } from "services/api";
import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";

interface AuthContextProps {
  user: Omit<Users, "password">;
  authenticating: boolean;
  logout: () => void;
  login: (email: string, password: string) => Promise<void>;
}

const defaultAuthValues: AuthContextProps = {
  user: null,
  authenticating: false,
  logout: () => {
    // TODO: handle in the future
  },
  login: async () => {
    // TODO: handle in the future
  },
};

const user = localStorage.getItem("user");
if (user) {
  defaultAuthValues.user = JSON.parse(user);
}

export const parseTokenToUser = (token: string): any => {
  const decodedToken = jwtDecode<{
    "https://hasura.io/jwt/claims": {
      "x-hasura-user-id": number;
      "x-hasura-user-email": string;
      "x-hasura-default-role": string;
    };
  }>(token);

  const claims = decodedToken["https://hasura.io/jwt/claims"];

  return {
    id: claims["x-hasura-user-id"],
    email: claims["x-hasura-user-email"],
    role: claims["x-hasura-default-role"],
  };
};

const AuthContext = createContext<AuthContextProps>(defaultAuthValues);

export const useJWTAuth = (): AuthContextProps => useContext(AuthContext);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [doLogin] = useLoginMutation();
  const navigate = useNavigate();

  const [authData, setAuthData] = useState<Omit<AuthContextProps, "login" | "logout">>({
    ...defaultAuthValues,
  });

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");

    setAuthData((auth) => ({ ...auth, user: null }));
  };

  const login = async (email: string, password: string): Promise<void> => {
    setAuthData((auth) => ({ ...auth, authenticating: true }));

    const loginResult = await doLogin({
      variables: {
        input: {
          email,
          password,
        },
      },
    });

    if (loginResult.data.login?.token) {
      const user = parseTokenToUser(loginResult.data.login.token);

      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", loginResult.data.login.token);
      localStorage.setItem("refresh_token", loginResult.data.login.refresh_token);

      setAuthData((auth) => ({ ...auth, user }));

      navigate("/", {
        replace: true,
      });
    }

    setAuthData((auth) => ({ ...auth, authenticating: false }));
  };

  return (
    <AuthContext.Provider
      value={{
        ...authData,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
