/**
=========================================================
* Profiler Connextion - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Profiler Connextion components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Profiler Connextion examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "./DataTable";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";

function CoachList(): JSX.Element {
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            p={3}
            lineHeight={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <MDTypography variant="h5" fontWeight="medium">
              Entraîneurs
            </MDTypography>
            <MDButton
              type="submit"
              variant="gradient"
              color="dark"
              onClick={() => navigate("/dashboard/coaches/new-coach")}
            >
              Nouvel entraîneur
            </MDButton>
          </MDBox>
          <DataTable />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CoachList;
