/**
=========================================================
* Profiler Connextion - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState, useCallback } from "react";

// react-table components
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  TableInstance,
} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Profiler Connextion components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Profiler Connextion examples components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {
  GetUsersQueryVariables,
  Reservations,
  useGetReservationsLazyQuery,
  useGetUsersLazyQuery,
  useReleaseFundsMutation,
} from "services/api";
import { Link } from "react-router-dom";
import MDBadge from "components/MDBadge";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { useDialog } from "hooks/useDialog";

// Declaring props types for DataTable
interface Props {
  entriesPerPage?: {
    defaultValue: number;
    entries: number[];
  };
  canSearch?: boolean;
  showTotalEntries?: boolean;
  pagination?: {
    variant: "contained" | "gradient";
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  };
  isSorted?: boolean;
  noEndBorder?: boolean;
  pageCount?: number;
  fetchData?: (options: { limit: number; offset: number }) => void;
}

function DataTable({ showTotalEntries, pagination, isSorted, noEndBorder }: Props): JSX.Element {
  const dialog = useDialog();
  const [releaseFunds, { loading }] = useReleaseFundsMutation();

  const entriesPerPage = useMemo(() => ({ defaultValue: 10, entries: [10] }), []);
  const baseQuery: GetUsersQueryVariables = useMemo(
    () => ({
      limit: entriesPerPage.defaultValue,
      offset: 0,
    }),
    []
  );

  const [query, { data: bookingResult }] = useGetReservationsLazyQuery({
    variables: {
      ...baseQuery,
    },
  });

  const totalCount = useMemo(() => {
    if (!bookingResult?.reservations_aggregate) return 0;

    return bookingResult.reservations_aggregate.aggregate.count;
  }, [bookingResult?.reservations_aggregate]);

  const getStatusColor = useCallback((status: string) => {
    return {
      PENDING: "warning",
      CONFIRMED: "info",
      COMPLETED: "success",
    }[status];
  }, []);

  const confirmReleaseFunds = (e: Reservations) => {
    dialog.open({
      title: "Confirm fund release to coach?",
      body: (
        <MDBox>
          <MDBox display="flex" py={1} pr={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Name: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{e.coach.full_name}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Amount: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;${e.amount}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Status: &nbsp;
            </MDTypography>
            <MDBadge
              variant="contained"
              size="xs"
              badgeContent={e.reservation_status}
              container
              color={getStatusColor(e.reservation_status)}
              fontWeight="medium"
            />
          </MDBox>
        </MDBox>
      ),
      actions: [
        {
          label: "Annuler", // Annuler
          onClick: () => dialog.close(),
          type: "CANCEL",
        },
        {
          label: "Confirmer", // Confirm
          onClick: () => releaseFunds({ variables: { input: { reservation_id: e.id } } }),
          disabled: loading,
          type: "CONFIRM",
        },
      ],
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        Cell: ({ value }: any) => (
          <MDTypography component="b" variant="title">
            #{value}
          </MDTypography>
        ),
      },
      {
        Header: "Utilisateur",
        accessor: "user",
        Cell: ({ value }: any) => (
          <MDTypography
            component={Link}
            to={`/dashboard/users/${value.id}`}
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
          >
            {value.full_name}
          </MDTypography>
        ),
      },
      {
        Header: "Coach",
        accessor: "coach",
        Cell: ({ value }: any) => (
          <MDTypography
            component={Link}
            to={`/dashboard/coaches/${value.id}`}
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
          >
            {value.full_name}
          </MDTypography>
        ),
      },
      {
        Header: "Créneaux",
        accessor: "time_slots",
        Cell: ({ value = [] }: any) => (
          <Grid container spacing={1}>
            {value.map((v: any) => (
              <Grid item sm={6}>
                <MDBadge
                  variant="contained"
                  size="xs"
                  badgeContent={`${dayjs(v.start_time).format("ll")} ${dayjs(v.start_time).format(
                    "LT"
                  )} -> ${dayjs(v.end_time).format("LT")}`}
                  container
                  color={"primary"}
                  fontWeight="medium"
                />
              </Grid>
            ))}
          </Grid>
        ),
      },
      { Header: "Montant total", accessor: "amount" },
      {
        Header: "Statut",
        accessor: "reservation_status",
        Cell: ({ value }: any) => (
          <MDBadge
            variant="contained"
            size="xs"
            badgeContent={value}
            container
            color={getStatusColor(value)}
            fontWeight="medium"
          />
        ),
      },
      {
        Header: "Créé le",
        accessor: "created_at",
        Cell: ({ value }: any) => <span>{dayjs(value).format("lll")}</span>,
      },
      {
        Header: "Libération des fonds",
        accessor: "reservation_payments",
        Cell: (e: any) => {
          const reservations = e.cell.row.original as Reservations;

          const isUserPaid = reservations.reservation_payments.some(
            (rp) => rp.payer_id == reservations.user_id && rp.payment_status === "SUCCEEDED"
          );

          if (!isUserPaid) {
            return (
              <MDBadge
                variant="contained"
                size="xs"
                badgeContent="AWAITING PAYMENT FROM USER"
                container
                color={"warning"}
                fontWeight="medium"
              />
            );
          }

          if (reservations.reservation_status === "COMPLETED") {
            return (
              <MDBadge
                variant="contained"
                size="xs"
                badgeContent="FUNDS RELEASED"
                container
                color={"success"}
                fontWeight="medium"
              />
            );
          }

          return (
            <MDButton
              onClick={() => confirmReleaseFunds(e.row.original)}
              variant="contained"
              size="small"
              color="info"
            >
              Libération des fonds
            </MDButton>
          );
        },
      },
    ],
    []
  );

  const data = useMemo<any>(() => bookingResult?.reservations || [], [bookingResult]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    pageCount,
  } = useTable(
    {
      autoResetPage: false,
      autoResetExpanded: false,
      columns,
      data,
      manualPagination: true,
      pageCount: Math.ceil(totalCount / entriesPerPage.defaultValue),
      initialState: { pageIndex: 0, pageSize: entriesPerPage.defaultValue },
    },
    useSortBy,
    usePagination
  );

  const fetchData = useCallback((options: { limit: number; offset: number }) => {
    const { limit, offset } = options;

    query({
      variables: {
        ...baseQuery,
        limit,
        offset,
      },
    });
  }, []);

  useEffect(() => {
    fetchData({
      limit: pageSize,
      offset: pageIndex * pageSize,
    });
  }, [pageIndex]);

  const handleGotoPage = (currentPage: number) => {
    gotoPage(currentPage);
  };

  const handleInputPagination = ({ target: { value } }: any) => {
    const pageTarget = value > pageOptions.length || value < 0 ? 0 : Number(value);
    handleGotoPage(pageTarget);
  };

  const customizedPageOptions = pageOptions.map((option: any) => option + 1);

  const handleInputPaginationValue = ({ target: value }: any) =>
    handleGotoPage(Number(value.value - 1));

  const renderPagination = useMemo(() => {
    return pageOptions.map((option: any) => (
      <MDPagination
        item
        key={option}
        onClick={() => handleGotoPage(Number(option))}
        active={pageIndex === option}
      >
        {option + 1}
      </MDPagination>
    ));
  }, [pageOptions, pageIndex]);

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        {entriesPerPage && (
          <MDBox display="flex" alignItems="center">
            <Autocomplete
              disableClearable
              value={pageSize.toString()}
              options={entriesPerPage.entries.map((e) => e.toString())}
              onChange={(event, newValue) => {
                setPageSize(parseInt(newValue, 10));
                fetchData({
                  limit: parseInt(newValue, 10),
                  offset: 0,
                });
              }}
              size="small"
              sx={{ width: "5rem" }}
              renderInput={(params) => <MDInput {...params} />}
            />
            <MDTypography variant="caption" color="secondary">
              &nbsp;&nbsp;Entrées par page
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup: any, key: any) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, key: any) => (
                <DataTableHeadCell
                  key={key}
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row: any, key: any) => {
            prepareRow(row);
            return (
              <TableRow key={key} {...row.getRowProps()}>
                {row.cells.map((cell: any, key: any) => (
                  <DataTableBodyCell
                    key={key}
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Page {pageIndex + 1} sur {pageCount}
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination
                item
                onClick={() => {
                  previousPage();
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(event: any) => {
                    handleInputPagination(event);
                    handleInputPaginationValue(event);
                  }}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination
                item
                onClick={() => {
                  nextPage();
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

// Declaring default props for DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: ["5", "10", "15", "20", "25"] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

export default DataTable;
