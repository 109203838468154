import { default as dayjsLib } from "dayjs";

import utc from "dayjs/plugin/utc";
import dayJSTimezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isToday from "dayjs/plugin/isToday";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isBetween from "dayjs/plugin/isBetween";
import customParseFormat from "dayjs/plugin/customParseFormat";
import minMax from "dayjs/plugin/minMax";
import duration from "dayjs/plugin/duration";

dayjsLib.extend(utc);
dayjsLib.extend(dayJSTimezone);
dayjsLib.extend(advancedFormat);
dayjsLib.extend(isToday);
dayjsLib.extend(isSameOrAfter);
dayjsLib.extend(isSameOrBefore);
dayjsLib.extend(isBetween);
dayjsLib.extend(customParseFormat);
dayjsLib.extend(customParseFormat);
dayjsLib.extend(minMax);
dayjsLib.extend(duration);

export const dayjs = dayjsLib;
