import React from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import CloseIcon from "@mui/icons-material/Close";

import { IDialogProps } from "./index";
import { DialogActions } from "@mui/material";
import MDButton from "components/MDButton";

const DialogNormal = (props: IDialogProps) => {
  const getColorButton = (type: "CANCEL" | "CONFIRM"): any => {
    return {
      CANCEL: "secondary",
      CONFIRM: "primary",
    }[type];
  };

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {props.title && <DialogTitle variant="h5">{props.title}</DialogTitle>}
      <IconButton sx={{ position: "absolute", top: "10px", right: "10px" }} onClick={props.onClose}>
        <CloseIcon color="secondary" fontSize="medium" />
      </IconButton>
      <Box sx={{ padding: "10px" }}>
        <DialogContent dividers sx={{ paddingBottom: "40px" }}>
          {props.body}
        </DialogContent>
        {props.actions && (
          <DialogActions>
            {props.actions.map((a) => (
              <MDButton
                variant="contained"
                size="small"
                disabled={a.disabled}
                color={getColorButton(a.type)}
                onClick={a.onClick}
              >
                {a.label}
              </MDButton>
            ))}
          </DialogActions>
        )}
      </Box>
    </Box>
  );
};

export default DialogNormal;
