import React from "react";
import { useJWTAuth } from "context/Auth";
import { Navigate, Outlet } from "react-router-dom";

const AuthGuard = () => {
  const { user } = useJWTAuth();

  return user ? <Outlet /> : <Navigate to={"/login"} replace />;
};

export default AuthGuard;
