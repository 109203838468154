/**
=========================================================
* Profiler Connextion - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { useCallback, useMemo } from "react";
import { dayjs } from "utils";
import { Dayjs } from "dayjs";
import CloseIcon from "@mui/icons-material/Close";

// Profiler Connextion components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Profiler Connextion examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Calendar from "./Calendar";
import { DateSelectArg, EventClickArg, EventContentArg } from "@fullcalendar/core";
import { useDialog } from "hooks/useDialog";

import AvailabilityConfirmation from "./AvailabilityConfirmation";
import {
  useDeleteAvailabilityTimeSlotMutation,
  useGetAvailabilityTimeSlotsByCoachQuery,
  useGetUserByIdQuery,
  useInsertAvailabilityTimeSlotMutation,
} from "services/api";
import { useNavigate, useParams } from "react-router-dom";
import MDProgress from "components/MDProgress";
import MDButton from "components/MDButton";

function AvailabilityTimeSlot(): JSX.Element {
  const dialog = useDialog();
  const navigate = useNavigate();
  const { coach_id: coachId } = useParams();

  const [insertAvailabilityTimeSlot, { loading }] = useInsertAvailabilityTimeSlotMutation();
  const [deleteAvailabilityTimeSlot, { loading: deleteLoading }] =
    useDeleteAvailabilityTimeSlotMutation();
  const { data, loading: userLoading } = useGetUserByIdQuery({
    variables: {
      id: Number(coachId),
    },
  });
  const { data: availabilityData, refetch } = useGetAvailabilityTimeSlotsByCoachQuery({
    variables: { coach_id: Number(coachId) },
  });

  const handleConfirm = async (start: Dayjs, end: Dayjs) => {
    const startTime = dayjs.utc(start).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    const endTime = dayjs.utc(end).format("YYYY-MM-DDTHH:mm:ss.SSSZ");

    await insertAvailabilityTimeSlot({
      variables: {
        input: {
          start_time: startTime,
          end_time: endTime,
          coach_id: Number(coachId),
        },
      },
    });

    await refetch();
    dialog.close();
  };

  const handleSelect = (e: DateSelectArg) => {
    dialog.open({
      body: (
        <AvailabilityConfirmation
          startTime={dayjs(e.start)}
          endTime={dayjs(e.end)}
          onConfirm={handleConfirm}
          loading={loading}
        />
      ),
      title: "Availability time confirmation",
    });
  };

  const availabilities = useMemo(() => {
    return availabilityData?.availability_time_slots || [];
  }, [availabilityData]);

  const handleDeleteEvent = async (id: number) => {
    await deleteAvailabilityTimeSlot({ variables: { id } });
    dialog.close();
    refetch();
  };

  const onConfirmDelete = (id: number) => {
    dialog.open({
      body: (
        <MDBox width="100%" display="flex" justifyContent="flex-end" mt={3}>
          <MDButton
            color="warning"
            disabled={deleteLoading}
            sx={{ marginRight: "10px" }}
            onClick={() => dialog.close()}
          >
            Annuler
          </MDButton>
          <MDButton color="dark" disabled={deleteLoading} onClick={() => handleDeleteEvent(id)}>
            Delete
          </MDButton>
        </MDBox>
      ),
      title: "Availability delete confirmation",
    });
  };

  const eventClick = (arg: EventClickArg) => {
    dialog.open({
      body: (
        <MDBox>
          <MDBox>
            <MDTypography>ID: #{arg.event.id}</MDTypography>
          </MDBox>
          <MDBox mt={2}>
            <MDTypography>Start: </MDTypography>
            <MDTypography ml={1} variant="body2">
              {dayjs(arg.event.start).format("YYYY-MM-DDTHH:mm:ss")}
            </MDTypography>
          </MDBox>
          <MDBox mt={2}>
            <MDTypography>End: </MDTypography>
            <MDTypography ml={1} variant="body2">
              {dayjs(arg.event.end).format("YYYY-MM-DDTHH:mm:ss")}
            </MDTypography>
          </MDBox>
          <MDBox width="100%" display="flex" justifyContent="flex-end" mt={3}>
            <MDButton
              color="dark"
              disabled={deleteLoading}
              onClick={() => onConfirmDelete(Number(arg.event.id))}
              fullWidth
            >
              Delete event
            </MDButton>
          </MDBox>
        </MDBox>
      ),
      title: "Availability information",
    });
  };

  const events = useMemo(() => {
    return availabilities.map((availability) => ({
      title: `Availability ID #${availability.id}`,
      start: dayjs(availability.start_time).format("YYYY-MM-DDTHH:mm:ss"),
      end: dayjs(availability.end_time).format("YYYY-MM-DDTHH:mm:ss"),
      className: "info",
      id: availability.id,
    }));
  }, [availabilities]);

  if (userLoading) {
    return <MDProgress value={100} />;
  }

  if (!data.users_by_pk || data.users_by_pk.role !== "COACH") {
    navigate("/dashboard/coaches");
    return null;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <MDBox mb={2}>
          <Card>
            <MDBox mx={2} p={3} mb={1}>
              <MDTypography variant="h5" fontWeight="medium" mt={1}>
                {data.users_by_pk.full_name}
              </MDTypography>
              <MDTypography variant="body2" mt={1}>
                Coach
              </MDTypography>
            </MDBox>
          </Card>
        </MDBox>
        <Calendar handleSelect={handleSelect} eventClick={eventClick} events={events} />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AvailabilityTimeSlot;
