import React from "react";

import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import DialogFullScreen from "./DialogFullScreen";
import DialogNormal from "./DialogNormal";

export interface IDialogProps {
  open?: boolean;
  fullScreen?: boolean;
  onClose?: () => void;
  title?: string;
  body?: React.ReactElement | string;
  actions?: {
    type: "CANCEL" | "CONFIRM";
    label: string;
    onClick: () => void;
    disabled?: boolean;
  }[];
}

const Transition = React.forwardRef(function Transition(props: any, ref) {
  return (
    <Slide direction="up" ref={ref} {...props} children={props.children as React.ReactElement} />
  );
});

const DialogCommon = (props: IDialogProps) => {
  const style: React.CSSProperties = {
    minWidth: "550px",
  };

  return (
    <Dialog
      fullScreen={props.fullScreen}
      scroll="paper"
      open={props.open}
      onClose={props.onClose}
      disableEnforceFocus
      TransitionComponent={Transition as any}
      PaperProps={{
        style: style,
      }}
    >
      {props.fullScreen ? <DialogFullScreen {...props} /> : <DialogNormal {...props} />}
    </Dialog>
  );
};

export default DialogCommon;
