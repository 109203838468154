import { useMemo, useEffect, useState, useCallback, Fragment } from "react";
import { useTable, usePagination, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 PRO React TS examples components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {
  Attachment_Tests,
  Matric_Question_Responses,
  Translations,
  useAddAttachentTestResponseMutation,
  useGetAttachmentTestResponsesByQuestionIdLazyQuery,
  useUpdateAttachmentTestResponseMutation,
  useUpdateTranslationMutation,
} from "services/api";
import { Delete, Edit } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import Icon from "@mui/material/Icon";

// Declaring props types for DataTable
interface Props {
  question: Attachment_Tests;
  entriesPerPage?: {
    defaultValue: number;
    entries: number[];
  };
  canSearch?: boolean;
  showTotalEntries?: boolean;
  pagination?: {
    variant: "contained" | "gradient";
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  };
  isSorted?: boolean;
  noEndBorder?: boolean;
  pageCount?: number;
  fetchData?: (options: { limit: number; offset: number }) => void;
}

function DataTable({
  question,
  showTotalEntries,
  pagination,
  isSorted,
  noEndBorder,
}: Props): JSX.Element {
  const entriesPerPage = useMemo(() => ({ defaultValue: 10, entries: [10] }), []);
  const [editContent, setEditContent] = useState("");
  const [translation, setTranslation] = useState<Translations>(null);

  const [editPoint, setEditPoint] = useState("");
  const [response, setResponse] = useState<Matric_Question_Responses>(null);

  const [updateTranslation] = useUpdateTranslationMutation();
  const [updateResponse] = useUpdateAttachmentTestResponseMutation();
  const [addQuestionResponse] = useAddAttachentTestResponseMutation();

  const [inputContentEn, setInputContentEn] = useState("");
  const [inputContentFr, setInputContentFr] = useState("");
  const [inputPoint, setInputPoint] = useState("");

  useEffect(() => {
    translation && setEditContent(translation.translation);
  }, [translation]);

  useEffect(() => {
    response && setEditPoint(response.point);
  }, [response]);

  const [query, { data: dataResopnse }] = useGetAttachmentTestResponsesByQuestionIdLazyQuery({
    variables: {
      where: { attachment_test_id: { _eq: question.id } },
    },
    fetchPolicy: "no-cache",
  });

  const totalCount = useMemo(() => {
    return dataResopnse?.attachment_test_responses_aggregate?.aggregate?.count || 0;
  }, [dataResopnse]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        Cell: (props: any) => {
          const { original } = props.cell.row;
          return <span>{original?.id}</span>;
        },
      },
      {
        Header: "Français",
        Cell: (props: any) => {
          const { original } = props.cell.row;
          const translation = original?.content?.translations?.find(
            (e: any) => e.language_code === "fr"
          );
          return (
            <Fragment>
              <span>{translation.translation?.slice(0, 60) || ""}</span>
              <IconButton onClick={() => handleShowUpdateTranslation(translation)}>
                <Edit />
              </IconButton>
            </Fragment>
          );
        },
      },
      {
        Header: "Anglais",
        Cell: (props: any) => {
          const { original } = props.cell.row;
          const translation = original?.content?.translations?.find(
            (e: any) => e.language_code === "en"
          );
          return (
            <Fragment>
              <span>{translation.translation?.slice(0, 60) || ""}</span>
              <IconButton onClick={() => handleShowUpdateTranslation(translation)}>
                <Edit />
              </IconButton>
            </Fragment>
          );
        },
      },
      {
        Header: "Indiquer",
        Cell: (props: any) => {
          const { original } = props.cell.row;
          return (
            <Fragment>
              <span>{original?.point}</span>
              <IconButton onClick={() => handleShowUpdatePoint(original)}>
                <Edit />
              </IconButton>
            </Fragment>
          );
        },
      },
      {
        Header: "Actions",
        Cell: (props: any) => {
          const { original } = props.cell.row;
          return (
            <IconButton disabled={true} onClick={() => handleRemoveComment(original)}>
              <Delete />
            </IconButton>
          );
        },
      },
    ],
    []
  );

  const data = useMemo<any>(() => dataResopnse?.attachment_test_responses || [], [dataResopnse]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    pageCount,
  } = useTable(
    {
      autoResetPage: false,
      autoResetExpanded: false,
      columns,
      data,
      manualPagination: true,
      pageCount: Math.ceil(totalCount / entriesPerPage.defaultValue),
      initialState: { pageIndex: 0, pageSize: entriesPerPage.defaultValue },
    },
    useSortBy,
    usePagination
  );

  const fetchData = useCallback(() => {
    question &&
      query({
        variables: {
          where: { attachment_test_id: { _eq: question.id } },
        },
        fetchPolicy: "no-cache",
      });
  }, [question]);

  useEffect(() => {
    !translation && fetchData();
  }, [translation]);

  useEffect(() => {
    query({
      variables: {
        where: { attachment_test_id: { _eq: question.id } },
      },
      fetchPolicy: "no-cache",
    });
  }, []);

  const handleGotoPage = (currentPage: number) => {
    gotoPage(currentPage);
  };

  const handleInputPagination = ({ target: { value } }: any) => {
    const pageTarget = value > pageOptions.length || value < 0 ? 0 : Number(value);
    handleGotoPage(pageTarget);
  };

  const customizedPageOptions = pageOptions.map((option: any) => option + 1);

  const handleInputPaginationValue = ({ target: value }: any) =>
    handleGotoPage(Number(value.value - 1));

  const renderPagination = useMemo(() => {
    return pageOptions.map((option: any) => (
      <MDPagination
        item
        key={option}
        onClick={() => handleGotoPage(Number(option))}
        active={pageIndex === option}
      >
        {option + 1}
      </MDPagination>
    ));
  }, [pageOptions, pageIndex]);

  const handleShowUpdateTranslation = (translation: Translations) => {
    setTranslation(translation);
  };

  const handleShowUpdatePoint = (response: Matric_Question_Responses) => {
    setResponse(response);
  };

  const handleRemoveComment = async (response: Matric_Question_Responses) => {
    await updateResponse({
      variables: {
        id: response.id,
        changes: {
          deleted_at: new Date(),
        },
      },
    });
    fetchData();
  };

  const handleCloseUpdateTranslation = () => {
    setTranslation(null);
  };

  const handleCloseUpdatePoint = () => {
    setResponse(null);
  };

  const handleUpdateTranslation = async () => {
    await updateTranslation({
      variables: {
        id: translation.id,
        translation: editContent,
      },
    });
    fetchData();
    handleCloseUpdateTranslation();
  };

  const handleUpdatePoint = async () => {
    await updateResponse({
      variables: {
        id: response.id,
        changes: {
          point: +editPoint || 0,
        },
      },
    });
    fetchData();
    handleCloseUpdatePoint();
  };

  const handleSubmit = async () => {
    await addQuestionResponse({
      variables: {
        attachment_test_id: question.id,
        contentEn: inputContentEn,
        contentFr: inputContentFr,
        point: inputPoint,
      },
    });
    setInputContentEn("");
    setInputContentFr("");
    fetchData();
  };

  return (
    <Fragment>
      <Fragment>
        <form style={{ padding: "5px" }}>
          <TextField
            label="Français"
            variant="outlined"
            fullWidth
            value={inputContentFr}
            onChange={(e) => setInputContentFr(e.target.value)}
          />
          <br />
          <TextField
            label="Anglaise"
            variant="outlined"
            fullWidth
            value={inputContentEn}
            onChange={(e) => setInputContentEn(e.target.value)}
          />
          <br />
          <TextField
            label="Indiquer"
            variant="outlined"
            type="number"
            onChange={(e) => setInputPoint(e.target.value)}
          />
          <Button onClick={handleSubmit} color="primary">
            Ajouter une réponse
          </Button>
        </form>
      </Fragment>
      <TableContainer sx={{ boxShadow: "none" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entriesPerPage.entries.map((e) => e.toString())}
                onChange={(event, newValue) => {
                  setPageSize(parseInt(newValue, 10));
                  fetchData();
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;Entrées par page
              </MDTypography>
            </MDBox>
          )}
        </MDBox>

        <Table {...getTableProps()}>
          <MDBox component="thead">
            {headerGroups.map((headerGroup: any, key: any) => (
              <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, key: any) => (
                  <DataTableHeadCell
                    key={key}
                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </MDBox>
          <TableBody {...getTableBodyProps()}>
            {page.map((row: any, key: any) => {
              prepareRow(row);
              return (
                <TableRow key={key} {...row.getRowProps()}>
                  {row.cells.map((cell: any, key: any) => (
                    <DataTableBodyCell
                      key={key}
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <MDBox
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
        >
          {showTotalEntries && (
            <MDBox mb={{ xs: 3, sm: 0 }}>
              <MDTypography variant="button" color="secondary" fontWeight="regular">
                Page {pageIndex + 1} sur {pageCount}
              </MDTypography>
            </MDBox>
          )}
          {pageOptions.length > 1 && (
            <MDPagination
              variant={pagination.variant ? pagination.variant : "gradient"}
              color={pagination.color ? pagination.color : "info"}
            >
              {canPreviousPage && (
                <MDPagination
                  item
                  onClick={() => {
                    previousPage();
                  }}
                >
                  <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                </MDPagination>
              )}
              {renderPagination.length > 6 ? (
                <MDBox width="5rem" mx={1}>
                  <MDInput
                    inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                    value={customizedPageOptions[pageIndex]}
                    onChange={(event: any) => {
                      handleInputPagination(event);
                      handleInputPaginationValue(event);
                    }}
                  />
                </MDBox>
              ) : (
                renderPagination
              )}
              {canNextPage && (
                <MDPagination
                  item
                  onClick={() => {
                    nextPage();
                  }}
                >
                  <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                </MDPagination>
              )}
            </MDPagination>
          )}
        </MDBox>
        {translation && (
          <Dialog open={translation != null} onClose={handleCloseUpdateTranslation}>
            <DialogTitle>Modifier le contenu</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="content"
                type="text"
                defaultValue={translation?.translation}
                value={editContent}
                onChange={(e: any) => setEditContent(e.target.value)}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleUpdateTranslation} color="primary" type="submit">
                Modifier le contenu
              </Button>
              <Button onClick={handleCloseUpdateTranslation} color="primary">
                Annuler
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {response && (
          <Dialog open={response != null} onClose={handleCloseUpdatePoint}>
            <DialogTitle>Edit Indiquer</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="point"
                type="text"
                defaultValue={response?.point?.toString()}
                value={editPoint}
                onChange={(e: any) => setEditPoint(e.target.value)}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleUpdatePoint} color="primary" type="submit">
                Modifier le contenu
              </Button>
              <Button onClick={handleCloseUpdatePoint} color="primary">
                Annuler
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </TableContainer>
    </Fragment>
  );
}

// Declaring default props for DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: ["5", "10", "15", "20", "25"] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

export default DataTable;
