import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useAddAvatarTestMutation } from "services/api";

export default function Form({ open, onClose }: any) {
  const [addQuestion] = useAddAvatarTestMutation();

  const [selectedCategory, setSelectedCategory] = useState("AVATAR");
  const [contentEn, setContentEn] = useState("");
  const [contentFr, setContentFr] = useState("");

  const handleSubmit = async () => {
    await addQuestion({
      variables: {
        question_category: selectedCategory,
        contentEn: contentEn,
        contentFr: contentFr,
      },
    });
    setContentEn("");
    setContentFr("");
    onClose();
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Add Question</DialogTitle>

      <DialogContent>
        <form style={{ padding: "5px" }}>
          <Select
            defaultValue={"AVATAR"}
            fullWidth
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            {["AVATAR", "TARGET_AVATAR"].map((category) => (
              <MenuItem value={category}>{category}</MenuItem>
            ))}
          </Select>
          <br />
          <br />
          <TextField
            label="Anglaise"
            variant="outlined"
            fullWidth
            value={contentEn}
            onChange={(e) => setContentEn(e.target.value)}
          />
          <br />
          <br />
          <TextField
            label="Français"
            variant="outlined"
            fullWidth
            value={contentFr}
            onChange={(e) => setContentFr(e.target.value)}
          />
          <br />
          <br />
        </form>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
        <Button onClick={() => onClose()} color="primary">
          Fermerr
        </Button>
      </DialogActions>
    </Dialog>
  );
}
