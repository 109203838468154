import { useMemo, useEffect, useState, useCallback, Fragment } from "react";
import { useTable, usePagination, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 PRO React TS examples components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {
  Attachment_Tests,
  GetAttachmentTestsQueryVariables,
  Translations,
  useGetAttachmentTestCategoriesQuery,
  useGetAttachmentTestsLazyQuery,
  useUpdateAttachmentTestByIdMutation,
  useUpdateTranslationMutation,
} from "services/api";
import { Delete, Edit, List } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import AnswersTable from "../AnswersTable";

// Declaring props types for DataTable
interface Props {
  entriesPerPage?: {
    defaultValue: number;
    entries: number[];
  };
  canSearch?: boolean;
  showTotalEntries?: boolean;
  pagination?: {
    variant: "contained" | "gradient";
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  };
  isSorted?: boolean;
  noEndBorder?: boolean;
  pageCount?: number;
  fetchData?: (options: { limit: number; offset: number }) => void;
}

function DataTable({ showTotalEntries, pagination, isSorted, noEndBorder }: Props): JSX.Element {
  const entriesPerPage = useMemo(() => ({ defaultValue: 10, entries: [10] }), []);
  const [editContent, setEditContent] = useState("");
  const [translation, setTranslation] = useState<Translations>(null);
  const [question, setQuestion] = useState<Attachment_Tests>(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [questionIdToUpdate, setQuestionIdToUpdate] = useState<number>(null);

  const [updateTranslation] = useUpdateTranslationMutation();
  const [updateQuestion] = useUpdateAttachmentTestByIdMutation();

  const { data: attachmentTestCategoriesResponse } = useGetAttachmentTestCategoriesQuery();

  const attachmentTestCategories = useMemo(
    () => attachmentTestCategoriesResponse?.attachment_test_categories || [],
    [attachmentTestCategoriesResponse]
  );

  useEffect(() => {
    translation && setEditContent(translation.translation);
  }, [translation]);

  const baseQuery: GetAttachmentTestsQueryVariables = useMemo(
    () => ({
      limit: entriesPerPage.defaultValue,
      offset: 0,
      where: {},
      order_by: {
        id: "asc",
      },
    }),
    []
  );

  const [query, { data: questionnaireResponses }] = useGetAttachmentTestsLazyQuery({
    variables: {
      ...baseQuery,
    },
    fetchPolicy: "no-cache",
  });

  const totalCount = useMemo(() => {
    return questionnaireResponses?.attachment_tests_aggregate?.aggregate?.count || 0;
  }, [questionnaireResponses]);

  const handleClickUpdateCategory = (questionId: number) => {
    setQuestionIdToUpdate(questionId);
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        Cell: (props: any) => {
          const { original } = props.cell.row;
          return <span>{original?.id}</span>;
        },
      },
      {
        Header: "Catégorie",
        Cell: (props: any) => {
          const { original } = props.cell.row;
          const translation = original?.category?.name?.translations?.find(
            (e: any) => e.language_code === "en"
          );

          return (
            <Fragment>
              <span>{translation?.translation?.slice(0, 50) || ""}</span>
              <IconButton onClick={() => handleClickUpdateCategory(original.id)}>
                <Edit />
              </IconButton>
            </Fragment>
          );
        },
      },
      {
        Header: "Français",
        Cell: (props: any) => {
          const { original } = props.cell.row;
          const translation = original?.content?.translations?.find(
            (e: any) => e.language_code === "fr"
          );
          return (
            <Fragment>
              <span>{translation.translation?.slice(0, 50) || ""}</span>
              <IconButton onClick={() => handleClicktranslation(translation)}>
                <Edit />
              </IconButton>
            </Fragment>
          );
        },
      },
      {
        Header: "Anglais",
        Cell: (props: any) => {
          const { original } = props.cell.row;
          const translation = original?.content?.translations?.find(
            (e: any) => e.language_code === "en"
          );
          return (
            <Fragment>
              <span>{translation.translation?.slice(0, 50) || ""}</span>
              <IconButton onClick={() => handleClicktranslation(translation)}>
                <Edit />
              </IconButton>
            </Fragment>
          );
        },
      },
      {
        Header: "Réponses",
        Cell: (props: any) => {
          const { original } = props.cell.row;
          return (
            <Fragment>
              <IconButton onClick={() => handleShowAnswers(original)}>
                <List />
              </IconButton>
              <IconButton disabled={true} onClick={() => handleDeleteQuestion(original)}>
                <Delete />
              </IconButton>
            </Fragment>
          );
        },
      },
    ],
    []
  );

  const data = useMemo<any>(
    () => questionnaireResponses?.attachment_tests || [],
    [questionnaireResponses]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    pageCount,
  } = useTable(
    {
      autoResetPage: false,
      autoResetExpanded: false,
      columns,
      data,
      manualPagination: true,
      pageCount: Math.ceil(totalCount / entriesPerPage.defaultValue),
      initialState: { pageIndex: 0, pageSize: entriesPerPage.defaultValue },
    },
    useSortBy,
    usePagination
  );

  const fetchData = useCallback((options: { limit: number; offset: number }) => {
    const { limit, offset } = options;
    query({
      variables: {
        ...baseQuery,
        limit,
        offset,
      },
    });
  }, []);

  useEffect(() => {
    !translation &&
      fetchData({
        limit: pageSize,
        offset: pageIndex * pageSize,
      });
  }, [pageIndex, translation]);

  const handleGotoPage = (currentPage: number) => {
    gotoPage(currentPage);
  };

  const handleInputPagination = ({ target: { value } }: any) => {
    const pageTarget = value > pageOptions.length || value < 0 ? 0 : Number(value);
    handleGotoPage(pageTarget);
  };

  const customizedPageOptions = pageOptions.map((option: any) => option + 1);

  const handleInputPaginationValue = ({ target: value }: any) =>
    handleGotoPage(Number(value.value - 1));

  const renderPagination = useMemo(() => {
    return pageOptions.map((option: any) => (
      <MDPagination
        item
        key={option}
        onClick={() => handleGotoPage(Number(option))}
        active={pageIndex === option}
      >
        {option + 1}
      </MDPagination>
    ));
  }, [pageOptions, pageIndex]);

  const handleClicktranslation = (translation: Translations) => {
    setTranslation(translation);
  };

  const handleShowAnswers = (question: Attachment_Tests) => {
    setQuestion(question);
  };

  const handleDeleteQuestion = async (question: Attachment_Tests) => {
    await updateQuestion({
      variables: {
        id: question.id,
        changes: { deleted_at: new Date() },
      },
    });
    fetchData({
      limit: 10,
      offset: pageIndex * pageSize,
    });
  };

  const handleClose = () => {
    setTranslation(null);
  };

  const handleCloseAnswers = () => {
    setQuestion(null);
  };

  const handleUpdate = async () => {
    await updateTranslation({
      variables: {
        id: translation.id,
        translation: editContent,
      },
    });
    fetchData({
      limit: 10,
      offset: pageIndex * pageSize,
    });
    handleClose();
  };

  return (
    <Fragment>
      <TableContainer sx={{ boxShadow: "none" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entriesPerPage.entries.map((e) => e.toString())}
                onChange={(_event, newValue) => {
                  setPageSize(parseInt(newValue, 10));
                  fetchData({
                    limit: parseInt(newValue, 10),
                    offset: 0,
                  });
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;Entrées par page
              </MDTypography>
            </MDBox>
          )}
        </MDBox>

        <Table {...getTableProps()}>
          <MDBox component="thead">
            {headerGroups.map((headerGroup: any, key: any) => (
              <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, key: any) => (
                  <DataTableHeadCell
                    key={key}
                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </MDBox>
          <TableBody {...getTableBodyProps()}>
            {page.map((row: any, key: any) => {
              prepareRow(row);
              return (
                <TableRow key={key} {...row.getRowProps()}>
                  {row.cells.map((cell: any, key: any) => (
                    <DataTableBodyCell
                      key={key}
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <MDBox
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
        >
          {showTotalEntries && (
            <MDBox mb={{ xs: 3, sm: 0 }}>
              <MDTypography variant="button" color="secondary" fontWeight="regular">
                Page {pageIndex + 1} sur {pageCount}
              </MDTypography>
            </MDBox>
          )}
          {pageOptions.length > 1 && (
            <MDPagination
              variant={pagination.variant ? pagination.variant : "gradient"}
              color={pagination.color ? pagination.color : "info"}
            >
              {canPreviousPage && (
                <MDPagination
                  item
                  onClick={() => {
                    previousPage();
                  }}
                >
                  <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                </MDPagination>
              )}
              {renderPagination.length > 6 ? (
                <MDBox width="5rem" mx={1}>
                  <MDInput
                    inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                    value={customizedPageOptions[pageIndex]}
                    onChange={(event: any) => {
                      handleInputPagination(event);
                      handleInputPaginationValue(event);
                    }}
                  />
                </MDBox>
              ) : (
                renderPagination
              )}
              {canNextPage && (
                <MDPagination
                  item
                  onClick={() => {
                    nextPage();
                  }}
                >
                  <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                </MDPagination>
              )}
            </MDPagination>
          )}
        </MDBox>
        <Dialog open={translation != null} onClose={handleClose}>
          <DialogTitle>Modifier le contenu</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Edit"
              type="text"
              defaultValue={translation?.translation}
              value={editContent}
              onChange={(e: any) => setEditContent(e.target.value)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleUpdate} color="primary" type="submit">
              Modifier le contenu
            </Button>
            <Button onClick={handleClose} color="primary">
              Annuler
            </Button>
          </DialogActions>
        </Dialog>

        {question && (
          <Dialog open={question != null} fullWidth>
            <DialogTitle>Réponses</DialogTitle>
            <AnswersTable question={question} />
            <DialogActions>
              <Button onClick={handleCloseAnswers} color="primary">
                Fermer
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {questionIdToUpdate && (
          <Dialog open={questionIdToUpdate != null} fullWidth>
            <DialogTitle>Catégorie</DialogTitle>
            <DialogContent>
              <Select
                defaultValue={attachmentTestCategories?.[0]?.id}
                fullWidth
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value as number)}
              >
                {attachmentTestCategories.map((result) => (
                  <MenuItem value={result.id}>
                    {result.name?.translations?.[0].translation}
                  </MenuItem>
                ))}
              </Select>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  updateQuestion({
                    variables: {
                      id: questionIdToUpdate,
                      changes: {
                        category_id: selectedCategory,
                      },
                    },
                  }).then(() => {
                    setQuestionIdToUpdate(null);
                    fetchData({
                      limit: pageSize,
                      offset: pageIndex * pageSize,
                    });
                  });
                }}
                color="primary"
                type="submit"
              >
                Modifier le contenu
              </Button>
              <Button
                onClick={() => {
                  setQuestionIdToUpdate(null);
                  fetchData({
                    limit: pageSize,
                    offset: pageIndex * pageSize,
                  });
                }}
                color="primary"
              >
                Fermer
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </TableContainer>
    </Fragment>
  );
}

// Declaring default props for DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: ["5", "10", "15", "20", "25"] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

export default DataTable;
