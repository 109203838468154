import { useContext } from "react";
import { DialogOptions, ModalDialogContext } from "../context/DialogProvider";

export const useDialog = () => {
  const context = useContext(ModalDialogContext);

  return {
    open: (options: DialogOptions) => {
      context.setOpen(true, options);
    },
    close: () => {
      context.onClose();
    },
  };
};
