import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useAddTemplateQuestionMutation } from "services/api";

export default function Form({ open, onClose }: any) {
  const [addQuestion] = useAddTemplateQuestionMutation();

  const [contentEn, setContentEn] = useState("");
  const [contentFr, setContentFr] = useState("");
  const [code, setCode] = useState("");

  const handleSubmit = async () => {
    await addQuestion({
      variables: {
        contentEn: contentEn,
        contentFr: contentFr,
        code: code,
      },
    });
    setContentEn("");
    setContentFr("");
    setCode("");
    onClose();
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Add Question</DialogTitle>
      <DialogContent>
        <form style={{ padding: "5px" }}>
          <TextField
            label="Français"
            variant="outlined"
            fullWidth
            value={contentFr}
            onChange={(e) => setContentFr(e.target.value)}
          />
          <br />
          <br />
          <TextField
            label="Anglaise"
            variant="outlined"
            fullWidth
            value={contentEn}
            onChange={(e) => setContentEn(e.target.value)}
          />
          <br />
          <br />{" "}
          <TextField
            label="Question Code"
            variant="outlined"
            fullWidth
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <br />
          <br />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
        <Button onClick={() => onClose()} color="primary">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
