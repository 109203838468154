import { DateTimePicker } from "@mui/x-date-pickers";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Dayjs } from "dayjs";

interface IProps {
  startTime: Dayjs;
  endTime: Dayjs;
  loading?: boolean;
  onConfirm: (start: Dayjs, end: Dayjs) => void;
}

const AvailabilityConfirmation = (props: IProps) => {
  return (
    <MDBox>
      <MDBox sx={{ display: "flex", flexDirection: "column" }}>
        <MDTypography variant="body">Start time</MDTypography>
        <DateTimePicker disableOpenPicker disabled value={props.startTime} />
      </MDBox>
      <MDBox sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
        <MDTypography variant="body">End time</MDTypography>
        <DateTimePicker disableOpenPicker disabled value={props.endTime} />
      </MDBox>
      <MDBox width="100%" display="flex" justifyContent="flex-end" mt={3}>
        <MDButton
          variant="gradient"
          color="dark"
          disabled={props.loading}
          onClick={() => props.onConfirm(props.startTime, props.endTime)}
        >
          Confirm
        </MDButton>
      </MDBox>
    </MDBox>
  );
};

export default AvailabilityConfirmation;
