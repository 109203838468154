/* eslint-disable indent */
import {
  ApolloClient,
  FetchResult,
  HttpLink,
  InMemoryCache,
  Observable,
  concat,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLError, OperationDefinitionNode } from "graphql";
import { RefreshTokenDocument, RefreshTokenResponse } from "services/api";

const httpLink = new HttpLink({
  uri:
    process.env.REACT_APP_GRAPHQL_MASTER_URL ||
    "https://console.dev.profilerconnexion.com/v1/graphql/",
  fetch,
});

const authLink = setContext((_, { headers = {} }) => {
  const token = localStorage.getItem("token");
  if (token) {
    headers["authorization"] = `Bearer ${token}`;
  }

  return {
    headers: {
      ...headers,
    },
  };
});

const wsLink = new WebSocketLink({
  uri: "wss://console.dev.profilerconnexion.com/v1/graphql",
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => {
      const token = localStorage.getItem("token");
      return {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      };
    },
    connectionCallback: (err: any) => {
      console.log("err_socket: ", err);
      if (err) {
        console.log("Websocket failed");
      } else {
        console.log("Websocket connected");
      }
    },
  },
});

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (operation.operationName === "RefreshToken") {
    return;
  }

  for (const graphQLError of graphQLErrors) {
    const extensions = graphQLError.extensions || {};

    if (extensions?.code !== "access-denied") {
      continue;
    }

    localStorage.removeItem("token");

    const observable = new Observable<FetchResult<Record<string, any>>>((observer) => {
      // used an annonymous function for using an async function
      (async () => {
        try {
          const accessToken = await refreshToken();

          if (!accessToken) {
            throw new GraphQLError("Empty AccessToken");
          }

          // Retry the failed request
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };

          forward(operation).subscribe(subscriber);
        } catch (err) {
          observer.error(err);
        }
      })();
    });

    return observable;
  }
});

// Choose the link to use based on operation
const link = wsLink
  ? split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;

        return kind === "OperationDefinition" && operation === "subscription";
      },
      wsLink,
      httpLink
    )
  : httpLink;

export const cache = new InMemoryCache();

export const client = new ApolloClient({
  ssrMode: typeof window === undefined,
  link: concat(errorLink, concat(authLink, link)),
  cache,
  connectToDevTools: process.env.NODE_ENV !== "production",
});

const refreshToken = async () => {
  const refreshToken = localStorage.getItem("refresh_token");

  const refreshResolverResponse = await client.mutate<{
    refreshToken: RefreshTokenResponse;
  }>({
    mutation: RefreshTokenDocument,

    variables: {
      refreshToken: refreshToken,
    },
  });

  const accessToken = refreshResolverResponse.data?.refreshToken.token;
  localStorage.setItem("token", accessToken);

  return accessToken;
};
