import { useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import FaceIcon from "@mui/icons-material/Face";
import Face3Icon from "@mui/icons-material/Face3";
import ShareLocation from "@mui/icons-material/ShareLocation";
import CategoryIcon from "@mui/icons-material/Category";
import TransgenderIcon from "@mui/icons-material/Transgender";
import DateRangeIcon from "@mui/icons-material/DateRange";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ControllerCard from "examples/Cards/ControllerCard";
import { useGetSettingsLazyQuery, useUpdateSettingByNameMutation } from "services/api";

function MatchSettings(): JSX.Element {
  const [fetchSettings, { data: settingData }] = useGetSettingsLazyQuery({
    variables: {},
    fetchPolicy: "no-cache",
  });
  const [updateSetting] = useUpdateSettingByNameMutation();

  useEffect(() => {
    fetchSettings();
  }, []);

  const {
    disableMatchByCategory,
    disableMatchByAavatar,
    disableMatchByAavatarSecond,
    disableMatchByGender,
    disableMatchByCity,
    disableMatchByAge,
  } = useMemo(() => {
    const settings = settingData?.settings || [];

    return {
      disableMatchByCategory:
        settings.find((s) => s.name === "MATCH_BY_CATEGORY_DISABLED")?.value == "true" || false,
      disableMatchByAavatar:
        settings.find((s) => s.name === "MATCH_BY_AVATAR_DISABLED")?.value == "true" || false,
      disableMatchByAavatarSecond:
        settings.find((s) => s.name === "MATCH_BY_AVATAR_2_DISABLED")?.value == "true" || false,
      disableMatchByGender:
        settings.find((s) => s.name === "MATCH_BY_GENDER_DISABLED")?.value == "true" || false,
      disableMatchByCity:
        settings.find((s) => s.name === "MATCH_BY_CITY_DISABLED")?.value == "true" || false,
      disableMatchByAge:
        settings.find((s) => s.name === "MATCH_BY_AGE_DISABLED")?.value == "true" || false,
    };
  }, [settingData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={2}>
            <ControllerCard
              state={!disableMatchByCategory}
              icon={<CategoryIcon fontSize="large"></CategoryIcon>}
              title="Match By Catgory"
              onChange={async () => {
                await updateSetting({
                  variables: {
                    where: {
                      name: {
                        _eq: "MATCH_BY_CATEGORY_DISABLED",
                      },
                    },
                    object: {
                      value: (!disableMatchByCategory).toString(),
                    },
                  },
                });
                fetchSettings();
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={2}>
            <ControllerCard
              state={!disableMatchByAge}
              icon={<DateRangeIcon fontSize="large"></DateRangeIcon>}
              title="Match By Age"
              onChange={async () => {
                await updateSetting({
                  variables: {
                    where: {
                      name: {
                        _eq: "MATCH_BY_AGE_DISABLED",
                      },
                    },
                    object: {
                      value: (!disableMatchByAge).toString(),
                    },
                  },
                });

                fetchSettings();
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={2}>
            <ControllerCard
              state={!disableMatchByGender}
              icon={<TransgenderIcon fontSize="large"></TransgenderIcon>}
              title="Match By Gender"
              onChange={async () => {
                await updateSetting({
                  variables: {
                    where: {
                      name: {
                        _eq: "MATCH_BY_GENDER_DISABLED",
                      },
                    },
                    object: {
                      value: (!disableMatchByGender).toString(),
                    },
                  },
                });

                fetchSettings();
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={2}>
            <ControllerCard
              state={!disableMatchByAavatar}
              icon={<FaceIcon fontSize="large"></FaceIcon>}
              title="Match By Avatar"
              onChange={async () => {
                await updateSetting({
                  variables: {
                    where: {
                      name: {
                        _eq: "MATCH_BY_AVATAR_DISABLED",
                      },
                    },
                    object: {
                      value: (!disableMatchByAavatar).toString(),
                    },
                  },
                });
                fetchSettings();
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={2}>
            <ControllerCard
              state={!disableMatchByAavatarSecond}
              icon={<Face3Icon fontSize="large"></Face3Icon>}
              title="Match By Avatar Second"
              onChange={async () => {
                await updateSetting({
                  variables: {
                    where: {
                      name: {
                        _eq: "MATCH_BY_AVATAR_2_DISABLED",
                      },
                    },
                    object: {
                      value: (!disableMatchByAavatarSecond).toString(),
                    },
                  },
                });
                fetchSettings();
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={2}>
            <ControllerCard
              state={!disableMatchByCity}
              icon={<ShareLocation fontSize="large"></ShareLocation>}
              title="Match By City"
              onChange={async () => {
                await updateSetting({
                  variables: {
                    where: {
                      name: {
                        _eq: "MATCH_BY_CITY_DISABLED",
                      },
                    },
                    object: {
                      value: (!disableMatchByCity).toString(),
                    },
                  },
                });

                fetchSettings();
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default MatchSettings;
