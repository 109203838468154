import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
{/* prettier-ignore */}
// THIS FILE IS GENERATED WITH `pnpm generate`
import "graphql/language/ast";
import * as Scalar from './scalars';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  availability_status_enum: { input: any; output: any; }
  avatar_test_type: { input: any; output: any; }
  code_enum: { input: any; output: any; }
  date: { input: any; output: any; }
  notification_type: { input: any; output: any; }
  numeric: { input: any; output: any; }
  repeat_interval_enum: { input: any; output: any; }
  reservation_payment_status_enum: { input: any; output: any; }
  reservation_status_enum: { input: any; output: any; }
  slot_status_enum: { input: any; output: any; }
  timestamp: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  user_role: { input: any; output: any; }
  voucher_status_enum: { input: any; output: any; }
};

export type ActiveUserInput = {
  email: Scalars['String']['input'];
  otp_code: Scalars['String']['input'];
};

export type ActiveUserResponse = {
  __typename?: 'ActiveUserResponse';
  is_activated: Scalars['Boolean']['output'];
};

export type ApproveFriendRequestInput = {
  request_id: Scalars['Int']['input'];
};

export type ApproveFriendRequestOutput = {
  __typename?: 'ApproveFriendRequestOutput';
  friend_id: Scalars['Int']['output'];
};

export type Avatar = {
  __typename?: 'Avatar';
  code?: Maybe<Scalars['String']['output']>;
  icon_url?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type BookCoachInput = {
  coach_id: Scalars['Int']['input'];
  slots: Array<InputMaybe<SlotInput>>;
  voucher?: InputMaybe<Scalars['String']['input']>;
};

export type BookCoachResponse = {
  __typename?: 'BookCoachResponse';
  discount_amount: Scalars['Int']['output'];
  final_amount: Scalars['Int']['output'];
  reservation_id: Scalars['Int']['output'];
  slot_ids: Array<Maybe<Scalars['Int']['output']>>;
  total_amount: Scalars['Int']['output'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type Category = {
  __typename?: 'Category';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ChangePasswordInput = {
  old_password: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type ChangePasswordResponse = {
  __typename?: 'ChangePasswordResponse';
  is_changed: Scalars['Boolean']['output'];
};

export type City = {
  __typename?: 'City';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CompleteBookingInput = {
  reservation_id: Scalars['Int']['input'];
};

export type CompleteBookingResponse = {
  __typename?: 'CompleteBookingResponse';
  is_completed: Scalars['Boolean']['output'];
};

export type CreateCoachInput = {
  city_id?: InputMaybe<Scalars['Int']['input']>;
  date_of_birth?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  full_name: Scalars['String']['input'];
  gender_id?: InputMaybe<Scalars['Int']['input']>;
  hourly_rate?: InputMaybe<Scalars['Int']['input']>;
  password: Scalars['String']['input'];
  skills?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CreateCoachResponse = {
  __typename?: 'CreateCoachResponse';
  id: Scalars['Int']['output'];
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  full_name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type CreateUserResponse = {
  __typename?: 'CreateUserResponse';
  id: Scalars['Int']['output'];
  voucher_code?: Maybe<Scalars['String']['output']>;
};

export type DeclineFriendRequestInput = {
  reason?: InputMaybe<Scalars['String']['input']>;
  request_id: Scalars['Int']['input'];
};

export type DeclineFriendRequestOutput = {
  __typename?: 'DeclineFriendRequestOutput';
  success: Scalars['Boolean']['output'];
};

export type FileUpload = {
  base64Str: Scalars['String']['input'];
  name: Scalars['String']['input'];
  prefix_key: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type ForgotPasswordInput = {
  email: Scalars['String']['input'];
  otp_code: Scalars['String']['input'];
};

export type ForgotPasswordResponse = {
  __typename?: 'ForgotPasswordResponse';
  token: Scalars['String']['output'];
};

export type FriendRequest = {
  __typename?: 'FriendRequest';
  id?: Maybe<Scalars['Int']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Gender = {
  __typename?: 'Gender';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type GetStripeAccountLinkInput = {
  user_id: Scalars['Int']['input'];
};

export type GetStripeAccountLinkResponse = {
  __typename?: 'GetStripeAccountLinkResponse';
  link: Scalars['String']['output'];
};

export type GetUploadPreSignedUrlInput = {
  keys: Array<Scalars['String']['input']>;
};

export type GetUploadPreSignedUrlOutput = {
  __typename?: 'GetUploadPreSignedUrlOutput';
  urls: Array<Scalars['String']['output']>;
};

export type InitializeReservationPaymentInput = {
  reservation_id: Scalars['Int']['input'];
};

export type InitializeReservationPaymentResponse = {
  __typename?: 'InitializeReservationPaymentResponse';
  client_secret: Scalars['String']['output'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['Int']['input']>>;
  _eq?: InputMaybe<Array<Scalars['Int']['input']>>;
  _gt?: InputMaybe<Array<Scalars['Int']['input']>>;
  _gte?: InputMaybe<Array<Scalars['Int']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['Int']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['Int']['input']>>;
  _lte?: InputMaybe<Array<Scalars['Int']['input']>>;
  _neq?: InputMaybe<Array<Scalars['Int']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['Int']['input']>>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  refresh_token: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type MatchProfileInput = {
  age_from?: InputMaybe<Scalars['Int']['input']>;
  age_to?: InputMaybe<Scalars['Int']['input']>;
  avatar?: InputMaybe<Scalars['Int']['input']>;
  avatars?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  cities?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  city?: InputMaybe<Scalars['Int']['input']>;
  commitment_index?: InputMaybe<Scalars['Int']['input']>;
  communication_index?: InputMaybe<Scalars['Int']['input']>;
  freedom_index?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['Int']['input']>;
  genders?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sexuality_index?: InputMaybe<Scalars['Int']['input']>;
  tenderness_index?: InputMaybe<Scalars['Int']['input']>;
};

export type MatchProfilesResponse = {
  __typename?: 'MatchProfilesResponse';
  data?: Maybe<Array<Maybe<MatchedProfile>>>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type MatchedProfile = {
  __typename?: 'MatchedProfile';
  personal_suns?: Maybe<Array<Maybe<PersonalSun>>>;
  profile?: Maybe<Profile>;
  request_recieved?: Maybe<FriendRequest>;
  request_sent?: Maybe<FriendRequest>;
  user?: Maybe<ProfileUser>;
};

export type NotificationInput = {
  body: Scalars['String']['input'];
  data_json?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  user_ids: Array<Scalars['Int']['input']>;
};

export type PersonalSun = {
  __typename?: 'PersonalSun';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  values?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type Profile = {
  __typename?: 'Profile';
  ambivalen_point?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Avatar>;
  city?: Maybe<City>;
  commitment_index?: Maybe<Scalars['Int']['output']>;
  communication_index?: Maybe<Scalars['Int']['output']>;
  disorganized_point?: Maybe<Scalars['Int']['output']>;
  final_category?: Maybe<Category>;
  freedom_index?: Maybe<Scalars['Int']['output']>;
  gender?: Maybe<Gender>;
  id: Scalars['Int']['output'];
  independent_point?: Maybe<Scalars['Int']['output']>;
  main_category?: Maybe<Category>;
  sercure_point?: Maybe<Scalars['Int']['output']>;
  sexuality_index?: Maybe<Scalars['Int']['output']>;
  sub_category?: Maybe<Category>;
  target_avatar?: Maybe<Avatar>;
  tenderness_index?: Maybe<Scalars['Int']['output']>;
};

export type ProfileUser = {
  __typename?: 'ProfileUser';
  avatar_url?: Maybe<Scalars['String']['output']>;
  date_of_birth?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type RefreshTokenInput = {
  refresh_token: Scalars['String']['input'];
};

export type RefreshTokenResponse = {
  __typename?: 'RefreshTokenResponse';
  token: Scalars['String']['output'];
};

export type ReleaseFundsInput = {
  reservation_id: Scalars['Int']['input'];
};

export type ReleaseFundsResponse = {
  __typename?: 'ReleaseFundsResponse';
  reservation_id: Scalars['Int']['output'];
};

export type RequestFriendInput = {
  receiver_id: Scalars['Int']['input'];
};

export type RequestFriendOutput = {
  __typename?: 'RequestFriendOutput';
  request_id: Scalars['Int']['output'];
};

export type RequestOtpInput = {
  email: Scalars['String']['input'];
};

export type RequestOtpResponse = {
  __typename?: 'RequestOTPResponse';
  is_sent: Scalars['Boolean']['output'];
};

export type ResetPasswordInput = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  is_reseted: Scalars['Boolean']['output'];
};

export type ReviewReservationInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  rating: Scalars['Int']['input'];
  reservation_id: Scalars['Int']['input'];
};

export type ReviewReservationOutput = {
  __typename?: 'ReviewReservationOutput';
  review_id: Scalars['Int']['output'];
};

export type SendEmailInput = {
  data: Scalars['String']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
  template_id: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type SendEmailResponse = {
  __typename?: 'SendEmailResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type SlotInput = {
  end_time: Scalars['String']['input'];
  start_time: Scalars['String']['input'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']['input']>>;
  _eq?: InputMaybe<Array<Scalars['String']['input']>>;
  _gt?: InputMaybe<Array<Scalars['String']['input']>>;
  _gte?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['String']['input']>>;
  _lte?: InputMaybe<Array<Scalars['String']['input']>>;
  _neq?: InputMaybe<Array<Scalars['String']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateReservationStatusInput = {
  reservation_id: Scalars['Int']['input'];
  status: Scalars['String']['input'];
};

export type UpdateReservationStatusOutput = {
  __typename?: 'UpdateReservationStatusOutput';
  is_success: Scalars['Boolean']['output'];
};

export type UploadFileInput = {
  files: Array<FileUpload>;
};

export type UploadFileOutput = {
  __typename?: 'UploadFileOutput';
  paths: Array<Scalars['String']['output']>;
};

export type UserTemplateQuestionResponse = {
  __typename?: 'UserTemplateQuestionResponse';
  code?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  response?: Maybe<Scalars['String']['output']>;
};

export type VerifyOtpInput = {
  email: Scalars['String']['input'];
  otp_code: Scalars['String']['input'];
};

export type VerifyOtpResponse = {
  __typename?: 'VerifyOTPResponse';
  is_verified: Scalars['Boolean']['output'];
};

/** columns and relationships of "attachment_test_categories" */
export type Attachment_Test_Categories = {
  __typename?: 'attachment_test_categories';
  code?: Maybe<Scalars['code_enum']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  description: Contents;
  description_id: Scalars['Int']['output'];
  icon_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  name?: Maybe<Contents>;
  name_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  profiles: Array<Profiles>;
  /** An aggregate relationship */
  profiles_aggregate: Profiles_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "attachment_test_categories" */
export type Attachment_Test_CategoriesProfilesArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


/** columns and relationships of "attachment_test_categories" */
export type Attachment_Test_CategoriesProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};

/** aggregated selection of "attachment_test_categories" */
export type Attachment_Test_Categories_Aggregate = {
  __typename?: 'attachment_test_categories_aggregate';
  aggregate?: Maybe<Attachment_Test_Categories_Aggregate_Fields>;
  nodes: Array<Attachment_Test_Categories>;
};

/** aggregate fields of "attachment_test_categories" */
export type Attachment_Test_Categories_Aggregate_Fields = {
  __typename?: 'attachment_test_categories_aggregate_fields';
  avg?: Maybe<Attachment_Test_Categories_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Attachment_Test_Categories_Max_Fields>;
  min?: Maybe<Attachment_Test_Categories_Min_Fields>;
  stddev?: Maybe<Attachment_Test_Categories_Stddev_Fields>;
  stddev_pop?: Maybe<Attachment_Test_Categories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Attachment_Test_Categories_Stddev_Samp_Fields>;
  sum?: Maybe<Attachment_Test_Categories_Sum_Fields>;
  var_pop?: Maybe<Attachment_Test_Categories_Var_Pop_Fields>;
  var_samp?: Maybe<Attachment_Test_Categories_Var_Samp_Fields>;
  variance?: Maybe<Attachment_Test_Categories_Variance_Fields>;
};


/** aggregate fields of "attachment_test_categories" */
export type Attachment_Test_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Attachment_Test_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Attachment_Test_Categories_Avg_Fields = {
  __typename?: 'attachment_test_categories_avg_fields';
  description_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "attachment_test_categories". All fields are combined with a logical 'AND'. */
export type Attachment_Test_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Attachment_Test_Categories_Bool_Exp>>;
  _not?: InputMaybe<Attachment_Test_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Attachment_Test_Categories_Bool_Exp>>;
  code?: InputMaybe<Code_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<Contents_Bool_Exp>;
  description_id?: InputMaybe<Int_Comparison_Exp>;
  icon_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<Contents_Bool_Exp>;
  name_id?: InputMaybe<Int_Comparison_Exp>;
  profiles?: InputMaybe<Profiles_Bool_Exp>;
  profiles_aggregate?: InputMaybe<Profiles_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "attachment_test_categories" */
export type Attachment_Test_Categories_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'attachment_test_categories_pkey';

/** input type for incrementing numeric columns in table "attachment_test_categories" */
export type Attachment_Test_Categories_Inc_Input = {
  description_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "attachment_test_categories" */
export type Attachment_Test_Categories_Insert_Input = {
  code?: InputMaybe<Scalars['code_enum']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  description_id?: InputMaybe<Scalars['Int']['input']>;
  icon_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
  profiles?: InputMaybe<Profiles_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Attachment_Test_Categories_Max_Fields = {
  __typename?: 'attachment_test_categories_max_fields';
  code?: Maybe<Scalars['code_enum']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description_id?: Maybe<Scalars['Int']['output']>;
  icon_url?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Attachment_Test_Categories_Min_Fields = {
  __typename?: 'attachment_test_categories_min_fields';
  code?: Maybe<Scalars['code_enum']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description_id?: Maybe<Scalars['Int']['output']>;
  icon_url?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "attachment_test_categories" */
export type Attachment_Test_Categories_Mutation_Response = {
  __typename?: 'attachment_test_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Attachment_Test_Categories>;
};

/** input type for inserting object relation for remote table "attachment_test_categories" */
export type Attachment_Test_Categories_Obj_Rel_Insert_Input = {
  data: Attachment_Test_Categories_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_Test_Categories_On_Conflict>;
};

/** on_conflict condition type for table "attachment_test_categories" */
export type Attachment_Test_Categories_On_Conflict = {
  constraint: Attachment_Test_Categories_Constraint;
  update_columns?: Array<Attachment_Test_Categories_Update_Column>;
  where?: InputMaybe<Attachment_Test_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "attachment_test_categories". */
export type Attachment_Test_Categories_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Contents_Order_By>;
  description_id?: InputMaybe<Order_By>;
  icon_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Contents_Order_By>;
  name_id?: InputMaybe<Order_By>;
  profiles_aggregate?: InputMaybe<Profiles_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: attachment_test_categories */
export type Attachment_Test_Categories_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "attachment_test_categories" */
export type Attachment_Test_Categories_Select_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'description_id'
  /** column name */
  | 'icon_url'
  /** column name */
  | 'id'
  /** column name */
  | 'name_id'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "attachment_test_categories" */
export type Attachment_Test_Categories_Set_Input = {
  code?: InputMaybe<Scalars['code_enum']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description_id?: InputMaybe<Scalars['Int']['input']>;
  icon_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Attachment_Test_Categories_Stddev_Fields = {
  __typename?: 'attachment_test_categories_stddev_fields';
  description_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Attachment_Test_Categories_Stddev_Pop_Fields = {
  __typename?: 'attachment_test_categories_stddev_pop_fields';
  description_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Attachment_Test_Categories_Stddev_Samp_Fields = {
  __typename?: 'attachment_test_categories_stddev_samp_fields';
  description_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "attachment_test_categories" */
export type Attachment_Test_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Attachment_Test_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attachment_Test_Categories_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['code_enum']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description_id?: InputMaybe<Scalars['Int']['input']>;
  icon_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Attachment_Test_Categories_Sum_Fields = {
  __typename?: 'attachment_test_categories_sum_fields';
  description_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "attachment_test_categories" */
export type Attachment_Test_Categories_Update_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'description_id'
  /** column name */
  | 'icon_url'
  /** column name */
  | 'id'
  /** column name */
  | 'name_id'
  /** column name */
  | 'updated_at';

export type Attachment_Test_Categories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Attachment_Test_Categories_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Test_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attachment_Test_Categories_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Attachment_Test_Categories_Var_Pop_Fields = {
  __typename?: 'attachment_test_categories_var_pop_fields';
  description_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Attachment_Test_Categories_Var_Samp_Fields = {
  __typename?: 'attachment_test_categories_var_samp_fields';
  description_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Attachment_Test_Categories_Variance_Fields = {
  __typename?: 'attachment_test_categories_variance_fields';
  description_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "attachment_test_responses" */
export type Attachment_Test_Responses = {
  __typename?: 'attachment_test_responses';
  /** An object relationship */
  attachment_test: Attachment_Tests;
  attachment_test_id: Scalars['Int']['output'];
  /** An object relationship */
  content: Contents;
  content_id: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  point: Scalars['numeric']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  user_attachment_test_responses: Array<User_Attachment_Test_Responses>;
  /** An aggregate relationship */
  user_attachment_test_responses_aggregate: User_Attachment_Test_Responses_Aggregate;
};


/** columns and relationships of "attachment_test_responses" */
export type Attachment_Test_ResponsesUser_Attachment_Test_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<User_Attachment_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Attachment_Test_Responses_Order_By>>;
  where?: InputMaybe<User_Attachment_Test_Responses_Bool_Exp>;
};


/** columns and relationships of "attachment_test_responses" */
export type Attachment_Test_ResponsesUser_Attachment_Test_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Attachment_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Attachment_Test_Responses_Order_By>>;
  where?: InputMaybe<User_Attachment_Test_Responses_Bool_Exp>;
};

/** aggregated selection of "attachment_test_responses" */
export type Attachment_Test_Responses_Aggregate = {
  __typename?: 'attachment_test_responses_aggregate';
  aggregate?: Maybe<Attachment_Test_Responses_Aggregate_Fields>;
  nodes: Array<Attachment_Test_Responses>;
};

export type Attachment_Test_Responses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Attachment_Test_Responses_Aggregate_Bool_Exp_Count>;
};

export type Attachment_Test_Responses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Attachment_Test_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Attachment_Test_Responses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "attachment_test_responses" */
export type Attachment_Test_Responses_Aggregate_Fields = {
  __typename?: 'attachment_test_responses_aggregate_fields';
  avg?: Maybe<Attachment_Test_Responses_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Attachment_Test_Responses_Max_Fields>;
  min?: Maybe<Attachment_Test_Responses_Min_Fields>;
  stddev?: Maybe<Attachment_Test_Responses_Stddev_Fields>;
  stddev_pop?: Maybe<Attachment_Test_Responses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Attachment_Test_Responses_Stddev_Samp_Fields>;
  sum?: Maybe<Attachment_Test_Responses_Sum_Fields>;
  var_pop?: Maybe<Attachment_Test_Responses_Var_Pop_Fields>;
  var_samp?: Maybe<Attachment_Test_Responses_Var_Samp_Fields>;
  variance?: Maybe<Attachment_Test_Responses_Variance_Fields>;
};


/** aggregate fields of "attachment_test_responses" */
export type Attachment_Test_Responses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Attachment_Test_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "attachment_test_responses" */
export type Attachment_Test_Responses_Aggregate_Order_By = {
  avg?: InputMaybe<Attachment_Test_Responses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Attachment_Test_Responses_Max_Order_By>;
  min?: InputMaybe<Attachment_Test_Responses_Min_Order_By>;
  stddev?: InputMaybe<Attachment_Test_Responses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Attachment_Test_Responses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Attachment_Test_Responses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Attachment_Test_Responses_Sum_Order_By>;
  var_pop?: InputMaybe<Attachment_Test_Responses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Attachment_Test_Responses_Var_Samp_Order_By>;
  variance?: InputMaybe<Attachment_Test_Responses_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "attachment_test_responses" */
export type Attachment_Test_Responses_Arr_Rel_Insert_Input = {
  data: Array<Attachment_Test_Responses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_Test_Responses_On_Conflict>;
};

/** aggregate avg on columns */
export type Attachment_Test_Responses_Avg_Fields = {
  __typename?: 'attachment_test_responses_avg_fields';
  attachment_test_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  point?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "attachment_test_responses" */
export type Attachment_Test_Responses_Avg_Order_By = {
  attachment_test_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "attachment_test_responses". All fields are combined with a logical 'AND'. */
export type Attachment_Test_Responses_Bool_Exp = {
  _and?: InputMaybe<Array<Attachment_Test_Responses_Bool_Exp>>;
  _not?: InputMaybe<Attachment_Test_Responses_Bool_Exp>;
  _or?: InputMaybe<Array<Attachment_Test_Responses_Bool_Exp>>;
  attachment_test?: InputMaybe<Attachment_Tests_Bool_Exp>;
  attachment_test_id?: InputMaybe<Int_Comparison_Exp>;
  content?: InputMaybe<Contents_Bool_Exp>;
  content_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  icon?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  point?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_attachment_test_responses?: InputMaybe<User_Attachment_Test_Responses_Bool_Exp>;
  user_attachment_test_responses_aggregate?: InputMaybe<User_Attachment_Test_Responses_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "attachment_test_responses" */
export type Attachment_Test_Responses_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'attachment_tests_responses_pkey';

/** input type for incrementing numeric columns in table "attachment_test_responses" */
export type Attachment_Test_Responses_Inc_Input = {
  attachment_test_id?: InputMaybe<Scalars['Int']['input']>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  point?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "attachment_test_responses" */
export type Attachment_Test_Responses_Insert_Input = {
  attachment_test?: InputMaybe<Attachment_Tests_Obj_Rel_Insert_Input>;
  attachment_test_id?: InputMaybe<Scalars['Int']['input']>;
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  point?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_attachment_test_responses?: InputMaybe<User_Attachment_Test_Responses_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Attachment_Test_Responses_Max_Fields = {
  __typename?: 'attachment_test_responses_max_fields';
  attachment_test_id?: Maybe<Scalars['Int']['output']>;
  content_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  point?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "attachment_test_responses" */
export type Attachment_Test_Responses_Max_Order_By = {
  attachment_test_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  icon?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Attachment_Test_Responses_Min_Fields = {
  __typename?: 'attachment_test_responses_min_fields';
  attachment_test_id?: Maybe<Scalars['Int']['output']>;
  content_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  point?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "attachment_test_responses" */
export type Attachment_Test_Responses_Min_Order_By = {
  attachment_test_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  icon?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "attachment_test_responses" */
export type Attachment_Test_Responses_Mutation_Response = {
  __typename?: 'attachment_test_responses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Attachment_Test_Responses>;
};

/** input type for inserting object relation for remote table "attachment_test_responses" */
export type Attachment_Test_Responses_Obj_Rel_Insert_Input = {
  data: Attachment_Test_Responses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_Test_Responses_On_Conflict>;
};

/** on_conflict condition type for table "attachment_test_responses" */
export type Attachment_Test_Responses_On_Conflict = {
  constraint: Attachment_Test_Responses_Constraint;
  update_columns?: Array<Attachment_Test_Responses_Update_Column>;
  where?: InputMaybe<Attachment_Test_Responses_Bool_Exp>;
};

/** Ordering options when selecting data from "attachment_test_responses". */
export type Attachment_Test_Responses_Order_By = {
  attachment_test?: InputMaybe<Attachment_Tests_Order_By>;
  attachment_test_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Contents_Order_By>;
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  icon?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_attachment_test_responses_aggregate?: InputMaybe<User_Attachment_Test_Responses_Aggregate_Order_By>;
};

/** primary key columns input for table: attachment_test_responses */
export type Attachment_Test_Responses_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "attachment_test_responses" */
export type Attachment_Test_Responses_Select_Column =
  /** column name */
  | 'attachment_test_id'
  /** column name */
  | 'content_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'icon'
  /** column name */
  | 'id'
  /** column name */
  | 'point'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "attachment_test_responses" */
export type Attachment_Test_Responses_Set_Input = {
  attachment_test_id?: InputMaybe<Scalars['Int']['input']>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  point?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Attachment_Test_Responses_Stddev_Fields = {
  __typename?: 'attachment_test_responses_stddev_fields';
  attachment_test_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  point?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "attachment_test_responses" */
export type Attachment_Test_Responses_Stddev_Order_By = {
  attachment_test_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Attachment_Test_Responses_Stddev_Pop_Fields = {
  __typename?: 'attachment_test_responses_stddev_pop_fields';
  attachment_test_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  point?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "attachment_test_responses" */
export type Attachment_Test_Responses_Stddev_Pop_Order_By = {
  attachment_test_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Attachment_Test_Responses_Stddev_Samp_Fields = {
  __typename?: 'attachment_test_responses_stddev_samp_fields';
  attachment_test_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  point?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "attachment_test_responses" */
export type Attachment_Test_Responses_Stddev_Samp_Order_By = {
  attachment_test_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "attachment_test_responses" */
export type Attachment_Test_Responses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Attachment_Test_Responses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attachment_Test_Responses_Stream_Cursor_Value_Input = {
  attachment_test_id?: InputMaybe<Scalars['Int']['input']>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  point?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Attachment_Test_Responses_Sum_Fields = {
  __typename?: 'attachment_test_responses_sum_fields';
  attachment_test_id?: Maybe<Scalars['Int']['output']>;
  content_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  point?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "attachment_test_responses" */
export type Attachment_Test_Responses_Sum_Order_By = {
  attachment_test_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
};

/** update columns of table "attachment_test_responses" */
export type Attachment_Test_Responses_Update_Column =
  /** column name */
  | 'attachment_test_id'
  /** column name */
  | 'content_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'icon'
  /** column name */
  | 'id'
  /** column name */
  | 'point'
  /** column name */
  | 'updated_at';

export type Attachment_Test_Responses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Attachment_Test_Responses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Test_Responses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attachment_Test_Responses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Attachment_Test_Responses_Var_Pop_Fields = {
  __typename?: 'attachment_test_responses_var_pop_fields';
  attachment_test_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  point?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "attachment_test_responses" */
export type Attachment_Test_Responses_Var_Pop_Order_By = {
  attachment_test_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Attachment_Test_Responses_Var_Samp_Fields = {
  __typename?: 'attachment_test_responses_var_samp_fields';
  attachment_test_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  point?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "attachment_test_responses" */
export type Attachment_Test_Responses_Var_Samp_Order_By = {
  attachment_test_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Attachment_Test_Responses_Variance_Fields = {
  __typename?: 'attachment_test_responses_variance_fields';
  attachment_test_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  point?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "attachment_test_responses" */
export type Attachment_Test_Responses_Variance_Order_By = {
  attachment_test_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
};

/** columns and relationships of "attachment_tests" */
export type Attachment_Tests = {
  __typename?: 'attachment_tests';
  /** An array relationship */
  attachment_test_responses: Array<Attachment_Test_Responses>;
  /** An aggregate relationship */
  attachment_test_responses_aggregate: Attachment_Test_Responses_Aggregate;
  /** An object relationship */
  category?: Maybe<Attachment_Test_Categories>;
  category_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  content: Contents;
  content_id: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  order?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  user_attachment_test_responses: Array<User_Attachment_Test_Responses>;
  /** An aggregate relationship */
  user_attachment_test_responses_aggregate: User_Attachment_Test_Responses_Aggregate;
};


/** columns and relationships of "attachment_tests" */
export type Attachment_TestsAttachment_Test_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Attachment_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attachment_Test_Responses_Order_By>>;
  where?: InputMaybe<Attachment_Test_Responses_Bool_Exp>;
};


/** columns and relationships of "attachment_tests" */
export type Attachment_TestsAttachment_Test_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attachment_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attachment_Test_Responses_Order_By>>;
  where?: InputMaybe<Attachment_Test_Responses_Bool_Exp>;
};


/** columns and relationships of "attachment_tests" */
export type Attachment_TestsUser_Attachment_Test_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<User_Attachment_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Attachment_Test_Responses_Order_By>>;
  where?: InputMaybe<User_Attachment_Test_Responses_Bool_Exp>;
};


/** columns and relationships of "attachment_tests" */
export type Attachment_TestsUser_Attachment_Test_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Attachment_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Attachment_Test_Responses_Order_By>>;
  where?: InputMaybe<User_Attachment_Test_Responses_Bool_Exp>;
};

/** aggregated selection of "attachment_tests" */
export type Attachment_Tests_Aggregate = {
  __typename?: 'attachment_tests_aggregate';
  aggregate?: Maybe<Attachment_Tests_Aggregate_Fields>;
  nodes: Array<Attachment_Tests>;
};

/** aggregate fields of "attachment_tests" */
export type Attachment_Tests_Aggregate_Fields = {
  __typename?: 'attachment_tests_aggregate_fields';
  avg?: Maybe<Attachment_Tests_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Attachment_Tests_Max_Fields>;
  min?: Maybe<Attachment_Tests_Min_Fields>;
  stddev?: Maybe<Attachment_Tests_Stddev_Fields>;
  stddev_pop?: Maybe<Attachment_Tests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Attachment_Tests_Stddev_Samp_Fields>;
  sum?: Maybe<Attachment_Tests_Sum_Fields>;
  var_pop?: Maybe<Attachment_Tests_Var_Pop_Fields>;
  var_samp?: Maybe<Attachment_Tests_Var_Samp_Fields>;
  variance?: Maybe<Attachment_Tests_Variance_Fields>;
};


/** aggregate fields of "attachment_tests" */
export type Attachment_Tests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Attachment_Tests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Attachment_Tests_Avg_Fields = {
  __typename?: 'attachment_tests_avg_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "attachment_tests". All fields are combined with a logical 'AND'. */
export type Attachment_Tests_Bool_Exp = {
  _and?: InputMaybe<Array<Attachment_Tests_Bool_Exp>>;
  _not?: InputMaybe<Attachment_Tests_Bool_Exp>;
  _or?: InputMaybe<Array<Attachment_Tests_Bool_Exp>>;
  attachment_test_responses?: InputMaybe<Attachment_Test_Responses_Bool_Exp>;
  attachment_test_responses_aggregate?: InputMaybe<Attachment_Test_Responses_Aggregate_Bool_Exp>;
  category?: InputMaybe<Attachment_Test_Categories_Bool_Exp>;
  category_id?: InputMaybe<Int_Comparison_Exp>;
  content?: InputMaybe<Contents_Bool_Exp>;
  content_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_attachment_test_responses?: InputMaybe<User_Attachment_Test_Responses_Bool_Exp>;
  user_attachment_test_responses_aggregate?: InputMaybe<User_Attachment_Test_Responses_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "attachment_tests" */
export type Attachment_Tests_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'attachment_tests_pkey';

/** input type for incrementing numeric columns in table "attachment_tests" */
export type Attachment_Tests_Inc_Input = {
  category_id?: InputMaybe<Scalars['Int']['input']>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "attachment_tests" */
export type Attachment_Tests_Insert_Input = {
  attachment_test_responses?: InputMaybe<Attachment_Test_Responses_Arr_Rel_Insert_Input>;
  category?: InputMaybe<Attachment_Test_Categories_Obj_Rel_Insert_Input>;
  category_id?: InputMaybe<Scalars['Int']['input']>;
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_attachment_test_responses?: InputMaybe<User_Attachment_Test_Responses_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Attachment_Tests_Max_Fields = {
  __typename?: 'attachment_tests_max_fields';
  category_id?: Maybe<Scalars['Int']['output']>;
  content_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Attachment_Tests_Min_Fields = {
  __typename?: 'attachment_tests_min_fields';
  category_id?: Maybe<Scalars['Int']['output']>;
  content_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "attachment_tests" */
export type Attachment_Tests_Mutation_Response = {
  __typename?: 'attachment_tests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Attachment_Tests>;
};

/** input type for inserting object relation for remote table "attachment_tests" */
export type Attachment_Tests_Obj_Rel_Insert_Input = {
  data: Attachment_Tests_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachment_Tests_On_Conflict>;
};

/** on_conflict condition type for table "attachment_tests" */
export type Attachment_Tests_On_Conflict = {
  constraint: Attachment_Tests_Constraint;
  update_columns?: Array<Attachment_Tests_Update_Column>;
  where?: InputMaybe<Attachment_Tests_Bool_Exp>;
};

/** Ordering options when selecting data from "attachment_tests". */
export type Attachment_Tests_Order_By = {
  attachment_test_responses_aggregate?: InputMaybe<Attachment_Test_Responses_Aggregate_Order_By>;
  category?: InputMaybe<Attachment_Test_Categories_Order_By>;
  category_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Contents_Order_By>;
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_attachment_test_responses_aggregate?: InputMaybe<User_Attachment_Test_Responses_Aggregate_Order_By>;
};

/** primary key columns input for table: attachment_tests */
export type Attachment_Tests_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "attachment_tests" */
export type Attachment_Tests_Select_Column =
  /** column name */
  | 'category_id'
  /** column name */
  | 'content_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'order'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "attachment_tests" */
export type Attachment_Tests_Set_Input = {
  category_id?: InputMaybe<Scalars['Int']['input']>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Attachment_Tests_Stddev_Fields = {
  __typename?: 'attachment_tests_stddev_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Attachment_Tests_Stddev_Pop_Fields = {
  __typename?: 'attachment_tests_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Attachment_Tests_Stddev_Samp_Fields = {
  __typename?: 'attachment_tests_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "attachment_tests" */
export type Attachment_Tests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Attachment_Tests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attachment_Tests_Stream_Cursor_Value_Input = {
  category_id?: InputMaybe<Scalars['Int']['input']>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Attachment_Tests_Sum_Fields = {
  __typename?: 'attachment_tests_sum_fields';
  category_id?: Maybe<Scalars['Int']['output']>;
  content_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "attachment_tests" */
export type Attachment_Tests_Update_Column =
  /** column name */
  | 'category_id'
  /** column name */
  | 'content_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'order'
  /** column name */
  | 'updated_at';

export type Attachment_Tests_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Attachment_Tests_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachment_Tests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attachment_Tests_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Attachment_Tests_Var_Pop_Fields = {
  __typename?: 'attachment_tests_var_pop_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Attachment_Tests_Var_Samp_Fields = {
  __typename?: 'attachment_tests_var_samp_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Attachment_Tests_Variance_Fields = {
  __typename?: 'attachment_tests_variance_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "availability_status_enum". All fields are combined with logical 'AND'. */
export type Availability_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['availability_status_enum']['input']>;
  _gt?: InputMaybe<Scalars['availability_status_enum']['input']>;
  _gte?: InputMaybe<Scalars['availability_status_enum']['input']>;
  _in?: InputMaybe<Array<Scalars['availability_status_enum']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['availability_status_enum']['input']>;
  _lte?: InputMaybe<Scalars['availability_status_enum']['input']>;
  _neq?: InputMaybe<Scalars['availability_status_enum']['input']>;
  _nin?: InputMaybe<Array<Scalars['availability_status_enum']['input']>>;
};

/** columns and relationships of "availability_time_slots" */
export type Availability_Time_Slots = {
  __typename?: 'availability_time_slots';
  availability_status?: Maybe<Scalars['availability_status_enum']['output']>;
  coach_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  end_time: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  repeat_days?: Maybe<Scalars['String']['output']>;
  repeat_interval?: Maybe<Scalars['repeat_interval_enum']['output']>;
  start_time: Scalars['timestamptz']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "availability_time_slots" */
export type Availability_Time_Slots_Aggregate = {
  __typename?: 'availability_time_slots_aggregate';
  aggregate?: Maybe<Availability_Time_Slots_Aggregate_Fields>;
  nodes: Array<Availability_Time_Slots>;
};

export type Availability_Time_Slots_Aggregate_Bool_Exp = {
  count?: InputMaybe<Availability_Time_Slots_Aggregate_Bool_Exp_Count>;
};

export type Availability_Time_Slots_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Availability_Time_Slots_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Availability_Time_Slots_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "availability_time_slots" */
export type Availability_Time_Slots_Aggregate_Fields = {
  __typename?: 'availability_time_slots_aggregate_fields';
  avg?: Maybe<Availability_Time_Slots_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Availability_Time_Slots_Max_Fields>;
  min?: Maybe<Availability_Time_Slots_Min_Fields>;
  stddev?: Maybe<Availability_Time_Slots_Stddev_Fields>;
  stddev_pop?: Maybe<Availability_Time_Slots_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Availability_Time_Slots_Stddev_Samp_Fields>;
  sum?: Maybe<Availability_Time_Slots_Sum_Fields>;
  var_pop?: Maybe<Availability_Time_Slots_Var_Pop_Fields>;
  var_samp?: Maybe<Availability_Time_Slots_Var_Samp_Fields>;
  variance?: Maybe<Availability_Time_Slots_Variance_Fields>;
};


/** aggregate fields of "availability_time_slots" */
export type Availability_Time_Slots_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Availability_Time_Slots_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "availability_time_slots" */
export type Availability_Time_Slots_Aggregate_Order_By = {
  avg?: InputMaybe<Availability_Time_Slots_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Availability_Time_Slots_Max_Order_By>;
  min?: InputMaybe<Availability_Time_Slots_Min_Order_By>;
  stddev?: InputMaybe<Availability_Time_Slots_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Availability_Time_Slots_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Availability_Time_Slots_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Availability_Time_Slots_Sum_Order_By>;
  var_pop?: InputMaybe<Availability_Time_Slots_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Availability_Time_Slots_Var_Samp_Order_By>;
  variance?: InputMaybe<Availability_Time_Slots_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "availability_time_slots" */
export type Availability_Time_Slots_Arr_Rel_Insert_Input = {
  data: Array<Availability_Time_Slots_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Availability_Time_Slots_On_Conflict>;
};

/** aggregate avg on columns */
export type Availability_Time_Slots_Avg_Fields = {
  __typename?: 'availability_time_slots_avg_fields';
  coach_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "availability_time_slots" */
export type Availability_Time_Slots_Avg_Order_By = {
  coach_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "availability_time_slots". All fields are combined with a logical 'AND'. */
export type Availability_Time_Slots_Bool_Exp = {
  _and?: InputMaybe<Array<Availability_Time_Slots_Bool_Exp>>;
  _not?: InputMaybe<Availability_Time_Slots_Bool_Exp>;
  _or?: InputMaybe<Array<Availability_Time_Slots_Bool_Exp>>;
  availability_status?: InputMaybe<Availability_Status_Enum_Comparison_Exp>;
  coach_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  end_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  repeat_days?: InputMaybe<String_Comparison_Exp>;
  repeat_interval?: InputMaybe<Repeat_Interval_Enum_Comparison_Exp>;
  start_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "availability_time_slots" */
export type Availability_Time_Slots_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'availability_time_slots_pkey';

/** input type for incrementing numeric columns in table "availability_time_slots" */
export type Availability_Time_Slots_Inc_Input = {
  coach_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "availability_time_slots" */
export type Availability_Time_Slots_Insert_Input = {
  availability_status?: InputMaybe<Scalars['availability_status_enum']['input']>;
  coach_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  repeat_days?: InputMaybe<Scalars['String']['input']>;
  repeat_interval?: InputMaybe<Scalars['repeat_interval_enum']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Availability_Time_Slots_Max_Fields = {
  __typename?: 'availability_time_slots_max_fields';
  availability_status?: Maybe<Scalars['availability_status_enum']['output']>;
  coach_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  end_time?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  repeat_days?: Maybe<Scalars['String']['output']>;
  repeat_interval?: Maybe<Scalars['repeat_interval_enum']['output']>;
  start_time?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "availability_time_slots" */
export type Availability_Time_Slots_Max_Order_By = {
  availability_status?: InputMaybe<Order_By>;
  coach_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  repeat_days?: InputMaybe<Order_By>;
  repeat_interval?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Availability_Time_Slots_Min_Fields = {
  __typename?: 'availability_time_slots_min_fields';
  availability_status?: Maybe<Scalars['availability_status_enum']['output']>;
  coach_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  end_time?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  repeat_days?: Maybe<Scalars['String']['output']>;
  repeat_interval?: Maybe<Scalars['repeat_interval_enum']['output']>;
  start_time?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "availability_time_slots" */
export type Availability_Time_Slots_Min_Order_By = {
  availability_status?: InputMaybe<Order_By>;
  coach_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  repeat_days?: InputMaybe<Order_By>;
  repeat_interval?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "availability_time_slots" */
export type Availability_Time_Slots_Mutation_Response = {
  __typename?: 'availability_time_slots_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Availability_Time_Slots>;
};

/** on_conflict condition type for table "availability_time_slots" */
export type Availability_Time_Slots_On_Conflict = {
  constraint: Availability_Time_Slots_Constraint;
  update_columns?: Array<Availability_Time_Slots_Update_Column>;
  where?: InputMaybe<Availability_Time_Slots_Bool_Exp>;
};

/** Ordering options when selecting data from "availability_time_slots". */
export type Availability_Time_Slots_Order_By = {
  availability_status?: InputMaybe<Order_By>;
  coach_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  repeat_days?: InputMaybe<Order_By>;
  repeat_interval?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: availability_time_slots */
export type Availability_Time_Slots_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "availability_time_slots" */
export type Availability_Time_Slots_Select_Column =
  /** column name */
  | 'availability_status'
  /** column name */
  | 'coach_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'end_time'
  /** column name */
  | 'id'
  /** column name */
  | 'repeat_days'
  /** column name */
  | 'repeat_interval'
  /** column name */
  | 'start_time'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "availability_time_slots" */
export type Availability_Time_Slots_Set_Input = {
  availability_status?: InputMaybe<Scalars['availability_status_enum']['input']>;
  coach_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  repeat_days?: InputMaybe<Scalars['String']['input']>;
  repeat_interval?: InputMaybe<Scalars['repeat_interval_enum']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Availability_Time_Slots_Stddev_Fields = {
  __typename?: 'availability_time_slots_stddev_fields';
  coach_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "availability_time_slots" */
export type Availability_Time_Slots_Stddev_Order_By = {
  coach_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Availability_Time_Slots_Stddev_Pop_Fields = {
  __typename?: 'availability_time_slots_stddev_pop_fields';
  coach_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "availability_time_slots" */
export type Availability_Time_Slots_Stddev_Pop_Order_By = {
  coach_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Availability_Time_Slots_Stddev_Samp_Fields = {
  __typename?: 'availability_time_slots_stddev_samp_fields';
  coach_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "availability_time_slots" */
export type Availability_Time_Slots_Stddev_Samp_Order_By = {
  coach_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "availability_time_slots" */
export type Availability_Time_Slots_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Availability_Time_Slots_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Availability_Time_Slots_Stream_Cursor_Value_Input = {
  availability_status?: InputMaybe<Scalars['availability_status_enum']['input']>;
  coach_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  repeat_days?: InputMaybe<Scalars['String']['input']>;
  repeat_interval?: InputMaybe<Scalars['repeat_interval_enum']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Availability_Time_Slots_Sum_Fields = {
  __typename?: 'availability_time_slots_sum_fields';
  coach_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "availability_time_slots" */
export type Availability_Time_Slots_Sum_Order_By = {
  coach_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "availability_time_slots" */
export type Availability_Time_Slots_Update_Column =
  /** column name */
  | 'availability_status'
  /** column name */
  | 'coach_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'end_time'
  /** column name */
  | 'id'
  /** column name */
  | 'repeat_days'
  /** column name */
  | 'repeat_interval'
  /** column name */
  | 'start_time'
  /** column name */
  | 'updated_at';

export type Availability_Time_Slots_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Availability_Time_Slots_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Availability_Time_Slots_Set_Input>;
  /** filter the rows which have to be updated */
  where: Availability_Time_Slots_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Availability_Time_Slots_Var_Pop_Fields = {
  __typename?: 'availability_time_slots_var_pop_fields';
  coach_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "availability_time_slots" */
export type Availability_Time_Slots_Var_Pop_Order_By = {
  coach_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Availability_Time_Slots_Var_Samp_Fields = {
  __typename?: 'availability_time_slots_var_samp_fields';
  coach_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "availability_time_slots" */
export type Availability_Time_Slots_Var_Samp_Order_By = {
  coach_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Availability_Time_Slots_Variance_Fields = {
  __typename?: 'availability_time_slots_variance_fields';
  coach_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "availability_time_slots" */
export type Availability_Time_Slots_Variance_Order_By = {
  coach_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "avatar_results" */
export type Avatar_Results = {
  __typename?: 'avatar_results';
  /** An array relationship */
  avatar_test_responses: Array<Avatar_Test_Responses>;
  /** An aggregate relationship */
  avatar_test_responses_aggregate: Avatar_Test_Responses_Aggregate;
  code: Scalars['String']['output'];
  /** An object relationship */
  content: Contents;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description_id: Scalars['Int']['output'];
  icon_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  name?: Maybe<Contents>;
  name_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  profiles: Array<Profiles>;
  /** An aggregate relationship */
  profiles_aggregate: Profiles_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "avatar_results" */
export type Avatar_ResultsAvatar_Test_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Avatar_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Avatar_Test_Responses_Order_By>>;
  where?: InputMaybe<Avatar_Test_Responses_Bool_Exp>;
};


/** columns and relationships of "avatar_results" */
export type Avatar_ResultsAvatar_Test_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Avatar_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Avatar_Test_Responses_Order_By>>;
  where?: InputMaybe<Avatar_Test_Responses_Bool_Exp>;
};


/** columns and relationships of "avatar_results" */
export type Avatar_ResultsProfilesArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


/** columns and relationships of "avatar_results" */
export type Avatar_ResultsProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};

/** aggregated selection of "avatar_results" */
export type Avatar_Results_Aggregate = {
  __typename?: 'avatar_results_aggregate';
  aggregate?: Maybe<Avatar_Results_Aggregate_Fields>;
  nodes: Array<Avatar_Results>;
};

/** aggregate fields of "avatar_results" */
export type Avatar_Results_Aggregate_Fields = {
  __typename?: 'avatar_results_aggregate_fields';
  avg?: Maybe<Avatar_Results_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Avatar_Results_Max_Fields>;
  min?: Maybe<Avatar_Results_Min_Fields>;
  stddev?: Maybe<Avatar_Results_Stddev_Fields>;
  stddev_pop?: Maybe<Avatar_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Avatar_Results_Stddev_Samp_Fields>;
  sum?: Maybe<Avatar_Results_Sum_Fields>;
  var_pop?: Maybe<Avatar_Results_Var_Pop_Fields>;
  var_samp?: Maybe<Avatar_Results_Var_Samp_Fields>;
  variance?: Maybe<Avatar_Results_Variance_Fields>;
};


/** aggregate fields of "avatar_results" */
export type Avatar_Results_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Avatar_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Avatar_Results_Avg_Fields = {
  __typename?: 'avatar_results_avg_fields';
  description_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "avatar_results". All fields are combined with a logical 'AND'. */
export type Avatar_Results_Bool_Exp = {
  _and?: InputMaybe<Array<Avatar_Results_Bool_Exp>>;
  _not?: InputMaybe<Avatar_Results_Bool_Exp>;
  _or?: InputMaybe<Array<Avatar_Results_Bool_Exp>>;
  avatar_test_responses?: InputMaybe<Avatar_Test_Responses_Bool_Exp>;
  avatar_test_responses_aggregate?: InputMaybe<Avatar_Test_Responses_Aggregate_Bool_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  content?: InputMaybe<Contents_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description_id?: InputMaybe<Int_Comparison_Exp>;
  icon_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<Contents_Bool_Exp>;
  name_id?: InputMaybe<Int_Comparison_Exp>;
  profiles?: InputMaybe<Profiles_Bool_Exp>;
  profiles_aggregate?: InputMaybe<Profiles_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "avatar_results" */
export type Avatar_Results_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'avatar_results_pkey';

/** input type for incrementing numeric columns in table "avatar_results" */
export type Avatar_Results_Inc_Input = {
  description_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "avatar_results" */
export type Avatar_Results_Insert_Input = {
  avatar_test_responses?: InputMaybe<Avatar_Test_Responses_Arr_Rel_Insert_Input>;
  code?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description_id?: InputMaybe<Scalars['Int']['input']>;
  icon_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
  profiles?: InputMaybe<Profiles_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Avatar_Results_Max_Fields = {
  __typename?: 'avatar_results_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description_id?: Maybe<Scalars['Int']['output']>;
  icon_url?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Avatar_Results_Min_Fields = {
  __typename?: 'avatar_results_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description_id?: Maybe<Scalars['Int']['output']>;
  icon_url?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "avatar_results" */
export type Avatar_Results_Mutation_Response = {
  __typename?: 'avatar_results_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Avatar_Results>;
};

/** input type for inserting object relation for remote table "avatar_results" */
export type Avatar_Results_Obj_Rel_Insert_Input = {
  data: Avatar_Results_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Avatar_Results_On_Conflict>;
};

/** on_conflict condition type for table "avatar_results" */
export type Avatar_Results_On_Conflict = {
  constraint: Avatar_Results_Constraint;
  update_columns?: Array<Avatar_Results_Update_Column>;
  where?: InputMaybe<Avatar_Results_Bool_Exp>;
};

/** Ordering options when selecting data from "avatar_results". */
export type Avatar_Results_Order_By = {
  avatar_test_responses_aggregate?: InputMaybe<Avatar_Test_Responses_Aggregate_Order_By>;
  code?: InputMaybe<Order_By>;
  content?: InputMaybe<Contents_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description_id?: InputMaybe<Order_By>;
  icon_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Contents_Order_By>;
  name_id?: InputMaybe<Order_By>;
  profiles_aggregate?: InputMaybe<Profiles_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: avatar_results */
export type Avatar_Results_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "avatar_results" */
export type Avatar_Results_Select_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'description_id'
  /** column name */
  | 'icon_url'
  /** column name */
  | 'id'
  /** column name */
  | 'name_id'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "avatar_results" */
export type Avatar_Results_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description_id?: InputMaybe<Scalars['Int']['input']>;
  icon_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Avatar_Results_Stddev_Fields = {
  __typename?: 'avatar_results_stddev_fields';
  description_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Avatar_Results_Stddev_Pop_Fields = {
  __typename?: 'avatar_results_stddev_pop_fields';
  description_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Avatar_Results_Stddev_Samp_Fields = {
  __typename?: 'avatar_results_stddev_samp_fields';
  description_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "avatar_results" */
export type Avatar_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Avatar_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Avatar_Results_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description_id?: InputMaybe<Scalars['Int']['input']>;
  icon_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Avatar_Results_Sum_Fields = {
  __typename?: 'avatar_results_sum_fields';
  description_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "avatar_results" */
export type Avatar_Results_Update_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'description_id'
  /** column name */
  | 'icon_url'
  /** column name */
  | 'id'
  /** column name */
  | 'name_id'
  /** column name */
  | 'updated_at';

export type Avatar_Results_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Avatar_Results_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Avatar_Results_Set_Input>;
  /** filter the rows which have to be updated */
  where: Avatar_Results_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Avatar_Results_Var_Pop_Fields = {
  __typename?: 'avatar_results_var_pop_fields';
  description_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Avatar_Results_Var_Samp_Fields = {
  __typename?: 'avatar_results_var_samp_fields';
  description_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Avatar_Results_Variance_Fields = {
  __typename?: 'avatar_results_variance_fields';
  description_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "avatar_test_responses" */
export type Avatar_Test_Responses = {
  __typename?: 'avatar_test_responses';
  /** An object relationship */
  avatar_result?: Maybe<Avatar_Results>;
  avatar_result_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  avatar_test: Avatar_Tests;
  avatar_test_id: Scalars['Int']['output'];
  /** An object relationship */
  content: Contents;
  content_id: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  user_avatar_test_responses: Array<User_Avatar_Test_Responses>;
  /** An aggregate relationship */
  user_avatar_test_responses_aggregate: User_Avatar_Test_Responses_Aggregate;
};


/** columns and relationships of "avatar_test_responses" */
export type Avatar_Test_ResponsesUser_Avatar_Test_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Avatar_Test_Responses_Order_By>>;
  where?: InputMaybe<User_Avatar_Test_Responses_Bool_Exp>;
};


/** columns and relationships of "avatar_test_responses" */
export type Avatar_Test_ResponsesUser_Avatar_Test_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Avatar_Test_Responses_Order_By>>;
  where?: InputMaybe<User_Avatar_Test_Responses_Bool_Exp>;
};

/** aggregated selection of "avatar_test_responses" */
export type Avatar_Test_Responses_Aggregate = {
  __typename?: 'avatar_test_responses_aggregate';
  aggregate?: Maybe<Avatar_Test_Responses_Aggregate_Fields>;
  nodes: Array<Avatar_Test_Responses>;
};

export type Avatar_Test_Responses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Avatar_Test_Responses_Aggregate_Bool_Exp_Count>;
};

export type Avatar_Test_Responses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Avatar_Test_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Avatar_Test_Responses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "avatar_test_responses" */
export type Avatar_Test_Responses_Aggregate_Fields = {
  __typename?: 'avatar_test_responses_aggregate_fields';
  avg?: Maybe<Avatar_Test_Responses_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Avatar_Test_Responses_Max_Fields>;
  min?: Maybe<Avatar_Test_Responses_Min_Fields>;
  stddev?: Maybe<Avatar_Test_Responses_Stddev_Fields>;
  stddev_pop?: Maybe<Avatar_Test_Responses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Avatar_Test_Responses_Stddev_Samp_Fields>;
  sum?: Maybe<Avatar_Test_Responses_Sum_Fields>;
  var_pop?: Maybe<Avatar_Test_Responses_Var_Pop_Fields>;
  var_samp?: Maybe<Avatar_Test_Responses_Var_Samp_Fields>;
  variance?: Maybe<Avatar_Test_Responses_Variance_Fields>;
};


/** aggregate fields of "avatar_test_responses" */
export type Avatar_Test_Responses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Avatar_Test_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "avatar_test_responses" */
export type Avatar_Test_Responses_Aggregate_Order_By = {
  avg?: InputMaybe<Avatar_Test_Responses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Avatar_Test_Responses_Max_Order_By>;
  min?: InputMaybe<Avatar_Test_Responses_Min_Order_By>;
  stddev?: InputMaybe<Avatar_Test_Responses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Avatar_Test_Responses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Avatar_Test_Responses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Avatar_Test_Responses_Sum_Order_By>;
  var_pop?: InputMaybe<Avatar_Test_Responses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Avatar_Test_Responses_Var_Samp_Order_By>;
  variance?: InputMaybe<Avatar_Test_Responses_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "avatar_test_responses" */
export type Avatar_Test_Responses_Arr_Rel_Insert_Input = {
  data: Array<Avatar_Test_Responses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Avatar_Test_Responses_On_Conflict>;
};

/** aggregate avg on columns */
export type Avatar_Test_Responses_Avg_Fields = {
  __typename?: 'avatar_test_responses_avg_fields';
  avatar_result_id?: Maybe<Scalars['Float']['output']>;
  avatar_test_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "avatar_test_responses" */
export type Avatar_Test_Responses_Avg_Order_By = {
  avatar_result_id?: InputMaybe<Order_By>;
  avatar_test_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "avatar_test_responses". All fields are combined with a logical 'AND'. */
export type Avatar_Test_Responses_Bool_Exp = {
  _and?: InputMaybe<Array<Avatar_Test_Responses_Bool_Exp>>;
  _not?: InputMaybe<Avatar_Test_Responses_Bool_Exp>;
  _or?: InputMaybe<Array<Avatar_Test_Responses_Bool_Exp>>;
  avatar_result?: InputMaybe<Avatar_Results_Bool_Exp>;
  avatar_result_id?: InputMaybe<Int_Comparison_Exp>;
  avatar_test?: InputMaybe<Avatar_Tests_Bool_Exp>;
  avatar_test_id?: InputMaybe<Int_Comparison_Exp>;
  content?: InputMaybe<Contents_Bool_Exp>;
  content_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  icon?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_avatar_test_responses?: InputMaybe<User_Avatar_Test_Responses_Bool_Exp>;
  user_avatar_test_responses_aggregate?: InputMaybe<User_Avatar_Test_Responses_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "avatar_test_responses" */
export type Avatar_Test_Responses_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'avatar_tests_responses_pkey';

/** input type for incrementing numeric columns in table "avatar_test_responses" */
export type Avatar_Test_Responses_Inc_Input = {
  avatar_result_id?: InputMaybe<Scalars['Int']['input']>;
  avatar_test_id?: InputMaybe<Scalars['Int']['input']>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "avatar_test_responses" */
export type Avatar_Test_Responses_Insert_Input = {
  avatar_result?: InputMaybe<Avatar_Results_Obj_Rel_Insert_Input>;
  avatar_result_id?: InputMaybe<Scalars['Int']['input']>;
  avatar_test?: InputMaybe<Avatar_Tests_Obj_Rel_Insert_Input>;
  avatar_test_id?: InputMaybe<Scalars['Int']['input']>;
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_avatar_test_responses?: InputMaybe<User_Avatar_Test_Responses_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Avatar_Test_Responses_Max_Fields = {
  __typename?: 'avatar_test_responses_max_fields';
  avatar_result_id?: Maybe<Scalars['Int']['output']>;
  avatar_test_id?: Maybe<Scalars['Int']['output']>;
  content_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "avatar_test_responses" */
export type Avatar_Test_Responses_Max_Order_By = {
  avatar_result_id?: InputMaybe<Order_By>;
  avatar_test_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  icon?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Avatar_Test_Responses_Min_Fields = {
  __typename?: 'avatar_test_responses_min_fields';
  avatar_result_id?: Maybe<Scalars['Int']['output']>;
  avatar_test_id?: Maybe<Scalars['Int']['output']>;
  content_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "avatar_test_responses" */
export type Avatar_Test_Responses_Min_Order_By = {
  avatar_result_id?: InputMaybe<Order_By>;
  avatar_test_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  icon?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "avatar_test_responses" */
export type Avatar_Test_Responses_Mutation_Response = {
  __typename?: 'avatar_test_responses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Avatar_Test_Responses>;
};

/** input type for inserting object relation for remote table "avatar_test_responses" */
export type Avatar_Test_Responses_Obj_Rel_Insert_Input = {
  data: Avatar_Test_Responses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Avatar_Test_Responses_On_Conflict>;
};

/** on_conflict condition type for table "avatar_test_responses" */
export type Avatar_Test_Responses_On_Conflict = {
  constraint: Avatar_Test_Responses_Constraint;
  update_columns?: Array<Avatar_Test_Responses_Update_Column>;
  where?: InputMaybe<Avatar_Test_Responses_Bool_Exp>;
};

/** Ordering options when selecting data from "avatar_test_responses". */
export type Avatar_Test_Responses_Order_By = {
  avatar_result?: InputMaybe<Avatar_Results_Order_By>;
  avatar_result_id?: InputMaybe<Order_By>;
  avatar_test?: InputMaybe<Avatar_Tests_Order_By>;
  avatar_test_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Contents_Order_By>;
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  icon?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_avatar_test_responses_aggregate?: InputMaybe<User_Avatar_Test_Responses_Aggregate_Order_By>;
};

/** primary key columns input for table: avatar_test_responses */
export type Avatar_Test_Responses_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "avatar_test_responses" */
export type Avatar_Test_Responses_Select_Column =
  /** column name */
  | 'avatar_result_id'
  /** column name */
  | 'avatar_test_id'
  /** column name */
  | 'content_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'icon'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "avatar_test_responses" */
export type Avatar_Test_Responses_Set_Input = {
  avatar_result_id?: InputMaybe<Scalars['Int']['input']>;
  avatar_test_id?: InputMaybe<Scalars['Int']['input']>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Avatar_Test_Responses_Stddev_Fields = {
  __typename?: 'avatar_test_responses_stddev_fields';
  avatar_result_id?: Maybe<Scalars['Float']['output']>;
  avatar_test_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "avatar_test_responses" */
export type Avatar_Test_Responses_Stddev_Order_By = {
  avatar_result_id?: InputMaybe<Order_By>;
  avatar_test_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Avatar_Test_Responses_Stddev_Pop_Fields = {
  __typename?: 'avatar_test_responses_stddev_pop_fields';
  avatar_result_id?: Maybe<Scalars['Float']['output']>;
  avatar_test_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "avatar_test_responses" */
export type Avatar_Test_Responses_Stddev_Pop_Order_By = {
  avatar_result_id?: InputMaybe<Order_By>;
  avatar_test_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Avatar_Test_Responses_Stddev_Samp_Fields = {
  __typename?: 'avatar_test_responses_stddev_samp_fields';
  avatar_result_id?: Maybe<Scalars['Float']['output']>;
  avatar_test_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "avatar_test_responses" */
export type Avatar_Test_Responses_Stddev_Samp_Order_By = {
  avatar_result_id?: InputMaybe<Order_By>;
  avatar_test_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "avatar_test_responses" */
export type Avatar_Test_Responses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Avatar_Test_Responses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Avatar_Test_Responses_Stream_Cursor_Value_Input = {
  avatar_result_id?: InputMaybe<Scalars['Int']['input']>;
  avatar_test_id?: InputMaybe<Scalars['Int']['input']>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Avatar_Test_Responses_Sum_Fields = {
  __typename?: 'avatar_test_responses_sum_fields';
  avatar_result_id?: Maybe<Scalars['Int']['output']>;
  avatar_test_id?: Maybe<Scalars['Int']['output']>;
  content_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "avatar_test_responses" */
export type Avatar_Test_Responses_Sum_Order_By = {
  avatar_result_id?: InputMaybe<Order_By>;
  avatar_test_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "avatar_test_responses" */
export type Avatar_Test_Responses_Update_Column =
  /** column name */
  | 'avatar_result_id'
  /** column name */
  | 'avatar_test_id'
  /** column name */
  | 'content_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'icon'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at';

export type Avatar_Test_Responses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Avatar_Test_Responses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Avatar_Test_Responses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Avatar_Test_Responses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Avatar_Test_Responses_Var_Pop_Fields = {
  __typename?: 'avatar_test_responses_var_pop_fields';
  avatar_result_id?: Maybe<Scalars['Float']['output']>;
  avatar_test_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "avatar_test_responses" */
export type Avatar_Test_Responses_Var_Pop_Order_By = {
  avatar_result_id?: InputMaybe<Order_By>;
  avatar_test_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Avatar_Test_Responses_Var_Samp_Fields = {
  __typename?: 'avatar_test_responses_var_samp_fields';
  avatar_result_id?: Maybe<Scalars['Float']['output']>;
  avatar_test_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "avatar_test_responses" */
export type Avatar_Test_Responses_Var_Samp_Order_By = {
  avatar_result_id?: InputMaybe<Order_By>;
  avatar_test_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Avatar_Test_Responses_Variance_Fields = {
  __typename?: 'avatar_test_responses_variance_fields';
  avatar_result_id?: Maybe<Scalars['Float']['output']>;
  avatar_test_id?: Maybe<Scalars['Float']['output']>;
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "avatar_test_responses" */
export type Avatar_Test_Responses_Variance_Order_By = {
  avatar_result_id?: InputMaybe<Order_By>;
  avatar_test_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "avatar_test_type". All fields are combined with logical 'AND'. */
export type Avatar_Test_Type_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['avatar_test_type']['input']>;
  _gt?: InputMaybe<Scalars['avatar_test_type']['input']>;
  _gte?: InputMaybe<Scalars['avatar_test_type']['input']>;
  _in?: InputMaybe<Array<Scalars['avatar_test_type']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['avatar_test_type']['input']>;
  _lte?: InputMaybe<Scalars['avatar_test_type']['input']>;
  _neq?: InputMaybe<Scalars['avatar_test_type']['input']>;
  _nin?: InputMaybe<Array<Scalars['avatar_test_type']['input']>>;
};

/** columns and relationships of "avatar_tests" */
export type Avatar_Tests = {
  __typename?: 'avatar_tests';
  /** An array relationship */
  avatar_test_responses: Array<Avatar_Test_Responses>;
  /** An aggregate relationship */
  avatar_test_responses_aggregate: Avatar_Test_Responses_Aggregate;
  category?: Maybe<Scalars['avatar_test_type']['output']>;
  /** An object relationship */
  content: Contents;
  content_id: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  order?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  user_avatar_test_responses: Array<User_Avatar_Test_Responses>;
  /** An aggregate relationship */
  user_avatar_test_responses_aggregate: User_Avatar_Test_Responses_Aggregate;
};


/** columns and relationships of "avatar_tests" */
export type Avatar_TestsAvatar_Test_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Avatar_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Avatar_Test_Responses_Order_By>>;
  where?: InputMaybe<Avatar_Test_Responses_Bool_Exp>;
};


/** columns and relationships of "avatar_tests" */
export type Avatar_TestsAvatar_Test_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Avatar_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Avatar_Test_Responses_Order_By>>;
  where?: InputMaybe<Avatar_Test_Responses_Bool_Exp>;
};


/** columns and relationships of "avatar_tests" */
export type Avatar_TestsUser_Avatar_Test_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Avatar_Test_Responses_Order_By>>;
  where?: InputMaybe<User_Avatar_Test_Responses_Bool_Exp>;
};


/** columns and relationships of "avatar_tests" */
export type Avatar_TestsUser_Avatar_Test_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Avatar_Test_Responses_Order_By>>;
  where?: InputMaybe<User_Avatar_Test_Responses_Bool_Exp>;
};

/** aggregated selection of "avatar_tests" */
export type Avatar_Tests_Aggregate = {
  __typename?: 'avatar_tests_aggregate';
  aggregate?: Maybe<Avatar_Tests_Aggregate_Fields>;
  nodes: Array<Avatar_Tests>;
};

/** aggregate fields of "avatar_tests" */
export type Avatar_Tests_Aggregate_Fields = {
  __typename?: 'avatar_tests_aggregate_fields';
  avg?: Maybe<Avatar_Tests_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Avatar_Tests_Max_Fields>;
  min?: Maybe<Avatar_Tests_Min_Fields>;
  stddev?: Maybe<Avatar_Tests_Stddev_Fields>;
  stddev_pop?: Maybe<Avatar_Tests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Avatar_Tests_Stddev_Samp_Fields>;
  sum?: Maybe<Avatar_Tests_Sum_Fields>;
  var_pop?: Maybe<Avatar_Tests_Var_Pop_Fields>;
  var_samp?: Maybe<Avatar_Tests_Var_Samp_Fields>;
  variance?: Maybe<Avatar_Tests_Variance_Fields>;
};


/** aggregate fields of "avatar_tests" */
export type Avatar_Tests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Avatar_Tests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Avatar_Tests_Avg_Fields = {
  __typename?: 'avatar_tests_avg_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "avatar_tests". All fields are combined with a logical 'AND'. */
export type Avatar_Tests_Bool_Exp = {
  _and?: InputMaybe<Array<Avatar_Tests_Bool_Exp>>;
  _not?: InputMaybe<Avatar_Tests_Bool_Exp>;
  _or?: InputMaybe<Array<Avatar_Tests_Bool_Exp>>;
  avatar_test_responses?: InputMaybe<Avatar_Test_Responses_Bool_Exp>;
  avatar_test_responses_aggregate?: InputMaybe<Avatar_Test_Responses_Aggregate_Bool_Exp>;
  category?: InputMaybe<Avatar_Test_Type_Comparison_Exp>;
  content?: InputMaybe<Contents_Bool_Exp>;
  content_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_avatar_test_responses?: InputMaybe<User_Avatar_Test_Responses_Bool_Exp>;
  user_avatar_test_responses_aggregate?: InputMaybe<User_Avatar_Test_Responses_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "avatar_tests" */
export type Avatar_Tests_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'avatar_tests_pkey';

/** input type for incrementing numeric columns in table "avatar_tests" */
export type Avatar_Tests_Inc_Input = {
  content_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "avatar_tests" */
export type Avatar_Tests_Insert_Input = {
  avatar_test_responses?: InputMaybe<Avatar_Test_Responses_Arr_Rel_Insert_Input>;
  category?: InputMaybe<Scalars['avatar_test_type']['input']>;
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_avatar_test_responses?: InputMaybe<User_Avatar_Test_Responses_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Avatar_Tests_Max_Fields = {
  __typename?: 'avatar_tests_max_fields';
  category?: Maybe<Scalars['avatar_test_type']['output']>;
  content_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Avatar_Tests_Min_Fields = {
  __typename?: 'avatar_tests_min_fields';
  category?: Maybe<Scalars['avatar_test_type']['output']>;
  content_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "avatar_tests" */
export type Avatar_Tests_Mutation_Response = {
  __typename?: 'avatar_tests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Avatar_Tests>;
};

/** input type for inserting object relation for remote table "avatar_tests" */
export type Avatar_Tests_Obj_Rel_Insert_Input = {
  data: Avatar_Tests_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Avatar_Tests_On_Conflict>;
};

/** on_conflict condition type for table "avatar_tests" */
export type Avatar_Tests_On_Conflict = {
  constraint: Avatar_Tests_Constraint;
  update_columns?: Array<Avatar_Tests_Update_Column>;
  where?: InputMaybe<Avatar_Tests_Bool_Exp>;
};

/** Ordering options when selecting data from "avatar_tests". */
export type Avatar_Tests_Order_By = {
  avatar_test_responses_aggregate?: InputMaybe<Avatar_Test_Responses_Aggregate_Order_By>;
  category?: InputMaybe<Order_By>;
  content?: InputMaybe<Contents_Order_By>;
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_avatar_test_responses_aggregate?: InputMaybe<User_Avatar_Test_Responses_Aggregate_Order_By>;
};

/** primary key columns input for table: avatar_tests */
export type Avatar_Tests_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "avatar_tests" */
export type Avatar_Tests_Select_Column =
  /** column name */
  | 'category'
  /** column name */
  | 'content_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'order'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "avatar_tests" */
export type Avatar_Tests_Set_Input = {
  category?: InputMaybe<Scalars['avatar_test_type']['input']>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Avatar_Tests_Stddev_Fields = {
  __typename?: 'avatar_tests_stddev_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Avatar_Tests_Stddev_Pop_Fields = {
  __typename?: 'avatar_tests_stddev_pop_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Avatar_Tests_Stddev_Samp_Fields = {
  __typename?: 'avatar_tests_stddev_samp_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "avatar_tests" */
export type Avatar_Tests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Avatar_Tests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Avatar_Tests_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Scalars['avatar_test_type']['input']>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Avatar_Tests_Sum_Fields = {
  __typename?: 'avatar_tests_sum_fields';
  content_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "avatar_tests" */
export type Avatar_Tests_Update_Column =
  /** column name */
  | 'category'
  /** column name */
  | 'content_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'order'
  /** column name */
  | 'updated_at';

export type Avatar_Tests_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Avatar_Tests_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Avatar_Tests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Avatar_Tests_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Avatar_Tests_Var_Pop_Fields = {
  __typename?: 'avatar_tests_var_pop_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Avatar_Tests_Var_Samp_Fields = {
  __typename?: 'avatar_tests_var_samp_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Avatar_Tests_Variance_Fields = {
  __typename?: 'avatar_tests_variance_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "channel_users" */
export type Channel_Users = {
  __typename?: 'channel_users';
  block_date?: Maybe<Scalars['timestamp']['output']>;
  blocked_by?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  blocker?: Maybe<Users>;
  /** An object relationship */
  channel: Channels;
  channel_id: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  is_blocked?: Maybe<Scalars['Boolean']['output']>;
  last_seen?: Maybe<Scalars['timestamptz']['output']>;
  last_typed?: Maybe<Scalars['timestamptz']['output']>;
  report?: Maybe<Scalars['String']['output']>;
  report_by?: Maybe<Scalars['Int']['output']>;
  report_date?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  reporter?: Maybe<Users>;
  unread_count?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "channel_users" */
export type Channel_Users_Aggregate = {
  __typename?: 'channel_users_aggregate';
  aggregate?: Maybe<Channel_Users_Aggregate_Fields>;
  nodes: Array<Channel_Users>;
};

export type Channel_Users_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Channel_Users_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Channel_Users_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Channel_Users_Aggregate_Bool_Exp_Count>;
};

export type Channel_Users_Aggregate_Bool_Exp_Bool_And = {
  arguments: Channel_Users_Select_Column_Channel_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Channel_Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Channel_Users_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Channel_Users_Select_Column_Channel_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Channel_Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Channel_Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Channel_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Channel_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "channel_users" */
export type Channel_Users_Aggregate_Fields = {
  __typename?: 'channel_users_aggregate_fields';
  avg?: Maybe<Channel_Users_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Channel_Users_Max_Fields>;
  min?: Maybe<Channel_Users_Min_Fields>;
  stddev?: Maybe<Channel_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Channel_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Channel_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Channel_Users_Sum_Fields>;
  var_pop?: Maybe<Channel_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Channel_Users_Var_Samp_Fields>;
  variance?: Maybe<Channel_Users_Variance_Fields>;
};


/** aggregate fields of "channel_users" */
export type Channel_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Channel_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "channel_users" */
export type Channel_Users_Aggregate_Order_By = {
  avg?: InputMaybe<Channel_Users_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Channel_Users_Max_Order_By>;
  min?: InputMaybe<Channel_Users_Min_Order_By>;
  stddev?: InputMaybe<Channel_Users_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Channel_Users_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Channel_Users_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Channel_Users_Sum_Order_By>;
  var_pop?: InputMaybe<Channel_Users_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Channel_Users_Var_Samp_Order_By>;
  variance?: InputMaybe<Channel_Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "channel_users" */
export type Channel_Users_Arr_Rel_Insert_Input = {
  data: Array<Channel_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Channel_Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Channel_Users_Avg_Fields = {
  __typename?: 'channel_users_avg_fields';
  blocked_by?: Maybe<Scalars['Float']['output']>;
  channel_id?: Maybe<Scalars['Float']['output']>;
  report_by?: Maybe<Scalars['Float']['output']>;
  unread_count?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "channel_users" */
export type Channel_Users_Avg_Order_By = {
  blocked_by?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  report_by?: InputMaybe<Order_By>;
  unread_count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "channel_users". All fields are combined with a logical 'AND'. */
export type Channel_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Channel_Users_Bool_Exp>>;
  _not?: InputMaybe<Channel_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Channel_Users_Bool_Exp>>;
  block_date?: InputMaybe<Timestamp_Comparison_Exp>;
  blocked_by?: InputMaybe<Int_Comparison_Exp>;
  blocker?: InputMaybe<Users_Bool_Exp>;
  channel?: InputMaybe<Channels_Bool_Exp>;
  channel_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  is_blocked?: InputMaybe<Boolean_Comparison_Exp>;
  last_seen?: InputMaybe<Timestamptz_Comparison_Exp>;
  last_typed?: InputMaybe<Timestamptz_Comparison_Exp>;
  report?: InputMaybe<String_Comparison_Exp>;
  report_by?: InputMaybe<Int_Comparison_Exp>;
  report_date?: InputMaybe<Timestamp_Comparison_Exp>;
  reporter?: InputMaybe<Users_Bool_Exp>;
  unread_count?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "channel_users" */
export type Channel_Users_Constraint =
  /** unique or primary key constraint on columns "user_id", "channel_id" */
  | 'channel_users_pkey';

/** input type for incrementing numeric columns in table "channel_users" */
export type Channel_Users_Inc_Input = {
  blocked_by?: InputMaybe<Scalars['Int']['input']>;
  channel_id?: InputMaybe<Scalars['Int']['input']>;
  report_by?: InputMaybe<Scalars['Int']['input']>;
  unread_count?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "channel_users" */
export type Channel_Users_Insert_Input = {
  block_date?: InputMaybe<Scalars['timestamp']['input']>;
  blocked_by?: InputMaybe<Scalars['Int']['input']>;
  blocker?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  channel?: InputMaybe<Channels_Obj_Rel_Insert_Input>;
  channel_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  is_blocked?: InputMaybe<Scalars['Boolean']['input']>;
  last_seen?: InputMaybe<Scalars['timestamptz']['input']>;
  last_typed?: InputMaybe<Scalars['timestamptz']['input']>;
  report?: InputMaybe<Scalars['String']['input']>;
  report_by?: InputMaybe<Scalars['Int']['input']>;
  report_date?: InputMaybe<Scalars['timestamp']['input']>;
  reporter?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  unread_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Channel_Users_Max_Fields = {
  __typename?: 'channel_users_max_fields';
  block_date?: Maybe<Scalars['timestamp']['output']>;
  blocked_by?: Maybe<Scalars['Int']['output']>;
  channel_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  last_seen?: Maybe<Scalars['timestamptz']['output']>;
  last_typed?: Maybe<Scalars['timestamptz']['output']>;
  report?: Maybe<Scalars['String']['output']>;
  report_by?: Maybe<Scalars['Int']['output']>;
  report_date?: Maybe<Scalars['timestamp']['output']>;
  unread_count?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "channel_users" */
export type Channel_Users_Max_Order_By = {
  block_date?: InputMaybe<Order_By>;
  blocked_by?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  last_seen?: InputMaybe<Order_By>;
  last_typed?: InputMaybe<Order_By>;
  report?: InputMaybe<Order_By>;
  report_by?: InputMaybe<Order_By>;
  report_date?: InputMaybe<Order_By>;
  unread_count?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Channel_Users_Min_Fields = {
  __typename?: 'channel_users_min_fields';
  block_date?: Maybe<Scalars['timestamp']['output']>;
  blocked_by?: Maybe<Scalars['Int']['output']>;
  channel_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  last_seen?: Maybe<Scalars['timestamptz']['output']>;
  last_typed?: Maybe<Scalars['timestamptz']['output']>;
  report?: Maybe<Scalars['String']['output']>;
  report_by?: Maybe<Scalars['Int']['output']>;
  report_date?: Maybe<Scalars['timestamp']['output']>;
  unread_count?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "channel_users" */
export type Channel_Users_Min_Order_By = {
  block_date?: InputMaybe<Order_By>;
  blocked_by?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  last_seen?: InputMaybe<Order_By>;
  last_typed?: InputMaybe<Order_By>;
  report?: InputMaybe<Order_By>;
  report_by?: InputMaybe<Order_By>;
  report_date?: InputMaybe<Order_By>;
  unread_count?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "channel_users" */
export type Channel_Users_Mutation_Response = {
  __typename?: 'channel_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Channel_Users>;
};

/** on_conflict condition type for table "channel_users" */
export type Channel_Users_On_Conflict = {
  constraint: Channel_Users_Constraint;
  update_columns?: Array<Channel_Users_Update_Column>;
  where?: InputMaybe<Channel_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "channel_users". */
export type Channel_Users_Order_By = {
  block_date?: InputMaybe<Order_By>;
  blocked_by?: InputMaybe<Order_By>;
  blocker?: InputMaybe<Users_Order_By>;
  channel?: InputMaybe<Channels_Order_By>;
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  is_blocked?: InputMaybe<Order_By>;
  last_seen?: InputMaybe<Order_By>;
  last_typed?: InputMaybe<Order_By>;
  report?: InputMaybe<Order_By>;
  report_by?: InputMaybe<Order_By>;
  report_date?: InputMaybe<Order_By>;
  reporter?: InputMaybe<Users_Order_By>;
  unread_count?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: channel_users */
export type Channel_Users_Pk_Columns_Input = {
  channel_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};

/** select columns of table "channel_users" */
export type Channel_Users_Select_Column =
  /** column name */
  | 'block_date'
  /** column name */
  | 'blocked_by'
  /** column name */
  | 'channel_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'is_blocked'
  /** column name */
  | 'last_seen'
  /** column name */
  | 'last_typed'
  /** column name */
  | 'report'
  /** column name */
  | 'report_by'
  /** column name */
  | 'report_date'
  /** column name */
  | 'unread_count'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

/** select "channel_users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "channel_users" */
export type Channel_Users_Select_Column_Channel_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'is_blocked';

/** select "channel_users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "channel_users" */
export type Channel_Users_Select_Column_Channel_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'is_blocked';

/** input type for updating data in table "channel_users" */
export type Channel_Users_Set_Input = {
  block_date?: InputMaybe<Scalars['timestamp']['input']>;
  blocked_by?: InputMaybe<Scalars['Int']['input']>;
  channel_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  is_blocked?: InputMaybe<Scalars['Boolean']['input']>;
  last_seen?: InputMaybe<Scalars['timestamptz']['input']>;
  last_typed?: InputMaybe<Scalars['timestamptz']['input']>;
  report?: InputMaybe<Scalars['String']['input']>;
  report_by?: InputMaybe<Scalars['Int']['input']>;
  report_date?: InputMaybe<Scalars['timestamp']['input']>;
  unread_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Channel_Users_Stddev_Fields = {
  __typename?: 'channel_users_stddev_fields';
  blocked_by?: Maybe<Scalars['Float']['output']>;
  channel_id?: Maybe<Scalars['Float']['output']>;
  report_by?: Maybe<Scalars['Float']['output']>;
  unread_count?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "channel_users" */
export type Channel_Users_Stddev_Order_By = {
  blocked_by?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  report_by?: InputMaybe<Order_By>;
  unread_count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Channel_Users_Stddev_Pop_Fields = {
  __typename?: 'channel_users_stddev_pop_fields';
  blocked_by?: Maybe<Scalars['Float']['output']>;
  channel_id?: Maybe<Scalars['Float']['output']>;
  report_by?: Maybe<Scalars['Float']['output']>;
  unread_count?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "channel_users" */
export type Channel_Users_Stddev_Pop_Order_By = {
  blocked_by?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  report_by?: InputMaybe<Order_By>;
  unread_count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Channel_Users_Stddev_Samp_Fields = {
  __typename?: 'channel_users_stddev_samp_fields';
  blocked_by?: Maybe<Scalars['Float']['output']>;
  channel_id?: Maybe<Scalars['Float']['output']>;
  report_by?: Maybe<Scalars['Float']['output']>;
  unread_count?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "channel_users" */
export type Channel_Users_Stddev_Samp_Order_By = {
  blocked_by?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  report_by?: InputMaybe<Order_By>;
  unread_count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "channel_users" */
export type Channel_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Channel_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channel_Users_Stream_Cursor_Value_Input = {
  block_date?: InputMaybe<Scalars['timestamp']['input']>;
  blocked_by?: InputMaybe<Scalars['Int']['input']>;
  channel_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  is_blocked?: InputMaybe<Scalars['Boolean']['input']>;
  last_seen?: InputMaybe<Scalars['timestamptz']['input']>;
  last_typed?: InputMaybe<Scalars['timestamptz']['input']>;
  report?: InputMaybe<Scalars['String']['input']>;
  report_by?: InputMaybe<Scalars['Int']['input']>;
  report_date?: InputMaybe<Scalars['timestamp']['input']>;
  unread_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Channel_Users_Sum_Fields = {
  __typename?: 'channel_users_sum_fields';
  blocked_by?: Maybe<Scalars['Int']['output']>;
  channel_id?: Maybe<Scalars['Int']['output']>;
  report_by?: Maybe<Scalars['Int']['output']>;
  unread_count?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "channel_users" */
export type Channel_Users_Sum_Order_By = {
  blocked_by?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  report_by?: InputMaybe<Order_By>;
  unread_count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "channel_users" */
export type Channel_Users_Update_Column =
  /** column name */
  | 'block_date'
  /** column name */
  | 'blocked_by'
  /** column name */
  | 'channel_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'is_blocked'
  /** column name */
  | 'last_seen'
  /** column name */
  | 'last_typed'
  /** column name */
  | 'report'
  /** column name */
  | 'report_by'
  /** column name */
  | 'report_date'
  /** column name */
  | 'unread_count'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

export type Channel_Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Channel_Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Channel_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Channel_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Channel_Users_Var_Pop_Fields = {
  __typename?: 'channel_users_var_pop_fields';
  blocked_by?: Maybe<Scalars['Float']['output']>;
  channel_id?: Maybe<Scalars['Float']['output']>;
  report_by?: Maybe<Scalars['Float']['output']>;
  unread_count?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "channel_users" */
export type Channel_Users_Var_Pop_Order_By = {
  blocked_by?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  report_by?: InputMaybe<Order_By>;
  unread_count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Channel_Users_Var_Samp_Fields = {
  __typename?: 'channel_users_var_samp_fields';
  blocked_by?: Maybe<Scalars['Float']['output']>;
  channel_id?: Maybe<Scalars['Float']['output']>;
  report_by?: Maybe<Scalars['Float']['output']>;
  unread_count?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "channel_users" */
export type Channel_Users_Var_Samp_Order_By = {
  blocked_by?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  report_by?: InputMaybe<Order_By>;
  unread_count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Channel_Users_Variance_Fields = {
  __typename?: 'channel_users_variance_fields';
  blocked_by?: Maybe<Scalars['Float']['output']>;
  channel_id?: Maybe<Scalars['Float']['output']>;
  report_by?: Maybe<Scalars['Float']['output']>;
  unread_count?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "channel_users" */
export type Channel_Users_Variance_Order_By = {
  blocked_by?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  report_by?: InputMaybe<Order_By>;
  unread_count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "channels" */
export type Channels = {
  __typename?: 'channels';
  /** An array relationship */
  channel_users: Array<Channel_Users>;
  /** An aggregate relationship */
  channel_users_aggregate: Channel_Users_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  creator: Users;
  creator_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** An array relationship */
  messages: Array<Messages>;
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "channels" */
export type ChannelsChannel_UsersArgs = {
  distinct_on?: InputMaybe<Array<Channel_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channel_Users_Order_By>>;
  where?: InputMaybe<Channel_Users_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsChannel_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channel_Users_Order_By>>;
  where?: InputMaybe<Channel_Users_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsMessagesArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};

/** aggregated selection of "channels" */
export type Channels_Aggregate = {
  __typename?: 'channels_aggregate';
  aggregate?: Maybe<Channels_Aggregate_Fields>;
  nodes: Array<Channels>;
};

export type Channels_Aggregate_Bool_Exp = {
  count?: InputMaybe<Channels_Aggregate_Bool_Exp_Count>;
};

export type Channels_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Channels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Channels_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "channels" */
export type Channels_Aggregate_Fields = {
  __typename?: 'channels_aggregate_fields';
  avg?: Maybe<Channels_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Channels_Max_Fields>;
  min?: Maybe<Channels_Min_Fields>;
  stddev?: Maybe<Channels_Stddev_Fields>;
  stddev_pop?: Maybe<Channels_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Channels_Stddev_Samp_Fields>;
  sum?: Maybe<Channels_Sum_Fields>;
  var_pop?: Maybe<Channels_Var_Pop_Fields>;
  var_samp?: Maybe<Channels_Var_Samp_Fields>;
  variance?: Maybe<Channels_Variance_Fields>;
};


/** aggregate fields of "channels" */
export type Channels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Channels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "channels" */
export type Channels_Aggregate_Order_By = {
  avg?: InputMaybe<Channels_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Channels_Max_Order_By>;
  min?: InputMaybe<Channels_Min_Order_By>;
  stddev?: InputMaybe<Channels_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Channels_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Channels_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Channels_Sum_Order_By>;
  var_pop?: InputMaybe<Channels_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Channels_Var_Samp_Order_By>;
  variance?: InputMaybe<Channels_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "channels" */
export type Channels_Arr_Rel_Insert_Input = {
  data: Array<Channels_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Channels_On_Conflict>;
};

/** aggregate avg on columns */
export type Channels_Avg_Fields = {
  __typename?: 'channels_avg_fields';
  creator_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "channels" */
export type Channels_Avg_Order_By = {
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "channels". All fields are combined with a logical 'AND'. */
export type Channels_Bool_Exp = {
  _and?: InputMaybe<Array<Channels_Bool_Exp>>;
  _not?: InputMaybe<Channels_Bool_Exp>;
  _or?: InputMaybe<Array<Channels_Bool_Exp>>;
  channel_users?: InputMaybe<Channel_Users_Bool_Exp>;
  channel_users_aggregate?: InputMaybe<Channel_Users_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  creator?: InputMaybe<Users_Bool_Exp>;
  creator_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  messages?: InputMaybe<Messages_Bool_Exp>;
  messages_aggregate?: InputMaybe<Messages_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "channels" */
export type Channels_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'channels_pkey';

/** input type for incrementing numeric columns in table "channels" */
export type Channels_Inc_Input = {
  creator_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "channels" */
export type Channels_Insert_Input = {
  channel_users?: InputMaybe<Channel_Users_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  creator?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  creator_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  messages?: InputMaybe<Messages_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Channels_Max_Fields = {
  __typename?: 'channels_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  creator_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "channels" */
export type Channels_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Channels_Min_Fields = {
  __typename?: 'channels_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  creator_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "channels" */
export type Channels_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "channels" */
export type Channels_Mutation_Response = {
  __typename?: 'channels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Channels>;
};

/** input type for inserting object relation for remote table "channels" */
export type Channels_Obj_Rel_Insert_Input = {
  data: Channels_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Channels_On_Conflict>;
};

/** on_conflict condition type for table "channels" */
export type Channels_On_Conflict = {
  constraint: Channels_Constraint;
  update_columns?: Array<Channels_Update_Column>;
  where?: InputMaybe<Channels_Bool_Exp>;
};

/** Ordering options when selecting data from "channels". */
export type Channels_Order_By = {
  channel_users_aggregate?: InputMaybe<Channel_Users_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator?: InputMaybe<Users_Order_By>;
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  messages_aggregate?: InputMaybe<Messages_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: channels */
export type Channels_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "channels" */
export type Channels_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'creator_id'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "channels" */
export type Channels_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  creator_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Channels_Stddev_Fields = {
  __typename?: 'channels_stddev_fields';
  creator_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "channels" */
export type Channels_Stddev_Order_By = {
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Channels_Stddev_Pop_Fields = {
  __typename?: 'channels_stddev_pop_fields';
  creator_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "channels" */
export type Channels_Stddev_Pop_Order_By = {
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Channels_Stddev_Samp_Fields = {
  __typename?: 'channels_stddev_samp_fields';
  creator_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "channels" */
export type Channels_Stddev_Samp_Order_By = {
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "channels" */
export type Channels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Channels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channels_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  creator_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Channels_Sum_Fields = {
  __typename?: 'channels_sum_fields';
  creator_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "channels" */
export type Channels_Sum_Order_By = {
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "channels" */
export type Channels_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'creator_id'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at';

export type Channels_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Channels_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Channels_Set_Input>;
  /** filter the rows which have to be updated */
  where: Channels_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Channels_Var_Pop_Fields = {
  __typename?: 'channels_var_pop_fields';
  creator_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "channels" */
export type Channels_Var_Pop_Order_By = {
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Channels_Var_Samp_Fields = {
  __typename?: 'channels_var_samp_fields';
  creator_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "channels" */
export type Channels_Var_Samp_Order_By = {
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Channels_Variance_Fields = {
  __typename?: 'channels_variance_fields';
  creator_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "channels" */
export type Channels_Variance_Order_By = {
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "cities" */
export type Cities = {
  __typename?: 'cities';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "cities" */
export type Cities_Aggregate = {
  __typename?: 'cities_aggregate';
  aggregate?: Maybe<Cities_Aggregate_Fields>;
  nodes: Array<Cities>;
};

/** aggregate fields of "cities" */
export type Cities_Aggregate_Fields = {
  __typename?: 'cities_aggregate_fields';
  avg?: Maybe<Cities_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Cities_Max_Fields>;
  min?: Maybe<Cities_Min_Fields>;
  stddev?: Maybe<Cities_Stddev_Fields>;
  stddev_pop?: Maybe<Cities_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cities_Stddev_Samp_Fields>;
  sum?: Maybe<Cities_Sum_Fields>;
  var_pop?: Maybe<Cities_Var_Pop_Fields>;
  var_samp?: Maybe<Cities_Var_Samp_Fields>;
  variance?: Maybe<Cities_Variance_Fields>;
};


/** aggregate fields of "cities" */
export type Cities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Cities_Avg_Fields = {
  __typename?: 'cities_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "cities". All fields are combined with a logical 'AND'. */
export type Cities_Bool_Exp = {
  _and?: InputMaybe<Array<Cities_Bool_Exp>>;
  _not?: InputMaybe<Cities_Bool_Exp>;
  _or?: InputMaybe<Array<Cities_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "cities" */
export type Cities_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'location_names_pkey';

/** input type for incrementing numeric columns in table "cities" */
export type Cities_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "cities" */
export type Cities_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Cities_Max_Fields = {
  __typename?: 'cities_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Cities_Min_Fields = {
  __typename?: 'cities_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "cities" */
export type Cities_Mutation_Response = {
  __typename?: 'cities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Cities>;
};

/** input type for inserting object relation for remote table "cities" */
export type Cities_Obj_Rel_Insert_Input = {
  data: Cities_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Cities_On_Conflict>;
};

/** on_conflict condition type for table "cities" */
export type Cities_On_Conflict = {
  constraint: Cities_Constraint;
  update_columns?: Array<Cities_Update_Column>;
  where?: InputMaybe<Cities_Bool_Exp>;
};

/** Ordering options when selecting data from "cities". */
export type Cities_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cities */
export type Cities_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "cities" */
export type Cities_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'order'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "cities" */
export type Cities_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Cities_Stddev_Fields = {
  __typename?: 'cities_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Cities_Stddev_Pop_Fields = {
  __typename?: 'cities_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Cities_Stddev_Samp_Fields = {
  __typename?: 'cities_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "cities" */
export type Cities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cities_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Cities_Sum_Fields = {
  __typename?: 'cities_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "cities" */
export type Cities_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'order'
  /** column name */
  | 'updated_at';

export type Cities_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Cities_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cities_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cities_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Cities_Var_Pop_Fields = {
  __typename?: 'cities_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Cities_Var_Samp_Fields = {
  __typename?: 'cities_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Cities_Variance_Fields = {
  __typename?: 'cities_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "code_enum". All fields are combined with logical 'AND'. */
export type Code_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['code_enum']['input']>;
  _gt?: InputMaybe<Scalars['code_enum']['input']>;
  _gte?: InputMaybe<Scalars['code_enum']['input']>;
  _in?: InputMaybe<Array<Scalars['code_enum']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['code_enum']['input']>;
  _lte?: InputMaybe<Scalars['code_enum']['input']>;
  _neq?: InputMaybe<Scalars['code_enum']['input']>;
  _nin?: InputMaybe<Array<Scalars['code_enum']['input']>>;
};

/** columns and relationships of "contact_forms" */
export type Contact_Forms = {
  __typename?: 'contact_forms';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

/** aggregated selection of "contact_forms" */
export type Contact_Forms_Aggregate = {
  __typename?: 'contact_forms_aggregate';
  aggregate?: Maybe<Contact_Forms_Aggregate_Fields>;
  nodes: Array<Contact_Forms>;
};

/** aggregate fields of "contact_forms" */
export type Contact_Forms_Aggregate_Fields = {
  __typename?: 'contact_forms_aggregate_fields';
  avg?: Maybe<Contact_Forms_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Contact_Forms_Max_Fields>;
  min?: Maybe<Contact_Forms_Min_Fields>;
  stddev?: Maybe<Contact_Forms_Stddev_Fields>;
  stddev_pop?: Maybe<Contact_Forms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contact_Forms_Stddev_Samp_Fields>;
  sum?: Maybe<Contact_Forms_Sum_Fields>;
  var_pop?: Maybe<Contact_Forms_Var_Pop_Fields>;
  var_samp?: Maybe<Contact_Forms_Var_Samp_Fields>;
  variance?: Maybe<Contact_Forms_Variance_Fields>;
};


/** aggregate fields of "contact_forms" */
export type Contact_Forms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contact_Forms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Contact_Forms_Avg_Fields = {
  __typename?: 'contact_forms_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "contact_forms". All fields are combined with a logical 'AND'. */
export type Contact_Forms_Bool_Exp = {
  _and?: InputMaybe<Array<Contact_Forms_Bool_Exp>>;
  _not?: InputMaybe<Contact_Forms_Bool_Exp>;
  _or?: InputMaybe<Array<Contact_Forms_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_forms" */
export type Contact_Forms_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'contact_forms_pkey';

/** input type for incrementing numeric columns in table "contact_forms" */
export type Contact_Forms_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "contact_forms" */
export type Contact_Forms_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Contact_Forms_Max_Fields = {
  __typename?: 'contact_forms_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Contact_Forms_Min_Fields = {
  __typename?: 'contact_forms_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "contact_forms" */
export type Contact_Forms_Mutation_Response = {
  __typename?: 'contact_forms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Forms>;
};

/** on_conflict condition type for table "contact_forms" */
export type Contact_Forms_On_Conflict = {
  constraint: Contact_Forms_Constraint;
  update_columns?: Array<Contact_Forms_Update_Column>;
  where?: InputMaybe<Contact_Forms_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_forms". */
export type Contact_Forms_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contact_forms */
export type Contact_Forms_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "contact_forms" */
export type Contact_Forms_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'description'
  /** column name */
  | 'email'
  /** column name */
  | 'id'
  /** column name */
  | 'title';

/** input type for updating data in table "contact_forms" */
export type Contact_Forms_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Contact_Forms_Stddev_Fields = {
  __typename?: 'contact_forms_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Contact_Forms_Stddev_Pop_Fields = {
  __typename?: 'contact_forms_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Contact_Forms_Stddev_Samp_Fields = {
  __typename?: 'contact_forms_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "contact_forms" */
export type Contact_Forms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Forms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Forms_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Contact_Forms_Sum_Fields = {
  __typename?: 'contact_forms_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "contact_forms" */
export type Contact_Forms_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'description'
  /** column name */
  | 'email'
  /** column name */
  | 'id'
  /** column name */
  | 'title';

export type Contact_Forms_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Contact_Forms_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contact_Forms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Forms_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Contact_Forms_Var_Pop_Fields = {
  __typename?: 'contact_forms_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Contact_Forms_Var_Samp_Fields = {
  __typename?: 'contact_forms_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Contact_Forms_Variance_Fields = {
  __typename?: 'contact_forms_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "contents" */
export type Contents = {
  __typename?: 'contents';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  /** An array relationship */
  translations: Array<Translations>;
  /** An aggregate relationship */
  translations_aggregate: Translations_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "contents" */
export type ContentsTranslationsArgs = {
  distinct_on?: InputMaybe<Array<Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Translations_Order_By>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type ContentsTranslations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Translations_Order_By>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};

/** aggregated selection of "contents" */
export type Contents_Aggregate = {
  __typename?: 'contents_aggregate';
  aggregate?: Maybe<Contents_Aggregate_Fields>;
  nodes: Array<Contents>;
};

/** aggregate fields of "contents" */
export type Contents_Aggregate_Fields = {
  __typename?: 'contents_aggregate_fields';
  avg?: Maybe<Contents_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Contents_Max_Fields>;
  min?: Maybe<Contents_Min_Fields>;
  stddev?: Maybe<Contents_Stddev_Fields>;
  stddev_pop?: Maybe<Contents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contents_Stddev_Samp_Fields>;
  sum?: Maybe<Contents_Sum_Fields>;
  var_pop?: Maybe<Contents_Var_Pop_Fields>;
  var_samp?: Maybe<Contents_Var_Samp_Fields>;
  variance?: Maybe<Contents_Variance_Fields>;
};


/** aggregate fields of "contents" */
export type Contents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Contents_Avg_Fields = {
  __typename?: 'contents_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "contents". All fields are combined with a logical 'AND'. */
export type Contents_Bool_Exp = {
  _and?: InputMaybe<Array<Contents_Bool_Exp>>;
  _not?: InputMaybe<Contents_Bool_Exp>;
  _or?: InputMaybe<Array<Contents_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  translations?: InputMaybe<Translations_Bool_Exp>;
  translations_aggregate?: InputMaybe<Translations_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contents" */
export type Contents_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'contents_pkey';

/** input type for incrementing numeric columns in table "contents" */
export type Contents_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "contents" */
export type Contents_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  translations?: InputMaybe<Translations_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Contents_Max_Fields = {
  __typename?: 'contents_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Contents_Min_Fields = {
  __typename?: 'contents_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "contents" */
export type Contents_Mutation_Response = {
  __typename?: 'contents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Contents>;
};

/** input type for inserting object relation for remote table "contents" */
export type Contents_Obj_Rel_Insert_Input = {
  data: Contents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Contents_On_Conflict>;
};

/** on_conflict condition type for table "contents" */
export type Contents_On_Conflict = {
  constraint: Contents_Constraint;
  update_columns?: Array<Contents_Update_Column>;
  where?: InputMaybe<Contents_Bool_Exp>;
};

/** Ordering options when selecting data from "contents". */
export type Contents_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  translations_aggregate?: InputMaybe<Translations_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contents */
export type Contents_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "contents" */
export type Contents_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "contents" */
export type Contents_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Contents_Stddev_Fields = {
  __typename?: 'contents_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Contents_Stddev_Pop_Fields = {
  __typename?: 'contents_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Contents_Stddev_Samp_Fields = {
  __typename?: 'contents_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "contents" */
export type Contents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contents_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Contents_Sum_Fields = {
  __typename?: 'contents_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "contents" */
export type Contents_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at';

export type Contents_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Contents_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contents_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Contents_Var_Pop_Fields = {
  __typename?: 'contents_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Contents_Var_Samp_Fields = {
  __typename?: 'contents_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Contents_Variance_Fields = {
  __typename?: 'contents_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** ordering argument of a cursor */
export type Cursor_Ordering =
  /** ascending ordering of the cursor */
  | 'ASC'
  /** descending ordering of the cursor */
  | 'DESC';

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

/** columns and relationships of "device_tokens" */
export type Device_Tokens = {
  __typename?: 'device_tokens';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  device_token: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "device_tokens" */
export type Device_Tokens_Aggregate = {
  __typename?: 'device_tokens_aggregate';
  aggregate?: Maybe<Device_Tokens_Aggregate_Fields>;
  nodes: Array<Device_Tokens>;
};

export type Device_Tokens_Aggregate_Bool_Exp = {
  count?: InputMaybe<Device_Tokens_Aggregate_Bool_Exp_Count>;
};

export type Device_Tokens_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Device_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Device_Tokens_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "device_tokens" */
export type Device_Tokens_Aggregate_Fields = {
  __typename?: 'device_tokens_aggregate_fields';
  avg?: Maybe<Device_Tokens_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Device_Tokens_Max_Fields>;
  min?: Maybe<Device_Tokens_Min_Fields>;
  stddev?: Maybe<Device_Tokens_Stddev_Fields>;
  stddev_pop?: Maybe<Device_Tokens_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Device_Tokens_Stddev_Samp_Fields>;
  sum?: Maybe<Device_Tokens_Sum_Fields>;
  var_pop?: Maybe<Device_Tokens_Var_Pop_Fields>;
  var_samp?: Maybe<Device_Tokens_Var_Samp_Fields>;
  variance?: Maybe<Device_Tokens_Variance_Fields>;
};


/** aggregate fields of "device_tokens" */
export type Device_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Device_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "device_tokens" */
export type Device_Tokens_Aggregate_Order_By = {
  avg?: InputMaybe<Device_Tokens_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Device_Tokens_Max_Order_By>;
  min?: InputMaybe<Device_Tokens_Min_Order_By>;
  stddev?: InputMaybe<Device_Tokens_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Device_Tokens_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Device_Tokens_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Device_Tokens_Sum_Order_By>;
  var_pop?: InputMaybe<Device_Tokens_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Device_Tokens_Var_Samp_Order_By>;
  variance?: InputMaybe<Device_Tokens_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "device_tokens" */
export type Device_Tokens_Arr_Rel_Insert_Input = {
  data: Array<Device_Tokens_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Device_Tokens_On_Conflict>;
};

/** aggregate avg on columns */
export type Device_Tokens_Avg_Fields = {
  __typename?: 'device_tokens_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "device_tokens" */
export type Device_Tokens_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "device_tokens". All fields are combined with a logical 'AND'. */
export type Device_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<Device_Tokens_Bool_Exp>>;
  _not?: InputMaybe<Device_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Device_Tokens_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  device_token?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "device_tokens" */
export type Device_Tokens_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'device_tokens_pkey'
  /** unique or primary key constraint on columns "user_id", "device_token" */
  | 'unique_user_id_device_token';

/** input type for incrementing numeric columns in table "device_tokens" */
export type Device_Tokens_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "device_tokens" */
export type Device_Tokens_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  device_token?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Device_Tokens_Max_Fields = {
  __typename?: 'device_tokens_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  device_token?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "device_tokens" */
export type Device_Tokens_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  device_token?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Device_Tokens_Min_Fields = {
  __typename?: 'device_tokens_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  device_token?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "device_tokens" */
export type Device_Tokens_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  device_token?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "device_tokens" */
export type Device_Tokens_Mutation_Response = {
  __typename?: 'device_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Device_Tokens>;
};

/** on_conflict condition type for table "device_tokens" */
export type Device_Tokens_On_Conflict = {
  constraint: Device_Tokens_Constraint;
  update_columns?: Array<Device_Tokens_Update_Column>;
  where?: InputMaybe<Device_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "device_tokens". */
export type Device_Tokens_Order_By = {
  created_at?: InputMaybe<Order_By>;
  device_token?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: device_tokens */
export type Device_Tokens_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "device_tokens" */
export type Device_Tokens_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'device_token'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

/** input type for updating data in table "device_tokens" */
export type Device_Tokens_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  device_token?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Device_Tokens_Stddev_Fields = {
  __typename?: 'device_tokens_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "device_tokens" */
export type Device_Tokens_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Device_Tokens_Stddev_Pop_Fields = {
  __typename?: 'device_tokens_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "device_tokens" */
export type Device_Tokens_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Device_Tokens_Stddev_Samp_Fields = {
  __typename?: 'device_tokens_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "device_tokens" */
export type Device_Tokens_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "device_tokens" */
export type Device_Tokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Device_Tokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Device_Tokens_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  device_token?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Device_Tokens_Sum_Fields = {
  __typename?: 'device_tokens_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "device_tokens" */
export type Device_Tokens_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "device_tokens" */
export type Device_Tokens_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'device_token'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

export type Device_Tokens_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Device_Tokens_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Device_Tokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: Device_Tokens_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Device_Tokens_Var_Pop_Fields = {
  __typename?: 'device_tokens_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "device_tokens" */
export type Device_Tokens_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Device_Tokens_Var_Samp_Fields = {
  __typename?: 'device_tokens_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "device_tokens" */
export type Device_Tokens_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Device_Tokens_Variance_Fields = {
  __typename?: 'device_tokens_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "device_tokens" */
export type Device_Tokens_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "faqs" */
export type Faqs = {
  __typename?: 'faqs';
  /** An object relationship */
  answer: Contents;
  answer_id: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  question: Contents;
  question_id: Scalars['Int']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "faqs" */
export type Faqs_Aggregate = {
  __typename?: 'faqs_aggregate';
  aggregate?: Maybe<Faqs_Aggregate_Fields>;
  nodes: Array<Faqs>;
};

/** aggregate fields of "faqs" */
export type Faqs_Aggregate_Fields = {
  __typename?: 'faqs_aggregate_fields';
  avg?: Maybe<Faqs_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Faqs_Max_Fields>;
  min?: Maybe<Faqs_Min_Fields>;
  stddev?: Maybe<Faqs_Stddev_Fields>;
  stddev_pop?: Maybe<Faqs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Faqs_Stddev_Samp_Fields>;
  sum?: Maybe<Faqs_Sum_Fields>;
  var_pop?: Maybe<Faqs_Var_Pop_Fields>;
  var_samp?: Maybe<Faqs_Var_Samp_Fields>;
  variance?: Maybe<Faqs_Variance_Fields>;
};


/** aggregate fields of "faqs" */
export type Faqs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Faqs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Faqs_Avg_Fields = {
  __typename?: 'faqs_avg_fields';
  answer_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "faqs". All fields are combined with a logical 'AND'. */
export type Faqs_Bool_Exp = {
  _and?: InputMaybe<Array<Faqs_Bool_Exp>>;
  _not?: InputMaybe<Faqs_Bool_Exp>;
  _or?: InputMaybe<Array<Faqs_Bool_Exp>>;
  answer?: InputMaybe<Contents_Bool_Exp>;
  answer_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  question?: InputMaybe<Contents_Bool_Exp>;
  question_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "faqs" */
export type Faqs_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'faqs_pkey';

/** input type for incrementing numeric columns in table "faqs" */
export type Faqs_Inc_Input = {
  answer_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "faqs" */
export type Faqs_Insert_Input = {
  answer?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  answer_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Faqs_Max_Fields = {
  __typename?: 'faqs_max_fields';
  answer_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Faqs_Min_Fields = {
  __typename?: 'faqs_min_fields';
  answer_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "faqs" */
export type Faqs_Mutation_Response = {
  __typename?: 'faqs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Faqs>;
};

/** on_conflict condition type for table "faqs" */
export type Faqs_On_Conflict = {
  constraint: Faqs_Constraint;
  update_columns?: Array<Faqs_Update_Column>;
  where?: InputMaybe<Faqs_Bool_Exp>;
};

/** Ordering options when selecting data from "faqs". */
export type Faqs_Order_By = {
  answer?: InputMaybe<Contents_Order_By>;
  answer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<Contents_Order_By>;
  question_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: faqs */
export type Faqs_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "faqs" */
export type Faqs_Select_Column =
  /** column name */
  | 'answer_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'question_id'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "faqs" */
export type Faqs_Set_Input = {
  answer_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Faqs_Stddev_Fields = {
  __typename?: 'faqs_stddev_fields';
  answer_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Faqs_Stddev_Pop_Fields = {
  __typename?: 'faqs_stddev_pop_fields';
  answer_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Faqs_Stddev_Samp_Fields = {
  __typename?: 'faqs_stddev_samp_fields';
  answer_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "faqs" */
export type Faqs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Faqs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Faqs_Stream_Cursor_Value_Input = {
  answer_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Faqs_Sum_Fields = {
  __typename?: 'faqs_sum_fields';
  answer_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "faqs" */
export type Faqs_Update_Column =
  /** column name */
  | 'answer_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'question_id'
  /** column name */
  | 'updated_at';

export type Faqs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Faqs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Faqs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Faqs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Faqs_Var_Pop_Fields = {
  __typename?: 'faqs_var_pop_fields';
  answer_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Faqs_Var_Samp_Fields = {
  __typename?: 'faqs_var_samp_fields';
  answer_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Faqs_Variance_Fields = {
  __typename?: 'faqs_variance_fields';
  answer_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "friend_requests" */
export type Friend_Requests = {
  __typename?: 'friend_requests';
  id: Scalars['Int']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  receiver?: Maybe<Users>;
  receiver_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  requester?: Maybe<Users>;
  requester_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "friend_requests" */
export type Friend_Requests_Aggregate = {
  __typename?: 'friend_requests_aggregate';
  aggregate?: Maybe<Friend_Requests_Aggregate_Fields>;
  nodes: Array<Friend_Requests>;
};

export type Friend_Requests_Aggregate_Bool_Exp = {
  count?: InputMaybe<Friend_Requests_Aggregate_Bool_Exp_Count>;
};

export type Friend_Requests_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Friend_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Friend_Requests_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "friend_requests" */
export type Friend_Requests_Aggregate_Fields = {
  __typename?: 'friend_requests_aggregate_fields';
  avg?: Maybe<Friend_Requests_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Friend_Requests_Max_Fields>;
  min?: Maybe<Friend_Requests_Min_Fields>;
  stddev?: Maybe<Friend_Requests_Stddev_Fields>;
  stddev_pop?: Maybe<Friend_Requests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Friend_Requests_Stddev_Samp_Fields>;
  sum?: Maybe<Friend_Requests_Sum_Fields>;
  var_pop?: Maybe<Friend_Requests_Var_Pop_Fields>;
  var_samp?: Maybe<Friend_Requests_Var_Samp_Fields>;
  variance?: Maybe<Friend_Requests_Variance_Fields>;
};


/** aggregate fields of "friend_requests" */
export type Friend_Requests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Friend_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "friend_requests" */
export type Friend_Requests_Aggregate_Order_By = {
  avg?: InputMaybe<Friend_Requests_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Friend_Requests_Max_Order_By>;
  min?: InputMaybe<Friend_Requests_Min_Order_By>;
  stddev?: InputMaybe<Friend_Requests_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Friend_Requests_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Friend_Requests_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Friend_Requests_Sum_Order_By>;
  var_pop?: InputMaybe<Friend_Requests_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Friend_Requests_Var_Samp_Order_By>;
  variance?: InputMaybe<Friend_Requests_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "friend_requests" */
export type Friend_Requests_Arr_Rel_Insert_Input = {
  data: Array<Friend_Requests_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Friend_Requests_On_Conflict>;
};

/** aggregate avg on columns */
export type Friend_Requests_Avg_Fields = {
  __typename?: 'friend_requests_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  receiver_id?: Maybe<Scalars['Float']['output']>;
  requester_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "friend_requests" */
export type Friend_Requests_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  receiver_id?: InputMaybe<Order_By>;
  requester_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "friend_requests". All fields are combined with a logical 'AND'. */
export type Friend_Requests_Bool_Exp = {
  _and?: InputMaybe<Array<Friend_Requests_Bool_Exp>>;
  _not?: InputMaybe<Friend_Requests_Bool_Exp>;
  _or?: InputMaybe<Array<Friend_Requests_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  receiver?: InputMaybe<Users_Bool_Exp>;
  receiver_id?: InputMaybe<Int_Comparison_Exp>;
  requester?: InputMaybe<Users_Bool_Exp>;
  requester_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "friend_requests" */
export type Friend_Requests_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'friend_requests_pkey';

/** input type for incrementing numeric columns in table "friend_requests" */
export type Friend_Requests_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  receiver_id?: InputMaybe<Scalars['Int']['input']>;
  requester_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "friend_requests" */
export type Friend_Requests_Insert_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  receiver?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  receiver_id?: InputMaybe<Scalars['Int']['input']>;
  requester?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  requester_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Friend_Requests_Max_Fields = {
  __typename?: 'friend_requests_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  receiver_id?: Maybe<Scalars['Int']['output']>;
  requester_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "friend_requests" */
export type Friend_Requests_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  receiver_id?: InputMaybe<Order_By>;
  requester_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Friend_Requests_Min_Fields = {
  __typename?: 'friend_requests_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  receiver_id?: Maybe<Scalars['Int']['output']>;
  requester_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "friend_requests" */
export type Friend_Requests_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  receiver_id?: InputMaybe<Order_By>;
  requester_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "friend_requests" */
export type Friend_Requests_Mutation_Response = {
  __typename?: 'friend_requests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Friend_Requests>;
};

/** on_conflict condition type for table "friend_requests" */
export type Friend_Requests_On_Conflict = {
  constraint: Friend_Requests_Constraint;
  update_columns?: Array<Friend_Requests_Update_Column>;
  where?: InputMaybe<Friend_Requests_Bool_Exp>;
};

/** Ordering options when selecting data from "friend_requests". */
export type Friend_Requests_Order_By = {
  id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  receiver?: InputMaybe<Users_Order_By>;
  receiver_id?: InputMaybe<Order_By>;
  requester?: InputMaybe<Users_Order_By>;
  requester_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: friend_requests */
export type Friend_Requests_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "friend_requests" */
export type Friend_Requests_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'reason'
  /** column name */
  | 'receiver_id'
  /** column name */
  | 'requester_id'
  /** column name */
  | 'status';

/** input type for updating data in table "friend_requests" */
export type Friend_Requests_Set_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  receiver_id?: InputMaybe<Scalars['Int']['input']>;
  requester_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Friend_Requests_Stddev_Fields = {
  __typename?: 'friend_requests_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  receiver_id?: Maybe<Scalars['Float']['output']>;
  requester_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "friend_requests" */
export type Friend_Requests_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  receiver_id?: InputMaybe<Order_By>;
  requester_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Friend_Requests_Stddev_Pop_Fields = {
  __typename?: 'friend_requests_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  receiver_id?: Maybe<Scalars['Float']['output']>;
  requester_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "friend_requests" */
export type Friend_Requests_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  receiver_id?: InputMaybe<Order_By>;
  requester_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Friend_Requests_Stddev_Samp_Fields = {
  __typename?: 'friend_requests_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  receiver_id?: Maybe<Scalars['Float']['output']>;
  requester_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "friend_requests" */
export type Friend_Requests_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  receiver_id?: InputMaybe<Order_By>;
  requester_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "friend_requests" */
export type Friend_Requests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Friend_Requests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Friend_Requests_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  receiver_id?: InputMaybe<Scalars['Int']['input']>;
  requester_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Friend_Requests_Sum_Fields = {
  __typename?: 'friend_requests_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  receiver_id?: Maybe<Scalars['Int']['output']>;
  requester_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "friend_requests" */
export type Friend_Requests_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  receiver_id?: InputMaybe<Order_By>;
  requester_id?: InputMaybe<Order_By>;
};

/** update columns of table "friend_requests" */
export type Friend_Requests_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'reason'
  /** column name */
  | 'receiver_id'
  /** column name */
  | 'requester_id'
  /** column name */
  | 'status';

export type Friend_Requests_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Friend_Requests_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Friend_Requests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Friend_Requests_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Friend_Requests_Var_Pop_Fields = {
  __typename?: 'friend_requests_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  receiver_id?: Maybe<Scalars['Float']['output']>;
  requester_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "friend_requests" */
export type Friend_Requests_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  receiver_id?: InputMaybe<Order_By>;
  requester_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Friend_Requests_Var_Samp_Fields = {
  __typename?: 'friend_requests_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  receiver_id?: Maybe<Scalars['Float']['output']>;
  requester_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "friend_requests" */
export type Friend_Requests_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  receiver_id?: InputMaybe<Order_By>;
  requester_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Friend_Requests_Variance_Fields = {
  __typename?: 'friend_requests_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  receiver_id?: Maybe<Scalars['Float']['output']>;
  requester_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "friend_requests" */
export type Friend_Requests_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  receiver_id?: InputMaybe<Order_By>;
  requester_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "friends" */
export type Friends = {
  __typename?: 'friends';
  /** An object relationship */
  friend?: Maybe<Users>;
  friend_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "friends" */
export type Friends_Aggregate = {
  __typename?: 'friends_aggregate';
  aggregate?: Maybe<Friends_Aggregate_Fields>;
  nodes: Array<Friends>;
};

export type Friends_Aggregate_Bool_Exp = {
  count?: InputMaybe<Friends_Aggregate_Bool_Exp_Count>;
};

export type Friends_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Friends_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Friends_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "friends" */
export type Friends_Aggregate_Fields = {
  __typename?: 'friends_aggregate_fields';
  avg?: Maybe<Friends_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Friends_Max_Fields>;
  min?: Maybe<Friends_Min_Fields>;
  stddev?: Maybe<Friends_Stddev_Fields>;
  stddev_pop?: Maybe<Friends_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Friends_Stddev_Samp_Fields>;
  sum?: Maybe<Friends_Sum_Fields>;
  var_pop?: Maybe<Friends_Var_Pop_Fields>;
  var_samp?: Maybe<Friends_Var_Samp_Fields>;
  variance?: Maybe<Friends_Variance_Fields>;
};


/** aggregate fields of "friends" */
export type Friends_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Friends_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "friends" */
export type Friends_Aggregate_Order_By = {
  avg?: InputMaybe<Friends_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Friends_Max_Order_By>;
  min?: InputMaybe<Friends_Min_Order_By>;
  stddev?: InputMaybe<Friends_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Friends_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Friends_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Friends_Sum_Order_By>;
  var_pop?: InputMaybe<Friends_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Friends_Var_Samp_Order_By>;
  variance?: InputMaybe<Friends_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "friends" */
export type Friends_Arr_Rel_Insert_Input = {
  data: Array<Friends_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Friends_On_Conflict>;
};

/** aggregate avg on columns */
export type Friends_Avg_Fields = {
  __typename?: 'friends_avg_fields';
  friend_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "friends" */
export type Friends_Avg_Order_By = {
  friend_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "friends". All fields are combined with a logical 'AND'. */
export type Friends_Bool_Exp = {
  _and?: InputMaybe<Array<Friends_Bool_Exp>>;
  _not?: InputMaybe<Friends_Bool_Exp>;
  _or?: InputMaybe<Array<Friends_Bool_Exp>>;
  friend?: InputMaybe<Users_Bool_Exp>;
  friend_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "friends" */
export type Friends_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'friends_pkey';

/** input type for incrementing numeric columns in table "friends" */
export type Friends_Inc_Input = {
  friend_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "friends" */
export type Friends_Insert_Input = {
  friend?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  friend_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Friends_Max_Fields = {
  __typename?: 'friends_max_fields';
  friend_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "friends" */
export type Friends_Max_Order_By = {
  friend_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Friends_Min_Fields = {
  __typename?: 'friends_min_fields';
  friend_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "friends" */
export type Friends_Min_Order_By = {
  friend_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "friends" */
export type Friends_Mutation_Response = {
  __typename?: 'friends_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Friends>;
};

/** on_conflict condition type for table "friends" */
export type Friends_On_Conflict = {
  constraint: Friends_Constraint;
  update_columns?: Array<Friends_Update_Column>;
  where?: InputMaybe<Friends_Bool_Exp>;
};

/** Ordering options when selecting data from "friends". */
export type Friends_Order_By = {
  friend?: InputMaybe<Users_Order_By>;
  friend_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: friends */
export type Friends_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "friends" */
export type Friends_Select_Column =
  /** column name */
  | 'friend_id'
  /** column name */
  | 'id'
  /** column name */
  | 'user_id';

/** input type for updating data in table "friends" */
export type Friends_Set_Input = {
  friend_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Friends_Stddev_Fields = {
  __typename?: 'friends_stddev_fields';
  friend_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "friends" */
export type Friends_Stddev_Order_By = {
  friend_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Friends_Stddev_Pop_Fields = {
  __typename?: 'friends_stddev_pop_fields';
  friend_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "friends" */
export type Friends_Stddev_Pop_Order_By = {
  friend_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Friends_Stddev_Samp_Fields = {
  __typename?: 'friends_stddev_samp_fields';
  friend_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "friends" */
export type Friends_Stddev_Samp_Order_By = {
  friend_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "friends" */
export type Friends_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Friends_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Friends_Stream_Cursor_Value_Input = {
  friend_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Friends_Sum_Fields = {
  __typename?: 'friends_sum_fields';
  friend_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "friends" */
export type Friends_Sum_Order_By = {
  friend_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "friends" */
export type Friends_Update_Column =
  /** column name */
  | 'friend_id'
  /** column name */
  | 'id'
  /** column name */
  | 'user_id';

export type Friends_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Friends_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Friends_Set_Input>;
  /** filter the rows which have to be updated */
  where: Friends_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Friends_Var_Pop_Fields = {
  __typename?: 'friends_var_pop_fields';
  friend_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "friends" */
export type Friends_Var_Pop_Order_By = {
  friend_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Friends_Var_Samp_Fields = {
  __typename?: 'friends_var_samp_fields';
  friend_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "friends" */
export type Friends_Var_Samp_Order_By = {
  friend_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Friends_Variance_Fields = {
  __typename?: 'friends_variance_fields';
  friend_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "friends" */
export type Friends_Variance_Order_By = {
  friend_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "genders" */
export type Genders = {
  __typename?: 'genders';
  code?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  content: Contents;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  name_id: Scalars['Int']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "genders" */
export type Genders_Aggregate = {
  __typename?: 'genders_aggregate';
  aggregate?: Maybe<Genders_Aggregate_Fields>;
  nodes: Array<Genders>;
};

/** aggregate fields of "genders" */
export type Genders_Aggregate_Fields = {
  __typename?: 'genders_aggregate_fields';
  avg?: Maybe<Genders_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Genders_Max_Fields>;
  min?: Maybe<Genders_Min_Fields>;
  stddev?: Maybe<Genders_Stddev_Fields>;
  stddev_pop?: Maybe<Genders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Genders_Stddev_Samp_Fields>;
  sum?: Maybe<Genders_Sum_Fields>;
  var_pop?: Maybe<Genders_Var_Pop_Fields>;
  var_samp?: Maybe<Genders_Var_Samp_Fields>;
  variance?: Maybe<Genders_Variance_Fields>;
};


/** aggregate fields of "genders" */
export type Genders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Genders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Genders_Avg_Fields = {
  __typename?: 'genders_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "genders". All fields are combined with a logical 'AND'. */
export type Genders_Bool_Exp = {
  _and?: InputMaybe<Array<Genders_Bool_Exp>>;
  _not?: InputMaybe<Genders_Bool_Exp>;
  _or?: InputMaybe<Array<Genders_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  content?: InputMaybe<Contents_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "genders" */
export type Genders_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'genders_pkey';

/** input type for incrementing numeric columns in table "genders" */
export type Genders_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "genders" */
export type Genders_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Genders_Max_Fields = {
  __typename?: 'genders_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Genders_Min_Fields = {
  __typename?: 'genders_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "genders" */
export type Genders_Mutation_Response = {
  __typename?: 'genders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Genders>;
};

/** input type for inserting object relation for remote table "genders" */
export type Genders_Obj_Rel_Insert_Input = {
  data: Genders_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Genders_On_Conflict>;
};

/** on_conflict condition type for table "genders" */
export type Genders_On_Conflict = {
  constraint: Genders_Constraint;
  update_columns?: Array<Genders_Update_Column>;
  where?: InputMaybe<Genders_Bool_Exp>;
};

/** Ordering options when selecting data from "genders". */
export type Genders_Order_By = {
  code?: InputMaybe<Order_By>;
  content?: InputMaybe<Contents_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: genders */
export type Genders_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "genders" */
export type Genders_Select_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'name_id'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "genders" */
export type Genders_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Genders_Stddev_Fields = {
  __typename?: 'genders_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Genders_Stddev_Pop_Fields = {
  __typename?: 'genders_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Genders_Stddev_Samp_Fields = {
  __typename?: 'genders_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "genders" */
export type Genders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Genders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Genders_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Genders_Sum_Fields = {
  __typename?: 'genders_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "genders" */
export type Genders_Update_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'name_id'
  /** column name */
  | 'updated_at';

export type Genders_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Genders_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Genders_Set_Input>;
  /** filter the rows which have to be updated */
  where: Genders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Genders_Var_Pop_Fields = {
  __typename?: 'genders_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Genders_Var_Samp_Fields = {
  __typename?: 'genders_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Genders_Variance_Fields = {
  __typename?: 'genders_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "languages" */
export type Languages = {
  __typename?: 'languages';
  code: Scalars['String']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  name: Scalars['String']['output'];
  /** An array relationship */
  translations: Array<Translations>;
  /** An aggregate relationship */
  translations_aggregate: Translations_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "languages" */
export type LanguagesTranslationsArgs = {
  distinct_on?: InputMaybe<Array<Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Translations_Order_By>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};


/** columns and relationships of "languages" */
export type LanguagesTranslations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Translations_Order_By>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};

/** aggregated selection of "languages" */
export type Languages_Aggregate = {
  __typename?: 'languages_aggregate';
  aggregate?: Maybe<Languages_Aggregate_Fields>;
  nodes: Array<Languages>;
};

/** aggregate fields of "languages" */
export type Languages_Aggregate_Fields = {
  __typename?: 'languages_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Languages_Max_Fields>;
  min?: Maybe<Languages_Min_Fields>;
};


/** aggregate fields of "languages" */
export type Languages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Languages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "languages". All fields are combined with a logical 'AND'. */
export type Languages_Bool_Exp = {
  _and?: InputMaybe<Array<Languages_Bool_Exp>>;
  _not?: InputMaybe<Languages_Bool_Exp>;
  _or?: InputMaybe<Array<Languages_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  translations?: InputMaybe<Translations_Bool_Exp>;
  translations_aggregate?: InputMaybe<Translations_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "languages" */
export type Languages_Constraint =
  /** unique or primary key constraint on columns "code" */
  | 'languages_pkey';

/** input type for inserting data into table "languages" */
export type Languages_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  translations?: InputMaybe<Translations_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Languages_Max_Fields = {
  __typename?: 'languages_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Languages_Min_Fields = {
  __typename?: 'languages_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "languages" */
export type Languages_Mutation_Response = {
  __typename?: 'languages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Languages>;
};

/** input type for inserting object relation for remote table "languages" */
export type Languages_Obj_Rel_Insert_Input = {
  data: Languages_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Languages_On_Conflict>;
};

/** on_conflict condition type for table "languages" */
export type Languages_On_Conflict = {
  constraint: Languages_Constraint;
  update_columns?: Array<Languages_Update_Column>;
  where?: InputMaybe<Languages_Bool_Exp>;
};

/** Ordering options when selecting data from "languages". */
export type Languages_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  translations_aggregate?: InputMaybe<Translations_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: languages */
export type Languages_Pk_Columns_Input = {
  code: Scalars['String']['input'];
};

/** select columns of table "languages" */
export type Languages_Select_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'name'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "languages" */
export type Languages_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "languages" */
export type Languages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Languages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Languages_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "languages" */
export type Languages_Update_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'name'
  /** column name */
  | 'updated_at';

export type Languages_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Languages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Languages_Bool_Exp;
};

/** columns and relationships of "matric_question_categories" */
export type Matric_Question_Categories = {
  __typename?: 'matric_question_categories';
  code?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  content: Contents;
  content_id: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  /** An array relationship */
  matric_questions: Array<Matric_Questions>;
  /** An aggregate relationship */
  matric_questions_aggregate: Matric_Questions_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "matric_question_categories" */
export type Matric_Question_CategoriesMatric_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Matric_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Matric_Questions_Order_By>>;
  where?: InputMaybe<Matric_Questions_Bool_Exp>;
};


/** columns and relationships of "matric_question_categories" */
export type Matric_Question_CategoriesMatric_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matric_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Matric_Questions_Order_By>>;
  where?: InputMaybe<Matric_Questions_Bool_Exp>;
};

/** aggregated selection of "matric_question_categories" */
export type Matric_Question_Categories_Aggregate = {
  __typename?: 'matric_question_categories_aggregate';
  aggregate?: Maybe<Matric_Question_Categories_Aggregate_Fields>;
  nodes: Array<Matric_Question_Categories>;
};

/** aggregate fields of "matric_question_categories" */
export type Matric_Question_Categories_Aggregate_Fields = {
  __typename?: 'matric_question_categories_aggregate_fields';
  avg?: Maybe<Matric_Question_Categories_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Matric_Question_Categories_Max_Fields>;
  min?: Maybe<Matric_Question_Categories_Min_Fields>;
  stddev?: Maybe<Matric_Question_Categories_Stddev_Fields>;
  stddev_pop?: Maybe<Matric_Question_Categories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Matric_Question_Categories_Stddev_Samp_Fields>;
  sum?: Maybe<Matric_Question_Categories_Sum_Fields>;
  var_pop?: Maybe<Matric_Question_Categories_Var_Pop_Fields>;
  var_samp?: Maybe<Matric_Question_Categories_Var_Samp_Fields>;
  variance?: Maybe<Matric_Question_Categories_Variance_Fields>;
};


/** aggregate fields of "matric_question_categories" */
export type Matric_Question_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Matric_Question_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Matric_Question_Categories_Avg_Fields = {
  __typename?: 'matric_question_categories_avg_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "matric_question_categories". All fields are combined with a logical 'AND'. */
export type Matric_Question_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Matric_Question_Categories_Bool_Exp>>;
  _not?: InputMaybe<Matric_Question_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Matric_Question_Categories_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  content?: InputMaybe<Contents_Bool_Exp>;
  content_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  matric_questions?: InputMaybe<Matric_Questions_Bool_Exp>;
  matric_questions_aggregate?: InputMaybe<Matric_Questions_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "matric_question_categories" */
export type Matric_Question_Categories_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'matric_question_categories_pkey';

/** input type for incrementing numeric columns in table "matric_question_categories" */
export type Matric_Question_Categories_Inc_Input = {
  content_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "matric_question_categories" */
export type Matric_Question_Categories_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  matric_questions?: InputMaybe<Matric_Questions_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Matric_Question_Categories_Max_Fields = {
  __typename?: 'matric_question_categories_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  content_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Matric_Question_Categories_Min_Fields = {
  __typename?: 'matric_question_categories_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  content_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "matric_question_categories" */
export type Matric_Question_Categories_Mutation_Response = {
  __typename?: 'matric_question_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Matric_Question_Categories>;
};

/** input type for inserting object relation for remote table "matric_question_categories" */
export type Matric_Question_Categories_Obj_Rel_Insert_Input = {
  data: Matric_Question_Categories_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Matric_Question_Categories_On_Conflict>;
};

/** on_conflict condition type for table "matric_question_categories" */
export type Matric_Question_Categories_On_Conflict = {
  constraint: Matric_Question_Categories_Constraint;
  update_columns?: Array<Matric_Question_Categories_Update_Column>;
  where?: InputMaybe<Matric_Question_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "matric_question_categories". */
export type Matric_Question_Categories_Order_By = {
  code?: InputMaybe<Order_By>;
  content?: InputMaybe<Contents_Order_By>;
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_questions_aggregate?: InputMaybe<Matric_Questions_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: matric_question_categories */
export type Matric_Question_Categories_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "matric_question_categories" */
export type Matric_Question_Categories_Select_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'content_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "matric_question_categories" */
export type Matric_Question_Categories_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Matric_Question_Categories_Stddev_Fields = {
  __typename?: 'matric_question_categories_stddev_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Matric_Question_Categories_Stddev_Pop_Fields = {
  __typename?: 'matric_question_categories_stddev_pop_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Matric_Question_Categories_Stddev_Samp_Fields = {
  __typename?: 'matric_question_categories_stddev_samp_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "matric_question_categories" */
export type Matric_Question_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Matric_Question_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Matric_Question_Categories_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Matric_Question_Categories_Sum_Fields = {
  __typename?: 'matric_question_categories_sum_fields';
  content_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "matric_question_categories" */
export type Matric_Question_Categories_Update_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'content_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at';

export type Matric_Question_Categories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Matric_Question_Categories_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Matric_Question_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Matric_Question_Categories_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Matric_Question_Categories_Var_Pop_Fields = {
  __typename?: 'matric_question_categories_var_pop_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Matric_Question_Categories_Var_Samp_Fields = {
  __typename?: 'matric_question_categories_var_samp_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Matric_Question_Categories_Variance_Fields = {
  __typename?: 'matric_question_categories_variance_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "matric_question_responses" */
export type Matric_Question_Responses = {
  __typename?: 'matric_question_responses';
  /** An object relationship */
  content: Contents;
  content_id: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  matric_question: Matric_Questions;
  matric_question_id: Scalars['Int']['output'];
  point: Scalars['numeric']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  user_matric_question_responses: Array<User_Matric_Question_Responses>;
  /** An aggregate relationship */
  user_matric_question_responses_aggregate: User_Matric_Question_Responses_Aggregate;
};


/** columns and relationships of "matric_question_responses" */
export type Matric_Question_ResponsesUser_Matric_Question_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<User_Matric_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Matric_Question_Responses_Order_By>>;
  where?: InputMaybe<User_Matric_Question_Responses_Bool_Exp>;
};


/** columns and relationships of "matric_question_responses" */
export type Matric_Question_ResponsesUser_Matric_Question_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Matric_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Matric_Question_Responses_Order_By>>;
  where?: InputMaybe<User_Matric_Question_Responses_Bool_Exp>;
};

/** aggregated selection of "matric_question_responses" */
export type Matric_Question_Responses_Aggregate = {
  __typename?: 'matric_question_responses_aggregate';
  aggregate?: Maybe<Matric_Question_Responses_Aggregate_Fields>;
  nodes: Array<Matric_Question_Responses>;
};

export type Matric_Question_Responses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Matric_Question_Responses_Aggregate_Bool_Exp_Count>;
};

export type Matric_Question_Responses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Matric_Question_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Matric_Question_Responses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "matric_question_responses" */
export type Matric_Question_Responses_Aggregate_Fields = {
  __typename?: 'matric_question_responses_aggregate_fields';
  avg?: Maybe<Matric_Question_Responses_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Matric_Question_Responses_Max_Fields>;
  min?: Maybe<Matric_Question_Responses_Min_Fields>;
  stddev?: Maybe<Matric_Question_Responses_Stddev_Fields>;
  stddev_pop?: Maybe<Matric_Question_Responses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Matric_Question_Responses_Stddev_Samp_Fields>;
  sum?: Maybe<Matric_Question_Responses_Sum_Fields>;
  var_pop?: Maybe<Matric_Question_Responses_Var_Pop_Fields>;
  var_samp?: Maybe<Matric_Question_Responses_Var_Samp_Fields>;
  variance?: Maybe<Matric_Question_Responses_Variance_Fields>;
};


/** aggregate fields of "matric_question_responses" */
export type Matric_Question_Responses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Matric_Question_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "matric_question_responses" */
export type Matric_Question_Responses_Aggregate_Order_By = {
  avg?: InputMaybe<Matric_Question_Responses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Matric_Question_Responses_Max_Order_By>;
  min?: InputMaybe<Matric_Question_Responses_Min_Order_By>;
  stddev?: InputMaybe<Matric_Question_Responses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Matric_Question_Responses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Matric_Question_Responses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Matric_Question_Responses_Sum_Order_By>;
  var_pop?: InputMaybe<Matric_Question_Responses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Matric_Question_Responses_Var_Samp_Order_By>;
  variance?: InputMaybe<Matric_Question_Responses_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "matric_question_responses" */
export type Matric_Question_Responses_Arr_Rel_Insert_Input = {
  data: Array<Matric_Question_Responses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Matric_Question_Responses_On_Conflict>;
};

/** aggregate avg on columns */
export type Matric_Question_Responses_Avg_Fields = {
  __typename?: 'matric_question_responses_avg_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_id?: Maybe<Scalars['Float']['output']>;
  point?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "matric_question_responses" */
export type Matric_Question_Responses_Avg_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "matric_question_responses". All fields are combined with a logical 'AND'. */
export type Matric_Question_Responses_Bool_Exp = {
  _and?: InputMaybe<Array<Matric_Question_Responses_Bool_Exp>>;
  _not?: InputMaybe<Matric_Question_Responses_Bool_Exp>;
  _or?: InputMaybe<Array<Matric_Question_Responses_Bool_Exp>>;
  content?: InputMaybe<Contents_Bool_Exp>;
  content_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  icon?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  matric_question?: InputMaybe<Matric_Questions_Bool_Exp>;
  matric_question_id?: InputMaybe<Int_Comparison_Exp>;
  point?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_matric_question_responses?: InputMaybe<User_Matric_Question_Responses_Bool_Exp>;
  user_matric_question_responses_aggregate?: InputMaybe<User_Matric_Question_Responses_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "matric_question_responses" */
export type Matric_Question_Responses_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'matric_questions_responses_pkey';

/** input type for incrementing numeric columns in table "matric_question_responses" */
export type Matric_Question_Responses_Inc_Input = {
  content_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  matric_question_id?: InputMaybe<Scalars['Int']['input']>;
  point?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "matric_question_responses" */
export type Matric_Question_Responses_Insert_Input = {
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  matric_question?: InputMaybe<Matric_Questions_Obj_Rel_Insert_Input>;
  matric_question_id?: InputMaybe<Scalars['Int']['input']>;
  point?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_matric_question_responses?: InputMaybe<User_Matric_Question_Responses_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Matric_Question_Responses_Max_Fields = {
  __typename?: 'matric_question_responses_max_fields';
  content_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  matric_question_id?: Maybe<Scalars['Int']['output']>;
  point?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "matric_question_responses" */
export type Matric_Question_Responses_Max_Order_By = {
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  icon?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Matric_Question_Responses_Min_Fields = {
  __typename?: 'matric_question_responses_min_fields';
  content_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  matric_question_id?: Maybe<Scalars['Int']['output']>;
  point?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "matric_question_responses" */
export type Matric_Question_Responses_Min_Order_By = {
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  icon?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "matric_question_responses" */
export type Matric_Question_Responses_Mutation_Response = {
  __typename?: 'matric_question_responses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Matric_Question_Responses>;
};

/** input type for inserting object relation for remote table "matric_question_responses" */
export type Matric_Question_Responses_Obj_Rel_Insert_Input = {
  data: Matric_Question_Responses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Matric_Question_Responses_On_Conflict>;
};

/** on_conflict condition type for table "matric_question_responses" */
export type Matric_Question_Responses_On_Conflict = {
  constraint: Matric_Question_Responses_Constraint;
  update_columns?: Array<Matric_Question_Responses_Update_Column>;
  where?: InputMaybe<Matric_Question_Responses_Bool_Exp>;
};

/** Ordering options when selecting data from "matric_question_responses". */
export type Matric_Question_Responses_Order_By = {
  content?: InputMaybe<Contents_Order_By>;
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  icon?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question?: InputMaybe<Matric_Questions_Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_matric_question_responses_aggregate?: InputMaybe<User_Matric_Question_Responses_Aggregate_Order_By>;
};

/** primary key columns input for table: matric_question_responses */
export type Matric_Question_Responses_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "matric_question_responses" */
export type Matric_Question_Responses_Select_Column =
  /** column name */
  | 'content_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'icon'
  /** column name */
  | 'id'
  /** column name */
  | 'matric_question_id'
  /** column name */
  | 'point'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "matric_question_responses" */
export type Matric_Question_Responses_Set_Input = {
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  matric_question_id?: InputMaybe<Scalars['Int']['input']>;
  point?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Matric_Question_Responses_Stddev_Fields = {
  __typename?: 'matric_question_responses_stddev_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_id?: Maybe<Scalars['Float']['output']>;
  point?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "matric_question_responses" */
export type Matric_Question_Responses_Stddev_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Matric_Question_Responses_Stddev_Pop_Fields = {
  __typename?: 'matric_question_responses_stddev_pop_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_id?: Maybe<Scalars['Float']['output']>;
  point?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "matric_question_responses" */
export type Matric_Question_Responses_Stddev_Pop_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Matric_Question_Responses_Stddev_Samp_Fields = {
  __typename?: 'matric_question_responses_stddev_samp_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_id?: Maybe<Scalars['Float']['output']>;
  point?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "matric_question_responses" */
export type Matric_Question_Responses_Stddev_Samp_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "matric_question_responses" */
export type Matric_Question_Responses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Matric_Question_Responses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Matric_Question_Responses_Stream_Cursor_Value_Input = {
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  matric_question_id?: InputMaybe<Scalars['Int']['input']>;
  point?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Matric_Question_Responses_Sum_Fields = {
  __typename?: 'matric_question_responses_sum_fields';
  content_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  matric_question_id?: Maybe<Scalars['Int']['output']>;
  point?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "matric_question_responses" */
export type Matric_Question_Responses_Sum_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
};

/** update columns of table "matric_question_responses" */
export type Matric_Question_Responses_Update_Column =
  /** column name */
  | 'content_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'icon'
  /** column name */
  | 'id'
  /** column name */
  | 'matric_question_id'
  /** column name */
  | 'point'
  /** column name */
  | 'updated_at';

export type Matric_Question_Responses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Matric_Question_Responses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Matric_Question_Responses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Matric_Question_Responses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Matric_Question_Responses_Var_Pop_Fields = {
  __typename?: 'matric_question_responses_var_pop_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_id?: Maybe<Scalars['Float']['output']>;
  point?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "matric_question_responses" */
export type Matric_Question_Responses_Var_Pop_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Matric_Question_Responses_Var_Samp_Fields = {
  __typename?: 'matric_question_responses_var_samp_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_id?: Maybe<Scalars['Float']['output']>;
  point?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "matric_question_responses" */
export type Matric_Question_Responses_Var_Samp_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Matric_Question_Responses_Variance_Fields = {
  __typename?: 'matric_question_responses_variance_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_id?: Maybe<Scalars['Float']['output']>;
  point?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "matric_question_responses" */
export type Matric_Question_Responses_Variance_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
};

/** columns and relationships of "matric_questions" */
export type Matric_Questions = {
  __typename?: 'matric_questions';
  /** An object relationship */
  content: Contents;
  content_id: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  matric_question_category: Matric_Question_Categories;
  matric_question_category_id: Scalars['Int']['output'];
  /** An array relationship */
  matric_question_responses: Array<Matric_Question_Responses>;
  /** An aggregate relationship */
  matric_question_responses_aggregate: Matric_Question_Responses_Aggregate;
  order?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  user_matric_question_responses: Array<User_Matric_Question_Responses>;
  /** An aggregate relationship */
  user_matric_question_responses_aggregate: User_Matric_Question_Responses_Aggregate;
};


/** columns and relationships of "matric_questions" */
export type Matric_QuestionsMatric_Question_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Matric_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Matric_Question_Responses_Order_By>>;
  where?: InputMaybe<Matric_Question_Responses_Bool_Exp>;
};


/** columns and relationships of "matric_questions" */
export type Matric_QuestionsMatric_Question_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matric_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Matric_Question_Responses_Order_By>>;
  where?: InputMaybe<Matric_Question_Responses_Bool_Exp>;
};


/** columns and relationships of "matric_questions" */
export type Matric_QuestionsUser_Matric_Question_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<User_Matric_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Matric_Question_Responses_Order_By>>;
  where?: InputMaybe<User_Matric_Question_Responses_Bool_Exp>;
};


/** columns and relationships of "matric_questions" */
export type Matric_QuestionsUser_Matric_Question_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Matric_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Matric_Question_Responses_Order_By>>;
  where?: InputMaybe<User_Matric_Question_Responses_Bool_Exp>;
};

/** aggregated selection of "matric_questions" */
export type Matric_Questions_Aggregate = {
  __typename?: 'matric_questions_aggregate';
  aggregate?: Maybe<Matric_Questions_Aggregate_Fields>;
  nodes: Array<Matric_Questions>;
};

export type Matric_Questions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Matric_Questions_Aggregate_Bool_Exp_Count>;
};

export type Matric_Questions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Matric_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Matric_Questions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "matric_questions" */
export type Matric_Questions_Aggregate_Fields = {
  __typename?: 'matric_questions_aggregate_fields';
  avg?: Maybe<Matric_Questions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Matric_Questions_Max_Fields>;
  min?: Maybe<Matric_Questions_Min_Fields>;
  stddev?: Maybe<Matric_Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Matric_Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Matric_Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Matric_Questions_Sum_Fields>;
  var_pop?: Maybe<Matric_Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Matric_Questions_Var_Samp_Fields>;
  variance?: Maybe<Matric_Questions_Variance_Fields>;
};


/** aggregate fields of "matric_questions" */
export type Matric_Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Matric_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "matric_questions" */
export type Matric_Questions_Aggregate_Order_By = {
  avg?: InputMaybe<Matric_Questions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Matric_Questions_Max_Order_By>;
  min?: InputMaybe<Matric_Questions_Min_Order_By>;
  stddev?: InputMaybe<Matric_Questions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Matric_Questions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Matric_Questions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Matric_Questions_Sum_Order_By>;
  var_pop?: InputMaybe<Matric_Questions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Matric_Questions_Var_Samp_Order_By>;
  variance?: InputMaybe<Matric_Questions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "matric_questions" */
export type Matric_Questions_Arr_Rel_Insert_Input = {
  data: Array<Matric_Questions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Matric_Questions_On_Conflict>;
};

/** aggregate avg on columns */
export type Matric_Questions_Avg_Fields = {
  __typename?: 'matric_questions_avg_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_category_id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "matric_questions" */
export type Matric_Questions_Avg_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_category_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "matric_questions". All fields are combined with a logical 'AND'. */
export type Matric_Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Matric_Questions_Bool_Exp>>;
  _not?: InputMaybe<Matric_Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Matric_Questions_Bool_Exp>>;
  content?: InputMaybe<Contents_Bool_Exp>;
  content_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  matric_question_category?: InputMaybe<Matric_Question_Categories_Bool_Exp>;
  matric_question_category_id?: InputMaybe<Int_Comparison_Exp>;
  matric_question_responses?: InputMaybe<Matric_Question_Responses_Bool_Exp>;
  matric_question_responses_aggregate?: InputMaybe<Matric_Question_Responses_Aggregate_Bool_Exp>;
  order?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_matric_question_responses?: InputMaybe<User_Matric_Question_Responses_Bool_Exp>;
  user_matric_question_responses_aggregate?: InputMaybe<User_Matric_Question_Responses_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "matric_questions" */
export type Matric_Questions_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'matric_questions_pkey';

/** input type for incrementing numeric columns in table "matric_questions" */
export type Matric_Questions_Inc_Input = {
  content_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  matric_question_category_id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "matric_questions" */
export type Matric_Questions_Insert_Input = {
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  matric_question_category?: InputMaybe<Matric_Question_Categories_Obj_Rel_Insert_Input>;
  matric_question_category_id?: InputMaybe<Scalars['Int']['input']>;
  matric_question_responses?: InputMaybe<Matric_Question_Responses_Arr_Rel_Insert_Input>;
  order?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_matric_question_responses?: InputMaybe<User_Matric_Question_Responses_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Matric_Questions_Max_Fields = {
  __typename?: 'matric_questions_max_fields';
  content_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  matric_question_category_id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "matric_questions" */
export type Matric_Questions_Max_Order_By = {
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_category_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Matric_Questions_Min_Fields = {
  __typename?: 'matric_questions_min_fields';
  content_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  matric_question_category_id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "matric_questions" */
export type Matric_Questions_Min_Order_By = {
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_category_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "matric_questions" */
export type Matric_Questions_Mutation_Response = {
  __typename?: 'matric_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Matric_Questions>;
};

/** input type for inserting object relation for remote table "matric_questions" */
export type Matric_Questions_Obj_Rel_Insert_Input = {
  data: Matric_Questions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Matric_Questions_On_Conflict>;
};

/** on_conflict condition type for table "matric_questions" */
export type Matric_Questions_On_Conflict = {
  constraint: Matric_Questions_Constraint;
  update_columns?: Array<Matric_Questions_Update_Column>;
  where?: InputMaybe<Matric_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "matric_questions". */
export type Matric_Questions_Order_By = {
  content?: InputMaybe<Contents_Order_By>;
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_category?: InputMaybe<Matric_Question_Categories_Order_By>;
  matric_question_category_id?: InputMaybe<Order_By>;
  matric_question_responses_aggregate?: InputMaybe<Matric_Question_Responses_Aggregate_Order_By>;
  order?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_matric_question_responses_aggregate?: InputMaybe<User_Matric_Question_Responses_Aggregate_Order_By>;
};

/** primary key columns input for table: matric_questions */
export type Matric_Questions_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "matric_questions" */
export type Matric_Questions_Select_Column =
  /** column name */
  | 'content_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'matric_question_category_id'
  /** column name */
  | 'order'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "matric_questions" */
export type Matric_Questions_Set_Input = {
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  matric_question_category_id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Matric_Questions_Stddev_Fields = {
  __typename?: 'matric_questions_stddev_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_category_id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "matric_questions" */
export type Matric_Questions_Stddev_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_category_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Matric_Questions_Stddev_Pop_Fields = {
  __typename?: 'matric_questions_stddev_pop_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_category_id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "matric_questions" */
export type Matric_Questions_Stddev_Pop_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_category_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Matric_Questions_Stddev_Samp_Fields = {
  __typename?: 'matric_questions_stddev_samp_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_category_id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "matric_questions" */
export type Matric_Questions_Stddev_Samp_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_category_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "matric_questions" */
export type Matric_Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Matric_Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Matric_Questions_Stream_Cursor_Value_Input = {
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  matric_question_category_id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Matric_Questions_Sum_Fields = {
  __typename?: 'matric_questions_sum_fields';
  content_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  matric_question_category_id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "matric_questions" */
export type Matric_Questions_Sum_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_category_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** update columns of table "matric_questions" */
export type Matric_Questions_Update_Column =
  /** column name */
  | 'content_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'matric_question_category_id'
  /** column name */
  | 'order'
  /** column name */
  | 'updated_at';

export type Matric_Questions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Matric_Questions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Matric_Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Matric_Questions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Matric_Questions_Var_Pop_Fields = {
  __typename?: 'matric_questions_var_pop_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_category_id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "matric_questions" */
export type Matric_Questions_Var_Pop_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_category_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Matric_Questions_Var_Samp_Fields = {
  __typename?: 'matric_questions_var_samp_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_category_id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "matric_questions" */
export type Matric_Questions_Var_Samp_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_category_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Matric_Questions_Variance_Fields = {
  __typename?: 'matric_questions_variance_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_category_id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "matric_questions" */
export type Matric_Questions_Variance_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_category_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "messages" */
export type Messages = {
  __typename?: 'messages';
  /** An object relationship */
  author: Users;
  author_id: Scalars['Int']['output'];
  /** An object relationship */
  channel: Channels;
  channel_id: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "messages" */
export type Messages_Aggregate = {
  __typename?: 'messages_aggregate';
  aggregate?: Maybe<Messages_Aggregate_Fields>;
  nodes: Array<Messages>;
};

export type Messages_Aggregate_Bool_Exp = {
  count?: InputMaybe<Messages_Aggregate_Bool_Exp_Count>;
};

export type Messages_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Messages_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "messages" */
export type Messages_Aggregate_Fields = {
  __typename?: 'messages_aggregate_fields';
  avg?: Maybe<Messages_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Messages_Max_Fields>;
  min?: Maybe<Messages_Min_Fields>;
  stddev?: Maybe<Messages_Stddev_Fields>;
  stddev_pop?: Maybe<Messages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Messages_Stddev_Samp_Fields>;
  sum?: Maybe<Messages_Sum_Fields>;
  var_pop?: Maybe<Messages_Var_Pop_Fields>;
  var_samp?: Maybe<Messages_Var_Samp_Fields>;
  variance?: Maybe<Messages_Variance_Fields>;
};


/** aggregate fields of "messages" */
export type Messages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "messages" */
export type Messages_Aggregate_Order_By = {
  avg?: InputMaybe<Messages_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Messages_Max_Order_By>;
  min?: InputMaybe<Messages_Min_Order_By>;
  stddev?: InputMaybe<Messages_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Messages_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Messages_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Messages_Sum_Order_By>;
  var_pop?: InputMaybe<Messages_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Messages_Var_Samp_Order_By>;
  variance?: InputMaybe<Messages_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "messages" */
export type Messages_Arr_Rel_Insert_Input = {
  data: Array<Messages_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Messages_On_Conflict>;
};

/** aggregate avg on columns */
export type Messages_Avg_Fields = {
  __typename?: 'messages_avg_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  channel_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "messages" */
export type Messages_Avg_Order_By = {
  author_id?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "messages". All fields are combined with a logical 'AND'. */
export type Messages_Bool_Exp = {
  _and?: InputMaybe<Array<Messages_Bool_Exp>>;
  _not?: InputMaybe<Messages_Bool_Exp>;
  _or?: InputMaybe<Array<Messages_Bool_Exp>>;
  author?: InputMaybe<Users_Bool_Exp>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  channel?: InputMaybe<Channels_Bool_Exp>;
  channel_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "messages" */
export type Messages_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'messages_pkey';

/** input type for incrementing numeric columns in table "messages" */
export type Messages_Inc_Input = {
  author_id?: InputMaybe<Scalars['Int']['input']>;
  channel_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "messages" */
export type Messages_Insert_Input = {
  author?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  author_id?: InputMaybe<Scalars['Int']['input']>;
  channel?: InputMaybe<Channels_Obj_Rel_Insert_Input>;
  channel_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Messages_Max_Fields = {
  __typename?: 'messages_max_fields';
  author_id?: Maybe<Scalars['Int']['output']>;
  channel_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "messages" */
export type Messages_Max_Order_By = {
  author_id?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Messages_Min_Fields = {
  __typename?: 'messages_min_fields';
  author_id?: Maybe<Scalars['Int']['output']>;
  channel_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "messages" */
export type Messages_Min_Order_By = {
  author_id?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "messages" */
export type Messages_Mutation_Response = {
  __typename?: 'messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Messages>;
};

/** on_conflict condition type for table "messages" */
export type Messages_On_Conflict = {
  constraint: Messages_Constraint;
  update_columns?: Array<Messages_Update_Column>;
  where?: InputMaybe<Messages_Bool_Exp>;
};

/** Ordering options when selecting data from "messages". */
export type Messages_Order_By = {
  author?: InputMaybe<Users_Order_By>;
  author_id?: InputMaybe<Order_By>;
  channel?: InputMaybe<Channels_Order_By>;
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: messages */
export type Messages_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "messages" */
export type Messages_Select_Column =
  /** column name */
  | 'author_id'
  /** column name */
  | 'channel_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'message'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "messages" */
export type Messages_Set_Input = {
  author_id?: InputMaybe<Scalars['Int']['input']>;
  channel_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Messages_Stddev_Fields = {
  __typename?: 'messages_stddev_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  channel_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "messages" */
export type Messages_Stddev_Order_By = {
  author_id?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Messages_Stddev_Pop_Fields = {
  __typename?: 'messages_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  channel_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "messages" */
export type Messages_Stddev_Pop_Order_By = {
  author_id?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Messages_Stddev_Samp_Fields = {
  __typename?: 'messages_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  channel_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "messages" */
export type Messages_Stddev_Samp_Order_By = {
  author_id?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "messages" */
export type Messages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Messages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Messages_Stream_Cursor_Value_Input = {
  author_id?: InputMaybe<Scalars['Int']['input']>;
  channel_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Messages_Sum_Fields = {
  __typename?: 'messages_sum_fields';
  author_id?: Maybe<Scalars['Int']['output']>;
  channel_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "messages" */
export type Messages_Sum_Order_By = {
  author_id?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "messages" */
export type Messages_Update_Column =
  /** column name */
  | 'author_id'
  /** column name */
  | 'channel_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'message'
  /** column name */
  | 'updated_at';

export type Messages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Messages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Messages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Messages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Messages_Var_Pop_Fields = {
  __typename?: 'messages_var_pop_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  channel_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "messages" */
export type Messages_Var_Pop_Order_By = {
  author_id?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Messages_Var_Samp_Fields = {
  __typename?: 'messages_var_samp_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  channel_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "messages" */
export type Messages_Var_Samp_Order_By = {
  author_id?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Messages_Variance_Fields = {
  __typename?: 'messages_variance_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  channel_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "messages" */
export type Messages_Variance_Order_By = {
  author_id?: InputMaybe<Order_By>;
  channel_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** Handle active user */
  activeUser: ActiveUserResponse;
  approveFriendRequest?: Maybe<ApproveFriendRequestOutput>;
  /** Handle book coach */
  bookCoach: BookCoachResponse;
  changePassword: ChangePasswordResponse;
  completeBooking: CompleteBookingResponse;
  createCoach: CreateCoachResponse;
  createUser?: Maybe<CreateUserResponse>;
  declineFriendRequest?: Maybe<DeclineFriendRequestOutput>;
  /** delete data from the table: "attachment_test_categories" */
  delete_attachment_test_categories?: Maybe<Attachment_Test_Categories_Mutation_Response>;
  /** delete single row from the table: "attachment_test_categories" */
  delete_attachment_test_categories_by_pk?: Maybe<Attachment_Test_Categories>;
  /** delete data from the table: "attachment_test_responses" */
  delete_attachment_test_responses?: Maybe<Attachment_Test_Responses_Mutation_Response>;
  /** delete single row from the table: "attachment_test_responses" */
  delete_attachment_test_responses_by_pk?: Maybe<Attachment_Test_Responses>;
  /** delete data from the table: "attachment_tests" */
  delete_attachment_tests?: Maybe<Attachment_Tests_Mutation_Response>;
  /** delete single row from the table: "attachment_tests" */
  delete_attachment_tests_by_pk?: Maybe<Attachment_Tests>;
  /** delete data from the table: "availability_time_slots" */
  delete_availability_time_slots?: Maybe<Availability_Time_Slots_Mutation_Response>;
  /** delete single row from the table: "availability_time_slots" */
  delete_availability_time_slots_by_pk?: Maybe<Availability_Time_Slots>;
  /** delete data from the table: "avatar_results" */
  delete_avatar_results?: Maybe<Avatar_Results_Mutation_Response>;
  /** delete single row from the table: "avatar_results" */
  delete_avatar_results_by_pk?: Maybe<Avatar_Results>;
  /** delete data from the table: "avatar_test_responses" */
  delete_avatar_test_responses?: Maybe<Avatar_Test_Responses_Mutation_Response>;
  /** delete single row from the table: "avatar_test_responses" */
  delete_avatar_test_responses_by_pk?: Maybe<Avatar_Test_Responses>;
  /** delete data from the table: "avatar_tests" */
  delete_avatar_tests?: Maybe<Avatar_Tests_Mutation_Response>;
  /** delete single row from the table: "avatar_tests" */
  delete_avatar_tests_by_pk?: Maybe<Avatar_Tests>;
  /** delete data from the table: "channel_users" */
  delete_channel_users?: Maybe<Channel_Users_Mutation_Response>;
  /** delete single row from the table: "channel_users" */
  delete_channel_users_by_pk?: Maybe<Channel_Users>;
  /** delete data from the table: "channels" */
  delete_channels?: Maybe<Channels_Mutation_Response>;
  /** delete single row from the table: "channels" */
  delete_channels_by_pk?: Maybe<Channels>;
  /** delete data from the table: "cities" */
  delete_cities?: Maybe<Cities_Mutation_Response>;
  /** delete single row from the table: "cities" */
  delete_cities_by_pk?: Maybe<Cities>;
  /** delete data from the table: "contact_forms" */
  delete_contact_forms?: Maybe<Contact_Forms_Mutation_Response>;
  /** delete single row from the table: "contact_forms" */
  delete_contact_forms_by_pk?: Maybe<Contact_Forms>;
  /** delete data from the table: "contents" */
  delete_contents?: Maybe<Contents_Mutation_Response>;
  /** delete single row from the table: "contents" */
  delete_contents_by_pk?: Maybe<Contents>;
  /** delete data from the table: "device_tokens" */
  delete_device_tokens?: Maybe<Device_Tokens_Mutation_Response>;
  /** delete single row from the table: "device_tokens" */
  delete_device_tokens_by_pk?: Maybe<Device_Tokens>;
  /** delete data from the table: "faqs" */
  delete_faqs?: Maybe<Faqs_Mutation_Response>;
  /** delete single row from the table: "faqs" */
  delete_faqs_by_pk?: Maybe<Faqs>;
  /** delete data from the table: "friend_requests" */
  delete_friend_requests?: Maybe<Friend_Requests_Mutation_Response>;
  /** delete single row from the table: "friend_requests" */
  delete_friend_requests_by_pk?: Maybe<Friend_Requests>;
  /** delete data from the table: "friends" */
  delete_friends?: Maybe<Friends_Mutation_Response>;
  /** delete single row from the table: "friends" */
  delete_friends_by_pk?: Maybe<Friends>;
  /** delete data from the table: "genders" */
  delete_genders?: Maybe<Genders_Mutation_Response>;
  /** delete single row from the table: "genders" */
  delete_genders_by_pk?: Maybe<Genders>;
  /** delete data from the table: "languages" */
  delete_languages?: Maybe<Languages_Mutation_Response>;
  /** delete single row from the table: "languages" */
  delete_languages_by_pk?: Maybe<Languages>;
  /** delete data from the table: "matric_question_categories" */
  delete_matric_question_categories?: Maybe<Matric_Question_Categories_Mutation_Response>;
  /** delete single row from the table: "matric_question_categories" */
  delete_matric_question_categories_by_pk?: Maybe<Matric_Question_Categories>;
  /** delete data from the table: "matric_question_responses" */
  delete_matric_question_responses?: Maybe<Matric_Question_Responses_Mutation_Response>;
  /** delete single row from the table: "matric_question_responses" */
  delete_matric_question_responses_by_pk?: Maybe<Matric_Question_Responses>;
  /** delete data from the table: "matric_questions" */
  delete_matric_questions?: Maybe<Matric_Questions_Mutation_Response>;
  /** delete single row from the table: "matric_questions" */
  delete_matric_questions_by_pk?: Maybe<Matric_Questions>;
  /** delete data from the table: "messages" */
  delete_messages?: Maybe<Messages_Mutation_Response>;
  /** delete single row from the table: "messages" */
  delete_messages_by_pk?: Maybe<Messages>;
  /** delete data from the table: "notifications" */
  delete_notifications?: Maybe<Notifications_Mutation_Response>;
  /** delete single row from the table: "notifications" */
  delete_notifications_by_pk?: Maybe<Notifications>;
  /** delete data from the table: "otps" */
  delete_otps?: Maybe<Otps_Mutation_Response>;
  /** delete single row from the table: "otps" */
  delete_otps_by_pk?: Maybe<Otps>;
  /** delete data from the table: "password_reset_tokens" */
  delete_password_reset_tokens?: Maybe<Password_Reset_Tokens_Mutation_Response>;
  /** delete single row from the table: "password_reset_tokens" */
  delete_password_reset_tokens_by_pk?: Maybe<Password_Reset_Tokens>;
  /** delete data from the table: "personal_sun_types" */
  delete_personal_sun_types?: Maybe<Personal_Sun_Types_Mutation_Response>;
  /** delete single row from the table: "personal_sun_types" */
  delete_personal_sun_types_by_pk?: Maybe<Personal_Sun_Types>;
  /** delete data from the table: "personal_suns" */
  delete_personal_suns?: Maybe<Personal_Suns_Mutation_Response>;
  /** delete single row from the table: "personal_suns" */
  delete_personal_suns_by_pk?: Maybe<Personal_Suns>;
  /** delete data from the table: "profiles" */
  delete_profiles?: Maybe<Profiles_Mutation_Response>;
  /** delete single row from the table: "profiles" */
  delete_profiles_by_pk?: Maybe<Profiles>;
  /** delete data from the table: "reservation_payments" */
  delete_reservation_payments?: Maybe<Reservation_Payments_Mutation_Response>;
  /** delete single row from the table: "reservation_payments" */
  delete_reservation_payments_by_pk?: Maybe<Reservation_Payments>;
  /** delete data from the table: "reservations" */
  delete_reservations?: Maybe<Reservations_Mutation_Response>;
  /** delete single row from the table: "reservations" */
  delete_reservations_by_pk?: Maybe<Reservations>;
  /** delete data from the table: "reviews" */
  delete_reviews?: Maybe<Reviews_Mutation_Response>;
  /** delete single row from the table: "reviews" */
  delete_reviews_by_pk?: Maybe<Reviews>;
  /** delete data from the table: "settings" */
  delete_settings?: Maybe<Settings_Mutation_Response>;
  /** delete single row from the table: "settings" */
  delete_settings_by_pk?: Maybe<Settings>;
  /** delete data from the table: "subscriptions" */
  delete_subscriptions?: Maybe<Subscriptions_Mutation_Response>;
  /** delete single row from the table: "subscriptions" */
  delete_subscriptions_by_pk?: Maybe<Subscriptions>;
  /** delete data from the table: "template_question_responses" */
  delete_template_question_responses?: Maybe<Template_Question_Responses_Mutation_Response>;
  /** delete single row from the table: "template_question_responses" */
  delete_template_question_responses_by_pk?: Maybe<Template_Question_Responses>;
  /** delete data from the table: "template_questions" */
  delete_template_questions?: Maybe<Template_Questions_Mutation_Response>;
  /** delete single row from the table: "template_questions" */
  delete_template_questions_by_pk?: Maybe<Template_Questions>;
  /** delete data from the table: "time_slots" */
  delete_time_slots?: Maybe<Time_Slots_Mutation_Response>;
  /** delete single row from the table: "time_slots" */
  delete_time_slots_by_pk?: Maybe<Time_Slots>;
  /** delete data from the table: "translations" */
  delete_translations?: Maybe<Translations_Mutation_Response>;
  /** delete single row from the table: "translations" */
  delete_translations_by_pk?: Maybe<Translations>;
  /** delete data from the table: "user_attachment_test_responses" */
  delete_user_attachment_test_responses?: Maybe<User_Attachment_Test_Responses_Mutation_Response>;
  /** delete single row from the table: "user_attachment_test_responses" */
  delete_user_attachment_test_responses_by_pk?: Maybe<User_Attachment_Test_Responses>;
  /** delete data from the table: "user_avatar_test_responses" */
  delete_user_avatar_test_responses?: Maybe<User_Avatar_Test_Responses_Mutation_Response>;
  /** delete single row from the table: "user_avatar_test_responses" */
  delete_user_avatar_test_responses_by_pk?: Maybe<User_Avatar_Test_Responses>;
  /** delete data from the table: "user_matric_question_responses" */
  delete_user_matric_question_responses?: Maybe<User_Matric_Question_Responses_Mutation_Response>;
  /** delete single row from the table: "user_matric_question_responses" */
  delete_user_matric_question_responses_by_pk?: Maybe<User_Matric_Question_Responses>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "vouchers" */
  delete_vouchers?: Maybe<Vouchers_Mutation_Response>;
  /** delete single row from the table: "vouchers" */
  delete_vouchers_by_pk?: Maybe<Vouchers>;
  exportUserProfile: Scalars['String']['output'];
  finalizeAttachmentTest: Scalars['Boolean']['output'];
  finalizeAvatarTest: Scalars['Boolean']['output'];
  finalizeMatricQuestion: Scalars['Boolean']['output'];
  /** Handle forgot password */
  forgotPassword: ForgotPasswordResponse;
  getMatchProfileById?: Maybe<MatchedProfile>;
  getMatchProfiles?: Maybe<MatchProfilesResponse>;
  initializeReservationPayment: InitializeReservationPaymentResponse;
  /** insert data into the table: "attachment_test_categories" */
  insert_attachment_test_categories?: Maybe<Attachment_Test_Categories_Mutation_Response>;
  /** insert a single row into the table: "attachment_test_categories" */
  insert_attachment_test_categories_one?: Maybe<Attachment_Test_Categories>;
  /** insert data into the table: "attachment_test_responses" */
  insert_attachment_test_responses?: Maybe<Attachment_Test_Responses_Mutation_Response>;
  /** insert a single row into the table: "attachment_test_responses" */
  insert_attachment_test_responses_one?: Maybe<Attachment_Test_Responses>;
  /** insert data into the table: "attachment_tests" */
  insert_attachment_tests?: Maybe<Attachment_Tests_Mutation_Response>;
  /** insert a single row into the table: "attachment_tests" */
  insert_attachment_tests_one?: Maybe<Attachment_Tests>;
  /** insert data into the table: "availability_time_slots" */
  insert_availability_time_slots?: Maybe<Availability_Time_Slots_Mutation_Response>;
  /** insert a single row into the table: "availability_time_slots" */
  insert_availability_time_slots_one?: Maybe<Availability_Time_Slots>;
  /** insert data into the table: "avatar_results" */
  insert_avatar_results?: Maybe<Avatar_Results_Mutation_Response>;
  /** insert a single row into the table: "avatar_results" */
  insert_avatar_results_one?: Maybe<Avatar_Results>;
  /** insert data into the table: "avatar_test_responses" */
  insert_avatar_test_responses?: Maybe<Avatar_Test_Responses_Mutation_Response>;
  /** insert a single row into the table: "avatar_test_responses" */
  insert_avatar_test_responses_one?: Maybe<Avatar_Test_Responses>;
  /** insert data into the table: "avatar_tests" */
  insert_avatar_tests?: Maybe<Avatar_Tests_Mutation_Response>;
  /** insert a single row into the table: "avatar_tests" */
  insert_avatar_tests_one?: Maybe<Avatar_Tests>;
  /** insert data into the table: "channel_users" */
  insert_channel_users?: Maybe<Channel_Users_Mutation_Response>;
  /** insert a single row into the table: "channel_users" */
  insert_channel_users_one?: Maybe<Channel_Users>;
  /** insert data into the table: "channels" */
  insert_channels?: Maybe<Channels_Mutation_Response>;
  /** insert a single row into the table: "channels" */
  insert_channels_one?: Maybe<Channels>;
  /** insert data into the table: "cities" */
  insert_cities?: Maybe<Cities_Mutation_Response>;
  /** insert a single row into the table: "cities" */
  insert_cities_one?: Maybe<Cities>;
  /** insert data into the table: "contact_forms" */
  insert_contact_forms?: Maybe<Contact_Forms_Mutation_Response>;
  /** insert a single row into the table: "contact_forms" */
  insert_contact_forms_one?: Maybe<Contact_Forms>;
  /** insert data into the table: "contents" */
  insert_contents?: Maybe<Contents_Mutation_Response>;
  /** insert a single row into the table: "contents" */
  insert_contents_one?: Maybe<Contents>;
  /** insert data into the table: "device_tokens" */
  insert_device_tokens?: Maybe<Device_Tokens_Mutation_Response>;
  /** insert a single row into the table: "device_tokens" */
  insert_device_tokens_one?: Maybe<Device_Tokens>;
  /** insert data into the table: "faqs" */
  insert_faqs?: Maybe<Faqs_Mutation_Response>;
  /** insert a single row into the table: "faqs" */
  insert_faqs_one?: Maybe<Faqs>;
  /** insert data into the table: "friend_requests" */
  insert_friend_requests?: Maybe<Friend_Requests_Mutation_Response>;
  /** insert a single row into the table: "friend_requests" */
  insert_friend_requests_one?: Maybe<Friend_Requests>;
  /** insert data into the table: "friends" */
  insert_friends?: Maybe<Friends_Mutation_Response>;
  /** insert a single row into the table: "friends" */
  insert_friends_one?: Maybe<Friends>;
  /** insert data into the table: "genders" */
  insert_genders?: Maybe<Genders_Mutation_Response>;
  /** insert a single row into the table: "genders" */
  insert_genders_one?: Maybe<Genders>;
  /** insert data into the table: "languages" */
  insert_languages?: Maybe<Languages_Mutation_Response>;
  /** insert a single row into the table: "languages" */
  insert_languages_one?: Maybe<Languages>;
  /** insert data into the table: "matric_question_categories" */
  insert_matric_question_categories?: Maybe<Matric_Question_Categories_Mutation_Response>;
  /** insert a single row into the table: "matric_question_categories" */
  insert_matric_question_categories_one?: Maybe<Matric_Question_Categories>;
  /** insert data into the table: "matric_question_responses" */
  insert_matric_question_responses?: Maybe<Matric_Question_Responses_Mutation_Response>;
  /** insert a single row into the table: "matric_question_responses" */
  insert_matric_question_responses_one?: Maybe<Matric_Question_Responses>;
  /** insert data into the table: "matric_questions" */
  insert_matric_questions?: Maybe<Matric_Questions_Mutation_Response>;
  /** insert a single row into the table: "matric_questions" */
  insert_matric_questions_one?: Maybe<Matric_Questions>;
  /** insert data into the table: "messages" */
  insert_messages?: Maybe<Messages_Mutation_Response>;
  /** insert a single row into the table: "messages" */
  insert_messages_one?: Maybe<Messages>;
  /** insert data into the table: "notifications" */
  insert_notifications?: Maybe<Notifications_Mutation_Response>;
  /** insert a single row into the table: "notifications" */
  insert_notifications_one?: Maybe<Notifications>;
  /** insert data into the table: "otps" */
  insert_otps?: Maybe<Otps_Mutation_Response>;
  /** insert a single row into the table: "otps" */
  insert_otps_one?: Maybe<Otps>;
  /** insert data into the table: "password_reset_tokens" */
  insert_password_reset_tokens?: Maybe<Password_Reset_Tokens_Mutation_Response>;
  /** insert a single row into the table: "password_reset_tokens" */
  insert_password_reset_tokens_one?: Maybe<Password_Reset_Tokens>;
  /** insert data into the table: "personal_sun_types" */
  insert_personal_sun_types?: Maybe<Personal_Sun_Types_Mutation_Response>;
  /** insert a single row into the table: "personal_sun_types" */
  insert_personal_sun_types_one?: Maybe<Personal_Sun_Types>;
  /** insert data into the table: "personal_suns" */
  insert_personal_suns?: Maybe<Personal_Suns_Mutation_Response>;
  /** insert a single row into the table: "personal_suns" */
  insert_personal_suns_one?: Maybe<Personal_Suns>;
  /** insert data into the table: "profiles" */
  insert_profiles?: Maybe<Profiles_Mutation_Response>;
  /** insert a single row into the table: "profiles" */
  insert_profiles_one?: Maybe<Profiles>;
  /** insert data into the table: "reservation_payments" */
  insert_reservation_payments?: Maybe<Reservation_Payments_Mutation_Response>;
  /** insert a single row into the table: "reservation_payments" */
  insert_reservation_payments_one?: Maybe<Reservation_Payments>;
  /** insert data into the table: "reservations" */
  insert_reservations?: Maybe<Reservations_Mutation_Response>;
  /** insert a single row into the table: "reservations" */
  insert_reservations_one?: Maybe<Reservations>;
  /** insert data into the table: "reviews" */
  insert_reviews?: Maybe<Reviews_Mutation_Response>;
  /** insert a single row into the table: "reviews" */
  insert_reviews_one?: Maybe<Reviews>;
  /** insert data into the table: "settings" */
  insert_settings?: Maybe<Settings_Mutation_Response>;
  /** insert a single row into the table: "settings" */
  insert_settings_one?: Maybe<Settings>;
  /** insert data into the table: "subscriptions" */
  insert_subscriptions?: Maybe<Subscriptions_Mutation_Response>;
  /** insert a single row into the table: "subscriptions" */
  insert_subscriptions_one?: Maybe<Subscriptions>;
  /** insert data into the table: "template_question_responses" */
  insert_template_question_responses?: Maybe<Template_Question_Responses_Mutation_Response>;
  /** insert a single row into the table: "template_question_responses" */
  insert_template_question_responses_one?: Maybe<Template_Question_Responses>;
  /** insert data into the table: "template_questions" */
  insert_template_questions?: Maybe<Template_Questions_Mutation_Response>;
  /** insert a single row into the table: "template_questions" */
  insert_template_questions_one?: Maybe<Template_Questions>;
  /** insert data into the table: "time_slots" */
  insert_time_slots?: Maybe<Time_Slots_Mutation_Response>;
  /** insert a single row into the table: "time_slots" */
  insert_time_slots_one?: Maybe<Time_Slots>;
  /** insert data into the table: "translations" */
  insert_translations?: Maybe<Translations_Mutation_Response>;
  /** insert a single row into the table: "translations" */
  insert_translations_one?: Maybe<Translations>;
  /** insert data into the table: "user_attachment_test_responses" */
  insert_user_attachment_test_responses?: Maybe<User_Attachment_Test_Responses_Mutation_Response>;
  /** insert a single row into the table: "user_attachment_test_responses" */
  insert_user_attachment_test_responses_one?: Maybe<User_Attachment_Test_Responses>;
  /** insert data into the table: "user_avatar_test_responses" */
  insert_user_avatar_test_responses?: Maybe<User_Avatar_Test_Responses_Mutation_Response>;
  /** insert a single row into the table: "user_avatar_test_responses" */
  insert_user_avatar_test_responses_one?: Maybe<User_Avatar_Test_Responses>;
  /** insert data into the table: "user_matric_question_responses" */
  insert_user_matric_question_responses?: Maybe<User_Matric_Question_Responses_Mutation_Response>;
  /** insert a single row into the table: "user_matric_question_responses" */
  insert_user_matric_question_responses_one?: Maybe<User_Matric_Question_Responses>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "vouchers" */
  insert_vouchers?: Maybe<Vouchers_Mutation_Response>;
  /** insert a single row into the table: "vouchers" */
  insert_vouchers_one?: Maybe<Vouchers>;
  /** Login function */
  login: LoginResponse;
  pushNotification: Scalars['Boolean']['output'];
  /** Handle renew token */
  refreshToken: RefreshTokenResponse;
  releaseFunds: ReleaseFundsResponse;
  requestFriend: RequestFriendOutput;
  requestOTP: RequestOtpResponse;
  /** Handle reset password */
  resetPassword: ResetPasswordResponse;
  reviewReservation: ReviewReservationOutput;
  sendEmail: SendEmailResponse;
  updateReservationStatus: UpdateReservationStatusOutput;
  /** update data of the table: "attachment_test_categories" */
  update_attachment_test_categories?: Maybe<Attachment_Test_Categories_Mutation_Response>;
  /** update single row of the table: "attachment_test_categories" */
  update_attachment_test_categories_by_pk?: Maybe<Attachment_Test_Categories>;
  /** update multiples rows of table: "attachment_test_categories" */
  update_attachment_test_categories_many?: Maybe<Array<Maybe<Attachment_Test_Categories_Mutation_Response>>>;
  /** update data of the table: "attachment_test_responses" */
  update_attachment_test_responses?: Maybe<Attachment_Test_Responses_Mutation_Response>;
  /** update single row of the table: "attachment_test_responses" */
  update_attachment_test_responses_by_pk?: Maybe<Attachment_Test_Responses>;
  /** update multiples rows of table: "attachment_test_responses" */
  update_attachment_test_responses_many?: Maybe<Array<Maybe<Attachment_Test_Responses_Mutation_Response>>>;
  /** update data of the table: "attachment_tests" */
  update_attachment_tests?: Maybe<Attachment_Tests_Mutation_Response>;
  /** update single row of the table: "attachment_tests" */
  update_attachment_tests_by_pk?: Maybe<Attachment_Tests>;
  /** update multiples rows of table: "attachment_tests" */
  update_attachment_tests_many?: Maybe<Array<Maybe<Attachment_Tests_Mutation_Response>>>;
  /** update data of the table: "availability_time_slots" */
  update_availability_time_slots?: Maybe<Availability_Time_Slots_Mutation_Response>;
  /** update single row of the table: "availability_time_slots" */
  update_availability_time_slots_by_pk?: Maybe<Availability_Time_Slots>;
  /** update multiples rows of table: "availability_time_slots" */
  update_availability_time_slots_many?: Maybe<Array<Maybe<Availability_Time_Slots_Mutation_Response>>>;
  /** update data of the table: "avatar_results" */
  update_avatar_results?: Maybe<Avatar_Results_Mutation_Response>;
  /** update single row of the table: "avatar_results" */
  update_avatar_results_by_pk?: Maybe<Avatar_Results>;
  /** update multiples rows of table: "avatar_results" */
  update_avatar_results_many?: Maybe<Array<Maybe<Avatar_Results_Mutation_Response>>>;
  /** update data of the table: "avatar_test_responses" */
  update_avatar_test_responses?: Maybe<Avatar_Test_Responses_Mutation_Response>;
  /** update single row of the table: "avatar_test_responses" */
  update_avatar_test_responses_by_pk?: Maybe<Avatar_Test_Responses>;
  /** update multiples rows of table: "avatar_test_responses" */
  update_avatar_test_responses_many?: Maybe<Array<Maybe<Avatar_Test_Responses_Mutation_Response>>>;
  /** update data of the table: "avatar_tests" */
  update_avatar_tests?: Maybe<Avatar_Tests_Mutation_Response>;
  /** update single row of the table: "avatar_tests" */
  update_avatar_tests_by_pk?: Maybe<Avatar_Tests>;
  /** update multiples rows of table: "avatar_tests" */
  update_avatar_tests_many?: Maybe<Array<Maybe<Avatar_Tests_Mutation_Response>>>;
  /** update data of the table: "channel_users" */
  update_channel_users?: Maybe<Channel_Users_Mutation_Response>;
  /** update single row of the table: "channel_users" */
  update_channel_users_by_pk?: Maybe<Channel_Users>;
  /** update multiples rows of table: "channel_users" */
  update_channel_users_many?: Maybe<Array<Maybe<Channel_Users_Mutation_Response>>>;
  /** update data of the table: "channels" */
  update_channels?: Maybe<Channels_Mutation_Response>;
  /** update single row of the table: "channels" */
  update_channels_by_pk?: Maybe<Channels>;
  /** update multiples rows of table: "channels" */
  update_channels_many?: Maybe<Array<Maybe<Channels_Mutation_Response>>>;
  /** update data of the table: "cities" */
  update_cities?: Maybe<Cities_Mutation_Response>;
  /** update single row of the table: "cities" */
  update_cities_by_pk?: Maybe<Cities>;
  /** update multiples rows of table: "cities" */
  update_cities_many?: Maybe<Array<Maybe<Cities_Mutation_Response>>>;
  /** update data of the table: "contact_forms" */
  update_contact_forms?: Maybe<Contact_Forms_Mutation_Response>;
  /** update single row of the table: "contact_forms" */
  update_contact_forms_by_pk?: Maybe<Contact_Forms>;
  /** update multiples rows of table: "contact_forms" */
  update_contact_forms_many?: Maybe<Array<Maybe<Contact_Forms_Mutation_Response>>>;
  /** update data of the table: "contents" */
  update_contents?: Maybe<Contents_Mutation_Response>;
  /** update single row of the table: "contents" */
  update_contents_by_pk?: Maybe<Contents>;
  /** update multiples rows of table: "contents" */
  update_contents_many?: Maybe<Array<Maybe<Contents_Mutation_Response>>>;
  /** update data of the table: "device_tokens" */
  update_device_tokens?: Maybe<Device_Tokens_Mutation_Response>;
  /** update single row of the table: "device_tokens" */
  update_device_tokens_by_pk?: Maybe<Device_Tokens>;
  /** update multiples rows of table: "device_tokens" */
  update_device_tokens_many?: Maybe<Array<Maybe<Device_Tokens_Mutation_Response>>>;
  /** update data of the table: "faqs" */
  update_faqs?: Maybe<Faqs_Mutation_Response>;
  /** update single row of the table: "faqs" */
  update_faqs_by_pk?: Maybe<Faqs>;
  /** update multiples rows of table: "faqs" */
  update_faqs_many?: Maybe<Array<Maybe<Faqs_Mutation_Response>>>;
  /** update data of the table: "friend_requests" */
  update_friend_requests?: Maybe<Friend_Requests_Mutation_Response>;
  /** update single row of the table: "friend_requests" */
  update_friend_requests_by_pk?: Maybe<Friend_Requests>;
  /** update multiples rows of table: "friend_requests" */
  update_friend_requests_many?: Maybe<Array<Maybe<Friend_Requests_Mutation_Response>>>;
  /** update data of the table: "friends" */
  update_friends?: Maybe<Friends_Mutation_Response>;
  /** update single row of the table: "friends" */
  update_friends_by_pk?: Maybe<Friends>;
  /** update multiples rows of table: "friends" */
  update_friends_many?: Maybe<Array<Maybe<Friends_Mutation_Response>>>;
  /** update data of the table: "genders" */
  update_genders?: Maybe<Genders_Mutation_Response>;
  /** update single row of the table: "genders" */
  update_genders_by_pk?: Maybe<Genders>;
  /** update multiples rows of table: "genders" */
  update_genders_many?: Maybe<Array<Maybe<Genders_Mutation_Response>>>;
  /** update data of the table: "languages" */
  update_languages?: Maybe<Languages_Mutation_Response>;
  /** update single row of the table: "languages" */
  update_languages_by_pk?: Maybe<Languages>;
  /** update multiples rows of table: "languages" */
  update_languages_many?: Maybe<Array<Maybe<Languages_Mutation_Response>>>;
  /** update data of the table: "matric_question_categories" */
  update_matric_question_categories?: Maybe<Matric_Question_Categories_Mutation_Response>;
  /** update single row of the table: "matric_question_categories" */
  update_matric_question_categories_by_pk?: Maybe<Matric_Question_Categories>;
  /** update multiples rows of table: "matric_question_categories" */
  update_matric_question_categories_many?: Maybe<Array<Maybe<Matric_Question_Categories_Mutation_Response>>>;
  /** update data of the table: "matric_question_responses" */
  update_matric_question_responses?: Maybe<Matric_Question_Responses_Mutation_Response>;
  /** update single row of the table: "matric_question_responses" */
  update_matric_question_responses_by_pk?: Maybe<Matric_Question_Responses>;
  /** update multiples rows of table: "matric_question_responses" */
  update_matric_question_responses_many?: Maybe<Array<Maybe<Matric_Question_Responses_Mutation_Response>>>;
  /** update data of the table: "matric_questions" */
  update_matric_questions?: Maybe<Matric_Questions_Mutation_Response>;
  /** update single row of the table: "matric_questions" */
  update_matric_questions_by_pk?: Maybe<Matric_Questions>;
  /** update multiples rows of table: "matric_questions" */
  update_matric_questions_many?: Maybe<Array<Maybe<Matric_Questions_Mutation_Response>>>;
  /** update data of the table: "messages" */
  update_messages?: Maybe<Messages_Mutation_Response>;
  /** update single row of the table: "messages" */
  update_messages_by_pk?: Maybe<Messages>;
  /** update multiples rows of table: "messages" */
  update_messages_many?: Maybe<Array<Maybe<Messages_Mutation_Response>>>;
  /** update data of the table: "notifications" */
  update_notifications?: Maybe<Notifications_Mutation_Response>;
  /** update single row of the table: "notifications" */
  update_notifications_by_pk?: Maybe<Notifications>;
  /** update multiples rows of table: "notifications" */
  update_notifications_many?: Maybe<Array<Maybe<Notifications_Mutation_Response>>>;
  /** update data of the table: "otps" */
  update_otps?: Maybe<Otps_Mutation_Response>;
  /** update single row of the table: "otps" */
  update_otps_by_pk?: Maybe<Otps>;
  /** update multiples rows of table: "otps" */
  update_otps_many?: Maybe<Array<Maybe<Otps_Mutation_Response>>>;
  /** update data of the table: "password_reset_tokens" */
  update_password_reset_tokens?: Maybe<Password_Reset_Tokens_Mutation_Response>;
  /** update single row of the table: "password_reset_tokens" */
  update_password_reset_tokens_by_pk?: Maybe<Password_Reset_Tokens>;
  /** update multiples rows of table: "password_reset_tokens" */
  update_password_reset_tokens_many?: Maybe<Array<Maybe<Password_Reset_Tokens_Mutation_Response>>>;
  /** update data of the table: "personal_sun_types" */
  update_personal_sun_types?: Maybe<Personal_Sun_Types_Mutation_Response>;
  /** update single row of the table: "personal_sun_types" */
  update_personal_sun_types_by_pk?: Maybe<Personal_Sun_Types>;
  /** update multiples rows of table: "personal_sun_types" */
  update_personal_sun_types_many?: Maybe<Array<Maybe<Personal_Sun_Types_Mutation_Response>>>;
  /** update data of the table: "personal_suns" */
  update_personal_suns?: Maybe<Personal_Suns_Mutation_Response>;
  /** update single row of the table: "personal_suns" */
  update_personal_suns_by_pk?: Maybe<Personal_Suns>;
  /** update multiples rows of table: "personal_suns" */
  update_personal_suns_many?: Maybe<Array<Maybe<Personal_Suns_Mutation_Response>>>;
  /** update data of the table: "profiles" */
  update_profiles?: Maybe<Profiles_Mutation_Response>;
  /** update single row of the table: "profiles" */
  update_profiles_by_pk?: Maybe<Profiles>;
  /** update multiples rows of table: "profiles" */
  update_profiles_many?: Maybe<Array<Maybe<Profiles_Mutation_Response>>>;
  /** update data of the table: "reservation_payments" */
  update_reservation_payments?: Maybe<Reservation_Payments_Mutation_Response>;
  /** update single row of the table: "reservation_payments" */
  update_reservation_payments_by_pk?: Maybe<Reservation_Payments>;
  /** update multiples rows of table: "reservation_payments" */
  update_reservation_payments_many?: Maybe<Array<Maybe<Reservation_Payments_Mutation_Response>>>;
  /** update data of the table: "reservations" */
  update_reservations?: Maybe<Reservations_Mutation_Response>;
  /** update single row of the table: "reservations" */
  update_reservations_by_pk?: Maybe<Reservations>;
  /** update multiples rows of table: "reservations" */
  update_reservations_many?: Maybe<Array<Maybe<Reservations_Mutation_Response>>>;
  /** update data of the table: "reviews" */
  update_reviews?: Maybe<Reviews_Mutation_Response>;
  /** update single row of the table: "reviews" */
  update_reviews_by_pk?: Maybe<Reviews>;
  /** update multiples rows of table: "reviews" */
  update_reviews_many?: Maybe<Array<Maybe<Reviews_Mutation_Response>>>;
  /** update data of the table: "settings" */
  update_settings?: Maybe<Settings_Mutation_Response>;
  /** update single row of the table: "settings" */
  update_settings_by_pk?: Maybe<Settings>;
  /** update multiples rows of table: "settings" */
  update_settings_many?: Maybe<Array<Maybe<Settings_Mutation_Response>>>;
  /** update data of the table: "subscriptions" */
  update_subscriptions?: Maybe<Subscriptions_Mutation_Response>;
  /** update single row of the table: "subscriptions" */
  update_subscriptions_by_pk?: Maybe<Subscriptions>;
  /** update multiples rows of table: "subscriptions" */
  update_subscriptions_many?: Maybe<Array<Maybe<Subscriptions_Mutation_Response>>>;
  /** update data of the table: "template_question_responses" */
  update_template_question_responses?: Maybe<Template_Question_Responses_Mutation_Response>;
  /** update single row of the table: "template_question_responses" */
  update_template_question_responses_by_pk?: Maybe<Template_Question_Responses>;
  /** update multiples rows of table: "template_question_responses" */
  update_template_question_responses_many?: Maybe<Array<Maybe<Template_Question_Responses_Mutation_Response>>>;
  /** update data of the table: "template_questions" */
  update_template_questions?: Maybe<Template_Questions_Mutation_Response>;
  /** update single row of the table: "template_questions" */
  update_template_questions_by_pk?: Maybe<Template_Questions>;
  /** update multiples rows of table: "template_questions" */
  update_template_questions_many?: Maybe<Array<Maybe<Template_Questions_Mutation_Response>>>;
  /** update data of the table: "time_slots" */
  update_time_slots?: Maybe<Time_Slots_Mutation_Response>;
  /** update single row of the table: "time_slots" */
  update_time_slots_by_pk?: Maybe<Time_Slots>;
  /** update multiples rows of table: "time_slots" */
  update_time_slots_many?: Maybe<Array<Maybe<Time_Slots_Mutation_Response>>>;
  /** update data of the table: "translations" */
  update_translations?: Maybe<Translations_Mutation_Response>;
  /** update single row of the table: "translations" */
  update_translations_by_pk?: Maybe<Translations>;
  /** update multiples rows of table: "translations" */
  update_translations_many?: Maybe<Array<Maybe<Translations_Mutation_Response>>>;
  /** update data of the table: "user_attachment_test_responses" */
  update_user_attachment_test_responses?: Maybe<User_Attachment_Test_Responses_Mutation_Response>;
  /** update single row of the table: "user_attachment_test_responses" */
  update_user_attachment_test_responses_by_pk?: Maybe<User_Attachment_Test_Responses>;
  /** update multiples rows of table: "user_attachment_test_responses" */
  update_user_attachment_test_responses_many?: Maybe<Array<Maybe<User_Attachment_Test_Responses_Mutation_Response>>>;
  /** update data of the table: "user_avatar_test_responses" */
  update_user_avatar_test_responses?: Maybe<User_Avatar_Test_Responses_Mutation_Response>;
  /** update single row of the table: "user_avatar_test_responses" */
  update_user_avatar_test_responses_by_pk?: Maybe<User_Avatar_Test_Responses>;
  /** update multiples rows of table: "user_avatar_test_responses" */
  update_user_avatar_test_responses_many?: Maybe<Array<Maybe<User_Avatar_Test_Responses_Mutation_Response>>>;
  /** update data of the table: "user_matric_question_responses" */
  update_user_matric_question_responses?: Maybe<User_Matric_Question_Responses_Mutation_Response>;
  /** update single row of the table: "user_matric_question_responses" */
  update_user_matric_question_responses_by_pk?: Maybe<User_Matric_Question_Responses>;
  /** update multiples rows of table: "user_matric_question_responses" */
  update_user_matric_question_responses_many?: Maybe<Array<Maybe<User_Matric_Question_Responses_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "vouchers" */
  update_vouchers?: Maybe<Vouchers_Mutation_Response>;
  /** update single row of the table: "vouchers" */
  update_vouchers_by_pk?: Maybe<Vouchers>;
  /** update multiples rows of table: "vouchers" */
  update_vouchers_many?: Maybe<Array<Maybe<Vouchers_Mutation_Response>>>;
  /** Handle upload file to s3 */
  uploadFile?: Maybe<UploadFileOutput>;
  /** Handle verify OTP */
  verifyOTP: VerifyOtpResponse;
};


/** mutation root */
export type Mutation_RootActiveUserArgs = {
  input: ActiveUserInput;
};


/** mutation root */
export type Mutation_RootApproveFriendRequestArgs = {
  input?: InputMaybe<ApproveFriendRequestInput>;
};


/** mutation root */
export type Mutation_RootBookCoachArgs = {
  input: BookCoachInput;
};


/** mutation root */
export type Mutation_RootChangePasswordArgs = {
  input: ChangePasswordInput;
};


/** mutation root */
export type Mutation_RootCompleteBookingArgs = {
  input: CompleteBookingInput;
};


/** mutation root */
export type Mutation_RootCreateCoachArgs = {
  input: CreateCoachInput;
};


/** mutation root */
export type Mutation_RootCreateUserArgs = {
  input: CreateUserInput;
};


/** mutation root */
export type Mutation_RootDeclineFriendRequestArgs = {
  input?: InputMaybe<DeclineFriendRequestInput>;
};


/** mutation root */
export type Mutation_RootDelete_Attachment_Test_CategoriesArgs = {
  where: Attachment_Test_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Attachment_Test_Categories_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Attachment_Test_ResponsesArgs = {
  where: Attachment_Test_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Attachment_Test_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Attachment_TestsArgs = {
  where: Attachment_Tests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Attachment_Tests_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Availability_Time_SlotsArgs = {
  where: Availability_Time_Slots_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Availability_Time_Slots_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Avatar_ResultsArgs = {
  where: Avatar_Results_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Avatar_Results_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Avatar_Test_ResponsesArgs = {
  where: Avatar_Test_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Avatar_Test_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Avatar_TestsArgs = {
  where: Avatar_Tests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Avatar_Tests_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Channel_UsersArgs = {
  where: Channel_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Channel_Users_By_PkArgs = {
  channel_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ChannelsArgs = {
  where: Channels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Channels_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CitiesArgs = {
  where: Cities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cities_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Contact_FormsArgs = {
  where: Contact_Forms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contact_Forms_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ContentsArgs = {
  where: Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contents_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Device_TokensArgs = {
  where: Device_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Device_Tokens_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FaqsArgs = {
  where: Faqs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Faqs_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Friend_RequestsArgs = {
  where: Friend_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Friend_Requests_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FriendsArgs = {
  where: Friends_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Friends_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_GendersArgs = {
  where: Genders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Genders_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_LanguagesArgs = {
  where: Languages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Languages_By_PkArgs = {
  code: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Matric_Question_CategoriesArgs = {
  where: Matric_Question_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Matric_Question_Categories_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Matric_Question_ResponsesArgs = {
  where: Matric_Question_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Matric_Question_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Matric_QuestionsArgs = {
  where: Matric_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Matric_Questions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_MessagesArgs = {
  where: Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Messages_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_NotificationsArgs = {
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notifications_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_OtpsArgs = {
  where: Otps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Otps_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Password_Reset_TokensArgs = {
  where: Password_Reset_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Password_Reset_Tokens_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Personal_Sun_TypesArgs = {
  where: Personal_Sun_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Personal_Sun_Types_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Personal_SunsArgs = {
  where: Personal_Suns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Personal_Suns_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ProfilesArgs = {
  where: Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profiles_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Reservation_PaymentsArgs = {
  where: Reservation_Payments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reservation_Payments_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ReservationsArgs = {
  where: Reservations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reservations_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ReviewsArgs = {
  where: Reviews_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reviews_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_SettingsArgs = {
  where: Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Settings_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_SubscriptionsArgs = {
  where: Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subscriptions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Template_Question_ResponsesArgs = {
  where: Template_Question_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Template_Question_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Template_QuestionsArgs = {
  where: Template_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Template_Questions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Time_SlotsArgs = {
  where: Time_Slots_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Time_Slots_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TranslationsArgs = {
  where: Translations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Translations_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Attachment_Test_ResponsesArgs = {
  where: User_Attachment_Test_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Attachment_Test_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Avatar_Test_ResponsesArgs = {
  where: User_Avatar_Test_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Avatar_Test_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Matric_Question_ResponsesArgs = {
  where: User_Matric_Question_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Matric_Question_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_VouchersArgs = {
  where: Vouchers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vouchers_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootExportUserProfileArgs = {
  user_id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


/** mutation root */
export type Mutation_RootGetMatchProfileByIdArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootGetMatchProfilesArgs = {
  input?: InputMaybe<MatchProfileInput>;
};


/** mutation root */
export type Mutation_RootInitializeReservationPaymentArgs = {
  input: InitializeReservationPaymentInput;
};


/** mutation root */
export type Mutation_RootInsert_Attachment_Test_CategoriesArgs = {
  objects: Array<Attachment_Test_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Attachment_Test_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Attachment_Test_Categories_OneArgs = {
  object: Attachment_Test_Categories_Insert_Input;
  on_conflict?: InputMaybe<Attachment_Test_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Attachment_Test_ResponsesArgs = {
  objects: Array<Attachment_Test_Responses_Insert_Input>;
  on_conflict?: InputMaybe<Attachment_Test_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Attachment_Test_Responses_OneArgs = {
  object: Attachment_Test_Responses_Insert_Input;
  on_conflict?: InputMaybe<Attachment_Test_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Attachment_TestsArgs = {
  objects: Array<Attachment_Tests_Insert_Input>;
  on_conflict?: InputMaybe<Attachment_Tests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Attachment_Tests_OneArgs = {
  object: Attachment_Tests_Insert_Input;
  on_conflict?: InputMaybe<Attachment_Tests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Availability_Time_SlotsArgs = {
  objects: Array<Availability_Time_Slots_Insert_Input>;
  on_conflict?: InputMaybe<Availability_Time_Slots_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Availability_Time_Slots_OneArgs = {
  object: Availability_Time_Slots_Insert_Input;
  on_conflict?: InputMaybe<Availability_Time_Slots_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Avatar_ResultsArgs = {
  objects: Array<Avatar_Results_Insert_Input>;
  on_conflict?: InputMaybe<Avatar_Results_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Avatar_Results_OneArgs = {
  object: Avatar_Results_Insert_Input;
  on_conflict?: InputMaybe<Avatar_Results_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Avatar_Test_ResponsesArgs = {
  objects: Array<Avatar_Test_Responses_Insert_Input>;
  on_conflict?: InputMaybe<Avatar_Test_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Avatar_Test_Responses_OneArgs = {
  object: Avatar_Test_Responses_Insert_Input;
  on_conflict?: InputMaybe<Avatar_Test_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Avatar_TestsArgs = {
  objects: Array<Avatar_Tests_Insert_Input>;
  on_conflict?: InputMaybe<Avatar_Tests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Avatar_Tests_OneArgs = {
  object: Avatar_Tests_Insert_Input;
  on_conflict?: InputMaybe<Avatar_Tests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channel_UsersArgs = {
  objects: Array<Channel_Users_Insert_Input>;
  on_conflict?: InputMaybe<Channel_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channel_Users_OneArgs = {
  object: Channel_Users_Insert_Input;
  on_conflict?: InputMaybe<Channel_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ChannelsArgs = {
  objects: Array<Channels_Insert_Input>;
  on_conflict?: InputMaybe<Channels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channels_OneArgs = {
  object: Channels_Insert_Input;
  on_conflict?: InputMaybe<Channels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CitiesArgs = {
  objects: Array<Cities_Insert_Input>;
  on_conflict?: InputMaybe<Cities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cities_OneArgs = {
  object: Cities_Insert_Input;
  on_conflict?: InputMaybe<Cities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_FormsArgs = {
  objects: Array<Contact_Forms_Insert_Input>;
  on_conflict?: InputMaybe<Contact_Forms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_Forms_OneArgs = {
  object: Contact_Forms_Insert_Input;
  on_conflict?: InputMaybe<Contact_Forms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentsArgs = {
  objects: Array<Contents_Insert_Input>;
  on_conflict?: InputMaybe<Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contents_OneArgs = {
  object: Contents_Insert_Input;
  on_conflict?: InputMaybe<Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Device_TokensArgs = {
  objects: Array<Device_Tokens_Insert_Input>;
  on_conflict?: InputMaybe<Device_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Device_Tokens_OneArgs = {
  object: Device_Tokens_Insert_Input;
  on_conflict?: InputMaybe<Device_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FaqsArgs = {
  objects: Array<Faqs_Insert_Input>;
  on_conflict?: InputMaybe<Faqs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Faqs_OneArgs = {
  object: Faqs_Insert_Input;
  on_conflict?: InputMaybe<Faqs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Friend_RequestsArgs = {
  objects: Array<Friend_Requests_Insert_Input>;
  on_conflict?: InputMaybe<Friend_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Friend_Requests_OneArgs = {
  object: Friend_Requests_Insert_Input;
  on_conflict?: InputMaybe<Friend_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FriendsArgs = {
  objects: Array<Friends_Insert_Input>;
  on_conflict?: InputMaybe<Friends_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Friends_OneArgs = {
  object: Friends_Insert_Input;
  on_conflict?: InputMaybe<Friends_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GendersArgs = {
  objects: Array<Genders_Insert_Input>;
  on_conflict?: InputMaybe<Genders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Genders_OneArgs = {
  object: Genders_Insert_Input;
  on_conflict?: InputMaybe<Genders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LanguagesArgs = {
  objects: Array<Languages_Insert_Input>;
  on_conflict?: InputMaybe<Languages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Languages_OneArgs = {
  object: Languages_Insert_Input;
  on_conflict?: InputMaybe<Languages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Matric_Question_CategoriesArgs = {
  objects: Array<Matric_Question_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Matric_Question_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Matric_Question_Categories_OneArgs = {
  object: Matric_Question_Categories_Insert_Input;
  on_conflict?: InputMaybe<Matric_Question_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Matric_Question_ResponsesArgs = {
  objects: Array<Matric_Question_Responses_Insert_Input>;
  on_conflict?: InputMaybe<Matric_Question_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Matric_Question_Responses_OneArgs = {
  object: Matric_Question_Responses_Insert_Input;
  on_conflict?: InputMaybe<Matric_Question_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Matric_QuestionsArgs = {
  objects: Array<Matric_Questions_Insert_Input>;
  on_conflict?: InputMaybe<Matric_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Matric_Questions_OneArgs = {
  object: Matric_Questions_Insert_Input;
  on_conflict?: InputMaybe<Matric_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MessagesArgs = {
  objects: Array<Messages_Insert_Input>;
  on_conflict?: InputMaybe<Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Messages_OneArgs = {
  object: Messages_Insert_Input;
  on_conflict?: InputMaybe<Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotificationsArgs = {
  objects: Array<Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notifications_OneArgs = {
  object: Notifications_Insert_Input;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OtpsArgs = {
  objects: Array<Otps_Insert_Input>;
  on_conflict?: InputMaybe<Otps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Otps_OneArgs = {
  object: Otps_Insert_Input;
  on_conflict?: InputMaybe<Otps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Password_Reset_TokensArgs = {
  objects: Array<Password_Reset_Tokens_Insert_Input>;
  on_conflict?: InputMaybe<Password_Reset_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Password_Reset_Tokens_OneArgs = {
  object: Password_Reset_Tokens_Insert_Input;
  on_conflict?: InputMaybe<Password_Reset_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Personal_Sun_TypesArgs = {
  objects: Array<Personal_Sun_Types_Insert_Input>;
  on_conflict?: InputMaybe<Personal_Sun_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Personal_Sun_Types_OneArgs = {
  object: Personal_Sun_Types_Insert_Input;
  on_conflict?: InputMaybe<Personal_Sun_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Personal_SunsArgs = {
  objects: Array<Personal_Suns_Insert_Input>;
  on_conflict?: InputMaybe<Personal_Suns_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Personal_Suns_OneArgs = {
  object: Personal_Suns_Insert_Input;
  on_conflict?: InputMaybe<Personal_Suns_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProfilesArgs = {
  objects: Array<Profiles_Insert_Input>;
  on_conflict?: InputMaybe<Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profiles_OneArgs = {
  object: Profiles_Insert_Input;
  on_conflict?: InputMaybe<Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservation_PaymentsArgs = {
  objects: Array<Reservation_Payments_Insert_Input>;
  on_conflict?: InputMaybe<Reservation_Payments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservation_Payments_OneArgs = {
  object: Reservation_Payments_Insert_Input;
  on_conflict?: InputMaybe<Reservation_Payments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReservationsArgs = {
  objects: Array<Reservations_Insert_Input>;
  on_conflict?: InputMaybe<Reservations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservations_OneArgs = {
  object: Reservations_Insert_Input;
  on_conflict?: InputMaybe<Reservations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewsArgs = {
  objects: Array<Reviews_Insert_Input>;
  on_conflict?: InputMaybe<Reviews_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reviews_OneArgs = {
  object: Reviews_Insert_Input;
  on_conflict?: InputMaybe<Reviews_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SettingsArgs = {
  objects: Array<Settings_Insert_Input>;
  on_conflict?: InputMaybe<Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Settings_OneArgs = {
  object: Settings_Insert_Input;
  on_conflict?: InputMaybe<Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SubscriptionsArgs = {
  objects: Array<Subscriptions_Insert_Input>;
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscriptions_OneArgs = {
  object: Subscriptions_Insert_Input;
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Template_Question_ResponsesArgs = {
  objects: Array<Template_Question_Responses_Insert_Input>;
  on_conflict?: InputMaybe<Template_Question_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Template_Question_Responses_OneArgs = {
  object: Template_Question_Responses_Insert_Input;
  on_conflict?: InputMaybe<Template_Question_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Template_QuestionsArgs = {
  objects: Array<Template_Questions_Insert_Input>;
  on_conflict?: InputMaybe<Template_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Template_Questions_OneArgs = {
  object: Template_Questions_Insert_Input;
  on_conflict?: InputMaybe<Template_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Time_SlotsArgs = {
  objects: Array<Time_Slots_Insert_Input>;
  on_conflict?: InputMaybe<Time_Slots_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Time_Slots_OneArgs = {
  object: Time_Slots_Insert_Input;
  on_conflict?: InputMaybe<Time_Slots_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TranslationsArgs = {
  objects: Array<Translations_Insert_Input>;
  on_conflict?: InputMaybe<Translations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Translations_OneArgs = {
  object: Translations_Insert_Input;
  on_conflict?: InputMaybe<Translations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Attachment_Test_ResponsesArgs = {
  objects: Array<User_Attachment_Test_Responses_Insert_Input>;
  on_conflict?: InputMaybe<User_Attachment_Test_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Attachment_Test_Responses_OneArgs = {
  object: User_Attachment_Test_Responses_Insert_Input;
  on_conflict?: InputMaybe<User_Attachment_Test_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Avatar_Test_ResponsesArgs = {
  objects: Array<User_Avatar_Test_Responses_Insert_Input>;
  on_conflict?: InputMaybe<User_Avatar_Test_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Avatar_Test_Responses_OneArgs = {
  object: User_Avatar_Test_Responses_Insert_Input;
  on_conflict?: InputMaybe<User_Avatar_Test_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Matric_Question_ResponsesArgs = {
  objects: Array<User_Matric_Question_Responses_Insert_Input>;
  on_conflict?: InputMaybe<User_Matric_Question_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Matric_Question_Responses_OneArgs = {
  object: User_Matric_Question_Responses_Insert_Input;
  on_conflict?: InputMaybe<User_Matric_Question_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VouchersArgs = {
  objects: Array<Vouchers_Insert_Input>;
  on_conflict?: InputMaybe<Vouchers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vouchers_OneArgs = {
  object: Vouchers_Insert_Input;
  on_conflict?: InputMaybe<Vouchers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  input: LoginInput;
};


/** mutation root */
export type Mutation_RootPushNotificationArgs = {
  input: NotificationInput;
};


/** mutation root */
export type Mutation_RootRefreshTokenArgs = {
  input: RefreshTokenInput;
};


/** mutation root */
export type Mutation_RootReleaseFundsArgs = {
  input: ReleaseFundsInput;
};


/** mutation root */
export type Mutation_RootRequestFriendArgs = {
  input: RequestFriendInput;
};


/** mutation root */
export type Mutation_RootRequestOtpArgs = {
  input: RequestOtpInput;
};


/** mutation root */
export type Mutation_RootResetPasswordArgs = {
  input: ResetPasswordInput;
};


/** mutation root */
export type Mutation_RootReviewReservationArgs = {
  input: ReviewReservationInput;
};


/** mutation root */
export type Mutation_RootSendEmailArgs = {
  input: SendEmailInput;
};


/** mutation root */
export type Mutation_RootUpdateReservationStatusArgs = {
  input: UpdateReservationStatusInput;
};


/** mutation root */
export type Mutation_RootUpdate_Attachment_Test_CategoriesArgs = {
  _inc?: InputMaybe<Attachment_Test_Categories_Inc_Input>;
  _set?: InputMaybe<Attachment_Test_Categories_Set_Input>;
  where: Attachment_Test_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Attachment_Test_Categories_By_PkArgs = {
  _inc?: InputMaybe<Attachment_Test_Categories_Inc_Input>;
  _set?: InputMaybe<Attachment_Test_Categories_Set_Input>;
  pk_columns: Attachment_Test_Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Attachment_Test_Categories_ManyArgs = {
  updates: Array<Attachment_Test_Categories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Attachment_Test_ResponsesArgs = {
  _inc?: InputMaybe<Attachment_Test_Responses_Inc_Input>;
  _set?: InputMaybe<Attachment_Test_Responses_Set_Input>;
  where: Attachment_Test_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Attachment_Test_Responses_By_PkArgs = {
  _inc?: InputMaybe<Attachment_Test_Responses_Inc_Input>;
  _set?: InputMaybe<Attachment_Test_Responses_Set_Input>;
  pk_columns: Attachment_Test_Responses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Attachment_Test_Responses_ManyArgs = {
  updates: Array<Attachment_Test_Responses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Attachment_TestsArgs = {
  _inc?: InputMaybe<Attachment_Tests_Inc_Input>;
  _set?: InputMaybe<Attachment_Tests_Set_Input>;
  where: Attachment_Tests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Attachment_Tests_By_PkArgs = {
  _inc?: InputMaybe<Attachment_Tests_Inc_Input>;
  _set?: InputMaybe<Attachment_Tests_Set_Input>;
  pk_columns: Attachment_Tests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Attachment_Tests_ManyArgs = {
  updates: Array<Attachment_Tests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Availability_Time_SlotsArgs = {
  _inc?: InputMaybe<Availability_Time_Slots_Inc_Input>;
  _set?: InputMaybe<Availability_Time_Slots_Set_Input>;
  where: Availability_Time_Slots_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Availability_Time_Slots_By_PkArgs = {
  _inc?: InputMaybe<Availability_Time_Slots_Inc_Input>;
  _set?: InputMaybe<Availability_Time_Slots_Set_Input>;
  pk_columns: Availability_Time_Slots_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Availability_Time_Slots_ManyArgs = {
  updates: Array<Availability_Time_Slots_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Avatar_ResultsArgs = {
  _inc?: InputMaybe<Avatar_Results_Inc_Input>;
  _set?: InputMaybe<Avatar_Results_Set_Input>;
  where: Avatar_Results_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Avatar_Results_By_PkArgs = {
  _inc?: InputMaybe<Avatar_Results_Inc_Input>;
  _set?: InputMaybe<Avatar_Results_Set_Input>;
  pk_columns: Avatar_Results_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Avatar_Results_ManyArgs = {
  updates: Array<Avatar_Results_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Avatar_Test_ResponsesArgs = {
  _inc?: InputMaybe<Avatar_Test_Responses_Inc_Input>;
  _set?: InputMaybe<Avatar_Test_Responses_Set_Input>;
  where: Avatar_Test_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Avatar_Test_Responses_By_PkArgs = {
  _inc?: InputMaybe<Avatar_Test_Responses_Inc_Input>;
  _set?: InputMaybe<Avatar_Test_Responses_Set_Input>;
  pk_columns: Avatar_Test_Responses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Avatar_Test_Responses_ManyArgs = {
  updates: Array<Avatar_Test_Responses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Avatar_TestsArgs = {
  _inc?: InputMaybe<Avatar_Tests_Inc_Input>;
  _set?: InputMaybe<Avatar_Tests_Set_Input>;
  where: Avatar_Tests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Avatar_Tests_By_PkArgs = {
  _inc?: InputMaybe<Avatar_Tests_Inc_Input>;
  _set?: InputMaybe<Avatar_Tests_Set_Input>;
  pk_columns: Avatar_Tests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Avatar_Tests_ManyArgs = {
  updates: Array<Avatar_Tests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Channel_UsersArgs = {
  _inc?: InputMaybe<Channel_Users_Inc_Input>;
  _set?: InputMaybe<Channel_Users_Set_Input>;
  where: Channel_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Channel_Users_By_PkArgs = {
  _inc?: InputMaybe<Channel_Users_Inc_Input>;
  _set?: InputMaybe<Channel_Users_Set_Input>;
  pk_columns: Channel_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Channel_Users_ManyArgs = {
  updates: Array<Channel_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ChannelsArgs = {
  _inc?: InputMaybe<Channels_Inc_Input>;
  _set?: InputMaybe<Channels_Set_Input>;
  where: Channels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_By_PkArgs = {
  _inc?: InputMaybe<Channels_Inc_Input>;
  _set?: InputMaybe<Channels_Set_Input>;
  pk_columns: Channels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_ManyArgs = {
  updates: Array<Channels_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CitiesArgs = {
  _inc?: InputMaybe<Cities_Inc_Input>;
  _set?: InputMaybe<Cities_Set_Input>;
  where: Cities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cities_By_PkArgs = {
  _inc?: InputMaybe<Cities_Inc_Input>;
  _set?: InputMaybe<Cities_Set_Input>;
  pk_columns: Cities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Cities_ManyArgs = {
  updates: Array<Cities_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_FormsArgs = {
  _inc?: InputMaybe<Contact_Forms_Inc_Input>;
  _set?: InputMaybe<Contact_Forms_Set_Input>;
  where: Contact_Forms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Forms_By_PkArgs = {
  _inc?: InputMaybe<Contact_Forms_Inc_Input>;
  _set?: InputMaybe<Contact_Forms_Set_Input>;
  pk_columns: Contact_Forms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Forms_ManyArgs = {
  updates: Array<Contact_Forms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentsArgs = {
  _inc?: InputMaybe<Contents_Inc_Input>;
  _set?: InputMaybe<Contents_Set_Input>;
  where: Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contents_By_PkArgs = {
  _inc?: InputMaybe<Contents_Inc_Input>;
  _set?: InputMaybe<Contents_Set_Input>;
  pk_columns: Contents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contents_ManyArgs = {
  updates: Array<Contents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Device_TokensArgs = {
  _inc?: InputMaybe<Device_Tokens_Inc_Input>;
  _set?: InputMaybe<Device_Tokens_Set_Input>;
  where: Device_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Device_Tokens_By_PkArgs = {
  _inc?: InputMaybe<Device_Tokens_Inc_Input>;
  _set?: InputMaybe<Device_Tokens_Set_Input>;
  pk_columns: Device_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Device_Tokens_ManyArgs = {
  updates: Array<Device_Tokens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FaqsArgs = {
  _inc?: InputMaybe<Faqs_Inc_Input>;
  _set?: InputMaybe<Faqs_Set_Input>;
  where: Faqs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Faqs_By_PkArgs = {
  _inc?: InputMaybe<Faqs_Inc_Input>;
  _set?: InputMaybe<Faqs_Set_Input>;
  pk_columns: Faqs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Faqs_ManyArgs = {
  updates: Array<Faqs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Friend_RequestsArgs = {
  _inc?: InputMaybe<Friend_Requests_Inc_Input>;
  _set?: InputMaybe<Friend_Requests_Set_Input>;
  where: Friend_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Friend_Requests_By_PkArgs = {
  _inc?: InputMaybe<Friend_Requests_Inc_Input>;
  _set?: InputMaybe<Friend_Requests_Set_Input>;
  pk_columns: Friend_Requests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Friend_Requests_ManyArgs = {
  updates: Array<Friend_Requests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FriendsArgs = {
  _inc?: InputMaybe<Friends_Inc_Input>;
  _set?: InputMaybe<Friends_Set_Input>;
  where: Friends_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Friends_By_PkArgs = {
  _inc?: InputMaybe<Friends_Inc_Input>;
  _set?: InputMaybe<Friends_Set_Input>;
  pk_columns: Friends_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Friends_ManyArgs = {
  updates: Array<Friends_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GendersArgs = {
  _inc?: InputMaybe<Genders_Inc_Input>;
  _set?: InputMaybe<Genders_Set_Input>;
  where: Genders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Genders_By_PkArgs = {
  _inc?: InputMaybe<Genders_Inc_Input>;
  _set?: InputMaybe<Genders_Set_Input>;
  pk_columns: Genders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Genders_ManyArgs = {
  updates: Array<Genders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LanguagesArgs = {
  _set?: InputMaybe<Languages_Set_Input>;
  where: Languages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Languages_By_PkArgs = {
  _set?: InputMaybe<Languages_Set_Input>;
  pk_columns: Languages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Languages_ManyArgs = {
  updates: Array<Languages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Matric_Question_CategoriesArgs = {
  _inc?: InputMaybe<Matric_Question_Categories_Inc_Input>;
  _set?: InputMaybe<Matric_Question_Categories_Set_Input>;
  where: Matric_Question_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Matric_Question_Categories_By_PkArgs = {
  _inc?: InputMaybe<Matric_Question_Categories_Inc_Input>;
  _set?: InputMaybe<Matric_Question_Categories_Set_Input>;
  pk_columns: Matric_Question_Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Matric_Question_Categories_ManyArgs = {
  updates: Array<Matric_Question_Categories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Matric_Question_ResponsesArgs = {
  _inc?: InputMaybe<Matric_Question_Responses_Inc_Input>;
  _set?: InputMaybe<Matric_Question_Responses_Set_Input>;
  where: Matric_Question_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Matric_Question_Responses_By_PkArgs = {
  _inc?: InputMaybe<Matric_Question_Responses_Inc_Input>;
  _set?: InputMaybe<Matric_Question_Responses_Set_Input>;
  pk_columns: Matric_Question_Responses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Matric_Question_Responses_ManyArgs = {
  updates: Array<Matric_Question_Responses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Matric_QuestionsArgs = {
  _inc?: InputMaybe<Matric_Questions_Inc_Input>;
  _set?: InputMaybe<Matric_Questions_Set_Input>;
  where: Matric_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Matric_Questions_By_PkArgs = {
  _inc?: InputMaybe<Matric_Questions_Inc_Input>;
  _set?: InputMaybe<Matric_Questions_Set_Input>;
  pk_columns: Matric_Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Matric_Questions_ManyArgs = {
  updates: Array<Matric_Questions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MessagesArgs = {
  _inc?: InputMaybe<Messages_Inc_Input>;
  _set?: InputMaybe<Messages_Set_Input>;
  where: Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Messages_By_PkArgs = {
  _inc?: InputMaybe<Messages_Inc_Input>;
  _set?: InputMaybe<Messages_Set_Input>;
  pk_columns: Messages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Messages_ManyArgs = {
  updates: Array<Messages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_NotificationsArgs = {
  _inc?: InputMaybe<Notifications_Inc_Input>;
  _set?: InputMaybe<Notifications_Set_Input>;
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_By_PkArgs = {
  _inc?: InputMaybe<Notifications_Inc_Input>;
  _set?: InputMaybe<Notifications_Set_Input>;
  pk_columns: Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_ManyArgs = {
  updates: Array<Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OtpsArgs = {
  _inc?: InputMaybe<Otps_Inc_Input>;
  _set?: InputMaybe<Otps_Set_Input>;
  where: Otps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Otps_By_PkArgs = {
  _inc?: InputMaybe<Otps_Inc_Input>;
  _set?: InputMaybe<Otps_Set_Input>;
  pk_columns: Otps_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Otps_ManyArgs = {
  updates: Array<Otps_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Password_Reset_TokensArgs = {
  _inc?: InputMaybe<Password_Reset_Tokens_Inc_Input>;
  _set?: InputMaybe<Password_Reset_Tokens_Set_Input>;
  where: Password_Reset_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Password_Reset_Tokens_By_PkArgs = {
  _inc?: InputMaybe<Password_Reset_Tokens_Inc_Input>;
  _set?: InputMaybe<Password_Reset_Tokens_Set_Input>;
  pk_columns: Password_Reset_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Password_Reset_Tokens_ManyArgs = {
  updates: Array<Password_Reset_Tokens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Personal_Sun_TypesArgs = {
  _inc?: InputMaybe<Personal_Sun_Types_Inc_Input>;
  _set?: InputMaybe<Personal_Sun_Types_Set_Input>;
  where: Personal_Sun_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Personal_Sun_Types_By_PkArgs = {
  _inc?: InputMaybe<Personal_Sun_Types_Inc_Input>;
  _set?: InputMaybe<Personal_Sun_Types_Set_Input>;
  pk_columns: Personal_Sun_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Personal_Sun_Types_ManyArgs = {
  updates: Array<Personal_Sun_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Personal_SunsArgs = {
  _inc?: InputMaybe<Personal_Suns_Inc_Input>;
  _set?: InputMaybe<Personal_Suns_Set_Input>;
  where: Personal_Suns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Personal_Suns_By_PkArgs = {
  _inc?: InputMaybe<Personal_Suns_Inc_Input>;
  _set?: InputMaybe<Personal_Suns_Set_Input>;
  pk_columns: Personal_Suns_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Personal_Suns_ManyArgs = {
  updates: Array<Personal_Suns_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProfilesArgs = {
  _inc?: InputMaybe<Profiles_Inc_Input>;
  _set?: InputMaybe<Profiles_Set_Input>;
  where: Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profiles_By_PkArgs = {
  _inc?: InputMaybe<Profiles_Inc_Input>;
  _set?: InputMaybe<Profiles_Set_Input>;
  pk_columns: Profiles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profiles_ManyArgs = {
  updates: Array<Profiles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Reservation_PaymentsArgs = {
  _inc?: InputMaybe<Reservation_Payments_Inc_Input>;
  _set?: InputMaybe<Reservation_Payments_Set_Input>;
  where: Reservation_Payments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reservation_Payments_By_PkArgs = {
  _inc?: InputMaybe<Reservation_Payments_Inc_Input>;
  _set?: InputMaybe<Reservation_Payments_Set_Input>;
  pk_columns: Reservation_Payments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reservation_Payments_ManyArgs = {
  updates: Array<Reservation_Payments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReservationsArgs = {
  _inc?: InputMaybe<Reservations_Inc_Input>;
  _set?: InputMaybe<Reservations_Set_Input>;
  where: Reservations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reservations_By_PkArgs = {
  _inc?: InputMaybe<Reservations_Inc_Input>;
  _set?: InputMaybe<Reservations_Set_Input>;
  pk_columns: Reservations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reservations_ManyArgs = {
  updates: Array<Reservations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewsArgs = {
  _inc?: InputMaybe<Reviews_Inc_Input>;
  _set?: InputMaybe<Reviews_Set_Input>;
  where: Reviews_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reviews_By_PkArgs = {
  _inc?: InputMaybe<Reviews_Inc_Input>;
  _set?: InputMaybe<Reviews_Set_Input>;
  pk_columns: Reviews_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reviews_ManyArgs = {
  updates: Array<Reviews_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SettingsArgs = {
  _inc?: InputMaybe<Settings_Inc_Input>;
  _set?: InputMaybe<Settings_Set_Input>;
  where: Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Settings_By_PkArgs = {
  _inc?: InputMaybe<Settings_Inc_Input>;
  _set?: InputMaybe<Settings_Set_Input>;
  pk_columns: Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Settings_ManyArgs = {
  updates: Array<Settings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SubscriptionsArgs = {
  _inc?: InputMaybe<Subscriptions_Inc_Input>;
  _set?: InputMaybe<Subscriptions_Set_Input>;
  where: Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subscriptions_By_PkArgs = {
  _inc?: InputMaybe<Subscriptions_Inc_Input>;
  _set?: InputMaybe<Subscriptions_Set_Input>;
  pk_columns: Subscriptions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Subscriptions_ManyArgs = {
  updates: Array<Subscriptions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Template_Question_ResponsesArgs = {
  _inc?: InputMaybe<Template_Question_Responses_Inc_Input>;
  _set?: InputMaybe<Template_Question_Responses_Set_Input>;
  where: Template_Question_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Template_Question_Responses_By_PkArgs = {
  _inc?: InputMaybe<Template_Question_Responses_Inc_Input>;
  _set?: InputMaybe<Template_Question_Responses_Set_Input>;
  pk_columns: Template_Question_Responses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Template_Question_Responses_ManyArgs = {
  updates: Array<Template_Question_Responses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Template_QuestionsArgs = {
  _inc?: InputMaybe<Template_Questions_Inc_Input>;
  _set?: InputMaybe<Template_Questions_Set_Input>;
  where: Template_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Template_Questions_By_PkArgs = {
  _inc?: InputMaybe<Template_Questions_Inc_Input>;
  _set?: InputMaybe<Template_Questions_Set_Input>;
  pk_columns: Template_Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Template_Questions_ManyArgs = {
  updates: Array<Template_Questions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Time_SlotsArgs = {
  _inc?: InputMaybe<Time_Slots_Inc_Input>;
  _set?: InputMaybe<Time_Slots_Set_Input>;
  where: Time_Slots_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Time_Slots_By_PkArgs = {
  _inc?: InputMaybe<Time_Slots_Inc_Input>;
  _set?: InputMaybe<Time_Slots_Set_Input>;
  pk_columns: Time_Slots_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Time_Slots_ManyArgs = {
  updates: Array<Time_Slots_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TranslationsArgs = {
  _inc?: InputMaybe<Translations_Inc_Input>;
  _set?: InputMaybe<Translations_Set_Input>;
  where: Translations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Translations_By_PkArgs = {
  _inc?: InputMaybe<Translations_Inc_Input>;
  _set?: InputMaybe<Translations_Set_Input>;
  pk_columns: Translations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Translations_ManyArgs = {
  updates: Array<Translations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Attachment_Test_ResponsesArgs = {
  _inc?: InputMaybe<User_Attachment_Test_Responses_Inc_Input>;
  _set?: InputMaybe<User_Attachment_Test_Responses_Set_Input>;
  where: User_Attachment_Test_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Attachment_Test_Responses_By_PkArgs = {
  _inc?: InputMaybe<User_Attachment_Test_Responses_Inc_Input>;
  _set?: InputMaybe<User_Attachment_Test_Responses_Set_Input>;
  pk_columns: User_Attachment_Test_Responses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Attachment_Test_Responses_ManyArgs = {
  updates: Array<User_Attachment_Test_Responses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Avatar_Test_ResponsesArgs = {
  _inc?: InputMaybe<User_Avatar_Test_Responses_Inc_Input>;
  _set?: InputMaybe<User_Avatar_Test_Responses_Set_Input>;
  where: User_Avatar_Test_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Avatar_Test_Responses_By_PkArgs = {
  _inc?: InputMaybe<User_Avatar_Test_Responses_Inc_Input>;
  _set?: InputMaybe<User_Avatar_Test_Responses_Set_Input>;
  pk_columns: User_Avatar_Test_Responses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Avatar_Test_Responses_ManyArgs = {
  updates: Array<User_Avatar_Test_Responses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Matric_Question_ResponsesArgs = {
  _inc?: InputMaybe<User_Matric_Question_Responses_Inc_Input>;
  _set?: InputMaybe<User_Matric_Question_Responses_Set_Input>;
  where: User_Matric_Question_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Matric_Question_Responses_By_PkArgs = {
  _inc?: InputMaybe<User_Matric_Question_Responses_Inc_Input>;
  _set?: InputMaybe<User_Matric_Question_Responses_Set_Input>;
  pk_columns: User_Matric_Question_Responses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Matric_Question_Responses_ManyArgs = {
  updates: Array<User_Matric_Question_Responses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VouchersArgs = {
  _inc?: InputMaybe<Vouchers_Inc_Input>;
  _set?: InputMaybe<Vouchers_Set_Input>;
  where: Vouchers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vouchers_By_PkArgs = {
  _inc?: InputMaybe<Vouchers_Inc_Input>;
  _set?: InputMaybe<Vouchers_Set_Input>;
  pk_columns: Vouchers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vouchers_ManyArgs = {
  updates: Array<Vouchers_Updates>;
};


/** mutation root */
export type Mutation_RootUploadFileArgs = {
  payload: UploadFileInput;
};


/** mutation root */
export type Mutation_RootVerifyOtpArgs = {
  input: VerifyOtpInput;
};

/** Boolean expression to compare columns of type "notification_type". All fields are combined with logical 'AND'. */
export type Notification_Type_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['notification_type']['input']>;
  _gt?: InputMaybe<Scalars['notification_type']['input']>;
  _gte?: InputMaybe<Scalars['notification_type']['input']>;
  _in?: InputMaybe<Array<Scalars['notification_type']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['notification_type']['input']>;
  _lte?: InputMaybe<Scalars['notification_type']['input']>;
  _neq?: InputMaybe<Scalars['notification_type']['input']>;
  _nin?: InputMaybe<Array<Scalars['notification_type']['input']>>;
};

/** columns and relationships of "notifications" */
export type Notifications = {
  __typename?: 'notifications';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['Int']['output'];
  metadata?: Maybe<Scalars['String']['output']>;
  notification_type?: Maybe<Scalars['notification_type']['output']>;
  read_at?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  receiver?: Maybe<Users>;
  receiver_user_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  sender?: Maybe<Users>;
  sender_user_id?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
};

/** aggregated selection of "notifications" */
export type Notifications_Aggregate = {
  __typename?: 'notifications_aggregate';
  aggregate?: Maybe<Notifications_Aggregate_Fields>;
  nodes: Array<Notifications>;
};

/** aggregate fields of "notifications" */
export type Notifications_Aggregate_Fields = {
  __typename?: 'notifications_aggregate_fields';
  avg?: Maybe<Notifications_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Notifications_Max_Fields>;
  min?: Maybe<Notifications_Min_Fields>;
  stddev?: Maybe<Notifications_Stddev_Fields>;
  stddev_pop?: Maybe<Notifications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Notifications_Stddev_Samp_Fields>;
  sum?: Maybe<Notifications_Sum_Fields>;
  var_pop?: Maybe<Notifications_Var_Pop_Fields>;
  var_samp?: Maybe<Notifications_Var_Samp_Fields>;
  variance?: Maybe<Notifications_Variance_Fields>;
};


/** aggregate fields of "notifications" */
export type Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Notifications_Avg_Fields = {
  __typename?: 'notifications_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  receiver_user_id?: Maybe<Scalars['Float']['output']>;
  sender_user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export type Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Notifications_Bool_Exp>>;
  _not?: InputMaybe<Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Notifications_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  metadata?: InputMaybe<String_Comparison_Exp>;
  notification_type?: InputMaybe<Notification_Type_Comparison_Exp>;
  read_at?: InputMaybe<Timestamp_Comparison_Exp>;
  receiver?: InputMaybe<Users_Bool_Exp>;
  receiver_user_id?: InputMaybe<Int_Comparison_Exp>;
  sender?: InputMaybe<Users_Bool_Exp>;
  sender_user_id?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notifications" */
export type Notifications_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'notifications_pkey';

/** input type for incrementing numeric columns in table "notifications" */
export type Notifications_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  receiver_user_id?: InputMaybe<Scalars['Int']['input']>;
  sender_user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "notifications" */
export type Notifications_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  notification_type?: InputMaybe<Scalars['notification_type']['input']>;
  read_at?: InputMaybe<Scalars['timestamp']['input']>;
  receiver?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  receiver_user_id?: InputMaybe<Scalars['Int']['input']>;
  sender?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  sender_user_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Notifications_Max_Fields = {
  __typename?: 'notifications_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  metadata?: Maybe<Scalars['String']['output']>;
  notification_type?: Maybe<Scalars['notification_type']['output']>;
  read_at?: Maybe<Scalars['timestamp']['output']>;
  receiver_user_id?: Maybe<Scalars['Int']['output']>;
  sender_user_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Notifications_Min_Fields = {
  __typename?: 'notifications_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  metadata?: Maybe<Scalars['String']['output']>;
  notification_type?: Maybe<Scalars['notification_type']['output']>;
  read_at?: Maybe<Scalars['timestamp']['output']>;
  receiver_user_id?: Maybe<Scalars['Int']['output']>;
  sender_user_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "notifications" */
export type Notifications_Mutation_Response = {
  __typename?: 'notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notifications>;
};

/** on_conflict condition type for table "notifications" */
export type Notifications_On_Conflict = {
  constraint: Notifications_Constraint;
  update_columns?: Array<Notifications_Update_Column>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "notifications". */
export type Notifications_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  notification_type?: InputMaybe<Order_By>;
  read_at?: InputMaybe<Order_By>;
  receiver?: InputMaybe<Users_Order_By>;
  receiver_user_id?: InputMaybe<Order_By>;
  sender?: InputMaybe<Users_Order_By>;
  sender_user_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notifications */
export type Notifications_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "notifications" */
export type Notifications_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'metadata'
  /** column name */
  | 'notification_type'
  /** column name */
  | 'read_at'
  /** column name */
  | 'receiver_user_id'
  /** column name */
  | 'sender_user_id'
  /** column name */
  | 'title';

/** input type for updating data in table "notifications" */
export type Notifications_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  notification_type?: InputMaybe<Scalars['notification_type']['input']>;
  read_at?: InputMaybe<Scalars['timestamp']['input']>;
  receiver_user_id?: InputMaybe<Scalars['Int']['input']>;
  sender_user_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Notifications_Stddev_Fields = {
  __typename?: 'notifications_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  receiver_user_id?: Maybe<Scalars['Float']['output']>;
  sender_user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Notifications_Stddev_Pop_Fields = {
  __typename?: 'notifications_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  receiver_user_id?: Maybe<Scalars['Float']['output']>;
  sender_user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Notifications_Stddev_Samp_Fields = {
  __typename?: 'notifications_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  receiver_user_id?: Maybe<Scalars['Float']['output']>;
  sender_user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "notifications" */
export type Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notifications_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  notification_type?: InputMaybe<Scalars['notification_type']['input']>;
  read_at?: InputMaybe<Scalars['timestamp']['input']>;
  receiver_user_id?: InputMaybe<Scalars['Int']['input']>;
  sender_user_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Notifications_Sum_Fields = {
  __typename?: 'notifications_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  receiver_user_id?: Maybe<Scalars['Int']['output']>;
  sender_user_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "notifications" */
export type Notifications_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'metadata'
  /** column name */
  | 'notification_type'
  /** column name */
  | 'read_at'
  /** column name */
  | 'receiver_user_id'
  /** column name */
  | 'sender_user_id'
  /** column name */
  | 'title';

export type Notifications_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Notifications_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notifications_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Notifications_Var_Pop_Fields = {
  __typename?: 'notifications_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  receiver_user_id?: Maybe<Scalars['Float']['output']>;
  sender_user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Notifications_Var_Samp_Fields = {
  __typename?: 'notifications_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  receiver_user_id?: Maybe<Scalars['Float']['output']>;
  sender_user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Notifications_Variance_Fields = {
  __typename?: 'notifications_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  receiver_user_id?: Maybe<Scalars['Float']['output']>;
  sender_user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** column ordering options */
export type Order_By =
  /** in ascending order, nulls last */
  | 'asc'
  /** in ascending order, nulls first */
  | 'asc_nulls_first'
  /** in ascending order, nulls last */
  | 'asc_nulls_last'
  /** in descending order, nulls first */
  | 'desc'
  /** in descending order, nulls first */
  | 'desc_nulls_first'
  /** in descending order, nulls last */
  | 'desc_nulls_last';

/** columns and relationships of "otps" */
export type Otps = {
  __typename?: 'otps';
  attempt_count: Scalars['Int']['output'];
  code: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email: Scalars['String']['output'];
  expired_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "otps" */
export type Otps_Aggregate = {
  __typename?: 'otps_aggregate';
  aggregate?: Maybe<Otps_Aggregate_Fields>;
  nodes: Array<Otps>;
};

/** aggregate fields of "otps" */
export type Otps_Aggregate_Fields = {
  __typename?: 'otps_aggregate_fields';
  avg?: Maybe<Otps_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Otps_Max_Fields>;
  min?: Maybe<Otps_Min_Fields>;
  stddev?: Maybe<Otps_Stddev_Fields>;
  stddev_pop?: Maybe<Otps_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Otps_Stddev_Samp_Fields>;
  sum?: Maybe<Otps_Sum_Fields>;
  var_pop?: Maybe<Otps_Var_Pop_Fields>;
  var_samp?: Maybe<Otps_Var_Samp_Fields>;
  variance?: Maybe<Otps_Variance_Fields>;
};


/** aggregate fields of "otps" */
export type Otps_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Otps_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Otps_Avg_Fields = {
  __typename?: 'otps_avg_fields';
  attempt_count?: Maybe<Scalars['Float']['output']>;
  code?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "otps". All fields are combined with a logical 'AND'. */
export type Otps_Bool_Exp = {
  _and?: InputMaybe<Array<Otps_Bool_Exp>>;
  _not?: InputMaybe<Otps_Bool_Exp>;
  _or?: InputMaybe<Array<Otps_Bool_Exp>>;
  attempt_count?: InputMaybe<Int_Comparison_Exp>;
  code?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  expired_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "otps" */
export type Otps_Constraint =
  /** unique or primary key constraint on columns "email" */
  | 'otp_email_unique'
  /** unique or primary key constraint on columns "id" */
  | 'otps_pkey';

/** input type for incrementing numeric columns in table "otps" */
export type Otps_Inc_Input = {
  attempt_count?: InputMaybe<Scalars['Int']['input']>;
  code?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "otps" */
export type Otps_Insert_Input = {
  attempt_count?: InputMaybe<Scalars['Int']['input']>;
  code?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expired_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Otps_Max_Fields = {
  __typename?: 'otps_max_fields';
  attempt_count?: Maybe<Scalars['Int']['output']>;
  code?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expired_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Otps_Min_Fields = {
  __typename?: 'otps_min_fields';
  attempt_count?: Maybe<Scalars['Int']['output']>;
  code?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expired_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "otps" */
export type Otps_Mutation_Response = {
  __typename?: 'otps_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Otps>;
};

/** input type for inserting object relation for remote table "otps" */
export type Otps_Obj_Rel_Insert_Input = {
  data: Otps_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Otps_On_Conflict>;
};

/** on_conflict condition type for table "otps" */
export type Otps_On_Conflict = {
  constraint: Otps_Constraint;
  update_columns?: Array<Otps_Update_Column>;
  where?: InputMaybe<Otps_Bool_Exp>;
};

/** Ordering options when selecting data from "otps". */
export type Otps_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  expired_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: otps */
export type Otps_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "otps" */
export type Otps_Select_Column =
  /** column name */
  | 'attempt_count'
  /** column name */
  | 'code'
  /** column name */
  | 'created_at'
  /** column name */
  | 'email'
  /** column name */
  | 'expired_at'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "otps" */
export type Otps_Set_Input = {
  attempt_count?: InputMaybe<Scalars['Int']['input']>;
  code?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expired_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Otps_Stddev_Fields = {
  __typename?: 'otps_stddev_fields';
  attempt_count?: Maybe<Scalars['Float']['output']>;
  code?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Otps_Stddev_Pop_Fields = {
  __typename?: 'otps_stddev_pop_fields';
  attempt_count?: Maybe<Scalars['Float']['output']>;
  code?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Otps_Stddev_Samp_Fields = {
  __typename?: 'otps_stddev_samp_fields';
  attempt_count?: Maybe<Scalars['Float']['output']>;
  code?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "otps" */
export type Otps_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Otps_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Otps_Stream_Cursor_Value_Input = {
  attempt_count?: InputMaybe<Scalars['Int']['input']>;
  code?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expired_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Otps_Sum_Fields = {
  __typename?: 'otps_sum_fields';
  attempt_count?: Maybe<Scalars['Int']['output']>;
  code?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "otps" */
export type Otps_Update_Column =
  /** column name */
  | 'attempt_count'
  /** column name */
  | 'code'
  /** column name */
  | 'created_at'
  /** column name */
  | 'email'
  /** column name */
  | 'expired_at'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at';

export type Otps_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Otps_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Otps_Set_Input>;
  /** filter the rows which have to be updated */
  where: Otps_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Otps_Var_Pop_Fields = {
  __typename?: 'otps_var_pop_fields';
  attempt_count?: Maybe<Scalars['Float']['output']>;
  code?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Otps_Var_Samp_Fields = {
  __typename?: 'otps_var_samp_fields';
  attempt_count?: Maybe<Scalars['Float']['output']>;
  code?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Otps_Variance_Fields = {
  __typename?: 'otps_variance_fields';
  attempt_count?: Maybe<Scalars['Float']['output']>;
  code?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "password_reset_tokens" */
export type Password_Reset_Tokens = {
  __typename?: 'password_reset_tokens';
  id: Scalars['Int']['output'];
  token: Scalars['String']['output'];
  token_expired_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "password_reset_tokens" */
export type Password_Reset_Tokens_Aggregate = {
  __typename?: 'password_reset_tokens_aggregate';
  aggregate?: Maybe<Password_Reset_Tokens_Aggregate_Fields>;
  nodes: Array<Password_Reset_Tokens>;
};

/** aggregate fields of "password_reset_tokens" */
export type Password_Reset_Tokens_Aggregate_Fields = {
  __typename?: 'password_reset_tokens_aggregate_fields';
  avg?: Maybe<Password_Reset_Tokens_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Password_Reset_Tokens_Max_Fields>;
  min?: Maybe<Password_Reset_Tokens_Min_Fields>;
  stddev?: Maybe<Password_Reset_Tokens_Stddev_Fields>;
  stddev_pop?: Maybe<Password_Reset_Tokens_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Password_Reset_Tokens_Stddev_Samp_Fields>;
  sum?: Maybe<Password_Reset_Tokens_Sum_Fields>;
  var_pop?: Maybe<Password_Reset_Tokens_Var_Pop_Fields>;
  var_samp?: Maybe<Password_Reset_Tokens_Var_Samp_Fields>;
  variance?: Maybe<Password_Reset_Tokens_Variance_Fields>;
};


/** aggregate fields of "password_reset_tokens" */
export type Password_Reset_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Password_Reset_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Password_Reset_Tokens_Avg_Fields = {
  __typename?: 'password_reset_tokens_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "password_reset_tokens". All fields are combined with a logical 'AND'. */
export type Password_Reset_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<Password_Reset_Tokens_Bool_Exp>>;
  _not?: InputMaybe<Password_Reset_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Password_Reset_Tokens_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
  token_expired_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "password_reset_tokens" */
export type Password_Reset_Tokens_Constraint =
  /** unique or primary key constraint on columns "token" */
  | 'password_reset_token_unique'
  /** unique or primary key constraint on columns "id" */
  | 'password_reset_tokens_pkey';

/** input type for incrementing numeric columns in table "password_reset_tokens" */
export type Password_Reset_Tokens_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "password_reset_tokens" */
export type Password_Reset_Tokens_Insert_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_expired_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Password_Reset_Tokens_Max_Fields = {
  __typename?: 'password_reset_tokens_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  token_expired_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Password_Reset_Tokens_Min_Fields = {
  __typename?: 'password_reset_tokens_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  token_expired_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "password_reset_tokens" */
export type Password_Reset_Tokens_Mutation_Response = {
  __typename?: 'password_reset_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Password_Reset_Tokens>;
};

/** input type for inserting object relation for remote table "password_reset_tokens" */
export type Password_Reset_Tokens_Obj_Rel_Insert_Input = {
  data: Password_Reset_Tokens_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Password_Reset_Tokens_On_Conflict>;
};

/** on_conflict condition type for table "password_reset_tokens" */
export type Password_Reset_Tokens_On_Conflict = {
  constraint: Password_Reset_Tokens_Constraint;
  update_columns?: Array<Password_Reset_Tokens_Update_Column>;
  where?: InputMaybe<Password_Reset_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "password_reset_tokens". */
export type Password_Reset_Tokens_Order_By = {
  id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  token_expired_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: password_reset_tokens */
export type Password_Reset_Tokens_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "password_reset_tokens" */
export type Password_Reset_Tokens_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'token'
  /** column name */
  | 'token_expired_at'
  /** column name */
  | 'user_id';

/** input type for updating data in table "password_reset_tokens" */
export type Password_Reset_Tokens_Set_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_expired_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Password_Reset_Tokens_Stddev_Fields = {
  __typename?: 'password_reset_tokens_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Password_Reset_Tokens_Stddev_Pop_Fields = {
  __typename?: 'password_reset_tokens_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Password_Reset_Tokens_Stddev_Samp_Fields = {
  __typename?: 'password_reset_tokens_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "password_reset_tokens" */
export type Password_Reset_Tokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Password_Reset_Tokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Password_Reset_Tokens_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_expired_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Password_Reset_Tokens_Sum_Fields = {
  __typename?: 'password_reset_tokens_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "password_reset_tokens" */
export type Password_Reset_Tokens_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'token'
  /** column name */
  | 'token_expired_at'
  /** column name */
  | 'user_id';

export type Password_Reset_Tokens_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Password_Reset_Tokens_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Password_Reset_Tokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: Password_Reset_Tokens_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Password_Reset_Tokens_Var_Pop_Fields = {
  __typename?: 'password_reset_tokens_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Password_Reset_Tokens_Var_Samp_Fields = {
  __typename?: 'password_reset_tokens_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Password_Reset_Tokens_Variance_Fields = {
  __typename?: 'password_reset_tokens_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "personal_sun_types" */
export type Personal_Sun_Types = {
  __typename?: 'personal_sun_types';
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  /** An array relationship */
  personal_suns: Array<Personal_Suns>;
  /** An aggregate relationship */
  personal_suns_aggregate: Personal_Suns_Aggregate;
  title: Scalars['String']['output'];
};


/** columns and relationships of "personal_sun_types" */
export type Personal_Sun_TypesPersonal_SunsArgs = {
  distinct_on?: InputMaybe<Array<Personal_Suns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Personal_Suns_Order_By>>;
  where?: InputMaybe<Personal_Suns_Bool_Exp>;
};


/** columns and relationships of "personal_sun_types" */
export type Personal_Sun_TypesPersonal_Suns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Personal_Suns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Personal_Suns_Order_By>>;
  where?: InputMaybe<Personal_Suns_Bool_Exp>;
};

/** aggregated selection of "personal_sun_types" */
export type Personal_Sun_Types_Aggregate = {
  __typename?: 'personal_sun_types_aggregate';
  aggregate?: Maybe<Personal_Sun_Types_Aggregate_Fields>;
  nodes: Array<Personal_Sun_Types>;
};

/** aggregate fields of "personal_sun_types" */
export type Personal_Sun_Types_Aggregate_Fields = {
  __typename?: 'personal_sun_types_aggregate_fields';
  avg?: Maybe<Personal_Sun_Types_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Personal_Sun_Types_Max_Fields>;
  min?: Maybe<Personal_Sun_Types_Min_Fields>;
  stddev?: Maybe<Personal_Sun_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Personal_Sun_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Personal_Sun_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Personal_Sun_Types_Sum_Fields>;
  var_pop?: Maybe<Personal_Sun_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Personal_Sun_Types_Var_Samp_Fields>;
  variance?: Maybe<Personal_Sun_Types_Variance_Fields>;
};


/** aggregate fields of "personal_sun_types" */
export type Personal_Sun_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Personal_Sun_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Personal_Sun_Types_Avg_Fields = {
  __typename?: 'personal_sun_types_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "personal_sun_types". All fields are combined with a logical 'AND'. */
export type Personal_Sun_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Personal_Sun_Types_Bool_Exp>>;
  _not?: InputMaybe<Personal_Sun_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Personal_Sun_Types_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  personal_suns?: InputMaybe<Personal_Suns_Bool_Exp>;
  personal_suns_aggregate?: InputMaybe<Personal_Suns_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "personal_sun_types" */
export type Personal_Sun_Types_Constraint =
  /** unique or primary key constraint on columns "code" */
  | 'personal_sun_types_code_key'
  /** unique or primary key constraint on columns "id" */
  | 'personal_sun_types_pkey';

/** input type for incrementing numeric columns in table "personal_sun_types" */
export type Personal_Sun_Types_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "personal_sun_types" */
export type Personal_Sun_Types_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  personal_suns?: InputMaybe<Personal_Suns_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Personal_Sun_Types_Max_Fields = {
  __typename?: 'personal_sun_types_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Personal_Sun_Types_Min_Fields = {
  __typename?: 'personal_sun_types_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "personal_sun_types" */
export type Personal_Sun_Types_Mutation_Response = {
  __typename?: 'personal_sun_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Personal_Sun_Types>;
};

/** input type for inserting object relation for remote table "personal_sun_types" */
export type Personal_Sun_Types_Obj_Rel_Insert_Input = {
  data: Personal_Sun_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Personal_Sun_Types_On_Conflict>;
};

/** on_conflict condition type for table "personal_sun_types" */
export type Personal_Sun_Types_On_Conflict = {
  constraint: Personal_Sun_Types_Constraint;
  update_columns?: Array<Personal_Sun_Types_Update_Column>;
  where?: InputMaybe<Personal_Sun_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "personal_sun_types". */
export type Personal_Sun_Types_Order_By = {
  code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  personal_suns_aggregate?: InputMaybe<Personal_Suns_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: personal_sun_types */
export type Personal_Sun_Types_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "personal_sun_types" */
export type Personal_Sun_Types_Select_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'id'
  /** column name */
  | 'order'
  /** column name */
  | 'title';

/** input type for updating data in table "personal_sun_types" */
export type Personal_Sun_Types_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Personal_Sun_Types_Stddev_Fields = {
  __typename?: 'personal_sun_types_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Personal_Sun_Types_Stddev_Pop_Fields = {
  __typename?: 'personal_sun_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Personal_Sun_Types_Stddev_Samp_Fields = {
  __typename?: 'personal_sun_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "personal_sun_types" */
export type Personal_Sun_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Personal_Sun_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Personal_Sun_Types_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Personal_Sun_Types_Sum_Fields = {
  __typename?: 'personal_sun_types_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "personal_sun_types" */
export type Personal_Sun_Types_Update_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'id'
  /** column name */
  | 'order'
  /** column name */
  | 'title';

export type Personal_Sun_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Personal_Sun_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Personal_Sun_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Personal_Sun_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Personal_Sun_Types_Var_Pop_Fields = {
  __typename?: 'personal_sun_types_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Personal_Sun_Types_Var_Samp_Fields = {
  __typename?: 'personal_sun_types_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Personal_Sun_Types_Variance_Fields = {
  __typename?: 'personal_sun_types_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "personal_suns" */
export type Personal_Suns = {
  __typename?: 'personal_suns';
  id: Scalars['Int']['output'];
  /** An object relationship */
  personal_sun_type: Personal_Sun_Types;
  personal_sun_type_id: Scalars['Int']['output'];
  /** An object relationship */
  type?: Maybe<Personal_Sun_Types>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['Int']['output'];
  values?: Maybe<Array<Scalars['String']['output']>>;
};

/** aggregated selection of "personal_suns" */
export type Personal_Suns_Aggregate = {
  __typename?: 'personal_suns_aggregate';
  aggregate?: Maybe<Personal_Suns_Aggregate_Fields>;
  nodes: Array<Personal_Suns>;
};

export type Personal_Suns_Aggregate_Bool_Exp = {
  count?: InputMaybe<Personal_Suns_Aggregate_Bool_Exp_Count>;
};

export type Personal_Suns_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Personal_Suns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Personal_Suns_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "personal_suns" */
export type Personal_Suns_Aggregate_Fields = {
  __typename?: 'personal_suns_aggregate_fields';
  avg?: Maybe<Personal_Suns_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Personal_Suns_Max_Fields>;
  min?: Maybe<Personal_Suns_Min_Fields>;
  stddev?: Maybe<Personal_Suns_Stddev_Fields>;
  stddev_pop?: Maybe<Personal_Suns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Personal_Suns_Stddev_Samp_Fields>;
  sum?: Maybe<Personal_Suns_Sum_Fields>;
  var_pop?: Maybe<Personal_Suns_Var_Pop_Fields>;
  var_samp?: Maybe<Personal_Suns_Var_Samp_Fields>;
  variance?: Maybe<Personal_Suns_Variance_Fields>;
};


/** aggregate fields of "personal_suns" */
export type Personal_Suns_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Personal_Suns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "personal_suns" */
export type Personal_Suns_Aggregate_Order_By = {
  avg?: InputMaybe<Personal_Suns_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Personal_Suns_Max_Order_By>;
  min?: InputMaybe<Personal_Suns_Min_Order_By>;
  stddev?: InputMaybe<Personal_Suns_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Personal_Suns_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Personal_Suns_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Personal_Suns_Sum_Order_By>;
  var_pop?: InputMaybe<Personal_Suns_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Personal_Suns_Var_Samp_Order_By>;
  variance?: InputMaybe<Personal_Suns_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "personal_suns" */
export type Personal_Suns_Arr_Rel_Insert_Input = {
  data: Array<Personal_Suns_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Personal_Suns_On_Conflict>;
};

/** aggregate avg on columns */
export type Personal_Suns_Avg_Fields = {
  __typename?: 'personal_suns_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  personal_sun_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "personal_suns" */
export type Personal_Suns_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  personal_sun_type_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "personal_suns". All fields are combined with a logical 'AND'. */
export type Personal_Suns_Bool_Exp = {
  _and?: InputMaybe<Array<Personal_Suns_Bool_Exp>>;
  _not?: InputMaybe<Personal_Suns_Bool_Exp>;
  _or?: InputMaybe<Array<Personal_Suns_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  personal_sun_type?: InputMaybe<Personal_Sun_Types_Bool_Exp>;
  personal_sun_type_id?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<Personal_Sun_Types_Bool_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
  values?: InputMaybe<String_Array_Comparison_Exp>;
};

/** unique or primary key constraints on table "personal_suns" */
export type Personal_Suns_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'personal_suns_pkey'
  /** unique or primary key constraint on columns "user_id", "personal_sun_type_id" */
  | 'personal_suns_user_id_personal_sun_type_id_key';

/** input type for incrementing numeric columns in table "personal_suns" */
export type Personal_Suns_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  personal_sun_type_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "personal_suns" */
export type Personal_Suns_Insert_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  personal_sun_type?: InputMaybe<Personal_Sun_Types_Obj_Rel_Insert_Input>;
  personal_sun_type_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Personal_Sun_Types_Obj_Rel_Insert_Input>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** aggregate max on columns */
export type Personal_Suns_Max_Fields = {
  __typename?: 'personal_suns_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  personal_sun_type_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  values?: Maybe<Array<Scalars['String']['output']>>;
};

/** order by max() on columns of table "personal_suns" */
export type Personal_Suns_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  personal_sun_type_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  values?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Personal_Suns_Min_Fields = {
  __typename?: 'personal_suns_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  personal_sun_type_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  values?: Maybe<Array<Scalars['String']['output']>>;
};

/** order by min() on columns of table "personal_suns" */
export type Personal_Suns_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  personal_sun_type_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  values?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "personal_suns" */
export type Personal_Suns_Mutation_Response = {
  __typename?: 'personal_suns_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Personal_Suns>;
};

/** on_conflict condition type for table "personal_suns" */
export type Personal_Suns_On_Conflict = {
  constraint: Personal_Suns_Constraint;
  update_columns?: Array<Personal_Suns_Update_Column>;
  where?: InputMaybe<Personal_Suns_Bool_Exp>;
};

/** Ordering options when selecting data from "personal_suns". */
export type Personal_Suns_Order_By = {
  id?: InputMaybe<Order_By>;
  personal_sun_type?: InputMaybe<Personal_Sun_Types_Order_By>;
  personal_sun_type_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Personal_Sun_Types_Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
  values?: InputMaybe<Order_By>;
};

/** primary key columns input for table: personal_suns */
export type Personal_Suns_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "personal_suns" */
export type Personal_Suns_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'personal_sun_type_id'
  /** column name */
  | 'user_id'
  /** column name */
  | 'values';

/** input type for updating data in table "personal_suns" */
export type Personal_Suns_Set_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  personal_sun_type_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** aggregate stddev on columns */
export type Personal_Suns_Stddev_Fields = {
  __typename?: 'personal_suns_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  personal_sun_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "personal_suns" */
export type Personal_Suns_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  personal_sun_type_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Personal_Suns_Stddev_Pop_Fields = {
  __typename?: 'personal_suns_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  personal_sun_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "personal_suns" */
export type Personal_Suns_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  personal_sun_type_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Personal_Suns_Stddev_Samp_Fields = {
  __typename?: 'personal_suns_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  personal_sun_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "personal_suns" */
export type Personal_Suns_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  personal_sun_type_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "personal_suns" */
export type Personal_Suns_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Personal_Suns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Personal_Suns_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  personal_sun_type_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** aggregate sum on columns */
export type Personal_Suns_Sum_Fields = {
  __typename?: 'personal_suns_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  personal_sun_type_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "personal_suns" */
export type Personal_Suns_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  personal_sun_type_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "personal_suns" */
export type Personal_Suns_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'personal_sun_type_id'
  /** column name */
  | 'user_id'
  /** column name */
  | 'values';

export type Personal_Suns_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Personal_Suns_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Personal_Suns_Set_Input>;
  /** filter the rows which have to be updated */
  where: Personal_Suns_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Personal_Suns_Var_Pop_Fields = {
  __typename?: 'personal_suns_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  personal_sun_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "personal_suns" */
export type Personal_Suns_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  personal_sun_type_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Personal_Suns_Var_Samp_Fields = {
  __typename?: 'personal_suns_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  personal_sun_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "personal_suns" */
export type Personal_Suns_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  personal_sun_type_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Personal_Suns_Variance_Fields = {
  __typename?: 'personal_suns_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  personal_sun_type_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "personal_suns" */
export type Personal_Suns_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  personal_sun_type_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "profiles" */
export type Profiles = {
  __typename?: 'profiles';
  ambivalen_point?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  attachment_test_final_category?: Maybe<Attachment_Test_Categories>;
  /** An object relationship */
  attachment_test_main_category?: Maybe<Attachment_Test_Categories>;
  /** An object relationship */
  attachment_test_sub_category?: Maybe<Attachment_Test_Categories>;
  /** An object relationship */
  avatar?: Maybe<Avatar_Results>;
  /** An object relationship */
  avatar2?: Maybe<Avatar_Results>;
  avatar_result?: Maybe<Scalars['Int']['output']>;
  avatar_result_2?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  city?: Maybe<Cities>;
  city_id?: Maybe<Scalars['Int']['output']>;
  commitment_index?: Maybe<Scalars['numeric']['output']>;
  communication_index?: Maybe<Scalars['numeric']['output']>;
  disorganized_point?: Maybe<Scalars['numeric']['output']>;
  final_category?: Maybe<Scalars['Int']['output']>;
  freedom_index?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  gender?: Maybe<Genders>;
  gender_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  independent_point?: Maybe<Scalars['numeric']['output']>;
  main_category?: Maybe<Scalars['Int']['output']>;
  sercure_point?: Maybe<Scalars['numeric']['output']>;
  sexuality_index?: Maybe<Scalars['numeric']['output']>;
  sub_category?: Maybe<Scalars['Int']['output']>;
  target_age_from?: Maybe<Scalars['Int']['output']>;
  target_age_to?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  target_avatar?: Maybe<Avatar_Results>;
  /** An object relationship */
  target_avatar2?: Maybe<Avatar_Results>;
  target_avatar_result?: Maybe<Scalars['Int']['output']>;
  target_avatar_result_2?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  target_city?: Maybe<Cities>;
  target_city_id?: Maybe<Scalars['Int']['output']>;
  target_city_ids?: Maybe<Array<Scalars['Int']['output']>>;
  /** An object relationship */
  target_gender?: Maybe<Genders>;
  target_gender_id?: Maybe<Scalars['Int']['output']>;
  target_gender_ids?: Maybe<Array<Scalars['Int']['output']>>;
  tenderness_index?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "profiles" */
export type Profiles_Aggregate = {
  __typename?: 'profiles_aggregate';
  aggregate?: Maybe<Profiles_Aggregate_Fields>;
  nodes: Array<Profiles>;
};

export type Profiles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Profiles_Aggregate_Bool_Exp_Count>;
};

export type Profiles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Profiles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "profiles" */
export type Profiles_Aggregate_Fields = {
  __typename?: 'profiles_aggregate_fields';
  avg?: Maybe<Profiles_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Profiles_Max_Fields>;
  min?: Maybe<Profiles_Min_Fields>;
  stddev?: Maybe<Profiles_Stddev_Fields>;
  stddev_pop?: Maybe<Profiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profiles_Stddev_Samp_Fields>;
  sum?: Maybe<Profiles_Sum_Fields>;
  var_pop?: Maybe<Profiles_Var_Pop_Fields>;
  var_samp?: Maybe<Profiles_Var_Samp_Fields>;
  variance?: Maybe<Profiles_Variance_Fields>;
};


/** aggregate fields of "profiles" */
export type Profiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "profiles" */
export type Profiles_Aggregate_Order_By = {
  avg?: InputMaybe<Profiles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Profiles_Max_Order_By>;
  min?: InputMaybe<Profiles_Min_Order_By>;
  stddev?: InputMaybe<Profiles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Profiles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Profiles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Profiles_Sum_Order_By>;
  var_pop?: InputMaybe<Profiles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Profiles_Var_Samp_Order_By>;
  variance?: InputMaybe<Profiles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "profiles" */
export type Profiles_Arr_Rel_Insert_Input = {
  data: Array<Profiles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Profiles_On_Conflict>;
};

/** aggregate avg on columns */
export type Profiles_Avg_Fields = {
  __typename?: 'profiles_avg_fields';
  ambivalen_point?: Maybe<Scalars['Float']['output']>;
  avatar_result?: Maybe<Scalars['Float']['output']>;
  avatar_result_2?: Maybe<Scalars['Float']['output']>;
  city_id?: Maybe<Scalars['Float']['output']>;
  commitment_index?: Maybe<Scalars['Float']['output']>;
  communication_index?: Maybe<Scalars['Float']['output']>;
  disorganized_point?: Maybe<Scalars['Float']['output']>;
  final_category?: Maybe<Scalars['Float']['output']>;
  freedom_index?: Maybe<Scalars['Float']['output']>;
  gender_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  independent_point?: Maybe<Scalars['Float']['output']>;
  main_category?: Maybe<Scalars['Float']['output']>;
  sercure_point?: Maybe<Scalars['Float']['output']>;
  sexuality_index?: Maybe<Scalars['Float']['output']>;
  sub_category?: Maybe<Scalars['Float']['output']>;
  target_age_from?: Maybe<Scalars['Float']['output']>;
  target_age_to?: Maybe<Scalars['Float']['output']>;
  target_avatar_result?: Maybe<Scalars['Float']['output']>;
  target_avatar_result_2?: Maybe<Scalars['Float']['output']>;
  target_city_id?: Maybe<Scalars['Float']['output']>;
  target_gender_id?: Maybe<Scalars['Float']['output']>;
  tenderness_index?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "profiles" */
export type Profiles_Avg_Order_By = {
  ambivalen_point?: InputMaybe<Order_By>;
  avatar_result?: InputMaybe<Order_By>;
  avatar_result_2?: InputMaybe<Order_By>;
  city_id?: InputMaybe<Order_By>;
  commitment_index?: InputMaybe<Order_By>;
  communication_index?: InputMaybe<Order_By>;
  disorganized_point?: InputMaybe<Order_By>;
  final_category?: InputMaybe<Order_By>;
  freedom_index?: InputMaybe<Order_By>;
  gender_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  independent_point?: InputMaybe<Order_By>;
  main_category?: InputMaybe<Order_By>;
  sercure_point?: InputMaybe<Order_By>;
  sexuality_index?: InputMaybe<Order_By>;
  sub_category?: InputMaybe<Order_By>;
  target_age_from?: InputMaybe<Order_By>;
  target_age_to?: InputMaybe<Order_By>;
  target_avatar_result?: InputMaybe<Order_By>;
  target_avatar_result_2?: InputMaybe<Order_By>;
  target_city_id?: InputMaybe<Order_By>;
  target_gender_id?: InputMaybe<Order_By>;
  tenderness_index?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "profiles". All fields are combined with a logical 'AND'. */
export type Profiles_Bool_Exp = {
  _and?: InputMaybe<Array<Profiles_Bool_Exp>>;
  _not?: InputMaybe<Profiles_Bool_Exp>;
  _or?: InputMaybe<Array<Profiles_Bool_Exp>>;
  ambivalen_point?: InputMaybe<Numeric_Comparison_Exp>;
  attachment_test_final_category?: InputMaybe<Attachment_Test_Categories_Bool_Exp>;
  attachment_test_main_category?: InputMaybe<Attachment_Test_Categories_Bool_Exp>;
  attachment_test_sub_category?: InputMaybe<Attachment_Test_Categories_Bool_Exp>;
  avatar?: InputMaybe<Avatar_Results_Bool_Exp>;
  avatar2?: InputMaybe<Avatar_Results_Bool_Exp>;
  avatar_result?: InputMaybe<Int_Comparison_Exp>;
  avatar_result_2?: InputMaybe<Int_Comparison_Exp>;
  city?: InputMaybe<Cities_Bool_Exp>;
  city_id?: InputMaybe<Int_Comparison_Exp>;
  commitment_index?: InputMaybe<Numeric_Comparison_Exp>;
  communication_index?: InputMaybe<Numeric_Comparison_Exp>;
  disorganized_point?: InputMaybe<Numeric_Comparison_Exp>;
  final_category?: InputMaybe<Int_Comparison_Exp>;
  freedom_index?: InputMaybe<Numeric_Comparison_Exp>;
  gender?: InputMaybe<Genders_Bool_Exp>;
  gender_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  independent_point?: InputMaybe<Numeric_Comparison_Exp>;
  main_category?: InputMaybe<Int_Comparison_Exp>;
  sercure_point?: InputMaybe<Numeric_Comparison_Exp>;
  sexuality_index?: InputMaybe<Numeric_Comparison_Exp>;
  sub_category?: InputMaybe<Int_Comparison_Exp>;
  target_age_from?: InputMaybe<Int_Comparison_Exp>;
  target_age_to?: InputMaybe<Int_Comparison_Exp>;
  target_avatar?: InputMaybe<Avatar_Results_Bool_Exp>;
  target_avatar2?: InputMaybe<Avatar_Results_Bool_Exp>;
  target_avatar_result?: InputMaybe<Int_Comparison_Exp>;
  target_avatar_result_2?: InputMaybe<Int_Comparison_Exp>;
  target_city?: InputMaybe<Cities_Bool_Exp>;
  target_city_id?: InputMaybe<Int_Comparison_Exp>;
  target_city_ids?: InputMaybe<Int_Array_Comparison_Exp>;
  target_gender?: InputMaybe<Genders_Bool_Exp>;
  target_gender_id?: InputMaybe<Int_Comparison_Exp>;
  target_gender_ids?: InputMaybe<Int_Array_Comparison_Exp>;
  tenderness_index?: InputMaybe<Numeric_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "profiles" */
export type Profiles_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'profiles_pkey'
  /** unique or primary key constraint on columns "user_id" */
  | 'profiles_user_id_key';

/** input type for incrementing numeric columns in table "profiles" */
export type Profiles_Inc_Input = {
  ambivalen_point?: InputMaybe<Scalars['numeric']['input']>;
  avatar_result?: InputMaybe<Scalars['Int']['input']>;
  avatar_result_2?: InputMaybe<Scalars['Int']['input']>;
  city_id?: InputMaybe<Scalars['Int']['input']>;
  commitment_index?: InputMaybe<Scalars['numeric']['input']>;
  communication_index?: InputMaybe<Scalars['numeric']['input']>;
  disorganized_point?: InputMaybe<Scalars['numeric']['input']>;
  final_category?: InputMaybe<Scalars['Int']['input']>;
  freedom_index?: InputMaybe<Scalars['numeric']['input']>;
  gender_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  independent_point?: InputMaybe<Scalars['numeric']['input']>;
  main_category?: InputMaybe<Scalars['Int']['input']>;
  sercure_point?: InputMaybe<Scalars['numeric']['input']>;
  sexuality_index?: InputMaybe<Scalars['numeric']['input']>;
  sub_category?: InputMaybe<Scalars['Int']['input']>;
  target_age_from?: InputMaybe<Scalars['Int']['input']>;
  target_age_to?: InputMaybe<Scalars['Int']['input']>;
  target_avatar_result?: InputMaybe<Scalars['Int']['input']>;
  target_avatar_result_2?: InputMaybe<Scalars['Int']['input']>;
  target_city_id?: InputMaybe<Scalars['Int']['input']>;
  target_gender_id?: InputMaybe<Scalars['Int']['input']>;
  tenderness_index?: InputMaybe<Scalars['numeric']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "profiles" */
export type Profiles_Insert_Input = {
  ambivalen_point?: InputMaybe<Scalars['numeric']['input']>;
  attachment_test_final_category?: InputMaybe<Attachment_Test_Categories_Obj_Rel_Insert_Input>;
  attachment_test_main_category?: InputMaybe<Attachment_Test_Categories_Obj_Rel_Insert_Input>;
  attachment_test_sub_category?: InputMaybe<Attachment_Test_Categories_Obj_Rel_Insert_Input>;
  avatar?: InputMaybe<Avatar_Results_Obj_Rel_Insert_Input>;
  avatar2?: InputMaybe<Avatar_Results_Obj_Rel_Insert_Input>;
  avatar_result?: InputMaybe<Scalars['Int']['input']>;
  avatar_result_2?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Cities_Obj_Rel_Insert_Input>;
  city_id?: InputMaybe<Scalars['Int']['input']>;
  commitment_index?: InputMaybe<Scalars['numeric']['input']>;
  communication_index?: InputMaybe<Scalars['numeric']['input']>;
  disorganized_point?: InputMaybe<Scalars['numeric']['input']>;
  final_category?: InputMaybe<Scalars['Int']['input']>;
  freedom_index?: InputMaybe<Scalars['numeric']['input']>;
  gender?: InputMaybe<Genders_Obj_Rel_Insert_Input>;
  gender_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  independent_point?: InputMaybe<Scalars['numeric']['input']>;
  main_category?: InputMaybe<Scalars['Int']['input']>;
  sercure_point?: InputMaybe<Scalars['numeric']['input']>;
  sexuality_index?: InputMaybe<Scalars['numeric']['input']>;
  sub_category?: InputMaybe<Scalars['Int']['input']>;
  target_age_from?: InputMaybe<Scalars['Int']['input']>;
  target_age_to?: InputMaybe<Scalars['Int']['input']>;
  target_avatar?: InputMaybe<Avatar_Results_Obj_Rel_Insert_Input>;
  target_avatar2?: InputMaybe<Avatar_Results_Obj_Rel_Insert_Input>;
  target_avatar_result?: InputMaybe<Scalars['Int']['input']>;
  target_avatar_result_2?: InputMaybe<Scalars['Int']['input']>;
  target_city?: InputMaybe<Cities_Obj_Rel_Insert_Input>;
  target_city_id?: InputMaybe<Scalars['Int']['input']>;
  target_city_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  target_gender?: InputMaybe<Genders_Obj_Rel_Insert_Input>;
  target_gender_id?: InputMaybe<Scalars['Int']['input']>;
  target_gender_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  tenderness_index?: InputMaybe<Scalars['numeric']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Profiles_Max_Fields = {
  __typename?: 'profiles_max_fields';
  ambivalen_point?: Maybe<Scalars['numeric']['output']>;
  avatar_result?: Maybe<Scalars['Int']['output']>;
  avatar_result_2?: Maybe<Scalars['Int']['output']>;
  city_id?: Maybe<Scalars['Int']['output']>;
  commitment_index?: Maybe<Scalars['numeric']['output']>;
  communication_index?: Maybe<Scalars['numeric']['output']>;
  disorganized_point?: Maybe<Scalars['numeric']['output']>;
  final_category?: Maybe<Scalars['Int']['output']>;
  freedom_index?: Maybe<Scalars['numeric']['output']>;
  gender_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  independent_point?: Maybe<Scalars['numeric']['output']>;
  main_category?: Maybe<Scalars['Int']['output']>;
  sercure_point?: Maybe<Scalars['numeric']['output']>;
  sexuality_index?: Maybe<Scalars['numeric']['output']>;
  sub_category?: Maybe<Scalars['Int']['output']>;
  target_age_from?: Maybe<Scalars['Int']['output']>;
  target_age_to?: Maybe<Scalars['Int']['output']>;
  target_avatar_result?: Maybe<Scalars['Int']['output']>;
  target_avatar_result_2?: Maybe<Scalars['Int']['output']>;
  target_city_id?: Maybe<Scalars['Int']['output']>;
  target_city_ids?: Maybe<Array<Scalars['Int']['output']>>;
  target_gender_id?: Maybe<Scalars['Int']['output']>;
  target_gender_ids?: Maybe<Array<Scalars['Int']['output']>>;
  tenderness_index?: Maybe<Scalars['numeric']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "profiles" */
export type Profiles_Max_Order_By = {
  ambivalen_point?: InputMaybe<Order_By>;
  avatar_result?: InputMaybe<Order_By>;
  avatar_result_2?: InputMaybe<Order_By>;
  city_id?: InputMaybe<Order_By>;
  commitment_index?: InputMaybe<Order_By>;
  communication_index?: InputMaybe<Order_By>;
  disorganized_point?: InputMaybe<Order_By>;
  final_category?: InputMaybe<Order_By>;
  freedom_index?: InputMaybe<Order_By>;
  gender_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  independent_point?: InputMaybe<Order_By>;
  main_category?: InputMaybe<Order_By>;
  sercure_point?: InputMaybe<Order_By>;
  sexuality_index?: InputMaybe<Order_By>;
  sub_category?: InputMaybe<Order_By>;
  target_age_from?: InputMaybe<Order_By>;
  target_age_to?: InputMaybe<Order_By>;
  target_avatar_result?: InputMaybe<Order_By>;
  target_avatar_result_2?: InputMaybe<Order_By>;
  target_city_id?: InputMaybe<Order_By>;
  target_city_ids?: InputMaybe<Order_By>;
  target_gender_id?: InputMaybe<Order_By>;
  target_gender_ids?: InputMaybe<Order_By>;
  tenderness_index?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Profiles_Min_Fields = {
  __typename?: 'profiles_min_fields';
  ambivalen_point?: Maybe<Scalars['numeric']['output']>;
  avatar_result?: Maybe<Scalars['Int']['output']>;
  avatar_result_2?: Maybe<Scalars['Int']['output']>;
  city_id?: Maybe<Scalars['Int']['output']>;
  commitment_index?: Maybe<Scalars['numeric']['output']>;
  communication_index?: Maybe<Scalars['numeric']['output']>;
  disorganized_point?: Maybe<Scalars['numeric']['output']>;
  final_category?: Maybe<Scalars['Int']['output']>;
  freedom_index?: Maybe<Scalars['numeric']['output']>;
  gender_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  independent_point?: Maybe<Scalars['numeric']['output']>;
  main_category?: Maybe<Scalars['Int']['output']>;
  sercure_point?: Maybe<Scalars['numeric']['output']>;
  sexuality_index?: Maybe<Scalars['numeric']['output']>;
  sub_category?: Maybe<Scalars['Int']['output']>;
  target_age_from?: Maybe<Scalars['Int']['output']>;
  target_age_to?: Maybe<Scalars['Int']['output']>;
  target_avatar_result?: Maybe<Scalars['Int']['output']>;
  target_avatar_result_2?: Maybe<Scalars['Int']['output']>;
  target_city_id?: Maybe<Scalars['Int']['output']>;
  target_city_ids?: Maybe<Array<Scalars['Int']['output']>>;
  target_gender_id?: Maybe<Scalars['Int']['output']>;
  target_gender_ids?: Maybe<Array<Scalars['Int']['output']>>;
  tenderness_index?: Maybe<Scalars['numeric']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "profiles" */
export type Profiles_Min_Order_By = {
  ambivalen_point?: InputMaybe<Order_By>;
  avatar_result?: InputMaybe<Order_By>;
  avatar_result_2?: InputMaybe<Order_By>;
  city_id?: InputMaybe<Order_By>;
  commitment_index?: InputMaybe<Order_By>;
  communication_index?: InputMaybe<Order_By>;
  disorganized_point?: InputMaybe<Order_By>;
  final_category?: InputMaybe<Order_By>;
  freedom_index?: InputMaybe<Order_By>;
  gender_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  independent_point?: InputMaybe<Order_By>;
  main_category?: InputMaybe<Order_By>;
  sercure_point?: InputMaybe<Order_By>;
  sexuality_index?: InputMaybe<Order_By>;
  sub_category?: InputMaybe<Order_By>;
  target_age_from?: InputMaybe<Order_By>;
  target_age_to?: InputMaybe<Order_By>;
  target_avatar_result?: InputMaybe<Order_By>;
  target_avatar_result_2?: InputMaybe<Order_By>;
  target_city_id?: InputMaybe<Order_By>;
  target_city_ids?: InputMaybe<Order_By>;
  target_gender_id?: InputMaybe<Order_By>;
  target_gender_ids?: InputMaybe<Order_By>;
  tenderness_index?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "profiles" */
export type Profiles_Mutation_Response = {
  __typename?: 'profiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Profiles>;
};

/** input type for inserting object relation for remote table "profiles" */
export type Profiles_Obj_Rel_Insert_Input = {
  data: Profiles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Profiles_On_Conflict>;
};

/** on_conflict condition type for table "profiles" */
export type Profiles_On_Conflict = {
  constraint: Profiles_Constraint;
  update_columns?: Array<Profiles_Update_Column>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};

/** Ordering options when selecting data from "profiles". */
export type Profiles_Order_By = {
  ambivalen_point?: InputMaybe<Order_By>;
  attachment_test_final_category?: InputMaybe<Attachment_Test_Categories_Order_By>;
  attachment_test_main_category?: InputMaybe<Attachment_Test_Categories_Order_By>;
  attachment_test_sub_category?: InputMaybe<Attachment_Test_Categories_Order_By>;
  avatar?: InputMaybe<Avatar_Results_Order_By>;
  avatar2?: InputMaybe<Avatar_Results_Order_By>;
  avatar_result?: InputMaybe<Order_By>;
  avatar_result_2?: InputMaybe<Order_By>;
  city?: InputMaybe<Cities_Order_By>;
  city_id?: InputMaybe<Order_By>;
  commitment_index?: InputMaybe<Order_By>;
  communication_index?: InputMaybe<Order_By>;
  disorganized_point?: InputMaybe<Order_By>;
  final_category?: InputMaybe<Order_By>;
  freedom_index?: InputMaybe<Order_By>;
  gender?: InputMaybe<Genders_Order_By>;
  gender_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  independent_point?: InputMaybe<Order_By>;
  main_category?: InputMaybe<Order_By>;
  sercure_point?: InputMaybe<Order_By>;
  sexuality_index?: InputMaybe<Order_By>;
  sub_category?: InputMaybe<Order_By>;
  target_age_from?: InputMaybe<Order_By>;
  target_age_to?: InputMaybe<Order_By>;
  target_avatar?: InputMaybe<Avatar_Results_Order_By>;
  target_avatar2?: InputMaybe<Avatar_Results_Order_By>;
  target_avatar_result?: InputMaybe<Order_By>;
  target_avatar_result_2?: InputMaybe<Order_By>;
  target_city?: InputMaybe<Cities_Order_By>;
  target_city_id?: InputMaybe<Order_By>;
  target_city_ids?: InputMaybe<Order_By>;
  target_gender?: InputMaybe<Genders_Order_By>;
  target_gender_id?: InputMaybe<Order_By>;
  target_gender_ids?: InputMaybe<Order_By>;
  tenderness_index?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: profiles */
export type Profiles_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "profiles" */
export type Profiles_Select_Column =
  /** column name */
  | 'ambivalen_point'
  /** column name */
  | 'avatar_result'
  /** column name */
  | 'avatar_result_2'
  /** column name */
  | 'city_id'
  /** column name */
  | 'commitment_index'
  /** column name */
  | 'communication_index'
  /** column name */
  | 'disorganized_point'
  /** column name */
  | 'final_category'
  /** column name */
  | 'freedom_index'
  /** column name */
  | 'gender_id'
  /** column name */
  | 'id'
  /** column name */
  | 'independent_point'
  /** column name */
  | 'main_category'
  /** column name */
  | 'sercure_point'
  /** column name */
  | 'sexuality_index'
  /** column name */
  | 'sub_category'
  /** column name */
  | 'target_age_from'
  /** column name */
  | 'target_age_to'
  /** column name */
  | 'target_avatar_result'
  /** column name */
  | 'target_avatar_result_2'
  /** column name */
  | 'target_city_id'
  /** column name */
  | 'target_city_ids'
  /** column name */
  | 'target_gender_id'
  /** column name */
  | 'target_gender_ids'
  /** column name */
  | 'tenderness_index'
  /** column name */
  | 'user_id';

/** input type for updating data in table "profiles" */
export type Profiles_Set_Input = {
  ambivalen_point?: InputMaybe<Scalars['numeric']['input']>;
  avatar_result?: InputMaybe<Scalars['Int']['input']>;
  avatar_result_2?: InputMaybe<Scalars['Int']['input']>;
  city_id?: InputMaybe<Scalars['Int']['input']>;
  commitment_index?: InputMaybe<Scalars['numeric']['input']>;
  communication_index?: InputMaybe<Scalars['numeric']['input']>;
  disorganized_point?: InputMaybe<Scalars['numeric']['input']>;
  final_category?: InputMaybe<Scalars['Int']['input']>;
  freedom_index?: InputMaybe<Scalars['numeric']['input']>;
  gender_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  independent_point?: InputMaybe<Scalars['numeric']['input']>;
  main_category?: InputMaybe<Scalars['Int']['input']>;
  sercure_point?: InputMaybe<Scalars['numeric']['input']>;
  sexuality_index?: InputMaybe<Scalars['numeric']['input']>;
  sub_category?: InputMaybe<Scalars['Int']['input']>;
  target_age_from?: InputMaybe<Scalars['Int']['input']>;
  target_age_to?: InputMaybe<Scalars['Int']['input']>;
  target_avatar_result?: InputMaybe<Scalars['Int']['input']>;
  target_avatar_result_2?: InputMaybe<Scalars['Int']['input']>;
  target_city_id?: InputMaybe<Scalars['Int']['input']>;
  target_city_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  target_gender_id?: InputMaybe<Scalars['Int']['input']>;
  target_gender_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  tenderness_index?: InputMaybe<Scalars['numeric']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Profiles_Stddev_Fields = {
  __typename?: 'profiles_stddev_fields';
  ambivalen_point?: Maybe<Scalars['Float']['output']>;
  avatar_result?: Maybe<Scalars['Float']['output']>;
  avatar_result_2?: Maybe<Scalars['Float']['output']>;
  city_id?: Maybe<Scalars['Float']['output']>;
  commitment_index?: Maybe<Scalars['Float']['output']>;
  communication_index?: Maybe<Scalars['Float']['output']>;
  disorganized_point?: Maybe<Scalars['Float']['output']>;
  final_category?: Maybe<Scalars['Float']['output']>;
  freedom_index?: Maybe<Scalars['Float']['output']>;
  gender_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  independent_point?: Maybe<Scalars['Float']['output']>;
  main_category?: Maybe<Scalars['Float']['output']>;
  sercure_point?: Maybe<Scalars['Float']['output']>;
  sexuality_index?: Maybe<Scalars['Float']['output']>;
  sub_category?: Maybe<Scalars['Float']['output']>;
  target_age_from?: Maybe<Scalars['Float']['output']>;
  target_age_to?: Maybe<Scalars['Float']['output']>;
  target_avatar_result?: Maybe<Scalars['Float']['output']>;
  target_avatar_result_2?: Maybe<Scalars['Float']['output']>;
  target_city_id?: Maybe<Scalars['Float']['output']>;
  target_gender_id?: Maybe<Scalars['Float']['output']>;
  tenderness_index?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "profiles" */
export type Profiles_Stddev_Order_By = {
  ambivalen_point?: InputMaybe<Order_By>;
  avatar_result?: InputMaybe<Order_By>;
  avatar_result_2?: InputMaybe<Order_By>;
  city_id?: InputMaybe<Order_By>;
  commitment_index?: InputMaybe<Order_By>;
  communication_index?: InputMaybe<Order_By>;
  disorganized_point?: InputMaybe<Order_By>;
  final_category?: InputMaybe<Order_By>;
  freedom_index?: InputMaybe<Order_By>;
  gender_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  independent_point?: InputMaybe<Order_By>;
  main_category?: InputMaybe<Order_By>;
  sercure_point?: InputMaybe<Order_By>;
  sexuality_index?: InputMaybe<Order_By>;
  sub_category?: InputMaybe<Order_By>;
  target_age_from?: InputMaybe<Order_By>;
  target_age_to?: InputMaybe<Order_By>;
  target_avatar_result?: InputMaybe<Order_By>;
  target_avatar_result_2?: InputMaybe<Order_By>;
  target_city_id?: InputMaybe<Order_By>;
  target_gender_id?: InputMaybe<Order_By>;
  tenderness_index?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profiles_Stddev_Pop_Fields = {
  __typename?: 'profiles_stddev_pop_fields';
  ambivalen_point?: Maybe<Scalars['Float']['output']>;
  avatar_result?: Maybe<Scalars['Float']['output']>;
  avatar_result_2?: Maybe<Scalars['Float']['output']>;
  city_id?: Maybe<Scalars['Float']['output']>;
  commitment_index?: Maybe<Scalars['Float']['output']>;
  communication_index?: Maybe<Scalars['Float']['output']>;
  disorganized_point?: Maybe<Scalars['Float']['output']>;
  final_category?: Maybe<Scalars['Float']['output']>;
  freedom_index?: Maybe<Scalars['Float']['output']>;
  gender_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  independent_point?: Maybe<Scalars['Float']['output']>;
  main_category?: Maybe<Scalars['Float']['output']>;
  sercure_point?: Maybe<Scalars['Float']['output']>;
  sexuality_index?: Maybe<Scalars['Float']['output']>;
  sub_category?: Maybe<Scalars['Float']['output']>;
  target_age_from?: Maybe<Scalars['Float']['output']>;
  target_age_to?: Maybe<Scalars['Float']['output']>;
  target_avatar_result?: Maybe<Scalars['Float']['output']>;
  target_avatar_result_2?: Maybe<Scalars['Float']['output']>;
  target_city_id?: Maybe<Scalars['Float']['output']>;
  target_gender_id?: Maybe<Scalars['Float']['output']>;
  tenderness_index?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "profiles" */
export type Profiles_Stddev_Pop_Order_By = {
  ambivalen_point?: InputMaybe<Order_By>;
  avatar_result?: InputMaybe<Order_By>;
  avatar_result_2?: InputMaybe<Order_By>;
  city_id?: InputMaybe<Order_By>;
  commitment_index?: InputMaybe<Order_By>;
  communication_index?: InputMaybe<Order_By>;
  disorganized_point?: InputMaybe<Order_By>;
  final_category?: InputMaybe<Order_By>;
  freedom_index?: InputMaybe<Order_By>;
  gender_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  independent_point?: InputMaybe<Order_By>;
  main_category?: InputMaybe<Order_By>;
  sercure_point?: InputMaybe<Order_By>;
  sexuality_index?: InputMaybe<Order_By>;
  sub_category?: InputMaybe<Order_By>;
  target_age_from?: InputMaybe<Order_By>;
  target_age_to?: InputMaybe<Order_By>;
  target_avatar_result?: InputMaybe<Order_By>;
  target_avatar_result_2?: InputMaybe<Order_By>;
  target_city_id?: InputMaybe<Order_By>;
  target_gender_id?: InputMaybe<Order_By>;
  tenderness_index?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profiles_Stddev_Samp_Fields = {
  __typename?: 'profiles_stddev_samp_fields';
  ambivalen_point?: Maybe<Scalars['Float']['output']>;
  avatar_result?: Maybe<Scalars['Float']['output']>;
  avatar_result_2?: Maybe<Scalars['Float']['output']>;
  city_id?: Maybe<Scalars['Float']['output']>;
  commitment_index?: Maybe<Scalars['Float']['output']>;
  communication_index?: Maybe<Scalars['Float']['output']>;
  disorganized_point?: Maybe<Scalars['Float']['output']>;
  final_category?: Maybe<Scalars['Float']['output']>;
  freedom_index?: Maybe<Scalars['Float']['output']>;
  gender_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  independent_point?: Maybe<Scalars['Float']['output']>;
  main_category?: Maybe<Scalars['Float']['output']>;
  sercure_point?: Maybe<Scalars['Float']['output']>;
  sexuality_index?: Maybe<Scalars['Float']['output']>;
  sub_category?: Maybe<Scalars['Float']['output']>;
  target_age_from?: Maybe<Scalars['Float']['output']>;
  target_age_to?: Maybe<Scalars['Float']['output']>;
  target_avatar_result?: Maybe<Scalars['Float']['output']>;
  target_avatar_result_2?: Maybe<Scalars['Float']['output']>;
  target_city_id?: Maybe<Scalars['Float']['output']>;
  target_gender_id?: Maybe<Scalars['Float']['output']>;
  tenderness_index?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "profiles" */
export type Profiles_Stddev_Samp_Order_By = {
  ambivalen_point?: InputMaybe<Order_By>;
  avatar_result?: InputMaybe<Order_By>;
  avatar_result_2?: InputMaybe<Order_By>;
  city_id?: InputMaybe<Order_By>;
  commitment_index?: InputMaybe<Order_By>;
  communication_index?: InputMaybe<Order_By>;
  disorganized_point?: InputMaybe<Order_By>;
  final_category?: InputMaybe<Order_By>;
  freedom_index?: InputMaybe<Order_By>;
  gender_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  independent_point?: InputMaybe<Order_By>;
  main_category?: InputMaybe<Order_By>;
  sercure_point?: InputMaybe<Order_By>;
  sexuality_index?: InputMaybe<Order_By>;
  sub_category?: InputMaybe<Order_By>;
  target_age_from?: InputMaybe<Order_By>;
  target_age_to?: InputMaybe<Order_By>;
  target_avatar_result?: InputMaybe<Order_By>;
  target_avatar_result_2?: InputMaybe<Order_By>;
  target_city_id?: InputMaybe<Order_By>;
  target_gender_id?: InputMaybe<Order_By>;
  tenderness_index?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "profiles" */
export type Profiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Profiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Profiles_Stream_Cursor_Value_Input = {
  ambivalen_point?: InputMaybe<Scalars['numeric']['input']>;
  avatar_result?: InputMaybe<Scalars['Int']['input']>;
  avatar_result_2?: InputMaybe<Scalars['Int']['input']>;
  city_id?: InputMaybe<Scalars['Int']['input']>;
  commitment_index?: InputMaybe<Scalars['numeric']['input']>;
  communication_index?: InputMaybe<Scalars['numeric']['input']>;
  disorganized_point?: InputMaybe<Scalars['numeric']['input']>;
  final_category?: InputMaybe<Scalars['Int']['input']>;
  freedom_index?: InputMaybe<Scalars['numeric']['input']>;
  gender_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  independent_point?: InputMaybe<Scalars['numeric']['input']>;
  main_category?: InputMaybe<Scalars['Int']['input']>;
  sercure_point?: InputMaybe<Scalars['numeric']['input']>;
  sexuality_index?: InputMaybe<Scalars['numeric']['input']>;
  sub_category?: InputMaybe<Scalars['Int']['input']>;
  target_age_from?: InputMaybe<Scalars['Int']['input']>;
  target_age_to?: InputMaybe<Scalars['Int']['input']>;
  target_avatar_result?: InputMaybe<Scalars['Int']['input']>;
  target_avatar_result_2?: InputMaybe<Scalars['Int']['input']>;
  target_city_id?: InputMaybe<Scalars['Int']['input']>;
  target_city_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  target_gender_id?: InputMaybe<Scalars['Int']['input']>;
  target_gender_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  tenderness_index?: InputMaybe<Scalars['numeric']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Profiles_Sum_Fields = {
  __typename?: 'profiles_sum_fields';
  ambivalen_point?: Maybe<Scalars['numeric']['output']>;
  avatar_result?: Maybe<Scalars['Int']['output']>;
  avatar_result_2?: Maybe<Scalars['Int']['output']>;
  city_id?: Maybe<Scalars['Int']['output']>;
  commitment_index?: Maybe<Scalars['numeric']['output']>;
  communication_index?: Maybe<Scalars['numeric']['output']>;
  disorganized_point?: Maybe<Scalars['numeric']['output']>;
  final_category?: Maybe<Scalars['Int']['output']>;
  freedom_index?: Maybe<Scalars['numeric']['output']>;
  gender_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  independent_point?: Maybe<Scalars['numeric']['output']>;
  main_category?: Maybe<Scalars['Int']['output']>;
  sercure_point?: Maybe<Scalars['numeric']['output']>;
  sexuality_index?: Maybe<Scalars['numeric']['output']>;
  sub_category?: Maybe<Scalars['Int']['output']>;
  target_age_from?: Maybe<Scalars['Int']['output']>;
  target_age_to?: Maybe<Scalars['Int']['output']>;
  target_avatar_result?: Maybe<Scalars['Int']['output']>;
  target_avatar_result_2?: Maybe<Scalars['Int']['output']>;
  target_city_id?: Maybe<Scalars['Int']['output']>;
  target_gender_id?: Maybe<Scalars['Int']['output']>;
  tenderness_index?: Maybe<Scalars['numeric']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "profiles" */
export type Profiles_Sum_Order_By = {
  ambivalen_point?: InputMaybe<Order_By>;
  avatar_result?: InputMaybe<Order_By>;
  avatar_result_2?: InputMaybe<Order_By>;
  city_id?: InputMaybe<Order_By>;
  commitment_index?: InputMaybe<Order_By>;
  communication_index?: InputMaybe<Order_By>;
  disorganized_point?: InputMaybe<Order_By>;
  final_category?: InputMaybe<Order_By>;
  freedom_index?: InputMaybe<Order_By>;
  gender_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  independent_point?: InputMaybe<Order_By>;
  main_category?: InputMaybe<Order_By>;
  sercure_point?: InputMaybe<Order_By>;
  sexuality_index?: InputMaybe<Order_By>;
  sub_category?: InputMaybe<Order_By>;
  target_age_from?: InputMaybe<Order_By>;
  target_age_to?: InputMaybe<Order_By>;
  target_avatar_result?: InputMaybe<Order_By>;
  target_avatar_result_2?: InputMaybe<Order_By>;
  target_city_id?: InputMaybe<Order_By>;
  target_gender_id?: InputMaybe<Order_By>;
  tenderness_index?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "profiles" */
export type Profiles_Update_Column =
  /** column name */
  | 'ambivalen_point'
  /** column name */
  | 'avatar_result'
  /** column name */
  | 'avatar_result_2'
  /** column name */
  | 'city_id'
  /** column name */
  | 'commitment_index'
  /** column name */
  | 'communication_index'
  /** column name */
  | 'disorganized_point'
  /** column name */
  | 'final_category'
  /** column name */
  | 'freedom_index'
  /** column name */
  | 'gender_id'
  /** column name */
  | 'id'
  /** column name */
  | 'independent_point'
  /** column name */
  | 'main_category'
  /** column name */
  | 'sercure_point'
  /** column name */
  | 'sexuality_index'
  /** column name */
  | 'sub_category'
  /** column name */
  | 'target_age_from'
  /** column name */
  | 'target_age_to'
  /** column name */
  | 'target_avatar_result'
  /** column name */
  | 'target_avatar_result_2'
  /** column name */
  | 'target_city_id'
  /** column name */
  | 'target_city_ids'
  /** column name */
  | 'target_gender_id'
  /** column name */
  | 'target_gender_ids'
  /** column name */
  | 'tenderness_index'
  /** column name */
  | 'user_id';

export type Profiles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Profiles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Profiles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Profiles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Profiles_Var_Pop_Fields = {
  __typename?: 'profiles_var_pop_fields';
  ambivalen_point?: Maybe<Scalars['Float']['output']>;
  avatar_result?: Maybe<Scalars['Float']['output']>;
  avatar_result_2?: Maybe<Scalars['Float']['output']>;
  city_id?: Maybe<Scalars['Float']['output']>;
  commitment_index?: Maybe<Scalars['Float']['output']>;
  communication_index?: Maybe<Scalars['Float']['output']>;
  disorganized_point?: Maybe<Scalars['Float']['output']>;
  final_category?: Maybe<Scalars['Float']['output']>;
  freedom_index?: Maybe<Scalars['Float']['output']>;
  gender_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  independent_point?: Maybe<Scalars['Float']['output']>;
  main_category?: Maybe<Scalars['Float']['output']>;
  sercure_point?: Maybe<Scalars['Float']['output']>;
  sexuality_index?: Maybe<Scalars['Float']['output']>;
  sub_category?: Maybe<Scalars['Float']['output']>;
  target_age_from?: Maybe<Scalars['Float']['output']>;
  target_age_to?: Maybe<Scalars['Float']['output']>;
  target_avatar_result?: Maybe<Scalars['Float']['output']>;
  target_avatar_result_2?: Maybe<Scalars['Float']['output']>;
  target_city_id?: Maybe<Scalars['Float']['output']>;
  target_gender_id?: Maybe<Scalars['Float']['output']>;
  tenderness_index?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "profiles" */
export type Profiles_Var_Pop_Order_By = {
  ambivalen_point?: InputMaybe<Order_By>;
  avatar_result?: InputMaybe<Order_By>;
  avatar_result_2?: InputMaybe<Order_By>;
  city_id?: InputMaybe<Order_By>;
  commitment_index?: InputMaybe<Order_By>;
  communication_index?: InputMaybe<Order_By>;
  disorganized_point?: InputMaybe<Order_By>;
  final_category?: InputMaybe<Order_By>;
  freedom_index?: InputMaybe<Order_By>;
  gender_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  independent_point?: InputMaybe<Order_By>;
  main_category?: InputMaybe<Order_By>;
  sercure_point?: InputMaybe<Order_By>;
  sexuality_index?: InputMaybe<Order_By>;
  sub_category?: InputMaybe<Order_By>;
  target_age_from?: InputMaybe<Order_By>;
  target_age_to?: InputMaybe<Order_By>;
  target_avatar_result?: InputMaybe<Order_By>;
  target_avatar_result_2?: InputMaybe<Order_By>;
  target_city_id?: InputMaybe<Order_By>;
  target_gender_id?: InputMaybe<Order_By>;
  tenderness_index?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profiles_Var_Samp_Fields = {
  __typename?: 'profiles_var_samp_fields';
  ambivalen_point?: Maybe<Scalars['Float']['output']>;
  avatar_result?: Maybe<Scalars['Float']['output']>;
  avatar_result_2?: Maybe<Scalars['Float']['output']>;
  city_id?: Maybe<Scalars['Float']['output']>;
  commitment_index?: Maybe<Scalars['Float']['output']>;
  communication_index?: Maybe<Scalars['Float']['output']>;
  disorganized_point?: Maybe<Scalars['Float']['output']>;
  final_category?: Maybe<Scalars['Float']['output']>;
  freedom_index?: Maybe<Scalars['Float']['output']>;
  gender_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  independent_point?: Maybe<Scalars['Float']['output']>;
  main_category?: Maybe<Scalars['Float']['output']>;
  sercure_point?: Maybe<Scalars['Float']['output']>;
  sexuality_index?: Maybe<Scalars['Float']['output']>;
  sub_category?: Maybe<Scalars['Float']['output']>;
  target_age_from?: Maybe<Scalars['Float']['output']>;
  target_age_to?: Maybe<Scalars['Float']['output']>;
  target_avatar_result?: Maybe<Scalars['Float']['output']>;
  target_avatar_result_2?: Maybe<Scalars['Float']['output']>;
  target_city_id?: Maybe<Scalars['Float']['output']>;
  target_gender_id?: Maybe<Scalars['Float']['output']>;
  tenderness_index?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "profiles" */
export type Profiles_Var_Samp_Order_By = {
  ambivalen_point?: InputMaybe<Order_By>;
  avatar_result?: InputMaybe<Order_By>;
  avatar_result_2?: InputMaybe<Order_By>;
  city_id?: InputMaybe<Order_By>;
  commitment_index?: InputMaybe<Order_By>;
  communication_index?: InputMaybe<Order_By>;
  disorganized_point?: InputMaybe<Order_By>;
  final_category?: InputMaybe<Order_By>;
  freedom_index?: InputMaybe<Order_By>;
  gender_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  independent_point?: InputMaybe<Order_By>;
  main_category?: InputMaybe<Order_By>;
  sercure_point?: InputMaybe<Order_By>;
  sexuality_index?: InputMaybe<Order_By>;
  sub_category?: InputMaybe<Order_By>;
  target_age_from?: InputMaybe<Order_By>;
  target_age_to?: InputMaybe<Order_By>;
  target_avatar_result?: InputMaybe<Order_By>;
  target_avatar_result_2?: InputMaybe<Order_By>;
  target_city_id?: InputMaybe<Order_By>;
  target_gender_id?: InputMaybe<Order_By>;
  tenderness_index?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Profiles_Variance_Fields = {
  __typename?: 'profiles_variance_fields';
  ambivalen_point?: Maybe<Scalars['Float']['output']>;
  avatar_result?: Maybe<Scalars['Float']['output']>;
  avatar_result_2?: Maybe<Scalars['Float']['output']>;
  city_id?: Maybe<Scalars['Float']['output']>;
  commitment_index?: Maybe<Scalars['Float']['output']>;
  communication_index?: Maybe<Scalars['Float']['output']>;
  disorganized_point?: Maybe<Scalars['Float']['output']>;
  final_category?: Maybe<Scalars['Float']['output']>;
  freedom_index?: Maybe<Scalars['Float']['output']>;
  gender_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  independent_point?: Maybe<Scalars['Float']['output']>;
  main_category?: Maybe<Scalars['Float']['output']>;
  sercure_point?: Maybe<Scalars['Float']['output']>;
  sexuality_index?: Maybe<Scalars['Float']['output']>;
  sub_category?: Maybe<Scalars['Float']['output']>;
  target_age_from?: Maybe<Scalars['Float']['output']>;
  target_age_to?: Maybe<Scalars['Float']['output']>;
  target_avatar_result?: Maybe<Scalars['Float']['output']>;
  target_avatar_result_2?: Maybe<Scalars['Float']['output']>;
  target_city_id?: Maybe<Scalars['Float']['output']>;
  target_gender_id?: Maybe<Scalars['Float']['output']>;
  tenderness_index?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "profiles" */
export type Profiles_Variance_Order_By = {
  ambivalen_point?: InputMaybe<Order_By>;
  avatar_result?: InputMaybe<Order_By>;
  avatar_result_2?: InputMaybe<Order_By>;
  city_id?: InputMaybe<Order_By>;
  commitment_index?: InputMaybe<Order_By>;
  communication_index?: InputMaybe<Order_By>;
  disorganized_point?: InputMaybe<Order_By>;
  final_category?: InputMaybe<Order_By>;
  freedom_index?: InputMaybe<Order_By>;
  gender_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  independent_point?: InputMaybe<Order_By>;
  main_category?: InputMaybe<Order_By>;
  sercure_point?: InputMaybe<Order_By>;
  sexuality_index?: InputMaybe<Order_By>;
  sub_category?: InputMaybe<Order_By>;
  target_age_from?: InputMaybe<Order_By>;
  target_age_to?: InputMaybe<Order_By>;
  target_avatar_result?: InputMaybe<Order_By>;
  target_avatar_result_2?: InputMaybe<Order_By>;
  target_city_id?: InputMaybe<Order_By>;
  target_gender_id?: InputMaybe<Order_By>;
  tenderness_index?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "attachment_test_categories" */
  attachment_test_categories: Array<Attachment_Test_Categories>;
  /** fetch aggregated fields from the table: "attachment_test_categories" */
  attachment_test_categories_aggregate: Attachment_Test_Categories_Aggregate;
  /** fetch data from the table: "attachment_test_categories" using primary key columns */
  attachment_test_categories_by_pk?: Maybe<Attachment_Test_Categories>;
  /** An array relationship */
  attachment_test_responses: Array<Attachment_Test_Responses>;
  /** An aggregate relationship */
  attachment_test_responses_aggregate: Attachment_Test_Responses_Aggregate;
  /** fetch data from the table: "attachment_test_responses" using primary key columns */
  attachment_test_responses_by_pk?: Maybe<Attachment_Test_Responses>;
  /** fetch data from the table: "attachment_tests" */
  attachment_tests: Array<Attachment_Tests>;
  /** fetch aggregated fields from the table: "attachment_tests" */
  attachment_tests_aggregate: Attachment_Tests_Aggregate;
  /** fetch data from the table: "attachment_tests" using primary key columns */
  attachment_tests_by_pk?: Maybe<Attachment_Tests>;
  /** An array relationship */
  availability_time_slots: Array<Availability_Time_Slots>;
  /** An aggregate relationship */
  availability_time_slots_aggregate: Availability_Time_Slots_Aggregate;
  /** fetch data from the table: "availability_time_slots" using primary key columns */
  availability_time_slots_by_pk?: Maybe<Availability_Time_Slots>;
  /** fetch data from the table: "avatar_results" */
  avatar_results: Array<Avatar_Results>;
  /** fetch aggregated fields from the table: "avatar_results" */
  avatar_results_aggregate: Avatar_Results_Aggregate;
  /** fetch data from the table: "avatar_results" using primary key columns */
  avatar_results_by_pk?: Maybe<Avatar_Results>;
  /** An array relationship */
  avatar_test_responses: Array<Avatar_Test_Responses>;
  /** An aggregate relationship */
  avatar_test_responses_aggregate: Avatar_Test_Responses_Aggregate;
  /** fetch data from the table: "avatar_test_responses" using primary key columns */
  avatar_test_responses_by_pk?: Maybe<Avatar_Test_Responses>;
  /** fetch data from the table: "avatar_tests" */
  avatar_tests: Array<Avatar_Tests>;
  /** fetch aggregated fields from the table: "avatar_tests" */
  avatar_tests_aggregate: Avatar_Tests_Aggregate;
  /** fetch data from the table: "avatar_tests" using primary key columns */
  avatar_tests_by_pk?: Maybe<Avatar_Tests>;
  /** An array relationship */
  channel_users: Array<Channel_Users>;
  /** An aggregate relationship */
  channel_users_aggregate: Channel_Users_Aggregate;
  /** fetch data from the table: "channel_users" using primary key columns */
  channel_users_by_pk?: Maybe<Channel_Users>;
  /** An array relationship */
  channels: Array<Channels>;
  /** An aggregate relationship */
  channels_aggregate: Channels_Aggregate;
  /** fetch data from the table: "channels" using primary key columns */
  channels_by_pk?: Maybe<Channels>;
  /** fetch data from the table: "cities" */
  cities: Array<Cities>;
  /** fetch aggregated fields from the table: "cities" */
  cities_aggregate: Cities_Aggregate;
  /** fetch data from the table: "cities" using primary key columns */
  cities_by_pk?: Maybe<Cities>;
  /** fetch data from the table: "contact_forms" */
  contact_forms: Array<Contact_Forms>;
  /** fetch aggregated fields from the table: "contact_forms" */
  contact_forms_aggregate: Contact_Forms_Aggregate;
  /** fetch data from the table: "contact_forms" using primary key columns */
  contact_forms_by_pk?: Maybe<Contact_Forms>;
  /** fetch data from the table: "contents" */
  contents: Array<Contents>;
  /** fetch aggregated fields from the table: "contents" */
  contents_aggregate: Contents_Aggregate;
  /** fetch data from the table: "contents" using primary key columns */
  contents_by_pk?: Maybe<Contents>;
  /** fetch data from the table: "device_tokens" */
  device_tokens: Array<Device_Tokens>;
  /** fetch aggregated fields from the table: "device_tokens" */
  device_tokens_aggregate: Device_Tokens_Aggregate;
  /** fetch data from the table: "device_tokens" using primary key columns */
  device_tokens_by_pk?: Maybe<Device_Tokens>;
  /** fetch data from the table: "faqs" */
  faqs: Array<Faqs>;
  /** fetch aggregated fields from the table: "faqs" */
  faqs_aggregate: Faqs_Aggregate;
  /** fetch data from the table: "faqs" using primary key columns */
  faqs_by_pk?: Maybe<Faqs>;
  /** fetch data from the table: "friend_requests" */
  friend_requests: Array<Friend_Requests>;
  /** fetch aggregated fields from the table: "friend_requests" */
  friend_requests_aggregate: Friend_Requests_Aggregate;
  /** fetch data from the table: "friend_requests" using primary key columns */
  friend_requests_by_pk?: Maybe<Friend_Requests>;
  /** fetch data from the table: "friends" */
  friends: Array<Friends>;
  /** fetch aggregated fields from the table: "friends" */
  friends_aggregate: Friends_Aggregate;
  /** fetch data from the table: "friends" using primary key columns */
  friends_by_pk?: Maybe<Friends>;
  /** fetch data from the table: "genders" */
  genders: Array<Genders>;
  /** fetch aggregated fields from the table: "genders" */
  genders_aggregate: Genders_Aggregate;
  /** fetch data from the table: "genders" using primary key columns */
  genders_by_pk?: Maybe<Genders>;
  /** Handle get stripe onboarding account */
  getStripeAccountLink: GetStripeAccountLinkResponse;
  /** Handle get upload pre-signed s3 url */
  getUploadPreSignedUrl?: Maybe<GetUploadPreSignedUrlOutput>;
  getUserTemplateQuestionResponses?: Maybe<Array<Maybe<UserTemplateQuestionResponse>>>;
  /** fetch data from the table: "languages" */
  languages: Array<Languages>;
  /** fetch aggregated fields from the table: "languages" */
  languages_aggregate: Languages_Aggregate;
  /** fetch data from the table: "languages" using primary key columns */
  languages_by_pk?: Maybe<Languages>;
  /** fetch data from the table: "matric_question_categories" */
  matric_question_categories: Array<Matric_Question_Categories>;
  /** fetch aggregated fields from the table: "matric_question_categories" */
  matric_question_categories_aggregate: Matric_Question_Categories_Aggregate;
  /** fetch data from the table: "matric_question_categories" using primary key columns */
  matric_question_categories_by_pk?: Maybe<Matric_Question_Categories>;
  /** An array relationship */
  matric_question_responses: Array<Matric_Question_Responses>;
  /** An aggregate relationship */
  matric_question_responses_aggregate: Matric_Question_Responses_Aggregate;
  /** fetch data from the table: "matric_question_responses" using primary key columns */
  matric_question_responses_by_pk?: Maybe<Matric_Question_Responses>;
  /** An array relationship */
  matric_questions: Array<Matric_Questions>;
  /** An aggregate relationship */
  matric_questions_aggregate: Matric_Questions_Aggregate;
  /** fetch data from the table: "matric_questions" using primary key columns */
  matric_questions_by_pk?: Maybe<Matric_Questions>;
  /** An array relationship */
  messages: Array<Messages>;
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate;
  /** fetch data from the table: "messages" using primary key columns */
  messages_by_pk?: Maybe<Messages>;
  /** fetch data from the table: "notifications" */
  notifications: Array<Notifications>;
  /** fetch aggregated fields from the table: "notifications" */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table: "otps" */
  otps: Array<Otps>;
  /** fetch aggregated fields from the table: "otps" */
  otps_aggregate: Otps_Aggregate;
  /** fetch data from the table: "otps" using primary key columns */
  otps_by_pk?: Maybe<Otps>;
  /** fetch data from the table: "password_reset_tokens" */
  password_reset_tokens: Array<Password_Reset_Tokens>;
  /** fetch aggregated fields from the table: "password_reset_tokens" */
  password_reset_tokens_aggregate: Password_Reset_Tokens_Aggregate;
  /** fetch data from the table: "password_reset_tokens" using primary key columns */
  password_reset_tokens_by_pk?: Maybe<Password_Reset_Tokens>;
  /** fetch data from the table: "personal_sun_types" */
  personal_sun_types: Array<Personal_Sun_Types>;
  /** fetch aggregated fields from the table: "personal_sun_types" */
  personal_sun_types_aggregate: Personal_Sun_Types_Aggregate;
  /** fetch data from the table: "personal_sun_types" using primary key columns */
  personal_sun_types_by_pk?: Maybe<Personal_Sun_Types>;
  /** An array relationship */
  personal_suns: Array<Personal_Suns>;
  /** An aggregate relationship */
  personal_suns_aggregate: Personal_Suns_Aggregate;
  /** fetch data from the table: "personal_suns" using primary key columns */
  personal_suns_by_pk?: Maybe<Personal_Suns>;
  /** An array relationship */
  profiles: Array<Profiles>;
  /** An aggregate relationship */
  profiles_aggregate: Profiles_Aggregate;
  /** fetch data from the table: "profiles" using primary key columns */
  profiles_by_pk?: Maybe<Profiles>;
  /** An array relationship */
  reservation_payments: Array<Reservation_Payments>;
  /** An aggregate relationship */
  reservation_payments_aggregate: Reservation_Payments_Aggregate;
  /** fetch data from the table: "reservation_payments" using primary key columns */
  reservation_payments_by_pk?: Maybe<Reservation_Payments>;
  /** An array relationship */
  reservations: Array<Reservations>;
  /** An aggregate relationship */
  reservations_aggregate: Reservations_Aggregate;
  /** fetch data from the table: "reservations" using primary key columns */
  reservations_by_pk?: Maybe<Reservations>;
  /** An array relationship */
  reviews: Array<Reviews>;
  /** An aggregate relationship */
  reviews_aggregate: Reviews_Aggregate;
  /** fetch data from the table: "reviews" using primary key columns */
  reviews_by_pk?: Maybe<Reviews>;
  /** fetch data from the table: "settings" */
  settings: Array<Settings>;
  /** fetch aggregated fields from the table: "settings" */
  settings_aggregate: Settings_Aggregate;
  /** fetch data from the table: "settings" using primary key columns */
  settings_by_pk?: Maybe<Settings>;
  /** An array relationship */
  subscriptions: Array<Subscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscriptions_Aggregate;
  /** fetch data from the table: "subscriptions" using primary key columns */
  subscriptions_by_pk?: Maybe<Subscriptions>;
  /** An array relationship */
  template_question_responses: Array<Template_Question_Responses>;
  /** An aggregate relationship */
  template_question_responses_aggregate: Template_Question_Responses_Aggregate;
  /** fetch data from the table: "template_question_responses" using primary key columns */
  template_question_responses_by_pk?: Maybe<Template_Question_Responses>;
  /** fetch data from the table: "template_questions" */
  template_questions: Array<Template_Questions>;
  /** fetch aggregated fields from the table: "template_questions" */
  template_questions_aggregate: Template_Questions_Aggregate;
  /** fetch data from the table: "template_questions" using primary key columns */
  template_questions_by_pk?: Maybe<Template_Questions>;
  /** An array relationship */
  time_slots: Array<Time_Slots>;
  /** An aggregate relationship */
  time_slots_aggregate: Time_Slots_Aggregate;
  /** fetch data from the table: "time_slots" using primary key columns */
  time_slots_by_pk?: Maybe<Time_Slots>;
  /** An array relationship */
  translations: Array<Translations>;
  /** An aggregate relationship */
  translations_aggregate: Translations_Aggregate;
  /** fetch data from the table: "translations" using primary key columns */
  translations_by_pk?: Maybe<Translations>;
  /** An array relationship */
  user_attachment_test_responses: Array<User_Attachment_Test_Responses>;
  /** An aggregate relationship */
  user_attachment_test_responses_aggregate: User_Attachment_Test_Responses_Aggregate;
  /** fetch data from the table: "user_attachment_test_responses" using primary key columns */
  user_attachment_test_responses_by_pk?: Maybe<User_Attachment_Test_Responses>;
  /** An array relationship */
  user_avatar_test_responses: Array<User_Avatar_Test_Responses>;
  /** An aggregate relationship */
  user_avatar_test_responses_aggregate: User_Avatar_Test_Responses_Aggregate;
  /** fetch data from the table: "user_avatar_test_responses" using primary key columns */
  user_avatar_test_responses_by_pk?: Maybe<User_Avatar_Test_Responses>;
  /** An array relationship */
  user_matric_question_responses: Array<User_Matric_Question_Responses>;
  /** An aggregate relationship */
  user_matric_question_responses_aggregate: User_Matric_Question_Responses_Aggregate;
  /** fetch data from the table: "user_matric_question_responses" using primary key columns */
  user_matric_question_responses_by_pk?: Maybe<User_Matric_Question_Responses>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "vouchers" */
  vouchers: Array<Vouchers>;
  /** fetch aggregated fields from the table: "vouchers" */
  vouchers_aggregate: Vouchers_Aggregate;
  /** fetch data from the table: "vouchers" using primary key columns */
  vouchers_by_pk?: Maybe<Vouchers>;
};


export type Query_RootAttachment_Test_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Attachment_Test_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attachment_Test_Categories_Order_By>>;
  where?: InputMaybe<Attachment_Test_Categories_Bool_Exp>;
};


export type Query_RootAttachment_Test_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attachment_Test_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attachment_Test_Categories_Order_By>>;
  where?: InputMaybe<Attachment_Test_Categories_Bool_Exp>;
};


export type Query_RootAttachment_Test_Categories_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootAttachment_Test_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Attachment_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attachment_Test_Responses_Order_By>>;
  where?: InputMaybe<Attachment_Test_Responses_Bool_Exp>;
};


export type Query_RootAttachment_Test_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attachment_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attachment_Test_Responses_Order_By>>;
  where?: InputMaybe<Attachment_Test_Responses_Bool_Exp>;
};


export type Query_RootAttachment_Test_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootAttachment_TestsArgs = {
  distinct_on?: InputMaybe<Array<Attachment_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attachment_Tests_Order_By>>;
  where?: InputMaybe<Attachment_Tests_Bool_Exp>;
};


export type Query_RootAttachment_Tests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attachment_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attachment_Tests_Order_By>>;
  where?: InputMaybe<Attachment_Tests_Bool_Exp>;
};


export type Query_RootAttachment_Tests_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootAvailability_Time_SlotsArgs = {
  distinct_on?: InputMaybe<Array<Availability_Time_Slots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Availability_Time_Slots_Order_By>>;
  where?: InputMaybe<Availability_Time_Slots_Bool_Exp>;
};


export type Query_RootAvailability_Time_Slots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Availability_Time_Slots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Availability_Time_Slots_Order_By>>;
  where?: InputMaybe<Availability_Time_Slots_Bool_Exp>;
};


export type Query_RootAvailability_Time_Slots_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootAvatar_ResultsArgs = {
  distinct_on?: InputMaybe<Array<Avatar_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Avatar_Results_Order_By>>;
  where?: InputMaybe<Avatar_Results_Bool_Exp>;
};


export type Query_RootAvatar_Results_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Avatar_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Avatar_Results_Order_By>>;
  where?: InputMaybe<Avatar_Results_Bool_Exp>;
};


export type Query_RootAvatar_Results_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootAvatar_Test_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Avatar_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Avatar_Test_Responses_Order_By>>;
  where?: InputMaybe<Avatar_Test_Responses_Bool_Exp>;
};


export type Query_RootAvatar_Test_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Avatar_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Avatar_Test_Responses_Order_By>>;
  where?: InputMaybe<Avatar_Test_Responses_Bool_Exp>;
};


export type Query_RootAvatar_Test_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootAvatar_TestsArgs = {
  distinct_on?: InputMaybe<Array<Avatar_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Avatar_Tests_Order_By>>;
  where?: InputMaybe<Avatar_Tests_Bool_Exp>;
};


export type Query_RootAvatar_Tests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Avatar_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Avatar_Tests_Order_By>>;
  where?: InputMaybe<Avatar_Tests_Bool_Exp>;
};


export type Query_RootAvatar_Tests_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootChannel_UsersArgs = {
  distinct_on?: InputMaybe<Array<Channel_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channel_Users_Order_By>>;
  where?: InputMaybe<Channel_Users_Bool_Exp>;
};


export type Query_RootChannel_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channel_Users_Order_By>>;
  where?: InputMaybe<Channel_Users_Bool_Exp>;
};


export type Query_RootChannel_Users_By_PkArgs = {
  channel_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};


export type Query_RootChannelsArgs = {
  distinct_on?: InputMaybe<Array<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Query_RootChannels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Query_RootChannels_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootCitiesArgs = {
  distinct_on?: InputMaybe<Array<Cities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Cities_Order_By>>;
  where?: InputMaybe<Cities_Bool_Exp>;
};


export type Query_RootCities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Cities_Order_By>>;
  where?: InputMaybe<Cities_Bool_Exp>;
};


export type Query_RootCities_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootContact_FormsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Forms_Order_By>>;
  where?: InputMaybe<Contact_Forms_Bool_Exp>;
};


export type Query_RootContact_Forms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Forms_Order_By>>;
  where?: InputMaybe<Contact_Forms_Bool_Exp>;
};


export type Query_RootContact_Forms_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootContentsArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Query_RootContents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Query_RootContents_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootDevice_TokensArgs = {
  distinct_on?: InputMaybe<Array<Device_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Device_Tokens_Order_By>>;
  where?: InputMaybe<Device_Tokens_Bool_Exp>;
};


export type Query_RootDevice_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Device_Tokens_Order_By>>;
  where?: InputMaybe<Device_Tokens_Bool_Exp>;
};


export type Query_RootDevice_Tokens_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootFaqsArgs = {
  distinct_on?: InputMaybe<Array<Faqs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Faqs_Order_By>>;
  where?: InputMaybe<Faqs_Bool_Exp>;
};


export type Query_RootFaqs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Faqs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Faqs_Order_By>>;
  where?: InputMaybe<Faqs_Bool_Exp>;
};


export type Query_RootFaqs_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootFriend_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Friend_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Friend_Requests_Order_By>>;
  where?: InputMaybe<Friend_Requests_Bool_Exp>;
};


export type Query_RootFriend_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Friend_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Friend_Requests_Order_By>>;
  where?: InputMaybe<Friend_Requests_Bool_Exp>;
};


export type Query_RootFriend_Requests_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootFriendsArgs = {
  distinct_on?: InputMaybe<Array<Friends_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Friends_Order_By>>;
  where?: InputMaybe<Friends_Bool_Exp>;
};


export type Query_RootFriends_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Friends_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Friends_Order_By>>;
  where?: InputMaybe<Friends_Bool_Exp>;
};


export type Query_RootFriends_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootGendersArgs = {
  distinct_on?: InputMaybe<Array<Genders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Genders_Order_By>>;
  where?: InputMaybe<Genders_Bool_Exp>;
};


export type Query_RootGenders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Genders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Genders_Order_By>>;
  where?: InputMaybe<Genders_Bool_Exp>;
};


export type Query_RootGenders_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootGetStripeAccountLinkArgs = {
  input: GetStripeAccountLinkInput;
};


export type Query_RootGetUploadPreSignedUrlArgs = {
  input: GetUploadPreSignedUrlInput;
};


export type Query_RootGetUserTemplateQuestionResponsesArgs = {
  codes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Query_RootLanguagesArgs = {
  distinct_on?: InputMaybe<Array<Languages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Languages_Order_By>>;
  where?: InputMaybe<Languages_Bool_Exp>;
};


export type Query_RootLanguages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Languages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Languages_Order_By>>;
  where?: InputMaybe<Languages_Bool_Exp>;
};


export type Query_RootLanguages_By_PkArgs = {
  code: Scalars['String']['input'];
};


export type Query_RootMatric_Question_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Matric_Question_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Matric_Question_Categories_Order_By>>;
  where?: InputMaybe<Matric_Question_Categories_Bool_Exp>;
};


export type Query_RootMatric_Question_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matric_Question_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Matric_Question_Categories_Order_By>>;
  where?: InputMaybe<Matric_Question_Categories_Bool_Exp>;
};


export type Query_RootMatric_Question_Categories_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootMatric_Question_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Matric_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Matric_Question_Responses_Order_By>>;
  where?: InputMaybe<Matric_Question_Responses_Bool_Exp>;
};


export type Query_RootMatric_Question_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matric_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Matric_Question_Responses_Order_By>>;
  where?: InputMaybe<Matric_Question_Responses_Bool_Exp>;
};


export type Query_RootMatric_Question_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootMatric_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Matric_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Matric_Questions_Order_By>>;
  where?: InputMaybe<Matric_Questions_Bool_Exp>;
};


export type Query_RootMatric_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matric_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Matric_Questions_Order_By>>;
  where?: InputMaybe<Matric_Questions_Bool_Exp>;
};


export type Query_RootMatric_Questions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootMessagesArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


export type Query_RootMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


export type Query_RootMessages_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootOtpsArgs = {
  distinct_on?: InputMaybe<Array<Otps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Otps_Order_By>>;
  where?: InputMaybe<Otps_Bool_Exp>;
};


export type Query_RootOtps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Otps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Otps_Order_By>>;
  where?: InputMaybe<Otps_Bool_Exp>;
};


export type Query_RootOtps_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootPassword_Reset_TokensArgs = {
  distinct_on?: InputMaybe<Array<Password_Reset_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Password_Reset_Tokens_Order_By>>;
  where?: InputMaybe<Password_Reset_Tokens_Bool_Exp>;
};


export type Query_RootPassword_Reset_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Password_Reset_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Password_Reset_Tokens_Order_By>>;
  where?: InputMaybe<Password_Reset_Tokens_Bool_Exp>;
};


export type Query_RootPassword_Reset_Tokens_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootPersonal_Sun_TypesArgs = {
  distinct_on?: InputMaybe<Array<Personal_Sun_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Personal_Sun_Types_Order_By>>;
  where?: InputMaybe<Personal_Sun_Types_Bool_Exp>;
};


export type Query_RootPersonal_Sun_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Personal_Sun_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Personal_Sun_Types_Order_By>>;
  where?: InputMaybe<Personal_Sun_Types_Bool_Exp>;
};


export type Query_RootPersonal_Sun_Types_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootPersonal_SunsArgs = {
  distinct_on?: InputMaybe<Array<Personal_Suns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Personal_Suns_Order_By>>;
  where?: InputMaybe<Personal_Suns_Bool_Exp>;
};


export type Query_RootPersonal_Suns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Personal_Suns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Personal_Suns_Order_By>>;
  where?: InputMaybe<Personal_Suns_Bool_Exp>;
};


export type Query_RootPersonal_Suns_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootProfilesArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


export type Query_RootProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


export type Query_RootProfiles_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootReservation_PaymentsArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reservation_Payments_Order_By>>;
  where?: InputMaybe<Reservation_Payments_Bool_Exp>;
};


export type Query_RootReservation_Payments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reservation_Payments_Order_By>>;
  where?: InputMaybe<Reservation_Payments_Bool_Exp>;
};


export type Query_RootReservation_Payments_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootReservationsArgs = {
  distinct_on?: InputMaybe<Array<Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reservations_Order_By>>;
  where?: InputMaybe<Reservations_Bool_Exp>;
};


export type Query_RootReservations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reservations_Order_By>>;
  where?: InputMaybe<Reservations_Bool_Exp>;
};


export type Query_RootReservations_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootReviewsArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};


export type Query_RootReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};


export type Query_RootReviews_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootSettingsArgs = {
  distinct_on?: InputMaybe<Array<Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Settings_Order_By>>;
  where?: InputMaybe<Settings_Bool_Exp>;
};


export type Query_RootSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Settings_Order_By>>;
  where?: InputMaybe<Settings_Bool_Exp>;
};


export type Query_RootSettings_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Query_RootSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Query_RootSubscriptions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootTemplate_Question_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Template_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Question_Responses_Order_By>>;
  where?: InputMaybe<Template_Question_Responses_Bool_Exp>;
};


export type Query_RootTemplate_Question_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Question_Responses_Order_By>>;
  where?: InputMaybe<Template_Question_Responses_Bool_Exp>;
};


export type Query_RootTemplate_Question_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootTemplate_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Template_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Questions_Order_By>>;
  where?: InputMaybe<Template_Questions_Bool_Exp>;
};


export type Query_RootTemplate_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Questions_Order_By>>;
  where?: InputMaybe<Template_Questions_Bool_Exp>;
};


export type Query_RootTemplate_Questions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootTime_SlotsArgs = {
  distinct_on?: InputMaybe<Array<Time_Slots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Time_Slots_Order_By>>;
  where?: InputMaybe<Time_Slots_Bool_Exp>;
};


export type Query_RootTime_Slots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Time_Slots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Time_Slots_Order_By>>;
  where?: InputMaybe<Time_Slots_Bool_Exp>;
};


export type Query_RootTime_Slots_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootTranslationsArgs = {
  distinct_on?: InputMaybe<Array<Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Translations_Order_By>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};


export type Query_RootTranslations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Translations_Order_By>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};


export type Query_RootTranslations_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootUser_Attachment_Test_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<User_Attachment_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Attachment_Test_Responses_Order_By>>;
  where?: InputMaybe<User_Attachment_Test_Responses_Bool_Exp>;
};


export type Query_RootUser_Attachment_Test_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Attachment_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Attachment_Test_Responses_Order_By>>;
  where?: InputMaybe<User_Attachment_Test_Responses_Bool_Exp>;
};


export type Query_RootUser_Attachment_Test_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootUser_Avatar_Test_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Avatar_Test_Responses_Order_By>>;
  where?: InputMaybe<User_Avatar_Test_Responses_Bool_Exp>;
};


export type Query_RootUser_Avatar_Test_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Avatar_Test_Responses_Order_By>>;
  where?: InputMaybe<User_Avatar_Test_Responses_Bool_Exp>;
};


export type Query_RootUser_Avatar_Test_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootUser_Matric_Question_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<User_Matric_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Matric_Question_Responses_Order_By>>;
  where?: InputMaybe<User_Matric_Question_Responses_Bool_Exp>;
};


export type Query_RootUser_Matric_Question_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Matric_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Matric_Question_Responses_Order_By>>;
  where?: InputMaybe<User_Matric_Question_Responses_Bool_Exp>;
};


export type Query_RootUser_Matric_Question_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootVouchersArgs = {
  distinct_on?: InputMaybe<Array<Vouchers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vouchers_Order_By>>;
  where?: InputMaybe<Vouchers_Bool_Exp>;
};


export type Query_RootVouchers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vouchers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vouchers_Order_By>>;
  where?: InputMaybe<Vouchers_Bool_Exp>;
};


export type Query_RootVouchers_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** Boolean expression to compare columns of type "repeat_interval_enum". All fields are combined with logical 'AND'. */
export type Repeat_Interval_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['repeat_interval_enum']['input']>;
  _gt?: InputMaybe<Scalars['repeat_interval_enum']['input']>;
  _gte?: InputMaybe<Scalars['repeat_interval_enum']['input']>;
  _in?: InputMaybe<Array<Scalars['repeat_interval_enum']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['repeat_interval_enum']['input']>;
  _lte?: InputMaybe<Scalars['repeat_interval_enum']['input']>;
  _neq?: InputMaybe<Scalars['repeat_interval_enum']['input']>;
  _nin?: InputMaybe<Array<Scalars['repeat_interval_enum']['input']>>;
};

/** Boolean expression to compare columns of type "reservation_payment_status_enum". All fields are combined with logical 'AND'. */
export type Reservation_Payment_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['reservation_payment_status_enum']['input']>;
  _gt?: InputMaybe<Scalars['reservation_payment_status_enum']['input']>;
  _gte?: InputMaybe<Scalars['reservation_payment_status_enum']['input']>;
  _in?: InputMaybe<Array<Scalars['reservation_payment_status_enum']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['reservation_payment_status_enum']['input']>;
  _lte?: InputMaybe<Scalars['reservation_payment_status_enum']['input']>;
  _neq?: InputMaybe<Scalars['reservation_payment_status_enum']['input']>;
  _nin?: InputMaybe<Array<Scalars['reservation_payment_status_enum']['input']>>;
};

/** columns and relationships of "reservation_payments" */
export type Reservation_Payments = {
  __typename?: 'reservation_payments';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  payer: Users;
  payer_id: Scalars['Int']['output'];
  payment_id: Scalars['String']['output'];
  payment_status: Scalars['reservation_payment_status_enum']['output'];
  /** An object relationship */
  reservation: Reservations;
  reservation_id: Scalars['Int']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "reservation_payments" */
export type Reservation_Payments_Aggregate = {
  __typename?: 'reservation_payments_aggregate';
  aggregate?: Maybe<Reservation_Payments_Aggregate_Fields>;
  nodes: Array<Reservation_Payments>;
};

export type Reservation_Payments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Reservation_Payments_Aggregate_Bool_Exp_Count>;
};

export type Reservation_Payments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reservation_Payments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reservation_Payments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reservation_payments" */
export type Reservation_Payments_Aggregate_Fields = {
  __typename?: 'reservation_payments_aggregate_fields';
  avg?: Maybe<Reservation_Payments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Reservation_Payments_Max_Fields>;
  min?: Maybe<Reservation_Payments_Min_Fields>;
  stddev?: Maybe<Reservation_Payments_Stddev_Fields>;
  stddev_pop?: Maybe<Reservation_Payments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reservation_Payments_Stddev_Samp_Fields>;
  sum?: Maybe<Reservation_Payments_Sum_Fields>;
  var_pop?: Maybe<Reservation_Payments_Var_Pop_Fields>;
  var_samp?: Maybe<Reservation_Payments_Var_Samp_Fields>;
  variance?: Maybe<Reservation_Payments_Variance_Fields>;
};


/** aggregate fields of "reservation_payments" */
export type Reservation_Payments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reservation_Payments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "reservation_payments" */
export type Reservation_Payments_Aggregate_Order_By = {
  avg?: InputMaybe<Reservation_Payments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reservation_Payments_Max_Order_By>;
  min?: InputMaybe<Reservation_Payments_Min_Order_By>;
  stddev?: InputMaybe<Reservation_Payments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Reservation_Payments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Reservation_Payments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Reservation_Payments_Sum_Order_By>;
  var_pop?: InputMaybe<Reservation_Payments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Reservation_Payments_Var_Samp_Order_By>;
  variance?: InputMaybe<Reservation_Payments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "reservation_payments" */
export type Reservation_Payments_Arr_Rel_Insert_Input = {
  data: Array<Reservation_Payments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reservation_Payments_On_Conflict>;
};

/** aggregate avg on columns */
export type Reservation_Payments_Avg_Fields = {
  __typename?: 'reservation_payments_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  payer_id?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "reservation_payments" */
export type Reservation_Payments_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  payer_id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "reservation_payments". All fields are combined with a logical 'AND'. */
export type Reservation_Payments_Bool_Exp = {
  _and?: InputMaybe<Array<Reservation_Payments_Bool_Exp>>;
  _not?: InputMaybe<Reservation_Payments_Bool_Exp>;
  _or?: InputMaybe<Array<Reservation_Payments_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  payer?: InputMaybe<Users_Bool_Exp>;
  payer_id?: InputMaybe<Int_Comparison_Exp>;
  payment_id?: InputMaybe<String_Comparison_Exp>;
  payment_status?: InputMaybe<Reservation_Payment_Status_Enum_Comparison_Exp>;
  reservation?: InputMaybe<Reservations_Bool_Exp>;
  reservation_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "reservation_payments" */
export type Reservation_Payments_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'reservation_payments_pkey'
  /** unique or primary key constraint on columns "reservation_id", "payer_id" */
  | 'unique_reservation_payment';

/** input type for incrementing numeric columns in table "reservation_payments" */
export type Reservation_Payments_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  payer_id?: InputMaybe<Scalars['Int']['input']>;
  reservation_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "reservation_payments" */
export type Reservation_Payments_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  payer?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  payer_id?: InputMaybe<Scalars['Int']['input']>;
  payment_id?: InputMaybe<Scalars['String']['input']>;
  payment_status?: InputMaybe<Scalars['reservation_payment_status_enum']['input']>;
  reservation?: InputMaybe<Reservations_Obj_Rel_Insert_Input>;
  reservation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Reservation_Payments_Max_Fields = {
  __typename?: 'reservation_payments_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  payer_id?: Maybe<Scalars['Int']['output']>;
  payment_id?: Maybe<Scalars['String']['output']>;
  payment_status?: Maybe<Scalars['reservation_payment_status_enum']['output']>;
  reservation_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "reservation_payments" */
export type Reservation_Payments_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payer_id?: InputMaybe<Order_By>;
  payment_id?: InputMaybe<Order_By>;
  payment_status?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reservation_Payments_Min_Fields = {
  __typename?: 'reservation_payments_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  payer_id?: Maybe<Scalars['Int']['output']>;
  payment_id?: Maybe<Scalars['String']['output']>;
  payment_status?: Maybe<Scalars['reservation_payment_status_enum']['output']>;
  reservation_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "reservation_payments" */
export type Reservation_Payments_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payer_id?: InputMaybe<Order_By>;
  payment_id?: InputMaybe<Order_By>;
  payment_status?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reservation_payments" */
export type Reservation_Payments_Mutation_Response = {
  __typename?: 'reservation_payments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Reservation_Payments>;
};

/** on_conflict condition type for table "reservation_payments" */
export type Reservation_Payments_On_Conflict = {
  constraint: Reservation_Payments_Constraint;
  update_columns?: Array<Reservation_Payments_Update_Column>;
  where?: InputMaybe<Reservation_Payments_Bool_Exp>;
};

/** Ordering options when selecting data from "reservation_payments". */
export type Reservation_Payments_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payer?: InputMaybe<Users_Order_By>;
  payer_id?: InputMaybe<Order_By>;
  payment_id?: InputMaybe<Order_By>;
  payment_status?: InputMaybe<Order_By>;
  reservation?: InputMaybe<Reservations_Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reservation_payments */
export type Reservation_Payments_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "reservation_payments" */
export type Reservation_Payments_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'payer_id'
  /** column name */
  | 'payment_id'
  /** column name */
  | 'payment_status'
  /** column name */
  | 'reservation_id'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "reservation_payments" */
export type Reservation_Payments_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  payer_id?: InputMaybe<Scalars['Int']['input']>;
  payment_id?: InputMaybe<Scalars['String']['input']>;
  payment_status?: InputMaybe<Scalars['reservation_payment_status_enum']['input']>;
  reservation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Reservation_Payments_Stddev_Fields = {
  __typename?: 'reservation_payments_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  payer_id?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "reservation_payments" */
export type Reservation_Payments_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  payer_id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Reservation_Payments_Stddev_Pop_Fields = {
  __typename?: 'reservation_payments_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  payer_id?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "reservation_payments" */
export type Reservation_Payments_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  payer_id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Reservation_Payments_Stddev_Samp_Fields = {
  __typename?: 'reservation_payments_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  payer_id?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "reservation_payments" */
export type Reservation_Payments_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  payer_id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "reservation_payments" */
export type Reservation_Payments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reservation_Payments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reservation_Payments_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  payer_id?: InputMaybe<Scalars['Int']['input']>;
  payment_id?: InputMaybe<Scalars['String']['input']>;
  payment_status?: InputMaybe<Scalars['reservation_payment_status_enum']['input']>;
  reservation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Reservation_Payments_Sum_Fields = {
  __typename?: 'reservation_payments_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  payer_id?: Maybe<Scalars['Int']['output']>;
  reservation_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "reservation_payments" */
export type Reservation_Payments_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  payer_id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
};

/** update columns of table "reservation_payments" */
export type Reservation_Payments_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'payer_id'
  /** column name */
  | 'payment_id'
  /** column name */
  | 'payment_status'
  /** column name */
  | 'reservation_id'
  /** column name */
  | 'updated_at';

export type Reservation_Payments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reservation_Payments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reservation_Payments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reservation_Payments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Reservation_Payments_Var_Pop_Fields = {
  __typename?: 'reservation_payments_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  payer_id?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "reservation_payments" */
export type Reservation_Payments_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  payer_id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Reservation_Payments_Var_Samp_Fields = {
  __typename?: 'reservation_payments_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  payer_id?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "reservation_payments" */
export type Reservation_Payments_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  payer_id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Reservation_Payments_Variance_Fields = {
  __typename?: 'reservation_payments_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  payer_id?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "reservation_payments" */
export type Reservation_Payments_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  payer_id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "reservation_status_enum". All fields are combined with logical 'AND'. */
export type Reservation_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['reservation_status_enum']['input']>;
  _gt?: InputMaybe<Scalars['reservation_status_enum']['input']>;
  _gte?: InputMaybe<Scalars['reservation_status_enum']['input']>;
  _in?: InputMaybe<Array<Scalars['reservation_status_enum']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['reservation_status_enum']['input']>;
  _lte?: InputMaybe<Scalars['reservation_status_enum']['input']>;
  _neq?: InputMaybe<Scalars['reservation_status_enum']['input']>;
  _nin?: InputMaybe<Array<Scalars['reservation_status_enum']['input']>>;
};

/** columns and relationships of "reservations" */
export type Reservations = {
  __typename?: 'reservations';
  amount: Scalars['numeric']['output'];
  /** An object relationship */
  coach: Users;
  coach_id: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  note?: Maybe<Scalars['String']['output']>;
  releaser?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  reservation_payments: Array<Reservation_Payments>;
  /** An aggregate relationship */
  reservation_payments_aggregate: Reservation_Payments_Aggregate;
  reservation_status?: Maybe<Scalars['reservation_status_enum']['output']>;
  /** An array relationship */
  reviews: Array<Reviews>;
  /** An aggregate relationship */
  reviews_aggregate: Reviews_Aggregate;
  /** An array relationship */
  time_slots: Array<Time_Slots>;
  /** An aggregate relationship */
  time_slots_aggregate: Time_Slots_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  /** An object relationship */
  userByReleaser?: Maybe<Users>;
  user_id: Scalars['Int']['output'];
};


/** columns and relationships of "reservations" */
export type ReservationsReservation_PaymentsArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reservation_Payments_Order_By>>;
  where?: InputMaybe<Reservation_Payments_Bool_Exp>;
};


/** columns and relationships of "reservations" */
export type ReservationsReservation_Payments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reservation_Payments_Order_By>>;
  where?: InputMaybe<Reservation_Payments_Bool_Exp>;
};


/** columns and relationships of "reservations" */
export type ReservationsReviewsArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};


/** columns and relationships of "reservations" */
export type ReservationsReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};


/** columns and relationships of "reservations" */
export type ReservationsTime_SlotsArgs = {
  distinct_on?: InputMaybe<Array<Time_Slots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Time_Slots_Order_By>>;
  where?: InputMaybe<Time_Slots_Bool_Exp>;
};


/** columns and relationships of "reservations" */
export type ReservationsTime_Slots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Time_Slots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Time_Slots_Order_By>>;
  where?: InputMaybe<Time_Slots_Bool_Exp>;
};

/** aggregated selection of "reservations" */
export type Reservations_Aggregate = {
  __typename?: 'reservations_aggregate';
  aggregate?: Maybe<Reservations_Aggregate_Fields>;
  nodes: Array<Reservations>;
};

export type Reservations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Reservations_Aggregate_Bool_Exp_Count>;
};

export type Reservations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reservations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reservations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reservations" */
export type Reservations_Aggregate_Fields = {
  __typename?: 'reservations_aggregate_fields';
  avg?: Maybe<Reservations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Reservations_Max_Fields>;
  min?: Maybe<Reservations_Min_Fields>;
  stddev?: Maybe<Reservations_Stddev_Fields>;
  stddev_pop?: Maybe<Reservations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reservations_Stddev_Samp_Fields>;
  sum?: Maybe<Reservations_Sum_Fields>;
  var_pop?: Maybe<Reservations_Var_Pop_Fields>;
  var_samp?: Maybe<Reservations_Var_Samp_Fields>;
  variance?: Maybe<Reservations_Variance_Fields>;
};


/** aggregate fields of "reservations" */
export type Reservations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reservations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "reservations" */
export type Reservations_Aggregate_Order_By = {
  avg?: InputMaybe<Reservations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reservations_Max_Order_By>;
  min?: InputMaybe<Reservations_Min_Order_By>;
  stddev?: InputMaybe<Reservations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Reservations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Reservations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Reservations_Sum_Order_By>;
  var_pop?: InputMaybe<Reservations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Reservations_Var_Samp_Order_By>;
  variance?: InputMaybe<Reservations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "reservations" */
export type Reservations_Arr_Rel_Insert_Input = {
  data: Array<Reservations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reservations_On_Conflict>;
};

/** aggregate avg on columns */
export type Reservations_Avg_Fields = {
  __typename?: 'reservations_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  coach_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  releaser?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "reservations" */
export type Reservations_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  coach_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  releaser?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "reservations". All fields are combined with a logical 'AND'. */
export type Reservations_Bool_Exp = {
  _and?: InputMaybe<Array<Reservations_Bool_Exp>>;
  _not?: InputMaybe<Reservations_Bool_Exp>;
  _or?: InputMaybe<Array<Reservations_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  coach?: InputMaybe<Users_Bool_Exp>;
  coach_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  releaser?: InputMaybe<Int_Comparison_Exp>;
  reservation_payments?: InputMaybe<Reservation_Payments_Bool_Exp>;
  reservation_payments_aggregate?: InputMaybe<Reservation_Payments_Aggregate_Bool_Exp>;
  reservation_status?: InputMaybe<Reservation_Status_Enum_Comparison_Exp>;
  reviews?: InputMaybe<Reviews_Bool_Exp>;
  reviews_aggregate?: InputMaybe<Reviews_Aggregate_Bool_Exp>;
  time_slots?: InputMaybe<Time_Slots_Bool_Exp>;
  time_slots_aggregate?: InputMaybe<Time_Slots_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByReleaser?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "reservations" */
export type Reservations_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'reservations_pkey';

/** input type for incrementing numeric columns in table "reservations" */
export type Reservations_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  coach_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  releaser?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "reservations" */
export type Reservations_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  coach?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  coach_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  releaser?: InputMaybe<Scalars['Int']['input']>;
  reservation_payments?: InputMaybe<Reservation_Payments_Arr_Rel_Insert_Input>;
  reservation_status?: InputMaybe<Scalars['reservation_status_enum']['input']>;
  reviews?: InputMaybe<Reviews_Arr_Rel_Insert_Input>;
  time_slots?: InputMaybe<Time_Slots_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByReleaser?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Reservations_Max_Fields = {
  __typename?: 'reservations_max_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  coach_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  releaser?: Maybe<Scalars['Int']['output']>;
  reservation_status?: Maybe<Scalars['reservation_status_enum']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "reservations" */
export type Reservations_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  coach_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  releaser?: InputMaybe<Order_By>;
  reservation_status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reservations_Min_Fields = {
  __typename?: 'reservations_min_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  coach_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  releaser?: Maybe<Scalars['Int']['output']>;
  reservation_status?: Maybe<Scalars['reservation_status_enum']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "reservations" */
export type Reservations_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  coach_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  releaser?: InputMaybe<Order_By>;
  reservation_status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reservations" */
export type Reservations_Mutation_Response = {
  __typename?: 'reservations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Reservations>;
};

/** input type for inserting object relation for remote table "reservations" */
export type Reservations_Obj_Rel_Insert_Input = {
  data: Reservations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Reservations_On_Conflict>;
};

/** on_conflict condition type for table "reservations" */
export type Reservations_On_Conflict = {
  constraint: Reservations_Constraint;
  update_columns?: Array<Reservations_Update_Column>;
  where?: InputMaybe<Reservations_Bool_Exp>;
};

/** Ordering options when selecting data from "reservations". */
export type Reservations_Order_By = {
  amount?: InputMaybe<Order_By>;
  coach?: InputMaybe<Users_Order_By>;
  coach_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  releaser?: InputMaybe<Order_By>;
  reservation_payments_aggregate?: InputMaybe<Reservation_Payments_Aggregate_Order_By>;
  reservation_status?: InputMaybe<Order_By>;
  reviews_aggregate?: InputMaybe<Reviews_Aggregate_Order_By>;
  time_slots_aggregate?: InputMaybe<Time_Slots_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByReleaser?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reservations */
export type Reservations_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "reservations" */
export type Reservations_Select_Column =
  /** column name */
  | 'amount'
  /** column name */
  | 'coach_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'note'
  /** column name */
  | 'releaser'
  /** column name */
  | 'reservation_status'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

/** input type for updating data in table "reservations" */
export type Reservations_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  coach_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  releaser?: InputMaybe<Scalars['Int']['input']>;
  reservation_status?: InputMaybe<Scalars['reservation_status_enum']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Reservations_Stddev_Fields = {
  __typename?: 'reservations_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  coach_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  releaser?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "reservations" */
export type Reservations_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  coach_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  releaser?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Reservations_Stddev_Pop_Fields = {
  __typename?: 'reservations_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  coach_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  releaser?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "reservations" */
export type Reservations_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  coach_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  releaser?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Reservations_Stddev_Samp_Fields = {
  __typename?: 'reservations_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  coach_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  releaser?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "reservations" */
export type Reservations_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  coach_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  releaser?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "reservations" */
export type Reservations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reservations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reservations_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  coach_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  releaser?: InputMaybe<Scalars['Int']['input']>;
  reservation_status?: InputMaybe<Scalars['reservation_status_enum']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Reservations_Sum_Fields = {
  __typename?: 'reservations_sum_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  coach_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  releaser?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "reservations" */
export type Reservations_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  coach_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  releaser?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "reservations" */
export type Reservations_Update_Column =
  /** column name */
  | 'amount'
  /** column name */
  | 'coach_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'note'
  /** column name */
  | 'releaser'
  /** column name */
  | 'reservation_status'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

export type Reservations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reservations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reservations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reservations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Reservations_Var_Pop_Fields = {
  __typename?: 'reservations_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  coach_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  releaser?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "reservations" */
export type Reservations_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  coach_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  releaser?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Reservations_Var_Samp_Fields = {
  __typename?: 'reservations_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  coach_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  releaser?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "reservations" */
export type Reservations_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  coach_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  releaser?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Reservations_Variance_Fields = {
  __typename?: 'reservations_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  coach_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  releaser?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "reservations" */
export type Reservations_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  coach_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  releaser?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "reviews" */
export type Reviews = {
  __typename?: 'reviews';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  rating: Scalars['Int']['output'];
  /** An object relationship */
  reservation?: Maybe<Reservations>;
  reservation_id?: Maybe<Scalars['Int']['output']>;
  reviewer_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "reviews" */
export type Reviews_Aggregate = {
  __typename?: 'reviews_aggregate';
  aggregate?: Maybe<Reviews_Aggregate_Fields>;
  nodes: Array<Reviews>;
};

export type Reviews_Aggregate_Bool_Exp = {
  count?: InputMaybe<Reviews_Aggregate_Bool_Exp_Count>;
};

export type Reviews_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reviews_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reviews_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reviews" */
export type Reviews_Aggregate_Fields = {
  __typename?: 'reviews_aggregate_fields';
  avg?: Maybe<Reviews_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Reviews_Max_Fields>;
  min?: Maybe<Reviews_Min_Fields>;
  stddev?: Maybe<Reviews_Stddev_Fields>;
  stddev_pop?: Maybe<Reviews_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reviews_Stddev_Samp_Fields>;
  sum?: Maybe<Reviews_Sum_Fields>;
  var_pop?: Maybe<Reviews_Var_Pop_Fields>;
  var_samp?: Maybe<Reviews_Var_Samp_Fields>;
  variance?: Maybe<Reviews_Variance_Fields>;
};


/** aggregate fields of "reviews" */
export type Reviews_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reviews_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "reviews" */
export type Reviews_Aggregate_Order_By = {
  avg?: InputMaybe<Reviews_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reviews_Max_Order_By>;
  min?: InputMaybe<Reviews_Min_Order_By>;
  stddev?: InputMaybe<Reviews_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Reviews_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Reviews_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Reviews_Sum_Order_By>;
  var_pop?: InputMaybe<Reviews_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Reviews_Var_Samp_Order_By>;
  variance?: InputMaybe<Reviews_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "reviews" */
export type Reviews_Arr_Rel_Insert_Input = {
  data: Array<Reviews_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reviews_On_Conflict>;
};

/** aggregate avg on columns */
export type Reviews_Avg_Fields = {
  __typename?: 'reviews_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
  reviewer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "reviews" */
export type Reviews_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "reviews". All fields are combined with a logical 'AND'. */
export type Reviews_Bool_Exp = {
  _and?: InputMaybe<Array<Reviews_Bool_Exp>>;
  _not?: InputMaybe<Reviews_Bool_Exp>;
  _or?: InputMaybe<Array<Reviews_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  rating?: InputMaybe<Int_Comparison_Exp>;
  reservation?: InputMaybe<Reservations_Bool_Exp>;
  reservation_id?: InputMaybe<Int_Comparison_Exp>;
  reviewer_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "reviews" */
export type Reviews_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'reviews_pkey';

/** input type for incrementing numeric columns in table "reviews" */
export type Reviews_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  reservation_id?: InputMaybe<Scalars['Int']['input']>;
  reviewer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "reviews" */
export type Reviews_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  reservation?: InputMaybe<Reservations_Obj_Rel_Insert_Input>;
  reservation_id?: InputMaybe<Scalars['Int']['input']>;
  reviewer_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Reviews_Max_Fields = {
  __typename?: 'reviews_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
  reservation_id?: Maybe<Scalars['Int']['output']>;
  reviewer_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "reviews" */
export type Reviews_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reviews_Min_Fields = {
  __typename?: 'reviews_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
  reservation_id?: Maybe<Scalars['Int']['output']>;
  reviewer_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "reviews" */
export type Reviews_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reviews" */
export type Reviews_Mutation_Response = {
  __typename?: 'reviews_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Reviews>;
};

/** on_conflict condition type for table "reviews" */
export type Reviews_On_Conflict = {
  constraint: Reviews_Constraint;
  update_columns?: Array<Reviews_Update_Column>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

/** Ordering options when selecting data from "reviews". */
export type Reviews_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  reservation?: InputMaybe<Reservations_Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reviews */
export type Reviews_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "reviews" */
export type Reviews_Select_Column =
  /** column name */
  | 'comment'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'rating'
  /** column name */
  | 'reservation_id'
  /** column name */
  | 'reviewer_id'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "reviews" */
export type Reviews_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  reservation_id?: InputMaybe<Scalars['Int']['input']>;
  reviewer_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Reviews_Stddev_Fields = {
  __typename?: 'reviews_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
  reviewer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "reviews" */
export type Reviews_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Reviews_Stddev_Pop_Fields = {
  __typename?: 'reviews_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
  reviewer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "reviews" */
export type Reviews_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Reviews_Stddev_Samp_Fields = {
  __typename?: 'reviews_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
  reviewer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "reviews" */
export type Reviews_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "reviews" */
export type Reviews_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reviews_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reviews_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  reservation_id?: InputMaybe<Scalars['Int']['input']>;
  reviewer_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Reviews_Sum_Fields = {
  __typename?: 'reviews_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
  reservation_id?: Maybe<Scalars['Int']['output']>;
  reviewer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "reviews" */
export type Reviews_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
};

/** update columns of table "reviews" */
export type Reviews_Update_Column =
  /** column name */
  | 'comment'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'rating'
  /** column name */
  | 'reservation_id'
  /** column name */
  | 'reviewer_id'
  /** column name */
  | 'updated_at';

export type Reviews_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reviews_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reviews_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reviews_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Reviews_Var_Pop_Fields = {
  __typename?: 'reviews_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
  reviewer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "reviews" */
export type Reviews_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Reviews_Var_Samp_Fields = {
  __typename?: 'reviews_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
  reviewer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "reviews" */
export type Reviews_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Reviews_Variance_Fields = {
  __typename?: 'reviews_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
  reviewer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "reviews" */
export type Reviews_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  reviewer_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "settings" */
export type Settings = {
  __typename?: 'settings';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "settings" */
export type Settings_Aggregate = {
  __typename?: 'settings_aggregate';
  aggregate?: Maybe<Settings_Aggregate_Fields>;
  nodes: Array<Settings>;
};

/** aggregate fields of "settings" */
export type Settings_Aggregate_Fields = {
  __typename?: 'settings_aggregate_fields';
  avg?: Maybe<Settings_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Settings_Max_Fields>;
  min?: Maybe<Settings_Min_Fields>;
  stddev?: Maybe<Settings_Stddev_Fields>;
  stddev_pop?: Maybe<Settings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Settings_Stddev_Samp_Fields>;
  sum?: Maybe<Settings_Sum_Fields>;
  var_pop?: Maybe<Settings_Var_Pop_Fields>;
  var_samp?: Maybe<Settings_Var_Samp_Fields>;
  variance?: Maybe<Settings_Variance_Fields>;
};


/** aggregate fields of "settings" */
export type Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Settings_Avg_Fields = {
  __typename?: 'settings_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "settings". All fields are combined with a logical 'AND'. */
export type Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Settings_Bool_Exp>>;
  _not?: InputMaybe<Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Settings_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "settings" */
export type Settings_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'settings_pkey';

/** input type for incrementing numeric columns in table "settings" */
export type Settings_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "settings" */
export type Settings_Insert_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Settings_Max_Fields = {
  __typename?: 'settings_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Settings_Min_Fields = {
  __typename?: 'settings_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "settings" */
export type Settings_Mutation_Response = {
  __typename?: 'settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Settings>;
};

/** on_conflict condition type for table "settings" */
export type Settings_On_Conflict = {
  constraint: Settings_Constraint;
  update_columns?: Array<Settings_Update_Column>;
  where?: InputMaybe<Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "settings". */
export type Settings_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: settings */
export type Settings_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "settings" */
export type Settings_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'value';

/** input type for updating data in table "settings" */
export type Settings_Set_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Settings_Stddev_Fields = {
  __typename?: 'settings_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Settings_Stddev_Pop_Fields = {
  __typename?: 'settings_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Settings_Stddev_Samp_Fields = {
  __typename?: 'settings_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "settings" */
export type Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Settings_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Settings_Sum_Fields = {
  __typename?: 'settings_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "settings" */
export type Settings_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'value';

export type Settings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Settings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Settings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Settings_Var_Pop_Fields = {
  __typename?: 'settings_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Settings_Var_Samp_Fields = {
  __typename?: 'settings_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Settings_Variance_Fields = {
  __typename?: 'settings_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "slot_status_enum". All fields are combined with logical 'AND'. */
export type Slot_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['slot_status_enum']['input']>;
  _gt?: InputMaybe<Scalars['slot_status_enum']['input']>;
  _gte?: InputMaybe<Scalars['slot_status_enum']['input']>;
  _in?: InputMaybe<Array<Scalars['slot_status_enum']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['slot_status_enum']['input']>;
  _lte?: InputMaybe<Scalars['slot_status_enum']['input']>;
  _neq?: InputMaybe<Scalars['slot_status_enum']['input']>;
  _nin?: InputMaybe<Array<Scalars['slot_status_enum']['input']>>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "attachment_test_categories" */
  attachment_test_categories: Array<Attachment_Test_Categories>;
  /** fetch aggregated fields from the table: "attachment_test_categories" */
  attachment_test_categories_aggregate: Attachment_Test_Categories_Aggregate;
  /** fetch data from the table: "attachment_test_categories" using primary key columns */
  attachment_test_categories_by_pk?: Maybe<Attachment_Test_Categories>;
  /** fetch data from the table in a streaming manner: "attachment_test_categories" */
  attachment_test_categories_stream: Array<Attachment_Test_Categories>;
  /** An array relationship */
  attachment_test_responses: Array<Attachment_Test_Responses>;
  /** An aggregate relationship */
  attachment_test_responses_aggregate: Attachment_Test_Responses_Aggregate;
  /** fetch data from the table: "attachment_test_responses" using primary key columns */
  attachment_test_responses_by_pk?: Maybe<Attachment_Test_Responses>;
  /** fetch data from the table in a streaming manner: "attachment_test_responses" */
  attachment_test_responses_stream: Array<Attachment_Test_Responses>;
  /** fetch data from the table: "attachment_tests" */
  attachment_tests: Array<Attachment_Tests>;
  /** fetch aggregated fields from the table: "attachment_tests" */
  attachment_tests_aggregate: Attachment_Tests_Aggregate;
  /** fetch data from the table: "attachment_tests" using primary key columns */
  attachment_tests_by_pk?: Maybe<Attachment_Tests>;
  /** fetch data from the table in a streaming manner: "attachment_tests" */
  attachment_tests_stream: Array<Attachment_Tests>;
  /** An array relationship */
  availability_time_slots: Array<Availability_Time_Slots>;
  /** An aggregate relationship */
  availability_time_slots_aggregate: Availability_Time_Slots_Aggregate;
  /** fetch data from the table: "availability_time_slots" using primary key columns */
  availability_time_slots_by_pk?: Maybe<Availability_Time_Slots>;
  /** fetch data from the table in a streaming manner: "availability_time_slots" */
  availability_time_slots_stream: Array<Availability_Time_Slots>;
  /** fetch data from the table: "avatar_results" */
  avatar_results: Array<Avatar_Results>;
  /** fetch aggregated fields from the table: "avatar_results" */
  avatar_results_aggregate: Avatar_Results_Aggregate;
  /** fetch data from the table: "avatar_results" using primary key columns */
  avatar_results_by_pk?: Maybe<Avatar_Results>;
  /** fetch data from the table in a streaming manner: "avatar_results" */
  avatar_results_stream: Array<Avatar_Results>;
  /** An array relationship */
  avatar_test_responses: Array<Avatar_Test_Responses>;
  /** An aggregate relationship */
  avatar_test_responses_aggregate: Avatar_Test_Responses_Aggregate;
  /** fetch data from the table: "avatar_test_responses" using primary key columns */
  avatar_test_responses_by_pk?: Maybe<Avatar_Test_Responses>;
  /** fetch data from the table in a streaming manner: "avatar_test_responses" */
  avatar_test_responses_stream: Array<Avatar_Test_Responses>;
  /** fetch data from the table: "avatar_tests" */
  avatar_tests: Array<Avatar_Tests>;
  /** fetch aggregated fields from the table: "avatar_tests" */
  avatar_tests_aggregate: Avatar_Tests_Aggregate;
  /** fetch data from the table: "avatar_tests" using primary key columns */
  avatar_tests_by_pk?: Maybe<Avatar_Tests>;
  /** fetch data from the table in a streaming manner: "avatar_tests" */
  avatar_tests_stream: Array<Avatar_Tests>;
  /** An array relationship */
  channel_users: Array<Channel_Users>;
  /** An aggregate relationship */
  channel_users_aggregate: Channel_Users_Aggregate;
  /** fetch data from the table: "channel_users" using primary key columns */
  channel_users_by_pk?: Maybe<Channel_Users>;
  /** fetch data from the table in a streaming manner: "channel_users" */
  channel_users_stream: Array<Channel_Users>;
  /** An array relationship */
  channels: Array<Channels>;
  /** An aggregate relationship */
  channels_aggregate: Channels_Aggregate;
  /** fetch data from the table: "channels" using primary key columns */
  channels_by_pk?: Maybe<Channels>;
  /** fetch data from the table in a streaming manner: "channels" */
  channels_stream: Array<Channels>;
  /** fetch data from the table: "cities" */
  cities: Array<Cities>;
  /** fetch aggregated fields from the table: "cities" */
  cities_aggregate: Cities_Aggregate;
  /** fetch data from the table: "cities" using primary key columns */
  cities_by_pk?: Maybe<Cities>;
  /** fetch data from the table in a streaming manner: "cities" */
  cities_stream: Array<Cities>;
  /** fetch data from the table: "contact_forms" */
  contact_forms: Array<Contact_Forms>;
  /** fetch aggregated fields from the table: "contact_forms" */
  contact_forms_aggregate: Contact_Forms_Aggregate;
  /** fetch data from the table: "contact_forms" using primary key columns */
  contact_forms_by_pk?: Maybe<Contact_Forms>;
  /** fetch data from the table in a streaming manner: "contact_forms" */
  contact_forms_stream: Array<Contact_Forms>;
  /** fetch data from the table: "contents" */
  contents: Array<Contents>;
  /** fetch aggregated fields from the table: "contents" */
  contents_aggregate: Contents_Aggregate;
  /** fetch data from the table: "contents" using primary key columns */
  contents_by_pk?: Maybe<Contents>;
  /** fetch data from the table in a streaming manner: "contents" */
  contents_stream: Array<Contents>;
  /** fetch data from the table: "device_tokens" */
  device_tokens: Array<Device_Tokens>;
  /** fetch aggregated fields from the table: "device_tokens" */
  device_tokens_aggregate: Device_Tokens_Aggregate;
  /** fetch data from the table: "device_tokens" using primary key columns */
  device_tokens_by_pk?: Maybe<Device_Tokens>;
  /** fetch data from the table in a streaming manner: "device_tokens" */
  device_tokens_stream: Array<Device_Tokens>;
  /** fetch data from the table: "faqs" */
  faqs: Array<Faqs>;
  /** fetch aggregated fields from the table: "faqs" */
  faqs_aggregate: Faqs_Aggregate;
  /** fetch data from the table: "faqs" using primary key columns */
  faqs_by_pk?: Maybe<Faqs>;
  /** fetch data from the table in a streaming manner: "faqs" */
  faqs_stream: Array<Faqs>;
  /** fetch data from the table: "friend_requests" */
  friend_requests: Array<Friend_Requests>;
  /** fetch aggregated fields from the table: "friend_requests" */
  friend_requests_aggregate: Friend_Requests_Aggregate;
  /** fetch data from the table: "friend_requests" using primary key columns */
  friend_requests_by_pk?: Maybe<Friend_Requests>;
  /** fetch data from the table in a streaming manner: "friend_requests" */
  friend_requests_stream: Array<Friend_Requests>;
  /** fetch data from the table: "friends" */
  friends: Array<Friends>;
  /** fetch aggregated fields from the table: "friends" */
  friends_aggregate: Friends_Aggregate;
  /** fetch data from the table: "friends" using primary key columns */
  friends_by_pk?: Maybe<Friends>;
  /** fetch data from the table in a streaming manner: "friends" */
  friends_stream: Array<Friends>;
  /** fetch data from the table: "genders" */
  genders: Array<Genders>;
  /** fetch aggregated fields from the table: "genders" */
  genders_aggregate: Genders_Aggregate;
  /** fetch data from the table: "genders" using primary key columns */
  genders_by_pk?: Maybe<Genders>;
  /** fetch data from the table in a streaming manner: "genders" */
  genders_stream: Array<Genders>;
  /** fetch data from the table: "languages" */
  languages: Array<Languages>;
  /** fetch aggregated fields from the table: "languages" */
  languages_aggregate: Languages_Aggregate;
  /** fetch data from the table: "languages" using primary key columns */
  languages_by_pk?: Maybe<Languages>;
  /** fetch data from the table in a streaming manner: "languages" */
  languages_stream: Array<Languages>;
  /** fetch data from the table: "matric_question_categories" */
  matric_question_categories: Array<Matric_Question_Categories>;
  /** fetch aggregated fields from the table: "matric_question_categories" */
  matric_question_categories_aggregate: Matric_Question_Categories_Aggregate;
  /** fetch data from the table: "matric_question_categories" using primary key columns */
  matric_question_categories_by_pk?: Maybe<Matric_Question_Categories>;
  /** fetch data from the table in a streaming manner: "matric_question_categories" */
  matric_question_categories_stream: Array<Matric_Question_Categories>;
  /** An array relationship */
  matric_question_responses: Array<Matric_Question_Responses>;
  /** An aggregate relationship */
  matric_question_responses_aggregate: Matric_Question_Responses_Aggregate;
  /** fetch data from the table: "matric_question_responses" using primary key columns */
  matric_question_responses_by_pk?: Maybe<Matric_Question_Responses>;
  /** fetch data from the table in a streaming manner: "matric_question_responses" */
  matric_question_responses_stream: Array<Matric_Question_Responses>;
  /** An array relationship */
  matric_questions: Array<Matric_Questions>;
  /** An aggregate relationship */
  matric_questions_aggregate: Matric_Questions_Aggregate;
  /** fetch data from the table: "matric_questions" using primary key columns */
  matric_questions_by_pk?: Maybe<Matric_Questions>;
  /** fetch data from the table in a streaming manner: "matric_questions" */
  matric_questions_stream: Array<Matric_Questions>;
  /** An array relationship */
  messages: Array<Messages>;
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate;
  /** fetch data from the table: "messages" using primary key columns */
  messages_by_pk?: Maybe<Messages>;
  /** fetch data from the table in a streaming manner: "messages" */
  messages_stream: Array<Messages>;
  /** fetch data from the table: "notifications" */
  notifications: Array<Notifications>;
  /** fetch aggregated fields from the table: "notifications" */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table in a streaming manner: "notifications" */
  notifications_stream: Array<Notifications>;
  /** fetch data from the table: "otps" */
  otps: Array<Otps>;
  /** fetch aggregated fields from the table: "otps" */
  otps_aggregate: Otps_Aggregate;
  /** fetch data from the table: "otps" using primary key columns */
  otps_by_pk?: Maybe<Otps>;
  /** fetch data from the table in a streaming manner: "otps" */
  otps_stream: Array<Otps>;
  /** fetch data from the table: "password_reset_tokens" */
  password_reset_tokens: Array<Password_Reset_Tokens>;
  /** fetch aggregated fields from the table: "password_reset_tokens" */
  password_reset_tokens_aggregate: Password_Reset_Tokens_Aggregate;
  /** fetch data from the table: "password_reset_tokens" using primary key columns */
  password_reset_tokens_by_pk?: Maybe<Password_Reset_Tokens>;
  /** fetch data from the table in a streaming manner: "password_reset_tokens" */
  password_reset_tokens_stream: Array<Password_Reset_Tokens>;
  /** fetch data from the table: "personal_sun_types" */
  personal_sun_types: Array<Personal_Sun_Types>;
  /** fetch aggregated fields from the table: "personal_sun_types" */
  personal_sun_types_aggregate: Personal_Sun_Types_Aggregate;
  /** fetch data from the table: "personal_sun_types" using primary key columns */
  personal_sun_types_by_pk?: Maybe<Personal_Sun_Types>;
  /** fetch data from the table in a streaming manner: "personal_sun_types" */
  personal_sun_types_stream: Array<Personal_Sun_Types>;
  /** An array relationship */
  personal_suns: Array<Personal_Suns>;
  /** An aggregate relationship */
  personal_suns_aggregate: Personal_Suns_Aggregate;
  /** fetch data from the table: "personal_suns" using primary key columns */
  personal_suns_by_pk?: Maybe<Personal_Suns>;
  /** fetch data from the table in a streaming manner: "personal_suns" */
  personal_suns_stream: Array<Personal_Suns>;
  /** An array relationship */
  profiles: Array<Profiles>;
  /** An aggregate relationship */
  profiles_aggregate: Profiles_Aggregate;
  /** fetch data from the table: "profiles" using primary key columns */
  profiles_by_pk?: Maybe<Profiles>;
  /** fetch data from the table in a streaming manner: "profiles" */
  profiles_stream: Array<Profiles>;
  /** An array relationship */
  reservation_payments: Array<Reservation_Payments>;
  /** An aggregate relationship */
  reservation_payments_aggregate: Reservation_Payments_Aggregate;
  /** fetch data from the table: "reservation_payments" using primary key columns */
  reservation_payments_by_pk?: Maybe<Reservation_Payments>;
  /** fetch data from the table in a streaming manner: "reservation_payments" */
  reservation_payments_stream: Array<Reservation_Payments>;
  /** An array relationship */
  reservations: Array<Reservations>;
  /** An aggregate relationship */
  reservations_aggregate: Reservations_Aggregate;
  /** fetch data from the table: "reservations" using primary key columns */
  reservations_by_pk?: Maybe<Reservations>;
  /** fetch data from the table in a streaming manner: "reservations" */
  reservations_stream: Array<Reservations>;
  /** An array relationship */
  reviews: Array<Reviews>;
  /** An aggregate relationship */
  reviews_aggregate: Reviews_Aggregate;
  /** fetch data from the table: "reviews" using primary key columns */
  reviews_by_pk?: Maybe<Reviews>;
  /** fetch data from the table in a streaming manner: "reviews" */
  reviews_stream: Array<Reviews>;
  /** fetch data from the table: "settings" */
  settings: Array<Settings>;
  /** fetch aggregated fields from the table: "settings" */
  settings_aggregate: Settings_Aggregate;
  /** fetch data from the table: "settings" using primary key columns */
  settings_by_pk?: Maybe<Settings>;
  /** fetch data from the table in a streaming manner: "settings" */
  settings_stream: Array<Settings>;
  /** An array relationship */
  subscriptions: Array<Subscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscriptions_Aggregate;
  /** fetch data from the table: "subscriptions" using primary key columns */
  subscriptions_by_pk?: Maybe<Subscriptions>;
  /** fetch data from the table in a streaming manner: "subscriptions" */
  subscriptions_stream: Array<Subscriptions>;
  /** An array relationship */
  template_question_responses: Array<Template_Question_Responses>;
  /** An aggregate relationship */
  template_question_responses_aggregate: Template_Question_Responses_Aggregate;
  /** fetch data from the table: "template_question_responses" using primary key columns */
  template_question_responses_by_pk?: Maybe<Template_Question_Responses>;
  /** fetch data from the table in a streaming manner: "template_question_responses" */
  template_question_responses_stream: Array<Template_Question_Responses>;
  /** fetch data from the table: "template_questions" */
  template_questions: Array<Template_Questions>;
  /** fetch aggregated fields from the table: "template_questions" */
  template_questions_aggregate: Template_Questions_Aggregate;
  /** fetch data from the table: "template_questions" using primary key columns */
  template_questions_by_pk?: Maybe<Template_Questions>;
  /** fetch data from the table in a streaming manner: "template_questions" */
  template_questions_stream: Array<Template_Questions>;
  /** An array relationship */
  time_slots: Array<Time_Slots>;
  /** An aggregate relationship */
  time_slots_aggregate: Time_Slots_Aggregate;
  /** fetch data from the table: "time_slots" using primary key columns */
  time_slots_by_pk?: Maybe<Time_Slots>;
  /** fetch data from the table in a streaming manner: "time_slots" */
  time_slots_stream: Array<Time_Slots>;
  /** An array relationship */
  translations: Array<Translations>;
  /** An aggregate relationship */
  translations_aggregate: Translations_Aggregate;
  /** fetch data from the table: "translations" using primary key columns */
  translations_by_pk?: Maybe<Translations>;
  /** fetch data from the table in a streaming manner: "translations" */
  translations_stream: Array<Translations>;
  /** An array relationship */
  user_attachment_test_responses: Array<User_Attachment_Test_Responses>;
  /** An aggregate relationship */
  user_attachment_test_responses_aggregate: User_Attachment_Test_Responses_Aggregate;
  /** fetch data from the table: "user_attachment_test_responses" using primary key columns */
  user_attachment_test_responses_by_pk?: Maybe<User_Attachment_Test_Responses>;
  /** fetch data from the table in a streaming manner: "user_attachment_test_responses" */
  user_attachment_test_responses_stream: Array<User_Attachment_Test_Responses>;
  /** An array relationship */
  user_avatar_test_responses: Array<User_Avatar_Test_Responses>;
  /** An aggregate relationship */
  user_avatar_test_responses_aggregate: User_Avatar_Test_Responses_Aggregate;
  /** fetch data from the table: "user_avatar_test_responses" using primary key columns */
  user_avatar_test_responses_by_pk?: Maybe<User_Avatar_Test_Responses>;
  /** fetch data from the table in a streaming manner: "user_avatar_test_responses" */
  user_avatar_test_responses_stream: Array<User_Avatar_Test_Responses>;
  /** An array relationship */
  user_matric_question_responses: Array<User_Matric_Question_Responses>;
  /** An aggregate relationship */
  user_matric_question_responses_aggregate: User_Matric_Question_Responses_Aggregate;
  /** fetch data from the table: "user_matric_question_responses" using primary key columns */
  user_matric_question_responses_by_pk?: Maybe<User_Matric_Question_Responses>;
  /** fetch data from the table in a streaming manner: "user_matric_question_responses" */
  user_matric_question_responses_stream: Array<User_Matric_Question_Responses>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "vouchers" */
  vouchers: Array<Vouchers>;
  /** fetch aggregated fields from the table: "vouchers" */
  vouchers_aggregate: Vouchers_Aggregate;
  /** fetch data from the table: "vouchers" using primary key columns */
  vouchers_by_pk?: Maybe<Vouchers>;
  /** fetch data from the table in a streaming manner: "vouchers" */
  vouchers_stream: Array<Vouchers>;
};


export type Subscription_RootAttachment_Test_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Attachment_Test_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attachment_Test_Categories_Order_By>>;
  where?: InputMaybe<Attachment_Test_Categories_Bool_Exp>;
};


export type Subscription_RootAttachment_Test_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attachment_Test_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attachment_Test_Categories_Order_By>>;
  where?: InputMaybe<Attachment_Test_Categories_Bool_Exp>;
};


export type Subscription_RootAttachment_Test_Categories_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootAttachment_Test_Categories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Attachment_Test_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Attachment_Test_Categories_Bool_Exp>;
};


export type Subscription_RootAttachment_Test_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Attachment_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attachment_Test_Responses_Order_By>>;
  where?: InputMaybe<Attachment_Test_Responses_Bool_Exp>;
};


export type Subscription_RootAttachment_Test_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attachment_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attachment_Test_Responses_Order_By>>;
  where?: InputMaybe<Attachment_Test_Responses_Bool_Exp>;
};


export type Subscription_RootAttachment_Test_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootAttachment_Test_Responses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Attachment_Test_Responses_Stream_Cursor_Input>>;
  where?: InputMaybe<Attachment_Test_Responses_Bool_Exp>;
};


export type Subscription_RootAttachment_TestsArgs = {
  distinct_on?: InputMaybe<Array<Attachment_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attachment_Tests_Order_By>>;
  where?: InputMaybe<Attachment_Tests_Bool_Exp>;
};


export type Subscription_RootAttachment_Tests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attachment_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attachment_Tests_Order_By>>;
  where?: InputMaybe<Attachment_Tests_Bool_Exp>;
};


export type Subscription_RootAttachment_Tests_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootAttachment_Tests_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Attachment_Tests_Stream_Cursor_Input>>;
  where?: InputMaybe<Attachment_Tests_Bool_Exp>;
};


export type Subscription_RootAvailability_Time_SlotsArgs = {
  distinct_on?: InputMaybe<Array<Availability_Time_Slots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Availability_Time_Slots_Order_By>>;
  where?: InputMaybe<Availability_Time_Slots_Bool_Exp>;
};


export type Subscription_RootAvailability_Time_Slots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Availability_Time_Slots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Availability_Time_Slots_Order_By>>;
  where?: InputMaybe<Availability_Time_Slots_Bool_Exp>;
};


export type Subscription_RootAvailability_Time_Slots_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootAvailability_Time_Slots_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Availability_Time_Slots_Stream_Cursor_Input>>;
  where?: InputMaybe<Availability_Time_Slots_Bool_Exp>;
};


export type Subscription_RootAvatar_ResultsArgs = {
  distinct_on?: InputMaybe<Array<Avatar_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Avatar_Results_Order_By>>;
  where?: InputMaybe<Avatar_Results_Bool_Exp>;
};


export type Subscription_RootAvatar_Results_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Avatar_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Avatar_Results_Order_By>>;
  where?: InputMaybe<Avatar_Results_Bool_Exp>;
};


export type Subscription_RootAvatar_Results_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootAvatar_Results_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Avatar_Results_Stream_Cursor_Input>>;
  where?: InputMaybe<Avatar_Results_Bool_Exp>;
};


export type Subscription_RootAvatar_Test_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Avatar_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Avatar_Test_Responses_Order_By>>;
  where?: InputMaybe<Avatar_Test_Responses_Bool_Exp>;
};


export type Subscription_RootAvatar_Test_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Avatar_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Avatar_Test_Responses_Order_By>>;
  where?: InputMaybe<Avatar_Test_Responses_Bool_Exp>;
};


export type Subscription_RootAvatar_Test_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootAvatar_Test_Responses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Avatar_Test_Responses_Stream_Cursor_Input>>;
  where?: InputMaybe<Avatar_Test_Responses_Bool_Exp>;
};


export type Subscription_RootAvatar_TestsArgs = {
  distinct_on?: InputMaybe<Array<Avatar_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Avatar_Tests_Order_By>>;
  where?: InputMaybe<Avatar_Tests_Bool_Exp>;
};


export type Subscription_RootAvatar_Tests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Avatar_Tests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Avatar_Tests_Order_By>>;
  where?: InputMaybe<Avatar_Tests_Bool_Exp>;
};


export type Subscription_RootAvatar_Tests_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootAvatar_Tests_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Avatar_Tests_Stream_Cursor_Input>>;
  where?: InputMaybe<Avatar_Tests_Bool_Exp>;
};


export type Subscription_RootChannel_UsersArgs = {
  distinct_on?: InputMaybe<Array<Channel_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channel_Users_Order_By>>;
  where?: InputMaybe<Channel_Users_Bool_Exp>;
};


export type Subscription_RootChannel_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channel_Users_Order_By>>;
  where?: InputMaybe<Channel_Users_Bool_Exp>;
};


export type Subscription_RootChannel_Users_By_PkArgs = {
  channel_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};


export type Subscription_RootChannel_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Channel_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Channel_Users_Bool_Exp>;
};


export type Subscription_RootChannelsArgs = {
  distinct_on?: InputMaybe<Array<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Subscription_RootChannels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Subscription_RootChannels_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootChannels_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Channels_Stream_Cursor_Input>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Subscription_RootCitiesArgs = {
  distinct_on?: InputMaybe<Array<Cities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Cities_Order_By>>;
  where?: InputMaybe<Cities_Bool_Exp>;
};


export type Subscription_RootCities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Cities_Order_By>>;
  where?: InputMaybe<Cities_Bool_Exp>;
};


export type Subscription_RootCities_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootCities_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Cities_Stream_Cursor_Input>>;
  where?: InputMaybe<Cities_Bool_Exp>;
};


export type Subscription_RootContact_FormsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Forms_Order_By>>;
  where?: InputMaybe<Contact_Forms_Bool_Exp>;
};


export type Subscription_RootContact_Forms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contact_Forms_Order_By>>;
  where?: InputMaybe<Contact_Forms_Bool_Exp>;
};


export type Subscription_RootContact_Forms_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootContact_Forms_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Contact_Forms_Stream_Cursor_Input>>;
  where?: InputMaybe<Contact_Forms_Bool_Exp>;
};


export type Subscription_RootContentsArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Subscription_RootContents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Subscription_RootContents_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootContents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Contents_Stream_Cursor_Input>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Subscription_RootDevice_TokensArgs = {
  distinct_on?: InputMaybe<Array<Device_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Device_Tokens_Order_By>>;
  where?: InputMaybe<Device_Tokens_Bool_Exp>;
};


export type Subscription_RootDevice_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Device_Tokens_Order_By>>;
  where?: InputMaybe<Device_Tokens_Bool_Exp>;
};


export type Subscription_RootDevice_Tokens_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootDevice_Tokens_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Device_Tokens_Stream_Cursor_Input>>;
  where?: InputMaybe<Device_Tokens_Bool_Exp>;
};


export type Subscription_RootFaqsArgs = {
  distinct_on?: InputMaybe<Array<Faqs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Faqs_Order_By>>;
  where?: InputMaybe<Faqs_Bool_Exp>;
};


export type Subscription_RootFaqs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Faqs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Faqs_Order_By>>;
  where?: InputMaybe<Faqs_Bool_Exp>;
};


export type Subscription_RootFaqs_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootFaqs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Faqs_Stream_Cursor_Input>>;
  where?: InputMaybe<Faqs_Bool_Exp>;
};


export type Subscription_RootFriend_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Friend_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Friend_Requests_Order_By>>;
  where?: InputMaybe<Friend_Requests_Bool_Exp>;
};


export type Subscription_RootFriend_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Friend_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Friend_Requests_Order_By>>;
  where?: InputMaybe<Friend_Requests_Bool_Exp>;
};


export type Subscription_RootFriend_Requests_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootFriend_Requests_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Friend_Requests_Stream_Cursor_Input>>;
  where?: InputMaybe<Friend_Requests_Bool_Exp>;
};


export type Subscription_RootFriendsArgs = {
  distinct_on?: InputMaybe<Array<Friends_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Friends_Order_By>>;
  where?: InputMaybe<Friends_Bool_Exp>;
};


export type Subscription_RootFriends_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Friends_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Friends_Order_By>>;
  where?: InputMaybe<Friends_Bool_Exp>;
};


export type Subscription_RootFriends_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootFriends_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Friends_Stream_Cursor_Input>>;
  where?: InputMaybe<Friends_Bool_Exp>;
};


export type Subscription_RootGendersArgs = {
  distinct_on?: InputMaybe<Array<Genders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Genders_Order_By>>;
  where?: InputMaybe<Genders_Bool_Exp>;
};


export type Subscription_RootGenders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Genders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Genders_Order_By>>;
  where?: InputMaybe<Genders_Bool_Exp>;
};


export type Subscription_RootGenders_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootGenders_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Genders_Stream_Cursor_Input>>;
  where?: InputMaybe<Genders_Bool_Exp>;
};


export type Subscription_RootLanguagesArgs = {
  distinct_on?: InputMaybe<Array<Languages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Languages_Order_By>>;
  where?: InputMaybe<Languages_Bool_Exp>;
};


export type Subscription_RootLanguages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Languages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Languages_Order_By>>;
  where?: InputMaybe<Languages_Bool_Exp>;
};


export type Subscription_RootLanguages_By_PkArgs = {
  code: Scalars['String']['input'];
};


export type Subscription_RootLanguages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Languages_Stream_Cursor_Input>>;
  where?: InputMaybe<Languages_Bool_Exp>;
};


export type Subscription_RootMatric_Question_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Matric_Question_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Matric_Question_Categories_Order_By>>;
  where?: InputMaybe<Matric_Question_Categories_Bool_Exp>;
};


export type Subscription_RootMatric_Question_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matric_Question_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Matric_Question_Categories_Order_By>>;
  where?: InputMaybe<Matric_Question_Categories_Bool_Exp>;
};


export type Subscription_RootMatric_Question_Categories_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootMatric_Question_Categories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Matric_Question_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Matric_Question_Categories_Bool_Exp>;
};


export type Subscription_RootMatric_Question_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Matric_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Matric_Question_Responses_Order_By>>;
  where?: InputMaybe<Matric_Question_Responses_Bool_Exp>;
};


export type Subscription_RootMatric_Question_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matric_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Matric_Question_Responses_Order_By>>;
  where?: InputMaybe<Matric_Question_Responses_Bool_Exp>;
};


export type Subscription_RootMatric_Question_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootMatric_Question_Responses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Matric_Question_Responses_Stream_Cursor_Input>>;
  where?: InputMaybe<Matric_Question_Responses_Bool_Exp>;
};


export type Subscription_RootMatric_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Matric_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Matric_Questions_Order_By>>;
  where?: InputMaybe<Matric_Questions_Bool_Exp>;
};


export type Subscription_RootMatric_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Matric_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Matric_Questions_Order_By>>;
  where?: InputMaybe<Matric_Questions_Bool_Exp>;
};


export type Subscription_RootMatric_Questions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootMatric_Questions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Matric_Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Matric_Questions_Bool_Exp>;
};


export type Subscription_RootMessagesArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


export type Subscription_RootMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


export type Subscription_RootMessages_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootMessages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Messages_Stream_Cursor_Input>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


export type Subscription_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootNotifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootOtpsArgs = {
  distinct_on?: InputMaybe<Array<Otps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Otps_Order_By>>;
  where?: InputMaybe<Otps_Bool_Exp>;
};


export type Subscription_RootOtps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Otps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Otps_Order_By>>;
  where?: InputMaybe<Otps_Bool_Exp>;
};


export type Subscription_RootOtps_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootOtps_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Otps_Stream_Cursor_Input>>;
  where?: InputMaybe<Otps_Bool_Exp>;
};


export type Subscription_RootPassword_Reset_TokensArgs = {
  distinct_on?: InputMaybe<Array<Password_Reset_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Password_Reset_Tokens_Order_By>>;
  where?: InputMaybe<Password_Reset_Tokens_Bool_Exp>;
};


export type Subscription_RootPassword_Reset_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Password_Reset_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Password_Reset_Tokens_Order_By>>;
  where?: InputMaybe<Password_Reset_Tokens_Bool_Exp>;
};


export type Subscription_RootPassword_Reset_Tokens_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootPassword_Reset_Tokens_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Password_Reset_Tokens_Stream_Cursor_Input>>;
  where?: InputMaybe<Password_Reset_Tokens_Bool_Exp>;
};


export type Subscription_RootPersonal_Sun_TypesArgs = {
  distinct_on?: InputMaybe<Array<Personal_Sun_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Personal_Sun_Types_Order_By>>;
  where?: InputMaybe<Personal_Sun_Types_Bool_Exp>;
};


export type Subscription_RootPersonal_Sun_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Personal_Sun_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Personal_Sun_Types_Order_By>>;
  where?: InputMaybe<Personal_Sun_Types_Bool_Exp>;
};


export type Subscription_RootPersonal_Sun_Types_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootPersonal_Sun_Types_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Personal_Sun_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Personal_Sun_Types_Bool_Exp>;
};


export type Subscription_RootPersonal_SunsArgs = {
  distinct_on?: InputMaybe<Array<Personal_Suns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Personal_Suns_Order_By>>;
  where?: InputMaybe<Personal_Suns_Bool_Exp>;
};


export type Subscription_RootPersonal_Suns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Personal_Suns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Personal_Suns_Order_By>>;
  where?: InputMaybe<Personal_Suns_Bool_Exp>;
};


export type Subscription_RootPersonal_Suns_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootPersonal_Suns_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Personal_Suns_Stream_Cursor_Input>>;
  where?: InputMaybe<Personal_Suns_Bool_Exp>;
};


export type Subscription_RootProfilesArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


export type Subscription_RootProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


export type Subscription_RootProfiles_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootProfiles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Profiles_Stream_Cursor_Input>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


export type Subscription_RootReservation_PaymentsArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reservation_Payments_Order_By>>;
  where?: InputMaybe<Reservation_Payments_Bool_Exp>;
};


export type Subscription_RootReservation_Payments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reservation_Payments_Order_By>>;
  where?: InputMaybe<Reservation_Payments_Bool_Exp>;
};


export type Subscription_RootReservation_Payments_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootReservation_Payments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Reservation_Payments_Stream_Cursor_Input>>;
  where?: InputMaybe<Reservation_Payments_Bool_Exp>;
};


export type Subscription_RootReservationsArgs = {
  distinct_on?: InputMaybe<Array<Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reservations_Order_By>>;
  where?: InputMaybe<Reservations_Bool_Exp>;
};


export type Subscription_RootReservations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reservations_Order_By>>;
  where?: InputMaybe<Reservations_Bool_Exp>;
};


export type Subscription_RootReservations_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootReservations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Reservations_Stream_Cursor_Input>>;
  where?: InputMaybe<Reservations_Bool_Exp>;
};


export type Subscription_RootReviewsArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};


export type Subscription_RootReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};


export type Subscription_RootReviews_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootReviews_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Reviews_Stream_Cursor_Input>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};


export type Subscription_RootSettingsArgs = {
  distinct_on?: InputMaybe<Array<Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Settings_Order_By>>;
  where?: InputMaybe<Settings_Bool_Exp>;
};


export type Subscription_RootSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Settings_Order_By>>;
  where?: InputMaybe<Settings_Bool_Exp>;
};


export type Subscription_RootSettings_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootSettings_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Settings_Bool_Exp>;
};


export type Subscription_RootSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Subscription_RootSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Subscription_RootSubscriptions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootSubscriptions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Subscriptions_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Subscription_RootTemplate_Question_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Template_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Question_Responses_Order_By>>;
  where?: InputMaybe<Template_Question_Responses_Bool_Exp>;
};


export type Subscription_RootTemplate_Question_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Question_Responses_Order_By>>;
  where?: InputMaybe<Template_Question_Responses_Bool_Exp>;
};


export type Subscription_RootTemplate_Question_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootTemplate_Question_Responses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Template_Question_Responses_Stream_Cursor_Input>>;
  where?: InputMaybe<Template_Question_Responses_Bool_Exp>;
};


export type Subscription_RootTemplate_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Template_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Questions_Order_By>>;
  where?: InputMaybe<Template_Questions_Bool_Exp>;
};


export type Subscription_RootTemplate_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Questions_Order_By>>;
  where?: InputMaybe<Template_Questions_Bool_Exp>;
};


export type Subscription_RootTemplate_Questions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootTemplate_Questions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Template_Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Template_Questions_Bool_Exp>;
};


export type Subscription_RootTime_SlotsArgs = {
  distinct_on?: InputMaybe<Array<Time_Slots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Time_Slots_Order_By>>;
  where?: InputMaybe<Time_Slots_Bool_Exp>;
};


export type Subscription_RootTime_Slots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Time_Slots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Time_Slots_Order_By>>;
  where?: InputMaybe<Time_Slots_Bool_Exp>;
};


export type Subscription_RootTime_Slots_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootTime_Slots_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Time_Slots_Stream_Cursor_Input>>;
  where?: InputMaybe<Time_Slots_Bool_Exp>;
};


export type Subscription_RootTranslationsArgs = {
  distinct_on?: InputMaybe<Array<Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Translations_Order_By>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};


export type Subscription_RootTranslations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Translations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Translations_Order_By>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};


export type Subscription_RootTranslations_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootTranslations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Translations_Stream_Cursor_Input>>;
  where?: InputMaybe<Translations_Bool_Exp>;
};


export type Subscription_RootUser_Attachment_Test_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<User_Attachment_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Attachment_Test_Responses_Order_By>>;
  where?: InputMaybe<User_Attachment_Test_Responses_Bool_Exp>;
};


export type Subscription_RootUser_Attachment_Test_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Attachment_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Attachment_Test_Responses_Order_By>>;
  where?: InputMaybe<User_Attachment_Test_Responses_Bool_Exp>;
};


export type Subscription_RootUser_Attachment_Test_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootUser_Attachment_Test_Responses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Attachment_Test_Responses_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Attachment_Test_Responses_Bool_Exp>;
};


export type Subscription_RootUser_Avatar_Test_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Avatar_Test_Responses_Order_By>>;
  where?: InputMaybe<User_Avatar_Test_Responses_Bool_Exp>;
};


export type Subscription_RootUser_Avatar_Test_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Avatar_Test_Responses_Order_By>>;
  where?: InputMaybe<User_Avatar_Test_Responses_Bool_Exp>;
};


export type Subscription_RootUser_Avatar_Test_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootUser_Avatar_Test_Responses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Avatar_Test_Responses_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Avatar_Test_Responses_Bool_Exp>;
};


export type Subscription_RootUser_Matric_Question_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<User_Matric_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Matric_Question_Responses_Order_By>>;
  where?: InputMaybe<User_Matric_Question_Responses_Bool_Exp>;
};


export type Subscription_RootUser_Matric_Question_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Matric_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Matric_Question_Responses_Order_By>>;
  where?: InputMaybe<User_Matric_Question_Responses_Bool_Exp>;
};


export type Subscription_RootUser_Matric_Question_Responses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootUser_Matric_Question_Responses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Matric_Question_Responses_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Matric_Question_Responses_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootVouchersArgs = {
  distinct_on?: InputMaybe<Array<Vouchers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vouchers_Order_By>>;
  where?: InputMaybe<Vouchers_Bool_Exp>;
};


export type Subscription_RootVouchers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vouchers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vouchers_Order_By>>;
  where?: InputMaybe<Vouchers_Bool_Exp>;
};


export type Subscription_RootVouchers_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootVouchers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vouchers_Stream_Cursor_Input>>;
  where?: InputMaybe<Vouchers_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type Subscriptions = {
  __typename?: 'subscriptions';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  price?: Maybe<Scalars['String']['output']>;
  price_in_purchased_currency?: Maybe<Scalars['Int']['output']>;
  revenuecat_event_id?: Maybe<Scalars['String']['output']>;
  revenuecat_product_id?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['timestamptz']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "subscriptions" */
export type Subscriptions_Aggregate = {
  __typename?: 'subscriptions_aggregate';
  aggregate?: Maybe<Subscriptions_Aggregate_Fields>;
  nodes: Array<Subscriptions>;
};

export type Subscriptions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Subscriptions_Aggregate_Bool_Exp_Count>;
};

export type Subscriptions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Subscriptions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "subscriptions" */
export type Subscriptions_Aggregate_Fields = {
  __typename?: 'subscriptions_aggregate_fields';
  avg?: Maybe<Subscriptions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Subscriptions_Max_Fields>;
  min?: Maybe<Subscriptions_Min_Fields>;
  stddev?: Maybe<Subscriptions_Stddev_Fields>;
  stddev_pop?: Maybe<Subscriptions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subscriptions_Stddev_Samp_Fields>;
  sum?: Maybe<Subscriptions_Sum_Fields>;
  var_pop?: Maybe<Subscriptions_Var_Pop_Fields>;
  var_samp?: Maybe<Subscriptions_Var_Samp_Fields>;
  variance?: Maybe<Subscriptions_Variance_Fields>;
};


/** aggregate fields of "subscriptions" */
export type Subscriptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriptions" */
export type Subscriptions_Aggregate_Order_By = {
  avg?: InputMaybe<Subscriptions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subscriptions_Max_Order_By>;
  min?: InputMaybe<Subscriptions_Min_Order_By>;
  stddev?: InputMaybe<Subscriptions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Subscriptions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Subscriptions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Subscriptions_Sum_Order_By>;
  var_pop?: InputMaybe<Subscriptions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Subscriptions_Var_Samp_Order_By>;
  variance?: InputMaybe<Subscriptions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "subscriptions" */
export type Subscriptions_Arr_Rel_Insert_Input = {
  data: Array<Subscriptions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>;
};

/** aggregate avg on columns */
export type Subscriptions_Avg_Fields = {
  __typename?: 'subscriptions_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price_in_purchased_currency?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriptions" */
export type Subscriptions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  price_in_purchased_currency?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "subscriptions". All fields are combined with a logical 'AND'. */
export type Subscriptions_Bool_Exp = {
  _and?: InputMaybe<Array<Subscriptions_Bool_Exp>>;
  _not?: InputMaybe<Subscriptions_Bool_Exp>;
  _or?: InputMaybe<Array<Subscriptions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  end_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<String_Comparison_Exp>;
  price_in_purchased_currency?: InputMaybe<Int_Comparison_Exp>;
  revenuecat_event_id?: InputMaybe<String_Comparison_Exp>;
  revenuecat_product_id?: InputMaybe<String_Comparison_Exp>;
  start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscriptions" */
export type Subscriptions_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'subscriptions_pkey';

/** input type for incrementing numeric columns in table "subscriptions" */
export type Subscriptions_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  price_in_purchased_currency?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriptions" */
export type Subscriptions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  end_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['String']['input']>;
  price_in_purchased_currency?: InputMaybe<Scalars['Int']['input']>;
  revenuecat_event_id?: InputMaybe<Scalars['String']['input']>;
  revenuecat_product_id?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Subscriptions_Max_Fields = {
  __typename?: 'subscriptions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  price_in_purchased_currency?: Maybe<Scalars['Int']['output']>;
  revenuecat_event_id?: Maybe<Scalars['String']['output']>;
  revenuecat_product_id?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['timestamptz']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "subscriptions" */
export type Subscriptions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  price_in_purchased_currency?: InputMaybe<Order_By>;
  revenuecat_event_id?: InputMaybe<Order_By>;
  revenuecat_product_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subscriptions_Min_Fields = {
  __typename?: 'subscriptions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  price_in_purchased_currency?: Maybe<Scalars['Int']['output']>;
  revenuecat_event_id?: Maybe<Scalars['String']['output']>;
  revenuecat_product_id?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['timestamptz']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "subscriptions" */
export type Subscriptions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  price_in_purchased_currency?: InputMaybe<Order_By>;
  revenuecat_event_id?: InputMaybe<Order_By>;
  revenuecat_product_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subscriptions" */
export type Subscriptions_Mutation_Response = {
  __typename?: 'subscriptions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Subscriptions>;
};

/** on_conflict condition type for table "subscriptions" */
export type Subscriptions_On_Conflict = {
  constraint: Subscriptions_Constraint;
  update_columns?: Array<Subscriptions_Update_Column>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

/** Ordering options when selecting data from "subscriptions". */
export type Subscriptions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  price_in_purchased_currency?: InputMaybe<Order_By>;
  revenuecat_event_id?: InputMaybe<Order_By>;
  revenuecat_product_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscriptions */
export type Subscriptions_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "subscriptions" */
export type Subscriptions_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'currency'
  /** column name */
  | 'end_date'
  /** column name */
  | 'id'
  /** column name */
  | 'price'
  /** column name */
  | 'price_in_purchased_currency'
  /** column name */
  | 'revenuecat_event_id'
  /** column name */
  | 'revenuecat_product_id'
  /** column name */
  | 'start_date'
  /** column name */
  | 'status'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

/** input type for updating data in table "subscriptions" */
export type Subscriptions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  end_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['String']['input']>;
  price_in_purchased_currency?: InputMaybe<Scalars['Int']['input']>;
  revenuecat_event_id?: InputMaybe<Scalars['String']['input']>;
  revenuecat_product_id?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Subscriptions_Stddev_Fields = {
  __typename?: 'subscriptions_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price_in_purchased_currency?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriptions" */
export type Subscriptions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  price_in_purchased_currency?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Subscriptions_Stddev_Pop_Fields = {
  __typename?: 'subscriptions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price_in_purchased_currency?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriptions" */
export type Subscriptions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  price_in_purchased_currency?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Subscriptions_Stddev_Samp_Fields = {
  __typename?: 'subscriptions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price_in_purchased_currency?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriptions" */
export type Subscriptions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  price_in_purchased_currency?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "subscriptions" */
export type Subscriptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscriptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscriptions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  end_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['String']['input']>;
  price_in_purchased_currency?: InputMaybe<Scalars['Int']['input']>;
  revenuecat_event_id?: InputMaybe<Scalars['String']['input']>;
  revenuecat_product_id?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Subscriptions_Sum_Fields = {
  __typename?: 'subscriptions_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  price_in_purchased_currency?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriptions" */
export type Subscriptions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  price_in_purchased_currency?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "subscriptions" */
export type Subscriptions_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'currency'
  /** column name */
  | 'end_date'
  /** column name */
  | 'id'
  /** column name */
  | 'price'
  /** column name */
  | 'price_in_purchased_currency'
  /** column name */
  | 'revenuecat_event_id'
  /** column name */
  | 'revenuecat_product_id'
  /** column name */
  | 'start_date'
  /** column name */
  | 'status'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

export type Subscriptions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Subscriptions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscriptions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscriptions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Subscriptions_Var_Pop_Fields = {
  __typename?: 'subscriptions_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price_in_purchased_currency?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriptions" */
export type Subscriptions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  price_in_purchased_currency?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Subscriptions_Var_Samp_Fields = {
  __typename?: 'subscriptions_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price_in_purchased_currency?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriptions" */
export type Subscriptions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  price_in_purchased_currency?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Subscriptions_Variance_Fields = {
  __typename?: 'subscriptions_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  price_in_purchased_currency?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriptions" */
export type Subscriptions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  price_in_purchased_currency?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "template_question_responses" */
export type Template_Question_Responses = {
  __typename?: 'template_question_responses';
  /** An object relationship */
  content: Contents;
  content_id: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  template_question: Template_Questions;
  template_question_id: Scalars['Int']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "template_question_responses" */
export type Template_Question_Responses_Aggregate = {
  __typename?: 'template_question_responses_aggregate';
  aggregate?: Maybe<Template_Question_Responses_Aggregate_Fields>;
  nodes: Array<Template_Question_Responses>;
};

export type Template_Question_Responses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Template_Question_Responses_Aggregate_Bool_Exp_Count>;
};

export type Template_Question_Responses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Template_Question_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Template_Question_Responses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "template_question_responses" */
export type Template_Question_Responses_Aggregate_Fields = {
  __typename?: 'template_question_responses_aggregate_fields';
  avg?: Maybe<Template_Question_Responses_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Template_Question_Responses_Max_Fields>;
  min?: Maybe<Template_Question_Responses_Min_Fields>;
  stddev?: Maybe<Template_Question_Responses_Stddev_Fields>;
  stddev_pop?: Maybe<Template_Question_Responses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Template_Question_Responses_Stddev_Samp_Fields>;
  sum?: Maybe<Template_Question_Responses_Sum_Fields>;
  var_pop?: Maybe<Template_Question_Responses_Var_Pop_Fields>;
  var_samp?: Maybe<Template_Question_Responses_Var_Samp_Fields>;
  variance?: Maybe<Template_Question_Responses_Variance_Fields>;
};


/** aggregate fields of "template_question_responses" */
export type Template_Question_Responses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Template_Question_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "template_question_responses" */
export type Template_Question_Responses_Aggregate_Order_By = {
  avg?: InputMaybe<Template_Question_Responses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Template_Question_Responses_Max_Order_By>;
  min?: InputMaybe<Template_Question_Responses_Min_Order_By>;
  stddev?: InputMaybe<Template_Question_Responses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Template_Question_Responses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Template_Question_Responses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Template_Question_Responses_Sum_Order_By>;
  var_pop?: InputMaybe<Template_Question_Responses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Template_Question_Responses_Var_Samp_Order_By>;
  variance?: InputMaybe<Template_Question_Responses_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "template_question_responses" */
export type Template_Question_Responses_Arr_Rel_Insert_Input = {
  data: Array<Template_Question_Responses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Template_Question_Responses_On_Conflict>;
};

/** aggregate avg on columns */
export type Template_Question_Responses_Avg_Fields = {
  __typename?: 'template_question_responses_avg_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  template_question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "template_question_responses" */
export type Template_Question_Responses_Avg_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_question_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "template_question_responses". All fields are combined with a logical 'AND'. */
export type Template_Question_Responses_Bool_Exp = {
  _and?: InputMaybe<Array<Template_Question_Responses_Bool_Exp>>;
  _not?: InputMaybe<Template_Question_Responses_Bool_Exp>;
  _or?: InputMaybe<Array<Template_Question_Responses_Bool_Exp>>;
  content?: InputMaybe<Contents_Bool_Exp>;
  content_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  template_question?: InputMaybe<Template_Questions_Bool_Exp>;
  template_question_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "template_question_responses" */
export type Template_Question_Responses_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'template_question_responses_pkey';

/** input type for incrementing numeric columns in table "template_question_responses" */
export type Template_Question_Responses_Inc_Input = {
  content_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  template_question_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "template_question_responses" */
export type Template_Question_Responses_Insert_Input = {
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  template_question?: InputMaybe<Template_Questions_Obj_Rel_Insert_Input>;
  template_question_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Template_Question_Responses_Max_Fields = {
  __typename?: 'template_question_responses_max_fields';
  content_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  template_question_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "template_question_responses" */
export type Template_Question_Responses_Max_Order_By = {
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_question_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Template_Question_Responses_Min_Fields = {
  __typename?: 'template_question_responses_min_fields';
  content_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  template_question_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "template_question_responses" */
export type Template_Question_Responses_Min_Order_By = {
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_question_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "template_question_responses" */
export type Template_Question_Responses_Mutation_Response = {
  __typename?: 'template_question_responses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Template_Question_Responses>;
};

/** on_conflict condition type for table "template_question_responses" */
export type Template_Question_Responses_On_Conflict = {
  constraint: Template_Question_Responses_Constraint;
  update_columns?: Array<Template_Question_Responses_Update_Column>;
  where?: InputMaybe<Template_Question_Responses_Bool_Exp>;
};

/** Ordering options when selecting data from "template_question_responses". */
export type Template_Question_Responses_Order_By = {
  content?: InputMaybe<Contents_Order_By>;
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_question?: InputMaybe<Template_Questions_Order_By>;
  template_question_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: template_question_responses */
export type Template_Question_Responses_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "template_question_responses" */
export type Template_Question_Responses_Select_Column =
  /** column name */
  | 'content_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'template_question_id'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "template_question_responses" */
export type Template_Question_Responses_Set_Input = {
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  template_question_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Template_Question_Responses_Stddev_Fields = {
  __typename?: 'template_question_responses_stddev_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  template_question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "template_question_responses" */
export type Template_Question_Responses_Stddev_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_question_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Template_Question_Responses_Stddev_Pop_Fields = {
  __typename?: 'template_question_responses_stddev_pop_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  template_question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "template_question_responses" */
export type Template_Question_Responses_Stddev_Pop_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_question_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Template_Question_Responses_Stddev_Samp_Fields = {
  __typename?: 'template_question_responses_stddev_samp_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  template_question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "template_question_responses" */
export type Template_Question_Responses_Stddev_Samp_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_question_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "template_question_responses" */
export type Template_Question_Responses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Template_Question_Responses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Template_Question_Responses_Stream_Cursor_Value_Input = {
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  template_question_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Template_Question_Responses_Sum_Fields = {
  __typename?: 'template_question_responses_sum_fields';
  content_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  template_question_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "template_question_responses" */
export type Template_Question_Responses_Sum_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_question_id?: InputMaybe<Order_By>;
};

/** update columns of table "template_question_responses" */
export type Template_Question_Responses_Update_Column =
  /** column name */
  | 'content_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'template_question_id'
  /** column name */
  | 'updated_at';

export type Template_Question_Responses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Template_Question_Responses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Template_Question_Responses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Template_Question_Responses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Template_Question_Responses_Var_Pop_Fields = {
  __typename?: 'template_question_responses_var_pop_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  template_question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "template_question_responses" */
export type Template_Question_Responses_Var_Pop_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_question_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Template_Question_Responses_Var_Samp_Fields = {
  __typename?: 'template_question_responses_var_samp_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  template_question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "template_question_responses" */
export type Template_Question_Responses_Var_Samp_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_question_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Template_Question_Responses_Variance_Fields = {
  __typename?: 'template_question_responses_variance_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  template_question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "template_question_responses" */
export type Template_Question_Responses_Variance_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_question_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "template_questions" */
export type Template_Questions = {
  __typename?: 'template_questions';
  code: Scalars['String']['output'];
  /** An object relationship */
  content: Contents;
  content_id: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  order?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  personal_sun_type?: Maybe<Personal_Sun_Types>;
  /** An array relationship */
  template_question_responses: Array<Template_Question_Responses>;
  /** An aggregate relationship */
  template_question_responses_aggregate: Template_Question_Responses_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "template_questions" */
export type Template_QuestionsTemplate_Question_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Template_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Question_Responses_Order_By>>;
  where?: InputMaybe<Template_Question_Responses_Bool_Exp>;
};


/** columns and relationships of "template_questions" */
export type Template_QuestionsTemplate_Question_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Question_Responses_Order_By>>;
  where?: InputMaybe<Template_Question_Responses_Bool_Exp>;
};

/** aggregated selection of "template_questions" */
export type Template_Questions_Aggregate = {
  __typename?: 'template_questions_aggregate';
  aggregate?: Maybe<Template_Questions_Aggregate_Fields>;
  nodes: Array<Template_Questions>;
};

/** aggregate fields of "template_questions" */
export type Template_Questions_Aggregate_Fields = {
  __typename?: 'template_questions_aggregate_fields';
  avg?: Maybe<Template_Questions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Template_Questions_Max_Fields>;
  min?: Maybe<Template_Questions_Min_Fields>;
  stddev?: Maybe<Template_Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Template_Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Template_Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Template_Questions_Sum_Fields>;
  var_pop?: Maybe<Template_Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Template_Questions_Var_Samp_Fields>;
  variance?: Maybe<Template_Questions_Variance_Fields>;
};


/** aggregate fields of "template_questions" */
export type Template_Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Template_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Template_Questions_Avg_Fields = {
  __typename?: 'template_questions_avg_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "template_questions". All fields are combined with a logical 'AND'. */
export type Template_Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Template_Questions_Bool_Exp>>;
  _not?: InputMaybe<Template_Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Template_Questions_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  content?: InputMaybe<Contents_Bool_Exp>;
  content_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Numeric_Comparison_Exp>;
  personal_sun_type?: InputMaybe<Personal_Sun_Types_Bool_Exp>;
  template_question_responses?: InputMaybe<Template_Question_Responses_Bool_Exp>;
  template_question_responses_aggregate?: InputMaybe<Template_Question_Responses_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "template_questions" */
export type Template_Questions_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'template_questions_pkey';

/** input type for incrementing numeric columns in table "template_questions" */
export type Template_Questions_Inc_Input = {
  content_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "template_questions" */
export type Template_Questions_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['numeric']['input']>;
  personal_sun_type?: InputMaybe<Personal_Sun_Types_Obj_Rel_Insert_Input>;
  template_question_responses?: InputMaybe<Template_Question_Responses_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Template_Questions_Max_Fields = {
  __typename?: 'template_questions_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  content_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Template_Questions_Min_Fields = {
  __typename?: 'template_questions_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  content_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "template_questions" */
export type Template_Questions_Mutation_Response = {
  __typename?: 'template_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Template_Questions>;
};

/** input type for inserting object relation for remote table "template_questions" */
export type Template_Questions_Obj_Rel_Insert_Input = {
  data: Template_Questions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Template_Questions_On_Conflict>;
};

/** on_conflict condition type for table "template_questions" */
export type Template_Questions_On_Conflict = {
  constraint: Template_Questions_Constraint;
  update_columns?: Array<Template_Questions_Update_Column>;
  where?: InputMaybe<Template_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "template_questions". */
export type Template_Questions_Order_By = {
  code?: InputMaybe<Order_By>;
  content?: InputMaybe<Contents_Order_By>;
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  personal_sun_type?: InputMaybe<Personal_Sun_Types_Order_By>;
  template_question_responses_aggregate?: InputMaybe<Template_Question_Responses_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: template_questions */
export type Template_Questions_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "template_questions" */
export type Template_Questions_Select_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'content_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'order'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "template_questions" */
export type Template_Questions_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Template_Questions_Stddev_Fields = {
  __typename?: 'template_questions_stddev_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Template_Questions_Stddev_Pop_Fields = {
  __typename?: 'template_questions_stddev_pop_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Template_Questions_Stddev_Samp_Fields = {
  __typename?: 'template_questions_stddev_samp_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "template_questions" */
export type Template_Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Template_Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Template_Questions_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Template_Questions_Sum_Fields = {
  __typename?: 'template_questions_sum_fields';
  content_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "template_questions" */
export type Template_Questions_Update_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'content_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'order'
  /** column name */
  | 'updated_at';

export type Template_Questions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Template_Questions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Template_Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Template_Questions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Template_Questions_Var_Pop_Fields = {
  __typename?: 'template_questions_var_pop_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Template_Questions_Var_Samp_Fields = {
  __typename?: 'template_questions_var_samp_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Template_Questions_Variance_Fields = {
  __typename?: 'template_questions_variance_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "time_slots" */
export type Time_Slots = {
  __typename?: 'time_slots';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  end_time: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  reservation: Reservations;
  reservation_id: Scalars['Int']['output'];
  slot_status?: Maybe<Scalars['slot_status_enum']['output']>;
  start_time: Scalars['timestamptz']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "time_slots" */
export type Time_Slots_Aggregate = {
  __typename?: 'time_slots_aggregate';
  aggregate?: Maybe<Time_Slots_Aggregate_Fields>;
  nodes: Array<Time_Slots>;
};

export type Time_Slots_Aggregate_Bool_Exp = {
  count?: InputMaybe<Time_Slots_Aggregate_Bool_Exp_Count>;
};

export type Time_Slots_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Time_Slots_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Time_Slots_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "time_slots" */
export type Time_Slots_Aggregate_Fields = {
  __typename?: 'time_slots_aggregate_fields';
  avg?: Maybe<Time_Slots_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Time_Slots_Max_Fields>;
  min?: Maybe<Time_Slots_Min_Fields>;
  stddev?: Maybe<Time_Slots_Stddev_Fields>;
  stddev_pop?: Maybe<Time_Slots_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Time_Slots_Stddev_Samp_Fields>;
  sum?: Maybe<Time_Slots_Sum_Fields>;
  var_pop?: Maybe<Time_Slots_Var_Pop_Fields>;
  var_samp?: Maybe<Time_Slots_Var_Samp_Fields>;
  variance?: Maybe<Time_Slots_Variance_Fields>;
};


/** aggregate fields of "time_slots" */
export type Time_Slots_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Time_Slots_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "time_slots" */
export type Time_Slots_Aggregate_Order_By = {
  avg?: InputMaybe<Time_Slots_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Time_Slots_Max_Order_By>;
  min?: InputMaybe<Time_Slots_Min_Order_By>;
  stddev?: InputMaybe<Time_Slots_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Time_Slots_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Time_Slots_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Time_Slots_Sum_Order_By>;
  var_pop?: InputMaybe<Time_Slots_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Time_Slots_Var_Samp_Order_By>;
  variance?: InputMaybe<Time_Slots_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "time_slots" */
export type Time_Slots_Arr_Rel_Insert_Input = {
  data: Array<Time_Slots_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Time_Slots_On_Conflict>;
};

/** aggregate avg on columns */
export type Time_Slots_Avg_Fields = {
  __typename?: 'time_slots_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "time_slots" */
export type Time_Slots_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "time_slots". All fields are combined with a logical 'AND'. */
export type Time_Slots_Bool_Exp = {
  _and?: InputMaybe<Array<Time_Slots_Bool_Exp>>;
  _not?: InputMaybe<Time_Slots_Bool_Exp>;
  _or?: InputMaybe<Array<Time_Slots_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  end_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  reservation?: InputMaybe<Reservations_Bool_Exp>;
  reservation_id?: InputMaybe<Int_Comparison_Exp>;
  slot_status?: InputMaybe<Slot_Status_Enum_Comparison_Exp>;
  start_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "time_slots" */
export type Time_Slots_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'time_slots_pkey';

/** input type for incrementing numeric columns in table "time_slots" */
export type Time_Slots_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  reservation_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "time_slots" */
export type Time_Slots_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  reservation?: InputMaybe<Reservations_Obj_Rel_Insert_Input>;
  reservation_id?: InputMaybe<Scalars['Int']['input']>;
  slot_status?: InputMaybe<Scalars['slot_status_enum']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Time_Slots_Max_Fields = {
  __typename?: 'time_slots_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  end_time?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  reservation_id?: Maybe<Scalars['Int']['output']>;
  slot_status?: Maybe<Scalars['slot_status_enum']['output']>;
  start_time?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "time_slots" */
export type Time_Slots_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  slot_status?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Time_Slots_Min_Fields = {
  __typename?: 'time_slots_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  end_time?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  reservation_id?: Maybe<Scalars['Int']['output']>;
  slot_status?: Maybe<Scalars['slot_status_enum']['output']>;
  start_time?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "time_slots" */
export type Time_Slots_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  slot_status?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "time_slots" */
export type Time_Slots_Mutation_Response = {
  __typename?: 'time_slots_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Time_Slots>;
};

/** on_conflict condition type for table "time_slots" */
export type Time_Slots_On_Conflict = {
  constraint: Time_Slots_Constraint;
  update_columns?: Array<Time_Slots_Update_Column>;
  where?: InputMaybe<Time_Slots_Bool_Exp>;
};

/** Ordering options when selecting data from "time_slots". */
export type Time_Slots_Order_By = {
  created_at?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reservation?: InputMaybe<Reservations_Order_By>;
  reservation_id?: InputMaybe<Order_By>;
  slot_status?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: time_slots */
export type Time_Slots_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "time_slots" */
export type Time_Slots_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'end_time'
  /** column name */
  | 'id'
  /** column name */
  | 'reservation_id'
  /** column name */
  | 'slot_status'
  /** column name */
  | 'start_time'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "time_slots" */
export type Time_Slots_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  reservation_id?: InputMaybe<Scalars['Int']['input']>;
  slot_status?: InputMaybe<Scalars['slot_status_enum']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Time_Slots_Stddev_Fields = {
  __typename?: 'time_slots_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "time_slots" */
export type Time_Slots_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Time_Slots_Stddev_Pop_Fields = {
  __typename?: 'time_slots_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "time_slots" */
export type Time_Slots_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Time_Slots_Stddev_Samp_Fields = {
  __typename?: 'time_slots_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "time_slots" */
export type Time_Slots_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "time_slots" */
export type Time_Slots_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Time_Slots_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Time_Slots_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  reservation_id?: InputMaybe<Scalars['Int']['input']>;
  slot_status?: InputMaybe<Scalars['slot_status_enum']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Time_Slots_Sum_Fields = {
  __typename?: 'time_slots_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  reservation_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "time_slots" */
export type Time_Slots_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
};

/** update columns of table "time_slots" */
export type Time_Slots_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'end_time'
  /** column name */
  | 'id'
  /** column name */
  | 'reservation_id'
  /** column name */
  | 'slot_status'
  /** column name */
  | 'start_time'
  /** column name */
  | 'updated_at';

export type Time_Slots_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Time_Slots_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Time_Slots_Set_Input>;
  /** filter the rows which have to be updated */
  where: Time_Slots_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Time_Slots_Var_Pop_Fields = {
  __typename?: 'time_slots_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "time_slots" */
export type Time_Slots_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Time_Slots_Var_Samp_Fields = {
  __typename?: 'time_slots_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "time_slots" */
export type Time_Slots_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Time_Slots_Variance_Fields = {
  __typename?: 'time_slots_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reservation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "time_slots" */
export type Time_Slots_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  reservation_id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "translations" */
export type Translations = {
  __typename?: 'translations';
  /** An object relationship */
  content: Contents;
  content_id: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  language: Languages;
  language_code: Scalars['String']['output'];
  translation?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "translations" */
export type Translations_Aggregate = {
  __typename?: 'translations_aggregate';
  aggregate?: Maybe<Translations_Aggregate_Fields>;
  nodes: Array<Translations>;
};

export type Translations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Translations_Aggregate_Bool_Exp_Count>;
};

export type Translations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Translations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Translations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "translations" */
export type Translations_Aggregate_Fields = {
  __typename?: 'translations_aggregate_fields';
  avg?: Maybe<Translations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Translations_Max_Fields>;
  min?: Maybe<Translations_Min_Fields>;
  stddev?: Maybe<Translations_Stddev_Fields>;
  stddev_pop?: Maybe<Translations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Translations_Stddev_Samp_Fields>;
  sum?: Maybe<Translations_Sum_Fields>;
  var_pop?: Maybe<Translations_Var_Pop_Fields>;
  var_samp?: Maybe<Translations_Var_Samp_Fields>;
  variance?: Maybe<Translations_Variance_Fields>;
};


/** aggregate fields of "translations" */
export type Translations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Translations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "translations" */
export type Translations_Aggregate_Order_By = {
  avg?: InputMaybe<Translations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Translations_Max_Order_By>;
  min?: InputMaybe<Translations_Min_Order_By>;
  stddev?: InputMaybe<Translations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Translations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Translations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Translations_Sum_Order_By>;
  var_pop?: InputMaybe<Translations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Translations_Var_Samp_Order_By>;
  variance?: InputMaybe<Translations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "translations" */
export type Translations_Arr_Rel_Insert_Input = {
  data: Array<Translations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Translations_On_Conflict>;
};

/** aggregate avg on columns */
export type Translations_Avg_Fields = {
  __typename?: 'translations_avg_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "translations" */
export type Translations_Avg_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "translations". All fields are combined with a logical 'AND'. */
export type Translations_Bool_Exp = {
  _and?: InputMaybe<Array<Translations_Bool_Exp>>;
  _not?: InputMaybe<Translations_Bool_Exp>;
  _or?: InputMaybe<Array<Translations_Bool_Exp>>;
  content?: InputMaybe<Contents_Bool_Exp>;
  content_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  language?: InputMaybe<Languages_Bool_Exp>;
  language_code?: InputMaybe<String_Comparison_Exp>;
  translation?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "translations" */
export type Translations_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'translations_pkey';

/** input type for incrementing numeric columns in table "translations" */
export type Translations_Inc_Input = {
  content_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "translations" */
export type Translations_Insert_Input = {
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages_Obj_Rel_Insert_Input>;
  language_code?: InputMaybe<Scalars['String']['input']>;
  translation?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Translations_Max_Fields = {
  __typename?: 'translations_max_fields';
  content_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language_code?: Maybe<Scalars['String']['output']>;
  translation?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "translations" */
export type Translations_Max_Order_By = {
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language_code?: InputMaybe<Order_By>;
  translation?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Translations_Min_Fields = {
  __typename?: 'translations_min_fields';
  content_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language_code?: Maybe<Scalars['String']['output']>;
  translation?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "translations" */
export type Translations_Min_Order_By = {
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language_code?: InputMaybe<Order_By>;
  translation?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "translations" */
export type Translations_Mutation_Response = {
  __typename?: 'translations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Translations>;
};

/** on_conflict condition type for table "translations" */
export type Translations_On_Conflict = {
  constraint: Translations_Constraint;
  update_columns?: Array<Translations_Update_Column>;
  where?: InputMaybe<Translations_Bool_Exp>;
};

/** Ordering options when selecting data from "translations". */
export type Translations_Order_By = {
  content?: InputMaybe<Contents_Order_By>;
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Languages_Order_By>;
  language_code?: InputMaybe<Order_By>;
  translation?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: translations */
export type Translations_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "translations" */
export type Translations_Select_Column =
  /** column name */
  | 'content_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'language_code'
  /** column name */
  | 'translation'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "translations" */
export type Translations_Set_Input = {
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language_code?: InputMaybe<Scalars['String']['input']>;
  translation?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Translations_Stddev_Fields = {
  __typename?: 'translations_stddev_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "translations" */
export type Translations_Stddev_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Translations_Stddev_Pop_Fields = {
  __typename?: 'translations_stddev_pop_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "translations" */
export type Translations_Stddev_Pop_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Translations_Stddev_Samp_Fields = {
  __typename?: 'translations_stddev_samp_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "translations" */
export type Translations_Stddev_Samp_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "translations" */
export type Translations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Translations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Translations_Stream_Cursor_Value_Input = {
  content_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language_code?: InputMaybe<Scalars['String']['input']>;
  translation?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Translations_Sum_Fields = {
  __typename?: 'translations_sum_fields';
  content_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "translations" */
export type Translations_Sum_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "translations" */
export type Translations_Update_Column =
  /** column name */
  | 'content_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'language_code'
  /** column name */
  | 'translation'
  /** column name */
  | 'updated_at';

export type Translations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Translations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Translations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Translations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Translations_Var_Pop_Fields = {
  __typename?: 'translations_var_pop_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "translations" */
export type Translations_Var_Pop_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Translations_Var_Samp_Fields = {
  __typename?: 'translations_var_samp_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "translations" */
export type Translations_Var_Samp_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Translations_Variance_Fields = {
  __typename?: 'translations_variance_fields';
  content_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "translations" */
export type Translations_Variance_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_attachment_test_responses" */
export type User_Attachment_Test_Responses = {
  __typename?: 'user_attachment_test_responses';
  /** An object relationship */
  attachment_test: Attachment_Tests;
  attachment_test_id: Scalars['Int']['output'];
  /** An object relationship */
  attachment_test_response: Attachment_Test_Responses;
  attachment_test_response_id: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Aggregate = {
  __typename?: 'user_attachment_test_responses_aggregate';
  aggregate?: Maybe<User_Attachment_Test_Responses_Aggregate_Fields>;
  nodes: Array<User_Attachment_Test_Responses>;
};

export type User_Attachment_Test_Responses_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Attachment_Test_Responses_Aggregate_Bool_Exp_Count>;
};

export type User_Attachment_Test_Responses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Attachment_Test_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Attachment_Test_Responses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Aggregate_Fields = {
  __typename?: 'user_attachment_test_responses_aggregate_fields';
  avg?: Maybe<User_Attachment_Test_Responses_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Attachment_Test_Responses_Max_Fields>;
  min?: Maybe<User_Attachment_Test_Responses_Min_Fields>;
  stddev?: Maybe<User_Attachment_Test_Responses_Stddev_Fields>;
  stddev_pop?: Maybe<User_Attachment_Test_Responses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Attachment_Test_Responses_Stddev_Samp_Fields>;
  sum?: Maybe<User_Attachment_Test_Responses_Sum_Fields>;
  var_pop?: Maybe<User_Attachment_Test_Responses_Var_Pop_Fields>;
  var_samp?: Maybe<User_Attachment_Test_Responses_Var_Samp_Fields>;
  variance?: Maybe<User_Attachment_Test_Responses_Variance_Fields>;
};


/** aggregate fields of "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Attachment_Test_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Aggregate_Order_By = {
  avg?: InputMaybe<User_Attachment_Test_Responses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Attachment_Test_Responses_Max_Order_By>;
  min?: InputMaybe<User_Attachment_Test_Responses_Min_Order_By>;
  stddev?: InputMaybe<User_Attachment_Test_Responses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Attachment_Test_Responses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Attachment_Test_Responses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Attachment_Test_Responses_Sum_Order_By>;
  var_pop?: InputMaybe<User_Attachment_Test_Responses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Attachment_Test_Responses_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Attachment_Test_Responses_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Arr_Rel_Insert_Input = {
  data: Array<User_Attachment_Test_Responses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Attachment_Test_Responses_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Attachment_Test_Responses_Avg_Fields = {
  __typename?: 'user_attachment_test_responses_avg_fields';
  attachment_test_id?: Maybe<Scalars['Float']['output']>;
  attachment_test_response_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Avg_Order_By = {
  attachment_test_id?: InputMaybe<Order_By>;
  attachment_test_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_attachment_test_responses". All fields are combined with a logical 'AND'. */
export type User_Attachment_Test_Responses_Bool_Exp = {
  _and?: InputMaybe<Array<User_Attachment_Test_Responses_Bool_Exp>>;
  _not?: InputMaybe<User_Attachment_Test_Responses_Bool_Exp>;
  _or?: InputMaybe<Array<User_Attachment_Test_Responses_Bool_Exp>>;
  attachment_test?: InputMaybe<Attachment_Tests_Bool_Exp>;
  attachment_test_id?: InputMaybe<Int_Comparison_Exp>;
  attachment_test_response?: InputMaybe<Attachment_Test_Responses_Bool_Exp>;
  attachment_test_response_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'user_attachment_test_responses_pkey'
  /** unique or primary key constraint on columns "user_id", "attachment_test_id" */
  | 'user_attachment_test_responses_user_id_attachment_test_id_key';

/** input type for incrementing numeric columns in table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Inc_Input = {
  attachment_test_id?: InputMaybe<Scalars['Int']['input']>;
  attachment_test_response_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Insert_Input = {
  attachment_test?: InputMaybe<Attachment_Tests_Obj_Rel_Insert_Input>;
  attachment_test_id?: InputMaybe<Scalars['Int']['input']>;
  attachment_test_response?: InputMaybe<Attachment_Test_Responses_Obj_Rel_Insert_Input>;
  attachment_test_response_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type User_Attachment_Test_Responses_Max_Fields = {
  __typename?: 'user_attachment_test_responses_max_fields';
  attachment_test_id?: Maybe<Scalars['Int']['output']>;
  attachment_test_response_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Max_Order_By = {
  attachment_test_id?: InputMaybe<Order_By>;
  attachment_test_response_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Attachment_Test_Responses_Min_Fields = {
  __typename?: 'user_attachment_test_responses_min_fields';
  attachment_test_id?: Maybe<Scalars['Int']['output']>;
  attachment_test_response_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Min_Order_By = {
  attachment_test_id?: InputMaybe<Order_By>;
  attachment_test_response_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Mutation_Response = {
  __typename?: 'user_attachment_test_responses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Attachment_Test_Responses>;
};

/** on_conflict condition type for table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_On_Conflict = {
  constraint: User_Attachment_Test_Responses_Constraint;
  update_columns?: Array<User_Attachment_Test_Responses_Update_Column>;
  where?: InputMaybe<User_Attachment_Test_Responses_Bool_Exp>;
};

/** Ordering options when selecting data from "user_attachment_test_responses". */
export type User_Attachment_Test_Responses_Order_By = {
  attachment_test?: InputMaybe<Attachment_Tests_Order_By>;
  attachment_test_id?: InputMaybe<Order_By>;
  attachment_test_response?: InputMaybe<Attachment_Test_Responses_Order_By>;
  attachment_test_response_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_attachment_test_responses */
export type User_Attachment_Test_Responses_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Select_Column =
  /** column name */
  | 'attachment_test_id'
  /** column name */
  | 'attachment_test_response_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

/** input type for updating data in table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Set_Input = {
  attachment_test_id?: InputMaybe<Scalars['Int']['input']>;
  attachment_test_response_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type User_Attachment_Test_Responses_Stddev_Fields = {
  __typename?: 'user_attachment_test_responses_stddev_fields';
  attachment_test_id?: Maybe<Scalars['Float']['output']>;
  attachment_test_response_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Stddev_Order_By = {
  attachment_test_id?: InputMaybe<Order_By>;
  attachment_test_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Attachment_Test_Responses_Stddev_Pop_Fields = {
  __typename?: 'user_attachment_test_responses_stddev_pop_fields';
  attachment_test_id?: Maybe<Scalars['Float']['output']>;
  attachment_test_response_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Stddev_Pop_Order_By = {
  attachment_test_id?: InputMaybe<Order_By>;
  attachment_test_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Attachment_Test_Responses_Stddev_Samp_Fields = {
  __typename?: 'user_attachment_test_responses_stddev_samp_fields';
  attachment_test_id?: Maybe<Scalars['Float']['output']>;
  attachment_test_response_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Stddev_Samp_Order_By = {
  attachment_test_id?: InputMaybe<Order_By>;
  attachment_test_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Attachment_Test_Responses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Attachment_Test_Responses_Stream_Cursor_Value_Input = {
  attachment_test_id?: InputMaybe<Scalars['Int']['input']>;
  attachment_test_response_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type User_Attachment_Test_Responses_Sum_Fields = {
  __typename?: 'user_attachment_test_responses_sum_fields';
  attachment_test_id?: Maybe<Scalars['Int']['output']>;
  attachment_test_response_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Sum_Order_By = {
  attachment_test_id?: InputMaybe<Order_By>;
  attachment_test_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Update_Column =
  /** column name */
  | 'attachment_test_id'
  /** column name */
  | 'attachment_test_response_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

export type User_Attachment_Test_Responses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Attachment_Test_Responses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Attachment_Test_Responses_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Attachment_Test_Responses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Attachment_Test_Responses_Var_Pop_Fields = {
  __typename?: 'user_attachment_test_responses_var_pop_fields';
  attachment_test_id?: Maybe<Scalars['Float']['output']>;
  attachment_test_response_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Var_Pop_Order_By = {
  attachment_test_id?: InputMaybe<Order_By>;
  attachment_test_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Attachment_Test_Responses_Var_Samp_Fields = {
  __typename?: 'user_attachment_test_responses_var_samp_fields';
  attachment_test_id?: Maybe<Scalars['Float']['output']>;
  attachment_test_response_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Var_Samp_Order_By = {
  attachment_test_id?: InputMaybe<Order_By>;
  attachment_test_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Attachment_Test_Responses_Variance_Fields = {
  __typename?: 'user_attachment_test_responses_variance_fields';
  attachment_test_id?: Maybe<Scalars['Float']['output']>;
  attachment_test_response_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "user_attachment_test_responses" */
export type User_Attachment_Test_Responses_Variance_Order_By = {
  attachment_test_id?: InputMaybe<Order_By>;
  attachment_test_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_avatar_test_responses" */
export type User_Avatar_Test_Responses = {
  __typename?: 'user_avatar_test_responses';
  /** An object relationship */
  avatar_test: Avatar_Tests;
  avatar_test_id: Scalars['Int']['output'];
  /** An object relationship */
  avatar_test_response: Avatar_Test_Responses;
  avatar_test_response_id: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Aggregate = {
  __typename?: 'user_avatar_test_responses_aggregate';
  aggregate?: Maybe<User_Avatar_Test_Responses_Aggregate_Fields>;
  nodes: Array<User_Avatar_Test_Responses>;
};

export type User_Avatar_Test_Responses_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Avatar_Test_Responses_Aggregate_Bool_Exp_Count>;
};

export type User_Avatar_Test_Responses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Avatar_Test_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Avatar_Test_Responses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Aggregate_Fields = {
  __typename?: 'user_avatar_test_responses_aggregate_fields';
  avg?: Maybe<User_Avatar_Test_Responses_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Avatar_Test_Responses_Max_Fields>;
  min?: Maybe<User_Avatar_Test_Responses_Min_Fields>;
  stddev?: Maybe<User_Avatar_Test_Responses_Stddev_Fields>;
  stddev_pop?: Maybe<User_Avatar_Test_Responses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Avatar_Test_Responses_Stddev_Samp_Fields>;
  sum?: Maybe<User_Avatar_Test_Responses_Sum_Fields>;
  var_pop?: Maybe<User_Avatar_Test_Responses_Var_Pop_Fields>;
  var_samp?: Maybe<User_Avatar_Test_Responses_Var_Samp_Fields>;
  variance?: Maybe<User_Avatar_Test_Responses_Variance_Fields>;
};


/** aggregate fields of "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Avatar_Test_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Aggregate_Order_By = {
  avg?: InputMaybe<User_Avatar_Test_Responses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Avatar_Test_Responses_Max_Order_By>;
  min?: InputMaybe<User_Avatar_Test_Responses_Min_Order_By>;
  stddev?: InputMaybe<User_Avatar_Test_Responses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Avatar_Test_Responses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Avatar_Test_Responses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Avatar_Test_Responses_Sum_Order_By>;
  var_pop?: InputMaybe<User_Avatar_Test_Responses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Avatar_Test_Responses_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Avatar_Test_Responses_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Arr_Rel_Insert_Input = {
  data: Array<User_Avatar_Test_Responses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Avatar_Test_Responses_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Avatar_Test_Responses_Avg_Fields = {
  __typename?: 'user_avatar_test_responses_avg_fields';
  avatar_test_id?: Maybe<Scalars['Float']['output']>;
  avatar_test_response_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Avg_Order_By = {
  avatar_test_id?: InputMaybe<Order_By>;
  avatar_test_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_avatar_test_responses". All fields are combined with a logical 'AND'. */
export type User_Avatar_Test_Responses_Bool_Exp = {
  _and?: InputMaybe<Array<User_Avatar_Test_Responses_Bool_Exp>>;
  _not?: InputMaybe<User_Avatar_Test_Responses_Bool_Exp>;
  _or?: InputMaybe<Array<User_Avatar_Test_Responses_Bool_Exp>>;
  avatar_test?: InputMaybe<Avatar_Tests_Bool_Exp>;
  avatar_test_id?: InputMaybe<Int_Comparison_Exp>;
  avatar_test_response?: InputMaybe<Avatar_Test_Responses_Bool_Exp>;
  avatar_test_response_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'user_avatar_test_responses_pkey'
  /** unique or primary key constraint on columns "user_id", "avatar_test_response_id", "avatar_test_id" */
  | 'user_avatar_test_responses_user_id_avatar_test_id_avatar_test_r';

/** input type for incrementing numeric columns in table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Inc_Input = {
  avatar_test_id?: InputMaybe<Scalars['Int']['input']>;
  avatar_test_response_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Insert_Input = {
  avatar_test?: InputMaybe<Avatar_Tests_Obj_Rel_Insert_Input>;
  avatar_test_id?: InputMaybe<Scalars['Int']['input']>;
  avatar_test_response?: InputMaybe<Avatar_Test_Responses_Obj_Rel_Insert_Input>;
  avatar_test_response_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type User_Avatar_Test_Responses_Max_Fields = {
  __typename?: 'user_avatar_test_responses_max_fields';
  avatar_test_id?: Maybe<Scalars['Int']['output']>;
  avatar_test_response_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Max_Order_By = {
  avatar_test_id?: InputMaybe<Order_By>;
  avatar_test_response_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Avatar_Test_Responses_Min_Fields = {
  __typename?: 'user_avatar_test_responses_min_fields';
  avatar_test_id?: Maybe<Scalars['Int']['output']>;
  avatar_test_response_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Min_Order_By = {
  avatar_test_id?: InputMaybe<Order_By>;
  avatar_test_response_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Mutation_Response = {
  __typename?: 'user_avatar_test_responses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Avatar_Test_Responses>;
};

/** on_conflict condition type for table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_On_Conflict = {
  constraint: User_Avatar_Test_Responses_Constraint;
  update_columns?: Array<User_Avatar_Test_Responses_Update_Column>;
  where?: InputMaybe<User_Avatar_Test_Responses_Bool_Exp>;
};

/** Ordering options when selecting data from "user_avatar_test_responses". */
export type User_Avatar_Test_Responses_Order_By = {
  avatar_test?: InputMaybe<Avatar_Tests_Order_By>;
  avatar_test_id?: InputMaybe<Order_By>;
  avatar_test_response?: InputMaybe<Avatar_Test_Responses_Order_By>;
  avatar_test_response_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_avatar_test_responses */
export type User_Avatar_Test_Responses_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Select_Column =
  /** column name */
  | 'avatar_test_id'
  /** column name */
  | 'avatar_test_response_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

/** input type for updating data in table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Set_Input = {
  avatar_test_id?: InputMaybe<Scalars['Int']['input']>;
  avatar_test_response_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type User_Avatar_Test_Responses_Stddev_Fields = {
  __typename?: 'user_avatar_test_responses_stddev_fields';
  avatar_test_id?: Maybe<Scalars['Float']['output']>;
  avatar_test_response_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Stddev_Order_By = {
  avatar_test_id?: InputMaybe<Order_By>;
  avatar_test_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Avatar_Test_Responses_Stddev_Pop_Fields = {
  __typename?: 'user_avatar_test_responses_stddev_pop_fields';
  avatar_test_id?: Maybe<Scalars['Float']['output']>;
  avatar_test_response_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Stddev_Pop_Order_By = {
  avatar_test_id?: InputMaybe<Order_By>;
  avatar_test_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Avatar_Test_Responses_Stddev_Samp_Fields = {
  __typename?: 'user_avatar_test_responses_stddev_samp_fields';
  avatar_test_id?: Maybe<Scalars['Float']['output']>;
  avatar_test_response_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Stddev_Samp_Order_By = {
  avatar_test_id?: InputMaybe<Order_By>;
  avatar_test_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Avatar_Test_Responses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Avatar_Test_Responses_Stream_Cursor_Value_Input = {
  avatar_test_id?: InputMaybe<Scalars['Int']['input']>;
  avatar_test_response_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type User_Avatar_Test_Responses_Sum_Fields = {
  __typename?: 'user_avatar_test_responses_sum_fields';
  avatar_test_id?: Maybe<Scalars['Int']['output']>;
  avatar_test_response_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Sum_Order_By = {
  avatar_test_id?: InputMaybe<Order_By>;
  avatar_test_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Update_Column =
  /** column name */
  | 'avatar_test_id'
  /** column name */
  | 'avatar_test_response_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

export type User_Avatar_Test_Responses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Avatar_Test_Responses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Avatar_Test_Responses_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Avatar_Test_Responses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Avatar_Test_Responses_Var_Pop_Fields = {
  __typename?: 'user_avatar_test_responses_var_pop_fields';
  avatar_test_id?: Maybe<Scalars['Float']['output']>;
  avatar_test_response_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Var_Pop_Order_By = {
  avatar_test_id?: InputMaybe<Order_By>;
  avatar_test_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Avatar_Test_Responses_Var_Samp_Fields = {
  __typename?: 'user_avatar_test_responses_var_samp_fields';
  avatar_test_id?: Maybe<Scalars['Float']['output']>;
  avatar_test_response_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Var_Samp_Order_By = {
  avatar_test_id?: InputMaybe<Order_By>;
  avatar_test_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Avatar_Test_Responses_Variance_Fields = {
  __typename?: 'user_avatar_test_responses_variance_fields';
  avatar_test_id?: Maybe<Scalars['Float']['output']>;
  avatar_test_response_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "user_avatar_test_responses" */
export type User_Avatar_Test_Responses_Variance_Order_By = {
  avatar_test_id?: InputMaybe<Order_By>;
  avatar_test_response_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_matric_question_responses" */
export type User_Matric_Question_Responses = {
  __typename?: 'user_matric_question_responses';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  matric_question: Matric_Questions;
  matric_question_id: Scalars['Int']['output'];
  /** An object relationship */
  matric_question_response: Matric_Question_Responses;
  matric_question_response_id: Scalars['Int']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "user_matric_question_responses" */
export type User_Matric_Question_Responses_Aggregate = {
  __typename?: 'user_matric_question_responses_aggregate';
  aggregate?: Maybe<User_Matric_Question_Responses_Aggregate_Fields>;
  nodes: Array<User_Matric_Question_Responses>;
};

export type User_Matric_Question_Responses_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Matric_Question_Responses_Aggregate_Bool_Exp_Count>;
};

export type User_Matric_Question_Responses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Matric_Question_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Matric_Question_Responses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_matric_question_responses" */
export type User_Matric_Question_Responses_Aggregate_Fields = {
  __typename?: 'user_matric_question_responses_aggregate_fields';
  avg?: Maybe<User_Matric_Question_Responses_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Matric_Question_Responses_Max_Fields>;
  min?: Maybe<User_Matric_Question_Responses_Min_Fields>;
  stddev?: Maybe<User_Matric_Question_Responses_Stddev_Fields>;
  stddev_pop?: Maybe<User_Matric_Question_Responses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Matric_Question_Responses_Stddev_Samp_Fields>;
  sum?: Maybe<User_Matric_Question_Responses_Sum_Fields>;
  var_pop?: Maybe<User_Matric_Question_Responses_Var_Pop_Fields>;
  var_samp?: Maybe<User_Matric_Question_Responses_Var_Samp_Fields>;
  variance?: Maybe<User_Matric_Question_Responses_Variance_Fields>;
};


/** aggregate fields of "user_matric_question_responses" */
export type User_Matric_Question_Responses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Matric_Question_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_matric_question_responses" */
export type User_Matric_Question_Responses_Aggregate_Order_By = {
  avg?: InputMaybe<User_Matric_Question_Responses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Matric_Question_Responses_Max_Order_By>;
  min?: InputMaybe<User_Matric_Question_Responses_Min_Order_By>;
  stddev?: InputMaybe<User_Matric_Question_Responses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Matric_Question_Responses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Matric_Question_Responses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Matric_Question_Responses_Sum_Order_By>;
  var_pop?: InputMaybe<User_Matric_Question_Responses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Matric_Question_Responses_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Matric_Question_Responses_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_matric_question_responses" */
export type User_Matric_Question_Responses_Arr_Rel_Insert_Input = {
  data: Array<User_Matric_Question_Responses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Matric_Question_Responses_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Matric_Question_Responses_Avg_Fields = {
  __typename?: 'user_matric_question_responses_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_id?: Maybe<Scalars['Float']['output']>;
  matric_question_response_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "user_matric_question_responses" */
export type User_Matric_Question_Responses_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  matric_question_response_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_matric_question_responses". All fields are combined with a logical 'AND'. */
export type User_Matric_Question_Responses_Bool_Exp = {
  _and?: InputMaybe<Array<User_Matric_Question_Responses_Bool_Exp>>;
  _not?: InputMaybe<User_Matric_Question_Responses_Bool_Exp>;
  _or?: InputMaybe<Array<User_Matric_Question_Responses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  matric_question?: InputMaybe<Matric_Questions_Bool_Exp>;
  matric_question_id?: InputMaybe<Int_Comparison_Exp>;
  matric_question_response?: InputMaybe<Matric_Question_Responses_Bool_Exp>;
  matric_question_response_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_matric_question_responses" */
export type User_Matric_Question_Responses_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'user_matric_question_responses_pkey'
  /** unique or primary key constraint on columns "matric_question_id", "user_id" */
  | 'user_matric_question_responses_user_id_matric_question_id_key';

/** input type for incrementing numeric columns in table "user_matric_question_responses" */
export type User_Matric_Question_Responses_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  matric_question_id?: InputMaybe<Scalars['Int']['input']>;
  matric_question_response_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user_matric_question_responses" */
export type User_Matric_Question_Responses_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  matric_question?: InputMaybe<Matric_Questions_Obj_Rel_Insert_Input>;
  matric_question_id?: InputMaybe<Scalars['Int']['input']>;
  matric_question_response?: InputMaybe<Matric_Question_Responses_Obj_Rel_Insert_Input>;
  matric_question_response_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type User_Matric_Question_Responses_Max_Fields = {
  __typename?: 'user_matric_question_responses_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  matric_question_id?: Maybe<Scalars['Int']['output']>;
  matric_question_response_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "user_matric_question_responses" */
export type User_Matric_Question_Responses_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  matric_question_response_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Matric_Question_Responses_Min_Fields = {
  __typename?: 'user_matric_question_responses_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  matric_question_id?: Maybe<Scalars['Int']['output']>;
  matric_question_response_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "user_matric_question_responses" */
export type User_Matric_Question_Responses_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  matric_question_response_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_matric_question_responses" */
export type User_Matric_Question_Responses_Mutation_Response = {
  __typename?: 'user_matric_question_responses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Matric_Question_Responses>;
};

/** on_conflict condition type for table "user_matric_question_responses" */
export type User_Matric_Question_Responses_On_Conflict = {
  constraint: User_Matric_Question_Responses_Constraint;
  update_columns?: Array<User_Matric_Question_Responses_Update_Column>;
  where?: InputMaybe<User_Matric_Question_Responses_Bool_Exp>;
};

/** Ordering options when selecting data from "user_matric_question_responses". */
export type User_Matric_Question_Responses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  matric_question?: InputMaybe<Matric_Questions_Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  matric_question_response?: InputMaybe<Matric_Question_Responses_Order_By>;
  matric_question_response_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_matric_question_responses */
export type User_Matric_Question_Responses_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "user_matric_question_responses" */
export type User_Matric_Question_Responses_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'matric_question_id'
  /** column name */
  | 'matric_question_response_id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

/** input type for updating data in table "user_matric_question_responses" */
export type User_Matric_Question_Responses_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  matric_question_id?: InputMaybe<Scalars['Int']['input']>;
  matric_question_response_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type User_Matric_Question_Responses_Stddev_Fields = {
  __typename?: 'user_matric_question_responses_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_id?: Maybe<Scalars['Float']['output']>;
  matric_question_response_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "user_matric_question_responses" */
export type User_Matric_Question_Responses_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  matric_question_response_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Matric_Question_Responses_Stddev_Pop_Fields = {
  __typename?: 'user_matric_question_responses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_id?: Maybe<Scalars['Float']['output']>;
  matric_question_response_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "user_matric_question_responses" */
export type User_Matric_Question_Responses_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  matric_question_response_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Matric_Question_Responses_Stddev_Samp_Fields = {
  __typename?: 'user_matric_question_responses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_id?: Maybe<Scalars['Float']['output']>;
  matric_question_response_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "user_matric_question_responses" */
export type User_Matric_Question_Responses_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  matric_question_response_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_matric_question_responses" */
export type User_Matric_Question_Responses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Matric_Question_Responses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Matric_Question_Responses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  matric_question_id?: InputMaybe<Scalars['Int']['input']>;
  matric_question_response_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type User_Matric_Question_Responses_Sum_Fields = {
  __typename?: 'user_matric_question_responses_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  matric_question_id?: Maybe<Scalars['Int']['output']>;
  matric_question_response_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "user_matric_question_responses" */
export type User_Matric_Question_Responses_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  matric_question_response_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "user_matric_question_responses" */
export type User_Matric_Question_Responses_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'id'
  /** column name */
  | 'matric_question_id'
  /** column name */
  | 'matric_question_response_id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

export type User_Matric_Question_Responses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Matric_Question_Responses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Matric_Question_Responses_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Matric_Question_Responses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Matric_Question_Responses_Var_Pop_Fields = {
  __typename?: 'user_matric_question_responses_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_id?: Maybe<Scalars['Float']['output']>;
  matric_question_response_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "user_matric_question_responses" */
export type User_Matric_Question_Responses_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  matric_question_response_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Matric_Question_Responses_Var_Samp_Fields = {
  __typename?: 'user_matric_question_responses_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_id?: Maybe<Scalars['Float']['output']>;
  matric_question_response_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "user_matric_question_responses" */
export type User_Matric_Question_Responses_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  matric_question_response_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Matric_Question_Responses_Variance_Fields = {
  __typename?: 'user_matric_question_responses_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  matric_question_id?: Maybe<Scalars['Float']['output']>;
  matric_question_response_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "user_matric_question_responses" */
export type User_Matric_Question_Responses_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  matric_question_id?: InputMaybe<Order_By>;
  matric_question_response_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "user_role". All fields are combined with logical 'AND'. */
export type User_Role_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['user_role']['input']>;
  _gt?: InputMaybe<Scalars['user_role']['input']>;
  _gte?: InputMaybe<Scalars['user_role']['input']>;
  _in?: InputMaybe<Array<Scalars['user_role']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['user_role']['input']>;
  _lte?: InputMaybe<Scalars['user_role']['input']>;
  _neq?: InputMaybe<Scalars['user_role']['input']>;
  _nin?: InputMaybe<Array<Scalars['user_role']['input']>>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An array relationship */
  availability_time_slots: Array<Availability_Time_Slots>;
  /** An aggregate relationship */
  availability_time_slots_aggregate: Availability_Time_Slots_Aggregate;
  avatar_url?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  channel_users: Array<Channel_Users>;
  /** An aggregate relationship */
  channel_users_aggregate: Channel_Users_Aggregate;
  /** An array relationship */
  channels: Array<Channels>;
  /** An aggregate relationship */
  channels_aggregate: Channels_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  date_of_birth?: Maybe<Scalars['date']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  devices: Array<Device_Tokens>;
  /** An aggregate relationship */
  devices_aggregate: Device_Tokens_Aggregate;
  email: Scalars['String']['output'];
  full_name?: Maybe<Scalars['String']['output']>;
  hourly_rate?: Maybe<Scalars['numeric']['output']>;
  id: Scalars['Int']['output'];
  /** An array relationship */
  in_requests: Array<Friend_Requests>;
  /** An aggregate relationship */
  in_requests_aggregate: Friend_Requests_Aggregate;
  is_active: Scalars['Boolean']['output'];
  is_stripe_account_ready: Scalars['Boolean']['output'];
  /** An array relationship */
  messages: Array<Messages>;
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate;
  mobile?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  my_friends: Array<Friends>;
  /** An aggregate relationship */
  my_friends_aggregate: Friends_Aggregate;
  /** An object relationship */
  otp?: Maybe<Otps>;
  /** An array relationship */
  out_requests: Array<Friend_Requests>;
  /** An aggregate relationship */
  out_requests_aggregate: Friend_Requests_Aggregate;
  password: Scalars['String']['output'];
  /** An object relationship */
  password_reset_token?: Maybe<Password_Reset_Tokens>;
  /** An array relationship */
  personal_suns: Array<Personal_Suns>;
  /** An aggregate relationship */
  personal_suns_aggregate: Personal_Suns_Aggregate;
  /** An object relationship */
  profile?: Maybe<Profiles>;
  promotional_credit?: Maybe<Scalars['numeric']['output']>;
  /** An array relationship */
  reservation_payments: Array<Reservation_Payments>;
  /** An aggregate relationship */
  reservation_payments_aggregate: Reservation_Payments_Aggregate;
  /** An array relationship */
  reservations: Array<Reservations>;
  /** An array relationship */
  reservationsByReleaser: Array<Reservations>;
  /** An aggregate relationship */
  reservationsByReleaser_aggregate: Reservations_Aggregate;
  /** An array relationship */
  reservationsByUserId: Array<Reservations>;
  /** An aggregate relationship */
  reservationsByUserId_aggregate: Reservations_Aggregate;
  /** An aggregate relationship */
  reservations_aggregate: Reservations_Aggregate;
  revenuecat_currency?: Maybe<Scalars['String']['output']>;
  revenuecat_expiration_at?: Maybe<Scalars['timestamptz']['output']>;
  revenuecat_incoming_product_id?: Maybe<Scalars['String']['output']>;
  revenuecat_price_in_purchased_currency?: Maybe<Scalars['Int']['output']>;
  revenuecat_product_id?: Maybe<Scalars['String']['output']>;
  role: Scalars['user_role']['output'];
  skills?: Maybe<Array<Scalars['String']['output']>>;
  skype?: Maybe<Scalars['String']['output']>;
  stripe_account_id?: Maybe<Scalars['String']['output']>;
  stripe_customer_id?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  subscriptions: Array<Subscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscriptions_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  user_attachment_test_responses: Array<User_Attachment_Test_Responses>;
  /** An aggregate relationship */
  user_attachment_test_responses_aggregate: User_Attachment_Test_Responses_Aggregate;
  /** An array relationship */
  user_avatar_test_responses: Array<User_Avatar_Test_Responses>;
  /** An aggregate relationship */
  user_avatar_test_responses_aggregate: User_Avatar_Test_Responses_Aggregate;
  /** An array relationship */
  user_matric_question_responses: Array<User_Matric_Question_Responses>;
  /** An aggregate relationship */
  user_matric_question_responses_aggregate: User_Matric_Question_Responses_Aggregate;
  work_phone?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "users" */
export type UsersAvailability_Time_SlotsArgs = {
  distinct_on?: InputMaybe<Array<Availability_Time_Slots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Availability_Time_Slots_Order_By>>;
  where?: InputMaybe<Availability_Time_Slots_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersAvailability_Time_Slots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Availability_Time_Slots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Availability_Time_Slots_Order_By>>;
  where?: InputMaybe<Availability_Time_Slots_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersChannel_UsersArgs = {
  distinct_on?: InputMaybe<Array<Channel_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channel_Users_Order_By>>;
  where?: InputMaybe<Channel_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersChannel_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channel_Users_Order_By>>;
  where?: InputMaybe<Channel_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersChannelsArgs = {
  distinct_on?: InputMaybe<Array<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersChannels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersDevicesArgs = {
  distinct_on?: InputMaybe<Array<Device_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Device_Tokens_Order_By>>;
  where?: InputMaybe<Device_Tokens_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Device_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Device_Tokens_Order_By>>;
  where?: InputMaybe<Device_Tokens_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersIn_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Friend_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Friend_Requests_Order_By>>;
  where?: InputMaybe<Friend_Requests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersIn_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Friend_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Friend_Requests_Order_By>>;
  where?: InputMaybe<Friend_Requests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersMessagesArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersMy_FriendsArgs = {
  distinct_on?: InputMaybe<Array<Friends_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Friends_Order_By>>;
  where?: InputMaybe<Friends_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersMy_Friends_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Friends_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Friends_Order_By>>;
  where?: InputMaybe<Friends_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersOut_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Friend_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Friend_Requests_Order_By>>;
  where?: InputMaybe<Friend_Requests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersOut_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Friend_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Friend_Requests_Order_By>>;
  where?: InputMaybe<Friend_Requests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPersonal_SunsArgs = {
  distinct_on?: InputMaybe<Array<Personal_Suns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Personal_Suns_Order_By>>;
  where?: InputMaybe<Personal_Suns_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPersonal_Suns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Personal_Suns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Personal_Suns_Order_By>>;
  where?: InputMaybe<Personal_Suns_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersReservation_PaymentsArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reservation_Payments_Order_By>>;
  where?: InputMaybe<Reservation_Payments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersReservation_Payments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservation_Payments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reservation_Payments_Order_By>>;
  where?: InputMaybe<Reservation_Payments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersReservationsArgs = {
  distinct_on?: InputMaybe<Array<Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reservations_Order_By>>;
  where?: InputMaybe<Reservations_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersReservationsByReleaserArgs = {
  distinct_on?: InputMaybe<Array<Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reservations_Order_By>>;
  where?: InputMaybe<Reservations_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersReservationsByReleaser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reservations_Order_By>>;
  where?: InputMaybe<Reservations_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersReservationsByUserIdArgs = {
  distinct_on?: InputMaybe<Array<Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reservations_Order_By>>;
  where?: InputMaybe<Reservations_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersReservationsByUserId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reservations_Order_By>>;
  where?: InputMaybe<Reservations_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersReservations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reservations_Order_By>>;
  where?: InputMaybe<Reservations_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Attachment_Test_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<User_Attachment_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Attachment_Test_Responses_Order_By>>;
  where?: InputMaybe<User_Attachment_Test_Responses_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Attachment_Test_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Attachment_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Attachment_Test_Responses_Order_By>>;
  where?: InputMaybe<User_Attachment_Test_Responses_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Avatar_Test_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Avatar_Test_Responses_Order_By>>;
  where?: InputMaybe<User_Avatar_Test_Responses_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Avatar_Test_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Avatar_Test_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Avatar_Test_Responses_Order_By>>;
  where?: InputMaybe<User_Avatar_Test_Responses_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Matric_Question_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<User_Matric_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Matric_Question_Responses_Order_By>>;
  where?: InputMaybe<User_Matric_Question_Responses_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Matric_Question_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Matric_Question_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Matric_Question_Responses_Order_By>>;
  where?: InputMaybe<User_Matric_Question_Responses_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  hourly_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  promotional_credit?: Maybe<Scalars['Float']['output']>;
  revenuecat_price_in_purchased_currency?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  availability_time_slots?: InputMaybe<Availability_Time_Slots_Bool_Exp>;
  availability_time_slots_aggregate?: InputMaybe<Availability_Time_Slots_Aggregate_Bool_Exp>;
  avatar_url?: InputMaybe<String_Comparison_Exp>;
  channel_users?: InputMaybe<Channel_Users_Bool_Exp>;
  channel_users_aggregate?: InputMaybe<Channel_Users_Aggregate_Bool_Exp>;
  channels?: InputMaybe<Channels_Bool_Exp>;
  channels_aggregate?: InputMaybe<Channels_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  date_of_birth?: InputMaybe<Date_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  devices?: InputMaybe<Device_Tokens_Bool_Exp>;
  devices_aggregate?: InputMaybe<Device_Tokens_Aggregate_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  hourly_rate?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  in_requests?: InputMaybe<Friend_Requests_Bool_Exp>;
  in_requests_aggregate?: InputMaybe<Friend_Requests_Aggregate_Bool_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_stripe_account_ready?: InputMaybe<Boolean_Comparison_Exp>;
  messages?: InputMaybe<Messages_Bool_Exp>;
  messages_aggregate?: InputMaybe<Messages_Aggregate_Bool_Exp>;
  mobile?: InputMaybe<String_Comparison_Exp>;
  my_friends?: InputMaybe<Friends_Bool_Exp>;
  my_friends_aggregate?: InputMaybe<Friends_Aggregate_Bool_Exp>;
  otp?: InputMaybe<Otps_Bool_Exp>;
  out_requests?: InputMaybe<Friend_Requests_Bool_Exp>;
  out_requests_aggregate?: InputMaybe<Friend_Requests_Aggregate_Bool_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  password_reset_token?: InputMaybe<Password_Reset_Tokens_Bool_Exp>;
  personal_suns?: InputMaybe<Personal_Suns_Bool_Exp>;
  personal_suns_aggregate?: InputMaybe<Personal_Suns_Aggregate_Bool_Exp>;
  profile?: InputMaybe<Profiles_Bool_Exp>;
  promotional_credit?: InputMaybe<Numeric_Comparison_Exp>;
  reservation_payments?: InputMaybe<Reservation_Payments_Bool_Exp>;
  reservation_payments_aggregate?: InputMaybe<Reservation_Payments_Aggregate_Bool_Exp>;
  reservations?: InputMaybe<Reservations_Bool_Exp>;
  reservationsByReleaser?: InputMaybe<Reservations_Bool_Exp>;
  reservationsByReleaser_aggregate?: InputMaybe<Reservations_Aggregate_Bool_Exp>;
  reservationsByUserId?: InputMaybe<Reservations_Bool_Exp>;
  reservationsByUserId_aggregate?: InputMaybe<Reservations_Aggregate_Bool_Exp>;
  reservations_aggregate?: InputMaybe<Reservations_Aggregate_Bool_Exp>;
  revenuecat_currency?: InputMaybe<String_Comparison_Exp>;
  revenuecat_expiration_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  revenuecat_incoming_product_id?: InputMaybe<String_Comparison_Exp>;
  revenuecat_price_in_purchased_currency?: InputMaybe<Int_Comparison_Exp>;
  revenuecat_product_id?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<User_Role_Comparison_Exp>;
  skills?: InputMaybe<String_Array_Comparison_Exp>;
  skype?: InputMaybe<String_Comparison_Exp>;
  stripe_account_id?: InputMaybe<String_Comparison_Exp>;
  stripe_customer_id?: InputMaybe<String_Comparison_Exp>;
  subscriptions?: InputMaybe<Subscriptions_Bool_Exp>;
  subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_attachment_test_responses?: InputMaybe<User_Attachment_Test_Responses_Bool_Exp>;
  user_attachment_test_responses_aggregate?: InputMaybe<User_Attachment_Test_Responses_Aggregate_Bool_Exp>;
  user_avatar_test_responses?: InputMaybe<User_Avatar_Test_Responses_Bool_Exp>;
  user_avatar_test_responses_aggregate?: InputMaybe<User_Avatar_Test_Responses_Aggregate_Bool_Exp>;
  user_matric_question_responses?: InputMaybe<User_Matric_Question_Responses_Bool_Exp>;
  user_matric_question_responses_aggregate?: InputMaybe<User_Matric_Question_Responses_Aggregate_Bool_Exp>;
  work_phone?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export type Users_Constraint =
  /** unique or primary key constraint on columns "email" */
  | 'users_email_unique'
  /** unique or primary key constraint on columns "mobile" */
  | 'users_mobile_unique'
  /** unique or primary key constraint on columns "id" */
  | 'users_pkey';

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  hourly_rate?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  promotional_credit?: InputMaybe<Scalars['numeric']['input']>;
  revenuecat_price_in_purchased_currency?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  availability_time_slots?: InputMaybe<Availability_Time_Slots_Arr_Rel_Insert_Input>;
  avatar_url?: InputMaybe<Scalars['String']['input']>;
  channel_users?: InputMaybe<Channel_Users_Arr_Rel_Insert_Input>;
  channels?: InputMaybe<Channels_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  date_of_birth?: InputMaybe<Scalars['date']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  devices?: InputMaybe<Device_Tokens_Arr_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  hourly_rate?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  in_requests?: InputMaybe<Friend_Requests_Arr_Rel_Insert_Input>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_stripe_account_ready?: InputMaybe<Scalars['Boolean']['input']>;
  messages?: InputMaybe<Messages_Arr_Rel_Insert_Input>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  my_friends?: InputMaybe<Friends_Arr_Rel_Insert_Input>;
  otp?: InputMaybe<Otps_Obj_Rel_Insert_Input>;
  out_requests?: InputMaybe<Friend_Requests_Arr_Rel_Insert_Input>;
  password?: InputMaybe<Scalars['String']['input']>;
  password_reset_token?: InputMaybe<Password_Reset_Tokens_Obj_Rel_Insert_Input>;
  personal_suns?: InputMaybe<Personal_Suns_Arr_Rel_Insert_Input>;
  profile?: InputMaybe<Profiles_Obj_Rel_Insert_Input>;
  promotional_credit?: InputMaybe<Scalars['numeric']['input']>;
  reservation_payments?: InputMaybe<Reservation_Payments_Arr_Rel_Insert_Input>;
  reservations?: InputMaybe<Reservations_Arr_Rel_Insert_Input>;
  reservationsByReleaser?: InputMaybe<Reservations_Arr_Rel_Insert_Input>;
  reservationsByUserId?: InputMaybe<Reservations_Arr_Rel_Insert_Input>;
  revenuecat_currency?: InputMaybe<Scalars['String']['input']>;
  revenuecat_expiration_at?: InputMaybe<Scalars['timestamptz']['input']>;
  revenuecat_incoming_product_id?: InputMaybe<Scalars['String']['input']>;
  revenuecat_price_in_purchased_currency?: InputMaybe<Scalars['Int']['input']>;
  revenuecat_product_id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['user_role']['input']>;
  skills?: InputMaybe<Array<Scalars['String']['input']>>;
  skype?: InputMaybe<Scalars['String']['input']>;
  stripe_account_id?: InputMaybe<Scalars['String']['input']>;
  stripe_customer_id?: InputMaybe<Scalars['String']['input']>;
  subscriptions?: InputMaybe<Subscriptions_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_attachment_test_responses?: InputMaybe<User_Attachment_Test_Responses_Arr_Rel_Insert_Input>;
  user_avatar_test_responses?: InputMaybe<User_Avatar_Test_Responses_Arr_Rel_Insert_Input>;
  user_matric_question_responses?: InputMaybe<User_Matric_Question_Responses_Arr_Rel_Insert_Input>;
  work_phone?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  avatar_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  date_of_birth?: Maybe<Scalars['date']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  hourly_rate?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  promotional_credit?: Maybe<Scalars['numeric']['output']>;
  revenuecat_currency?: Maybe<Scalars['String']['output']>;
  revenuecat_expiration_at?: Maybe<Scalars['timestamptz']['output']>;
  revenuecat_incoming_product_id?: Maybe<Scalars['String']['output']>;
  revenuecat_price_in_purchased_currency?: Maybe<Scalars['Int']['output']>;
  revenuecat_product_id?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['user_role']['output']>;
  skills?: Maybe<Array<Scalars['String']['output']>>;
  skype?: Maybe<Scalars['String']['output']>;
  stripe_account_id?: Maybe<Scalars['String']['output']>;
  stripe_customer_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  work_phone?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  avatar_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  date_of_birth?: Maybe<Scalars['date']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  hourly_rate?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  promotional_credit?: Maybe<Scalars['numeric']['output']>;
  revenuecat_currency?: Maybe<Scalars['String']['output']>;
  revenuecat_expiration_at?: Maybe<Scalars['timestamptz']['output']>;
  revenuecat_incoming_product_id?: Maybe<Scalars['String']['output']>;
  revenuecat_price_in_purchased_currency?: Maybe<Scalars['Int']['output']>;
  revenuecat_product_id?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['user_role']['output']>;
  skills?: Maybe<Array<Scalars['String']['output']>>;
  skype?: Maybe<Scalars['String']['output']>;
  stripe_account_id?: Maybe<Scalars['String']['output']>;
  stripe_customer_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  work_phone?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  availability_time_slots_aggregate?: InputMaybe<Availability_Time_Slots_Aggregate_Order_By>;
  avatar_url?: InputMaybe<Order_By>;
  channel_users_aggregate?: InputMaybe<Channel_Users_Aggregate_Order_By>;
  channels_aggregate?: InputMaybe<Channels_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  devices_aggregate?: InputMaybe<Device_Tokens_Aggregate_Order_By>;
  email?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  hourly_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  in_requests_aggregate?: InputMaybe<Friend_Requests_Aggregate_Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_stripe_account_ready?: InputMaybe<Order_By>;
  messages_aggregate?: InputMaybe<Messages_Aggregate_Order_By>;
  mobile?: InputMaybe<Order_By>;
  my_friends_aggregate?: InputMaybe<Friends_Aggregate_Order_By>;
  otp?: InputMaybe<Otps_Order_By>;
  out_requests_aggregate?: InputMaybe<Friend_Requests_Aggregate_Order_By>;
  password?: InputMaybe<Order_By>;
  password_reset_token?: InputMaybe<Password_Reset_Tokens_Order_By>;
  personal_suns_aggregate?: InputMaybe<Personal_Suns_Aggregate_Order_By>;
  profile?: InputMaybe<Profiles_Order_By>;
  promotional_credit?: InputMaybe<Order_By>;
  reservation_payments_aggregate?: InputMaybe<Reservation_Payments_Aggregate_Order_By>;
  reservationsByReleaser_aggregate?: InputMaybe<Reservations_Aggregate_Order_By>;
  reservationsByUserId_aggregate?: InputMaybe<Reservations_Aggregate_Order_By>;
  reservations_aggregate?: InputMaybe<Reservations_Aggregate_Order_By>;
  revenuecat_currency?: InputMaybe<Order_By>;
  revenuecat_expiration_at?: InputMaybe<Order_By>;
  revenuecat_incoming_product_id?: InputMaybe<Order_By>;
  revenuecat_price_in_purchased_currency?: InputMaybe<Order_By>;
  revenuecat_product_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  skills?: InputMaybe<Order_By>;
  skype?: InputMaybe<Order_By>;
  stripe_account_id?: InputMaybe<Order_By>;
  stripe_customer_id?: InputMaybe<Order_By>;
  subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_attachment_test_responses_aggregate?: InputMaybe<User_Attachment_Test_Responses_Aggregate_Order_By>;
  user_avatar_test_responses_aggregate?: InputMaybe<User_Avatar_Test_Responses_Aggregate_Order_By>;
  user_matric_question_responses_aggregate?: InputMaybe<User_Matric_Question_Responses_Aggregate_Order_By>;
  work_phone?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "users" */
export type Users_Select_Column =
  /** column name */
  | 'avatar_url'
  /** column name */
  | 'created_at'
  /** column name */
  | 'currency'
  /** column name */
  | 'date_of_birth'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'email'
  /** column name */
  | 'full_name'
  /** column name */
  | 'hourly_rate'
  /** column name */
  | 'id'
  /** column name */
  | 'is_active'
  /** column name */
  | 'is_stripe_account_ready'
  /** column name */
  | 'mobile'
  /** column name */
  | 'password'
  /** column name */
  | 'promotional_credit'
  /** column name */
  | 'revenuecat_currency'
  /** column name */
  | 'revenuecat_expiration_at'
  /** column name */
  | 'revenuecat_incoming_product_id'
  /** column name */
  | 'revenuecat_price_in_purchased_currency'
  /** column name */
  | 'revenuecat_product_id'
  /** column name */
  | 'role'
  /** column name */
  | 'skills'
  /** column name */
  | 'skype'
  /** column name */
  | 'stripe_account_id'
  /** column name */
  | 'stripe_customer_id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'work_phone';

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  avatar_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  date_of_birth?: InputMaybe<Scalars['date']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  hourly_rate?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_stripe_account_ready?: InputMaybe<Scalars['Boolean']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  promotional_credit?: InputMaybe<Scalars['numeric']['input']>;
  revenuecat_currency?: InputMaybe<Scalars['String']['input']>;
  revenuecat_expiration_at?: InputMaybe<Scalars['timestamptz']['input']>;
  revenuecat_incoming_product_id?: InputMaybe<Scalars['String']['input']>;
  revenuecat_price_in_purchased_currency?: InputMaybe<Scalars['Int']['input']>;
  revenuecat_product_id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['user_role']['input']>;
  skills?: InputMaybe<Array<Scalars['String']['input']>>;
  skype?: InputMaybe<Scalars['String']['input']>;
  stripe_account_id?: InputMaybe<Scalars['String']['input']>;
  stripe_customer_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_phone?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  hourly_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  promotional_credit?: Maybe<Scalars['Float']['output']>;
  revenuecat_price_in_purchased_currency?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  hourly_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  promotional_credit?: Maybe<Scalars['Float']['output']>;
  revenuecat_price_in_purchased_currency?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  hourly_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  promotional_credit?: Maybe<Scalars['Float']['output']>;
  revenuecat_price_in_purchased_currency?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  avatar_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  date_of_birth?: InputMaybe<Scalars['date']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  hourly_rate?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_stripe_account_ready?: InputMaybe<Scalars['Boolean']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  promotional_credit?: InputMaybe<Scalars['numeric']['input']>;
  revenuecat_currency?: InputMaybe<Scalars['String']['input']>;
  revenuecat_expiration_at?: InputMaybe<Scalars['timestamptz']['input']>;
  revenuecat_incoming_product_id?: InputMaybe<Scalars['String']['input']>;
  revenuecat_price_in_purchased_currency?: InputMaybe<Scalars['Int']['input']>;
  revenuecat_product_id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['user_role']['input']>;
  skills?: InputMaybe<Array<Scalars['String']['input']>>;
  skype?: InputMaybe<Scalars['String']['input']>;
  stripe_account_id?: InputMaybe<Scalars['String']['input']>;
  stripe_customer_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  work_phone?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  hourly_rate?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  promotional_credit?: Maybe<Scalars['numeric']['output']>;
  revenuecat_price_in_purchased_currency?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "users" */
export type Users_Update_Column =
  /** column name */
  | 'avatar_url'
  /** column name */
  | 'created_at'
  /** column name */
  | 'currency'
  /** column name */
  | 'date_of_birth'
  /** column name */
  | 'deleted_at'
  /** column name */
  | 'email'
  /** column name */
  | 'full_name'
  /** column name */
  | 'hourly_rate'
  /** column name */
  | 'id'
  /** column name */
  | 'is_active'
  /** column name */
  | 'is_stripe_account_ready'
  /** column name */
  | 'mobile'
  /** column name */
  | 'password'
  /** column name */
  | 'promotional_credit'
  /** column name */
  | 'revenuecat_currency'
  /** column name */
  | 'revenuecat_expiration_at'
  /** column name */
  | 'revenuecat_incoming_product_id'
  /** column name */
  | 'revenuecat_price_in_purchased_currency'
  /** column name */
  | 'revenuecat_product_id'
  /** column name */
  | 'role'
  /** column name */
  | 'skills'
  /** column name */
  | 'skype'
  /** column name */
  | 'stripe_account_id'
  /** column name */
  | 'stripe_customer_id'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'work_phone';

export type Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  hourly_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  promotional_credit?: Maybe<Scalars['Float']['output']>;
  revenuecat_price_in_purchased_currency?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  hourly_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  promotional_credit?: Maybe<Scalars['Float']['output']>;
  revenuecat_price_in_purchased_currency?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  hourly_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  promotional_credit?: Maybe<Scalars['Float']['output']>;
  revenuecat_price_in_purchased_currency?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "voucher_status_enum". All fields are combined with logical 'AND'. */
export type Voucher_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['voucher_status_enum']['input']>;
  _gt?: InputMaybe<Scalars['voucher_status_enum']['input']>;
  _gte?: InputMaybe<Scalars['voucher_status_enum']['input']>;
  _in?: InputMaybe<Array<Scalars['voucher_status_enum']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['voucher_status_enum']['input']>;
  _lte?: InputMaybe<Scalars['voucher_status_enum']['input']>;
  _neq?: InputMaybe<Scalars['voucher_status_enum']['input']>;
  _nin?: InputMaybe<Array<Scalars['voucher_status_enum']['input']>>;
};

/** columns and relationships of "vouchers" */
export type Vouchers = {
  __typename?: 'vouchers';
  code: Scalars['String']['output'];
  created_at?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount_amount: Scalars['numeric']['output'];
  expiry_date: Scalars['timestamp']['output'];
  id: Scalars['Int']['output'];
  status?: Maybe<Scalars['voucher_status_enum']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "vouchers" */
export type Vouchers_Aggregate = {
  __typename?: 'vouchers_aggregate';
  aggregate?: Maybe<Vouchers_Aggregate_Fields>;
  nodes: Array<Vouchers>;
};

/** aggregate fields of "vouchers" */
export type Vouchers_Aggregate_Fields = {
  __typename?: 'vouchers_aggregate_fields';
  avg?: Maybe<Vouchers_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Vouchers_Max_Fields>;
  min?: Maybe<Vouchers_Min_Fields>;
  stddev?: Maybe<Vouchers_Stddev_Fields>;
  stddev_pop?: Maybe<Vouchers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vouchers_Stddev_Samp_Fields>;
  sum?: Maybe<Vouchers_Sum_Fields>;
  var_pop?: Maybe<Vouchers_Var_Pop_Fields>;
  var_samp?: Maybe<Vouchers_Var_Samp_Fields>;
  variance?: Maybe<Vouchers_Variance_Fields>;
};


/** aggregate fields of "vouchers" */
export type Vouchers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vouchers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Vouchers_Avg_Fields = {
  __typename?: 'vouchers_avg_fields';
  discount_amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "vouchers". All fields are combined with a logical 'AND'. */
export type Vouchers_Bool_Exp = {
  _and?: InputMaybe<Array<Vouchers_Bool_Exp>>;
  _not?: InputMaybe<Vouchers_Bool_Exp>;
  _or?: InputMaybe<Array<Vouchers_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  discount_amount?: InputMaybe<Numeric_Comparison_Exp>;
  expiry_date?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<Voucher_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "vouchers" */
export type Vouchers_Constraint =
  /** unique or primary key constraint on columns "code" */
  | 'vouchers_code_key'
  /** unique or primary key constraint on columns "id" */
  | 'vouchers_pkey';

/** input type for incrementing numeric columns in table "vouchers" */
export type Vouchers_Inc_Input = {
  discount_amount?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "vouchers" */
export type Vouchers_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discount_amount?: InputMaybe<Scalars['numeric']['input']>;
  expiry_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['voucher_status_enum']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Vouchers_Max_Fields = {
  __typename?: 'vouchers_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount_amount?: Maybe<Scalars['numeric']['output']>;
  expiry_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['voucher_status_enum']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Vouchers_Min_Fields = {
  __typename?: 'vouchers_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount_amount?: Maybe<Scalars['numeric']['output']>;
  expiry_date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['voucher_status_enum']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "vouchers" */
export type Vouchers_Mutation_Response = {
  __typename?: 'vouchers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vouchers>;
};

/** on_conflict condition type for table "vouchers" */
export type Vouchers_On_Conflict = {
  constraint: Vouchers_Constraint;
  update_columns?: Array<Vouchers_Update_Column>;
  where?: InputMaybe<Vouchers_Bool_Exp>;
};

/** Ordering options when selecting data from "vouchers". */
export type Vouchers_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  discount_amount?: InputMaybe<Order_By>;
  expiry_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vouchers */
export type Vouchers_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "vouchers" */
export type Vouchers_Select_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'created_at'
  /** column name */
  | 'description'
  /** column name */
  | 'discount_amount'
  /** column name */
  | 'expiry_date'
  /** column name */
  | 'id'
  /** column name */
  | 'status'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

/** input type for updating data in table "vouchers" */
export type Vouchers_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discount_amount?: InputMaybe<Scalars['numeric']['input']>;
  expiry_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['voucher_status_enum']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Vouchers_Stddev_Fields = {
  __typename?: 'vouchers_stddev_fields';
  discount_amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Vouchers_Stddev_Pop_Fields = {
  __typename?: 'vouchers_stddev_pop_fields';
  discount_amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Vouchers_Stddev_Samp_Fields = {
  __typename?: 'vouchers_stddev_samp_fields';
  discount_amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "vouchers" */
export type Vouchers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vouchers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vouchers_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discount_amount?: InputMaybe<Scalars['numeric']['input']>;
  expiry_date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['voucher_status_enum']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Vouchers_Sum_Fields = {
  __typename?: 'vouchers_sum_fields';
  discount_amount?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "vouchers" */
export type Vouchers_Update_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'created_at'
  /** column name */
  | 'description'
  /** column name */
  | 'discount_amount'
  /** column name */
  | 'expiry_date'
  /** column name */
  | 'id'
  /** column name */
  | 'status'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'user_id';

export type Vouchers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Vouchers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vouchers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vouchers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Vouchers_Var_Pop_Fields = {
  __typename?: 'vouchers_var_pop_fields';
  discount_amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Vouchers_Var_Samp_Fields = {
  __typename?: 'vouchers_var_samp_fields';
  discount_amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Vouchers_Variance_Fields = {
  __typename?: 'vouchers_variance_fields';
  discount_amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

export type AddAttachentTestResponseMutationVariables = Exact<{
  attachment_test_id: Scalars['Int']['input'];
  point?: InputMaybe<Scalars['numeric']['input']>;
  contentEn?: InputMaybe<Scalars['String']['input']>;
  contentFr?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddAttachentTestResponseMutation = { __typename?: 'mutation_root', insert_attachment_test_responses_one?: { __typename?: 'attachment_test_responses', id: number } | null };

export type AddAttachmentTestMutationVariables = Exact<{
  contentEn?: InputMaybe<Scalars['String']['input']>;
  contentFr?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddAttachmentTestMutation = { __typename?: 'mutation_root', insert_attachment_tests_one?: { __typename?: 'attachment_tests', id: number } | null };

export type UpdateAttachmentTestByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  changes?: InputMaybe<Attachment_Tests_Set_Input>;
}>;


export type UpdateAttachmentTestByIdMutation = { __typename?: 'mutation_root', update_attachment_tests_by_pk?: { __typename?: 'attachment_tests', id: number } | null };

export type UpdateAttachmentTestResponseMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  changes?: InputMaybe<Attachment_Test_Responses_Set_Input>;
}>;


export type UpdateAttachmentTestResponseMutation = { __typename?: 'mutation_root', update_attachment_test_responses_by_pk?: { __typename?: 'attachment_test_responses', id: number } | null };

export type AddAvatarTestMutationVariables = Exact<{
  question_category: Scalars['avatar_test_type']['input'];
  contentEn?: InputMaybe<Scalars['String']['input']>;
  contentFr?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddAvatarTestMutation = { __typename?: 'mutation_root', insert_avatar_tests_one?: { __typename?: 'avatar_tests', id: number } | null };

export type AddAvatarTestResponseMutationVariables = Exact<{
  question_id: Scalars['Int']['input'];
  avatar_result_id: Scalars['Int']['input'];
  contentEn?: InputMaybe<Scalars['String']['input']>;
  contentFr?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddAvatarTestResponseMutation = { __typename?: 'mutation_root', insert_avatar_test_responses_one?: { __typename?: 'avatar_test_responses', id: number } | null };

export type UpdateAvatarTestByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  changes?: InputMaybe<Avatar_Tests_Set_Input>;
}>;


export type UpdateAvatarTestByIdMutation = { __typename?: 'mutation_root', update_avatar_tests_by_pk?: { __typename?: 'avatar_tests', id: number } | null };

export type UpdateAvatarResultByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  changes?: InputMaybe<Avatar_Results_Set_Input>;
}>;


export type UpdateAvatarResultByIdMutation = { __typename?: 'mutation_root', update_avatar_results_by_pk?: { __typename?: 'avatar_results', id: number } | null };

export type UpdateAvatarTestResponseMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  changes?: InputMaybe<Avatar_Test_Responses_Set_Input>;
}>;


export type UpdateAvatarTestResponseMutation = { __typename?: 'mutation_root', update_avatar_test_responses_by_pk?: { __typename?: 'avatar_test_responses', id: number } | null };

export type ReleaseFundsMutationVariables = Exact<{
  input: ReleaseFundsInput;
}>;


export type ReleaseFundsMutation = { __typename?: 'mutation_root', releaseFunds: { __typename?: 'ReleaseFundsResponse', reservation_id: number } };

export type CreateCoachMutationVariables = Exact<{
  input: CreateCoachInput;
}>;


export type CreateCoachMutation = { __typename?: 'mutation_root', createCoach: { __typename?: 'CreateCoachResponse', id: number } };

export type DeleteAvailabilityTimeSlotMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteAvailabilityTimeSlotMutation = { __typename?: 'mutation_root', delete_availability_time_slots_by_pk?: { __typename?: 'availability_time_slots', id: number } | null };

export type InsertAvailabilityTimeSlotMutationVariables = Exact<{
  input: Availability_Time_Slots_Insert_Input;
}>;


export type InsertAvailabilityTimeSlotMutation = { __typename?: 'mutation_root', insert_availability_time_slots_one?: { __typename?: 'availability_time_slots', id: number } | null };

export type InsertUserMutationVariables = Exact<{
  input: Users_Insert_Input;
}>;


export type InsertUserMutation = { __typename?: 'mutation_root', insert_users_one?: { __typename?: 'users', id: number, is_active: boolean, full_name?: string | null, currency?: string | null, role: any } | null };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'mutation_root', login: { __typename?: 'LoginResponse', token: string, refresh_token: string } };

export type AddMatricQuestionMutationVariables = Exact<{
  category_id: Scalars['Int']['input'];
  contentEn?: InputMaybe<Scalars['String']['input']>;
  contentFr?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddMatricQuestionMutation = { __typename?: 'mutation_root', insert_matric_questions_one?: { __typename?: 'matric_questions', id: number } | null };

export type AddMatricQuestionResponseMutationVariables = Exact<{
  question_id: Scalars['Int']['input'];
  point?: InputMaybe<Scalars['numeric']['input']>;
  contentEn?: InputMaybe<Scalars['String']['input']>;
  contentFr?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddMatricQuestionResponseMutation = { __typename?: 'mutation_root', insert_matric_question_responses_one?: { __typename?: 'matric_question_responses', id: number } | null };

export type UpdateMatricQuestionResponseMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  changes?: InputMaybe<Matric_Question_Responses_Set_Input>;
}>;


export type UpdateMatricQuestionResponseMutation = { __typename?: 'mutation_root', update_matric_question_responses_by_pk?: { __typename?: 'matric_question_responses', id: number } | null };

export type UpdateMatricQuestionByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  changes?: InputMaybe<Matric_Questions_Set_Input>;
}>;


export type UpdateMatricQuestionByIdMutation = { __typename?: 'mutation_root', update_matric_questions_by_pk?: { __typename?: 'matric_questions', id: number } | null };

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String']['input'];
}>;


export type RefreshTokenMutation = { __typename?: 'mutation_root', refreshToken: { __typename?: 'RefreshTokenResponse', token: string } };

export type AddTemplateQuestionMutationVariables = Exact<{
  contentEn?: InputMaybe<Scalars['String']['input']>;
  contentFr?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddTemplateQuestionMutation = { __typename?: 'mutation_root', insert_template_questions_one?: { __typename?: 'template_questions', id: number } | null };

export type AddTemplateQuestionResponseMutationVariables = Exact<{
  template_question_id: Scalars['Int']['input'];
  contentEn?: InputMaybe<Scalars['String']['input']>;
  contentFr?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddTemplateQuestionResponseMutation = { __typename?: 'mutation_root', insert_template_question_responses_one?: { __typename?: 'template_question_responses', id: number } | null };

export type UpdateTemplateQuestionResponseMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  changes?: InputMaybe<Template_Question_Responses_Set_Input>;
}>;


export type UpdateTemplateQuestionResponseMutation = { __typename?: 'mutation_root', update_template_question_responses_by_pk?: { __typename?: 'template_question_responses', id: number } | null };

export type UpdateTemplateQuestionByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  changes?: InputMaybe<Template_Questions_Set_Input>;
}>;


export type UpdateTemplateQuestionByIdMutation = { __typename?: 'mutation_root', update_template_questions_by_pk?: { __typename?: 'template_questions', id: number } | null };

export type UpdateTranslationMutationVariables = Exact<{
  translation?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
}>;


export type UpdateTranslationMutation = { __typename?: 'mutation_root', update_translations_by_pk?: { __typename?: 'translations', translation?: string | null } | null };

export type GetAttachmentTestCategoriesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attachment_Test_Categories_Order_By> | Attachment_Test_Categories_Order_By>;
  where?: InputMaybe<Attachment_Test_Categories_Bool_Exp>;
}>;


export type GetAttachmentTestCategoriesQuery = { __typename?: 'query_root', attachment_test_categories: Array<{ __typename?: 'attachment_test_categories', id: number, icon_url?: string | null, created_at?: any | null, updated_at?: any | null, name?: { __typename?: 'contents', id: number, translations: Array<{ __typename?: 'translations', id: number, translation?: string | null, language_code: string }> } | null, description: { __typename?: 'contents', id: number, translations: Array<{ __typename?: 'translations', id: number, translation?: string | null, language_code: string }> } }>, attachment_test_categories_aggregate: { __typename?: 'attachment_test_categories_aggregate', aggregate?: { __typename?: 'attachment_test_categories_aggregate_fields', count: number } | null } };

export type GetAttachmentTestResponsesByQuestionIdQueryVariables = Exact<{
  where?: InputMaybe<Attachment_Test_Responses_Bool_Exp>;
}>;


export type GetAttachmentTestResponsesByQuestionIdQuery = { __typename?: 'query_root', attachment_test_responses: Array<{ __typename?: 'attachment_test_responses', id: number, attachment_test_id: number, point: any, created_at?: any | null, updated_at?: any | null, content: { __typename?: 'contents', id: number, translations: Array<{ __typename?: 'translations', id: number, translation?: string | null, language_code: string }> } }>, attachment_test_responses_aggregate: { __typename?: 'attachment_test_responses_aggregate', aggregate?: { __typename?: 'attachment_test_responses_aggregate_fields', count: number } | null } };

export type GetAttachmentTestsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Attachment_Tests_Order_By> | Attachment_Tests_Order_By>;
  where?: InputMaybe<Attachment_Tests_Bool_Exp>;
}>;


export type GetAttachmentTestsQuery = { __typename?: 'query_root', attachment_tests: Array<{ __typename?: 'attachment_tests', id: number, created_at?: any | null, updated_at?: any | null, content: { __typename?: 'contents', id: number, translations: Array<{ __typename?: 'translations', id: number, translation?: string | null, language_code: string }> }, category?: { __typename?: 'attachment_test_categories', id: number, name?: { __typename?: 'contents', translations: Array<{ __typename?: 'translations', translation?: string | null, language_code: string }> } | null } | null }>, attachment_tests_aggregate: { __typename?: 'attachment_tests_aggregate', aggregate?: { __typename?: 'attachment_tests_aggregate_fields', count: number } | null } };

export type GetAvatarResultsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Avatar_Results_Order_By> | Avatar_Results_Order_By>;
  where?: InputMaybe<Avatar_Results_Bool_Exp>;
}>;


export type GetAvatarResultsQuery = { __typename?: 'query_root', avatar_results: Array<{ __typename?: 'avatar_results', id: number, code: string, icon_url?: string | null, created_at?: any | null, updated_at?: any | null, content: { __typename?: 'contents', id: number, translations: Array<{ __typename?: 'translations', id: number, translation?: string | null, language_code: string }> }, name?: { __typename?: 'contents', id: number, translations: Array<{ __typename?: 'translations', id: number, translation?: string | null, language_code: string }> } | null }>, avatar_results_aggregate: { __typename?: 'avatar_results_aggregate', aggregate?: { __typename?: 'avatar_results_aggregate_fields', count: number } | null } };

export type GetAvatarTestResponsesQueryVariables = Exact<{
  where?: InputMaybe<Avatar_Test_Responses_Bool_Exp>;
}>;


export type GetAvatarTestResponsesQuery = { __typename?: 'query_root', avatar_test_responses: Array<{ __typename?: 'avatar_test_responses', id: number, created_at?: any | null, updated_at?: any | null, content: { __typename?: 'contents', id: number, translations: Array<{ __typename?: 'translations', id: number, translation?: string | null, language_code: string }> }, avatar_result?: { __typename?: 'avatar_results', id: number, code: string } | null }>, avatar_test_responses_aggregate: { __typename?: 'avatar_test_responses_aggregate', aggregate?: { __typename?: 'avatar_test_responses_aggregate_fields', count: number } | null } };

export type GetAvatarTestsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Avatar_Tests_Order_By> | Avatar_Tests_Order_By>;
  where?: InputMaybe<Avatar_Tests_Bool_Exp>;
}>;


export type GetAvatarTestsQuery = { __typename?: 'query_root', avatar_tests: Array<{ __typename?: 'avatar_tests', id: number, category?: any | null, created_at?: any | null, updated_at?: any | null, content: { __typename?: 'contents', id: number, translations: Array<{ __typename?: 'translations', id: number, translation?: string | null, language_code: string }> } }>, avatar_tests_aggregate: { __typename?: 'avatar_tests_aggregate', aggregate?: { __typename?: 'avatar_tests_aggregate_fields', count: number } | null } };

export type GetOnboardingLinkQueryVariables = Exact<{
  input: GetStripeAccountLinkInput;
}>;


export type GetOnboardingLinkQuery = { __typename?: 'query_root', getStripeAccountLink: { __typename?: 'GetStripeAccountLinkResponse', link: string } };

export type GetGendersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGendersQuery = { __typename?: 'query_root', genders: Array<{ __typename?: 'genders', id: number, code?: string | null, content: { __typename?: 'contents', translations: Array<{ __typename?: 'translations', id: number, translation?: string | null, language_code: string }> } }> };

export type GetAvailabilityTimeSlotsByCoachQueryVariables = Exact<{
  coach_id: Scalars['Int']['input'];
}>;


export type GetAvailabilityTimeSlotsByCoachQuery = { __typename?: 'query_root', availability_time_slots: Array<{ __typename?: 'availability_time_slots', id: number, coach_id?: number | null, availability_status?: any | null, end_time: any, repeat_interval?: any | null, repeat_days?: string | null, start_time: any }> };

export type GetReservationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reservations_Order_By> | Reservations_Order_By>;
  where?: InputMaybe<Reservations_Bool_Exp>;
}>;


export type GetReservationsQuery = { __typename?: 'query_root', reservations: Array<{ __typename?: 'reservations', id: number, user_id: number, coach_id: number, amount: any, reservation_status?: any | null, updated_at?: any | null, created_at?: any | null, user: { __typename?: 'users', id: number, full_name?: string | null }, time_slots: Array<{ __typename?: 'time_slots', start_time: any, end_time: any, slot_status?: any | null }>, reservation_payments: Array<{ __typename?: 'reservation_payments', payment_status: any, payer_id: number }>, coach: { __typename?: 'users', id: number, full_name?: string | null } }>, reservations_aggregate: { __typename?: 'reservations_aggregate', aggregate?: { __typename?: 'reservations_aggregate_fields', count: number } | null } };

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetUserByIdQuery = { __typename?: 'query_root', users_by_pk?: { __typename?: 'users', id: number, role: any, email: string, full_name?: string | null, currency?: string | null } | null };

export type GetUsersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By> | Users_Order_By>;
  where?: InputMaybe<Users_Bool_Exp>;
}>;


export type GetUsersQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: number, email: string, full_name?: string | null, currency?: string | null, date_of_birth?: any | null, hourly_rate?: any | null, is_active: boolean, role: any, created_at?: any | null, stripe_account_id?: string | null, is_stripe_account_ready: boolean, profile?: { __typename?: 'profiles', city?: { __typename?: 'cities', id: number, name: string } | null, gender?: { __typename?: 'genders', id: number, content: { __typename?: 'contents', translations: Array<{ __typename?: 'translations', translation?: string | null, language_code: string }> } } | null } | null }>, users_aggregate: { __typename?: 'users_aggregate', aggregate?: { __typename?: 'users_aggregate_fields', count: number } | null } };

export type SubCountUserQueryVariables = Exact<{ [key: string]: never; }>;


export type SubCountUserQuery = { __typename?: 'query_root', users_aggregate: { __typename?: 'users_aggregate', aggregate?: { __typename?: 'users_aggregate_fields', count: number } | null } };

export type SubCountCoachQueryVariables = Exact<{ [key: string]: never; }>;


export type SubCountCoachQuery = { __typename?: 'query_root', users_aggregate: { __typename?: 'users_aggregate', aggregate?: { __typename?: 'users_aggregate_fields', count: number } | null } };

export type SubCountSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type SubCountSessionQuery = { __typename?: 'query_root', reservations_aggregate: { __typename?: 'reservations_aggregate', aggregate?: { __typename?: 'reservations_aggregate_fields', count: number } | null } };

export type GetMatricQuestionResponsesByQuestionIdQueryVariables = Exact<{
  where?: InputMaybe<Matric_Question_Responses_Bool_Exp>;
}>;


export type GetMatricQuestionResponsesByQuestionIdQuery = { __typename?: 'query_root', matric_question_responses: Array<{ __typename?: 'matric_question_responses', id: number, matric_question_id: number, point: any, created_at?: any | null, updated_at?: any | null, content: { __typename?: 'contents', id: number, translations: Array<{ __typename?: 'translations', id: number, translation?: string | null, language_code: string }> } }>, matric_question_responses_aggregate: { __typename?: 'matric_question_responses_aggregate', aggregate?: { __typename?: 'matric_question_responses_aggregate_fields', count: number } | null } };

export type GetMatricQuestionCategoriesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Matric_Question_Categories_Order_By> | Matric_Question_Categories_Order_By>;
  where?: InputMaybe<Matric_Question_Categories_Bool_Exp>;
}>;


export type GetMatricQuestionCategoriesQuery = { __typename?: 'query_root', matric_question_categories: Array<{ __typename?: 'matric_question_categories', id: number, created_at?: any | null, updated_at?: any | null, content: { __typename?: 'contents', id: number, translations: Array<{ __typename?: 'translations', id: number, translation?: string | null, language_code: string }> } }>, matric_question_categories_aggregate: { __typename?: 'matric_question_categories_aggregate', aggregate?: { __typename?: 'matric_question_categories_aggregate_fields', count: number } | null } };

export type GetMatricQuestionnairesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Matric_Questions_Order_By> | Matric_Questions_Order_By>;
  where?: InputMaybe<Matric_Questions_Bool_Exp>;
}>;


export type GetMatricQuestionnairesQuery = { __typename?: 'query_root', matric_questions: Array<{ __typename?: 'matric_questions', id: number, created_at?: any | null, updated_at?: any | null, content: { __typename?: 'contents', id: number, translations: Array<{ __typename?: 'translations', id: number, translation?: string | null, language_code: string }> }, matric_question_category: { __typename?: 'matric_question_categories', content: { __typename?: 'contents', translations: Array<{ __typename?: 'translations', translation?: string | null, language_code: string }> } } }>, matric_questions_aggregate: { __typename?: 'matric_questions_aggregate', aggregate?: { __typename?: 'matric_questions_aggregate_fields', count: number } | null } };

export type GetSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSettingsQuery = { __typename?: 'query_root', settings: Array<{ __typename?: 'settings', id: number, name?: string | null, value?: string | null }> };

export type UpdateSettingByNameMutationVariables = Exact<{
  where: Settings_Bool_Exp;
  object?: InputMaybe<Settings_Set_Input>;
}>;


export type UpdateSettingByNameMutation = { __typename?: 'mutation_root', update_settings?: { __typename?: 'settings_mutation_response', affected_rows: number } | null };

export type GetTemplateQuestionResponsesByQuestionIdQueryVariables = Exact<{
  where?: InputMaybe<Template_Question_Responses_Bool_Exp>;
}>;


export type GetTemplateQuestionResponsesByQuestionIdQuery = { __typename?: 'query_root', template_question_responses: Array<{ __typename?: 'template_question_responses', id: number, created_at?: any | null, updated_at?: any | null, content: { __typename?: 'contents', id: number, translations: Array<{ __typename?: 'translations', id: number, translation?: string | null, language_code: string }> } }>, template_question_responses_aggregate: { __typename?: 'template_question_responses_aggregate', aggregate?: { __typename?: 'template_question_responses_aggregate_fields', count: number } | null } };

export type GetTemplateQuestionsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Questions_Order_By> | Template_Questions_Order_By>;
  where?: InputMaybe<Template_Questions_Bool_Exp>;
}>;


export type GetTemplateQuestionsQuery = { __typename?: 'query_root', template_questions: Array<{ __typename?: 'template_questions', id: number, code: string, created_at?: any | null, updated_at?: any | null, content: { __typename?: 'contents', id: number, translations: Array<{ __typename?: 'translations', id: number, translation?: string | null, language_code: string }> } }>, template_questions_aggregate: { __typename?: 'template_questions_aggregate', aggregate?: { __typename?: 'template_questions_aggregate_fields', count: number } | null } };


export const AddAttachentTestResponseDocument = gql`
    mutation AddAttachentTestResponse($attachment_test_id: Int!, $point: numeric, $contentEn: String, $contentFr: String) {
  insert_attachment_test_responses_one(
    object: {attachment_test_id: $attachment_test_id, point: $point, content: {data: {translations: {data: [{translation: $contentEn, language_code: "en"}, {translation: $contentFr, language_code: "fr"}]}}}}
  ) {
    id
  }
}
    `;
export type AddAttachentTestResponseMutationFn = Apollo.MutationFunction<AddAttachentTestResponseMutation, AddAttachentTestResponseMutationVariables>;

/**
 * __useAddAttachentTestResponseMutation__
 *
 * To run a mutation, you first call `useAddAttachentTestResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAttachentTestResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAttachentTestResponseMutation, { data, loading, error }] = useAddAttachentTestResponseMutation({
 *   variables: {
 *      attachment_test_id: // value for 'attachment_test_id'
 *      point: // value for 'point'
 *      contentEn: // value for 'contentEn'
 *      contentFr: // value for 'contentFr'
 *   },
 * });
 */
export function useAddAttachentTestResponseMutation(baseOptions?: Apollo.MutationHookOptions<AddAttachentTestResponseMutation, AddAttachentTestResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAttachentTestResponseMutation, AddAttachentTestResponseMutationVariables>(AddAttachentTestResponseDocument, options);
      }
export type AddAttachentTestResponseMutationHookResult = ReturnType<typeof useAddAttachentTestResponseMutation>;
export type AddAttachentTestResponseMutationResult = Apollo.MutationResult<AddAttachentTestResponseMutation>;
export type AddAttachentTestResponseMutationOptions = Apollo.BaseMutationOptions<AddAttachentTestResponseMutation, AddAttachentTestResponseMutationVariables>;
export const AddAttachmentTestDocument = gql`
    mutation AddAttachmentTest($contentEn: String, $contentFr: String) {
  insert_attachment_tests_one(
    object: {content: {data: {translations: {data: [{translation: $contentEn, language_code: "en"}, {translation: $contentFr, language_code: "fr"}]}}}}
  ) {
    id
  }
}
    `;
export type AddAttachmentTestMutationFn = Apollo.MutationFunction<AddAttachmentTestMutation, AddAttachmentTestMutationVariables>;

/**
 * __useAddAttachmentTestMutation__
 *
 * To run a mutation, you first call `useAddAttachmentTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAttachmentTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAttachmentTestMutation, { data, loading, error }] = useAddAttachmentTestMutation({
 *   variables: {
 *      contentEn: // value for 'contentEn'
 *      contentFr: // value for 'contentFr'
 *   },
 * });
 */
export function useAddAttachmentTestMutation(baseOptions?: Apollo.MutationHookOptions<AddAttachmentTestMutation, AddAttachmentTestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAttachmentTestMutation, AddAttachmentTestMutationVariables>(AddAttachmentTestDocument, options);
      }
export type AddAttachmentTestMutationHookResult = ReturnType<typeof useAddAttachmentTestMutation>;
export type AddAttachmentTestMutationResult = Apollo.MutationResult<AddAttachmentTestMutation>;
export type AddAttachmentTestMutationOptions = Apollo.BaseMutationOptions<AddAttachmentTestMutation, AddAttachmentTestMutationVariables>;
export const UpdateAttachmentTestByIdDocument = gql`
    mutation UpdateAttachmentTestById($id: Int!, $changes: attachment_tests_set_input) {
  update_attachment_tests_by_pk(pk_columns: {id: $id}, _set: $changes) {
    id
  }
}
    `;
export type UpdateAttachmentTestByIdMutationFn = Apollo.MutationFunction<UpdateAttachmentTestByIdMutation, UpdateAttachmentTestByIdMutationVariables>;

/**
 * __useUpdateAttachmentTestByIdMutation__
 *
 * To run a mutation, you first call `useUpdateAttachmentTestByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttachmentTestByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttachmentTestByIdMutation, { data, loading, error }] = useUpdateAttachmentTestByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateAttachmentTestByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAttachmentTestByIdMutation, UpdateAttachmentTestByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAttachmentTestByIdMutation, UpdateAttachmentTestByIdMutationVariables>(UpdateAttachmentTestByIdDocument, options);
      }
export type UpdateAttachmentTestByIdMutationHookResult = ReturnType<typeof useUpdateAttachmentTestByIdMutation>;
export type UpdateAttachmentTestByIdMutationResult = Apollo.MutationResult<UpdateAttachmentTestByIdMutation>;
export type UpdateAttachmentTestByIdMutationOptions = Apollo.BaseMutationOptions<UpdateAttachmentTestByIdMutation, UpdateAttachmentTestByIdMutationVariables>;
export const UpdateAttachmentTestResponseDocument = gql`
    mutation UpdateAttachmentTestResponse($id: Int!, $changes: attachment_test_responses_set_input) {
  update_attachment_test_responses_by_pk(pk_columns: {id: $id}, _set: $changes) {
    id
  }
}
    `;
export type UpdateAttachmentTestResponseMutationFn = Apollo.MutationFunction<UpdateAttachmentTestResponseMutation, UpdateAttachmentTestResponseMutationVariables>;

/**
 * __useUpdateAttachmentTestResponseMutation__
 *
 * To run a mutation, you first call `useUpdateAttachmentTestResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttachmentTestResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttachmentTestResponseMutation, { data, loading, error }] = useUpdateAttachmentTestResponseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateAttachmentTestResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAttachmentTestResponseMutation, UpdateAttachmentTestResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAttachmentTestResponseMutation, UpdateAttachmentTestResponseMutationVariables>(UpdateAttachmentTestResponseDocument, options);
      }
export type UpdateAttachmentTestResponseMutationHookResult = ReturnType<typeof useUpdateAttachmentTestResponseMutation>;
export type UpdateAttachmentTestResponseMutationResult = Apollo.MutationResult<UpdateAttachmentTestResponseMutation>;
export type UpdateAttachmentTestResponseMutationOptions = Apollo.BaseMutationOptions<UpdateAttachmentTestResponseMutation, UpdateAttachmentTestResponseMutationVariables>;
export const AddAvatarTestDocument = gql`
    mutation AddAvatarTest($question_category: avatar_test_type!, $contentEn: String, $contentFr: String) {
  insert_avatar_tests_one(
    object: {category: $question_category, content: {data: {translations: {data: [{translation: $contentEn, language_code: "en"}, {translation: $contentFr, language_code: "fr"}]}}}}
  ) {
    id
  }
}
    `;
export type AddAvatarTestMutationFn = Apollo.MutationFunction<AddAvatarTestMutation, AddAvatarTestMutationVariables>;

/**
 * __useAddAvatarTestMutation__
 *
 * To run a mutation, you first call `useAddAvatarTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAvatarTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAvatarTestMutation, { data, loading, error }] = useAddAvatarTestMutation({
 *   variables: {
 *      question_category: // value for 'question_category'
 *      contentEn: // value for 'contentEn'
 *      contentFr: // value for 'contentFr'
 *   },
 * });
 */
export function useAddAvatarTestMutation(baseOptions?: Apollo.MutationHookOptions<AddAvatarTestMutation, AddAvatarTestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAvatarTestMutation, AddAvatarTestMutationVariables>(AddAvatarTestDocument, options);
      }
export type AddAvatarTestMutationHookResult = ReturnType<typeof useAddAvatarTestMutation>;
export type AddAvatarTestMutationResult = Apollo.MutationResult<AddAvatarTestMutation>;
export type AddAvatarTestMutationOptions = Apollo.BaseMutationOptions<AddAvatarTestMutation, AddAvatarTestMutationVariables>;
export const AddAvatarTestResponseDocument = gql`
    mutation AddAvatarTestResponse($question_id: Int!, $avatar_result_id: Int!, $contentEn: String, $contentFr: String) {
  insert_avatar_test_responses_one(
    object: {avatar_test_id: $question_id, avatar_result_id: $avatar_result_id, content: {data: {translations: {data: [{translation: $contentEn, language_code: "en"}, {translation: $contentFr, language_code: "fr"}]}}}}
  ) {
    id
  }
}
    `;
export type AddAvatarTestResponseMutationFn = Apollo.MutationFunction<AddAvatarTestResponseMutation, AddAvatarTestResponseMutationVariables>;

/**
 * __useAddAvatarTestResponseMutation__
 *
 * To run a mutation, you first call `useAddAvatarTestResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAvatarTestResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAvatarTestResponseMutation, { data, loading, error }] = useAddAvatarTestResponseMutation({
 *   variables: {
 *      question_id: // value for 'question_id'
 *      avatar_result_id: // value for 'avatar_result_id'
 *      contentEn: // value for 'contentEn'
 *      contentFr: // value for 'contentFr'
 *   },
 * });
 */
export function useAddAvatarTestResponseMutation(baseOptions?: Apollo.MutationHookOptions<AddAvatarTestResponseMutation, AddAvatarTestResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAvatarTestResponseMutation, AddAvatarTestResponseMutationVariables>(AddAvatarTestResponseDocument, options);
      }
export type AddAvatarTestResponseMutationHookResult = ReturnType<typeof useAddAvatarTestResponseMutation>;
export type AddAvatarTestResponseMutationResult = Apollo.MutationResult<AddAvatarTestResponseMutation>;
export type AddAvatarTestResponseMutationOptions = Apollo.BaseMutationOptions<AddAvatarTestResponseMutation, AddAvatarTestResponseMutationVariables>;
export const UpdateAvatarTestByIdDocument = gql`
    mutation UpdateAvatarTestById($id: Int!, $changes: avatar_tests_set_input) {
  update_avatar_tests_by_pk(pk_columns: {id: $id}, _set: $changes) {
    id
  }
}
    `;
export type UpdateAvatarTestByIdMutationFn = Apollo.MutationFunction<UpdateAvatarTestByIdMutation, UpdateAvatarTestByIdMutationVariables>;

/**
 * __useUpdateAvatarTestByIdMutation__
 *
 * To run a mutation, you first call `useUpdateAvatarTestByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAvatarTestByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAvatarTestByIdMutation, { data, loading, error }] = useUpdateAvatarTestByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateAvatarTestByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAvatarTestByIdMutation, UpdateAvatarTestByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAvatarTestByIdMutation, UpdateAvatarTestByIdMutationVariables>(UpdateAvatarTestByIdDocument, options);
      }
export type UpdateAvatarTestByIdMutationHookResult = ReturnType<typeof useUpdateAvatarTestByIdMutation>;
export type UpdateAvatarTestByIdMutationResult = Apollo.MutationResult<UpdateAvatarTestByIdMutation>;
export type UpdateAvatarTestByIdMutationOptions = Apollo.BaseMutationOptions<UpdateAvatarTestByIdMutation, UpdateAvatarTestByIdMutationVariables>;
export const UpdateAvatarResultByIdDocument = gql`
    mutation UpdateAvatarResultById($id: Int!, $changes: avatar_results_set_input) {
  update_avatar_results_by_pk(pk_columns: {id: $id}, _set: $changes) {
    id
  }
}
    `;
export type UpdateAvatarResultByIdMutationFn = Apollo.MutationFunction<UpdateAvatarResultByIdMutation, UpdateAvatarResultByIdMutationVariables>;

/**
 * __useUpdateAvatarResultByIdMutation__
 *
 * To run a mutation, you first call `useUpdateAvatarResultByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAvatarResultByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAvatarResultByIdMutation, { data, loading, error }] = useUpdateAvatarResultByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateAvatarResultByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAvatarResultByIdMutation, UpdateAvatarResultByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAvatarResultByIdMutation, UpdateAvatarResultByIdMutationVariables>(UpdateAvatarResultByIdDocument, options);
      }
export type UpdateAvatarResultByIdMutationHookResult = ReturnType<typeof useUpdateAvatarResultByIdMutation>;
export type UpdateAvatarResultByIdMutationResult = Apollo.MutationResult<UpdateAvatarResultByIdMutation>;
export type UpdateAvatarResultByIdMutationOptions = Apollo.BaseMutationOptions<UpdateAvatarResultByIdMutation, UpdateAvatarResultByIdMutationVariables>;
export const UpdateAvatarTestResponseDocument = gql`
    mutation UpdateAvatarTestResponse($id: Int!, $changes: avatar_test_responses_set_input) {
  update_avatar_test_responses_by_pk(pk_columns: {id: $id}, _set: $changes) {
    id
  }
}
    `;
export type UpdateAvatarTestResponseMutationFn = Apollo.MutationFunction<UpdateAvatarTestResponseMutation, UpdateAvatarTestResponseMutationVariables>;

/**
 * __useUpdateAvatarTestResponseMutation__
 *
 * To run a mutation, you first call `useUpdateAvatarTestResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAvatarTestResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAvatarTestResponseMutation, { data, loading, error }] = useUpdateAvatarTestResponseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateAvatarTestResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAvatarTestResponseMutation, UpdateAvatarTestResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAvatarTestResponseMutation, UpdateAvatarTestResponseMutationVariables>(UpdateAvatarTestResponseDocument, options);
      }
export type UpdateAvatarTestResponseMutationHookResult = ReturnType<typeof useUpdateAvatarTestResponseMutation>;
export type UpdateAvatarTestResponseMutationResult = Apollo.MutationResult<UpdateAvatarTestResponseMutation>;
export type UpdateAvatarTestResponseMutationOptions = Apollo.BaseMutationOptions<UpdateAvatarTestResponseMutation, UpdateAvatarTestResponseMutationVariables>;
export const ReleaseFundsDocument = gql`
    mutation ReleaseFunds($input: ReleaseFundsInput!) {
  releaseFunds(input: $input) {
    reservation_id
  }
}
    `;
export type ReleaseFundsMutationFn = Apollo.MutationFunction<ReleaseFundsMutation, ReleaseFundsMutationVariables>;

/**
 * __useReleaseFundsMutation__
 *
 * To run a mutation, you first call `useReleaseFundsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseFundsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseFundsMutation, { data, loading, error }] = useReleaseFundsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReleaseFundsMutation(baseOptions?: Apollo.MutationHookOptions<ReleaseFundsMutation, ReleaseFundsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReleaseFundsMutation, ReleaseFundsMutationVariables>(ReleaseFundsDocument, options);
      }
export type ReleaseFundsMutationHookResult = ReturnType<typeof useReleaseFundsMutation>;
export type ReleaseFundsMutationResult = Apollo.MutationResult<ReleaseFundsMutation>;
export type ReleaseFundsMutationOptions = Apollo.BaseMutationOptions<ReleaseFundsMutation, ReleaseFundsMutationVariables>;
export const CreateCoachDocument = gql`
    mutation CreateCoach($input: CreateCoachInput!) {
  createCoach(input: $input) {
    id
  }
}
    `;
export type CreateCoachMutationFn = Apollo.MutationFunction<CreateCoachMutation, CreateCoachMutationVariables>;

/**
 * __useCreateCoachMutation__
 *
 * To run a mutation, you first call `useCreateCoachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCoachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCoachMutation, { data, loading, error }] = useCreateCoachMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCoachMutation(baseOptions?: Apollo.MutationHookOptions<CreateCoachMutation, CreateCoachMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCoachMutation, CreateCoachMutationVariables>(CreateCoachDocument, options);
      }
export type CreateCoachMutationHookResult = ReturnType<typeof useCreateCoachMutation>;
export type CreateCoachMutationResult = Apollo.MutationResult<CreateCoachMutation>;
export type CreateCoachMutationOptions = Apollo.BaseMutationOptions<CreateCoachMutation, CreateCoachMutationVariables>;
export const DeleteAvailabilityTimeSlotDocument = gql`
    mutation DeleteAvailabilityTimeSlot($id: Int!) {
  delete_availability_time_slots_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteAvailabilityTimeSlotMutationFn = Apollo.MutationFunction<DeleteAvailabilityTimeSlotMutation, DeleteAvailabilityTimeSlotMutationVariables>;

/**
 * __useDeleteAvailabilityTimeSlotMutation__
 *
 * To run a mutation, you first call `useDeleteAvailabilityTimeSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAvailabilityTimeSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAvailabilityTimeSlotMutation, { data, loading, error }] = useDeleteAvailabilityTimeSlotMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAvailabilityTimeSlotMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAvailabilityTimeSlotMutation, DeleteAvailabilityTimeSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAvailabilityTimeSlotMutation, DeleteAvailabilityTimeSlotMutationVariables>(DeleteAvailabilityTimeSlotDocument, options);
      }
export type DeleteAvailabilityTimeSlotMutationHookResult = ReturnType<typeof useDeleteAvailabilityTimeSlotMutation>;
export type DeleteAvailabilityTimeSlotMutationResult = Apollo.MutationResult<DeleteAvailabilityTimeSlotMutation>;
export type DeleteAvailabilityTimeSlotMutationOptions = Apollo.BaseMutationOptions<DeleteAvailabilityTimeSlotMutation, DeleteAvailabilityTimeSlotMutationVariables>;
export const InsertAvailabilityTimeSlotDocument = gql`
    mutation InsertAvailabilityTimeSlot($input: availability_time_slots_insert_input!) {
  insert_availability_time_slots_one(object: $input) {
    id
  }
}
    `;
export type InsertAvailabilityTimeSlotMutationFn = Apollo.MutationFunction<InsertAvailabilityTimeSlotMutation, InsertAvailabilityTimeSlotMutationVariables>;

/**
 * __useInsertAvailabilityTimeSlotMutation__
 *
 * To run a mutation, you first call `useInsertAvailabilityTimeSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAvailabilityTimeSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAvailabilityTimeSlotMutation, { data, loading, error }] = useInsertAvailabilityTimeSlotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertAvailabilityTimeSlotMutation(baseOptions?: Apollo.MutationHookOptions<InsertAvailabilityTimeSlotMutation, InsertAvailabilityTimeSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertAvailabilityTimeSlotMutation, InsertAvailabilityTimeSlotMutationVariables>(InsertAvailabilityTimeSlotDocument, options);
      }
export type InsertAvailabilityTimeSlotMutationHookResult = ReturnType<typeof useInsertAvailabilityTimeSlotMutation>;
export type InsertAvailabilityTimeSlotMutationResult = Apollo.MutationResult<InsertAvailabilityTimeSlotMutation>;
export type InsertAvailabilityTimeSlotMutationOptions = Apollo.BaseMutationOptions<InsertAvailabilityTimeSlotMutation, InsertAvailabilityTimeSlotMutationVariables>;
export const InsertUserDocument = gql`
    mutation InsertUser($input: users_insert_input!) {
  insert_users_one(object: $input) {
    id
    is_active
    full_name
    currency
    role
  }
}
    `;
export type InsertUserMutationFn = Apollo.MutationFunction<InsertUserMutation, InsertUserMutationVariables>;

/**
 * __useInsertUserMutation__
 *
 * To run a mutation, you first call `useInsertUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserMutation, { data, loading, error }] = useInsertUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertUserMutation(baseOptions?: Apollo.MutationHookOptions<InsertUserMutation, InsertUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertUserMutation, InsertUserMutationVariables>(InsertUserDocument, options);
      }
export type InsertUserMutationHookResult = ReturnType<typeof useInsertUserMutation>;
export type InsertUserMutationResult = Apollo.MutationResult<InsertUserMutation>;
export type InsertUserMutationOptions = Apollo.BaseMutationOptions<InsertUserMutation, InsertUserMutationVariables>;
export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    token
    refresh_token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const AddMatricQuestionDocument = gql`
    mutation AddMatricQuestion($category_id: Int!, $contentEn: String, $contentFr: String) {
  insert_matric_questions_one(
    object: {matric_question_category_id: $category_id, content: {data: {translations: {data: [{translation: $contentEn, language_code: "en"}, {translation: $contentFr, language_code: "fr"}]}}}}
  ) {
    id
  }
}
    `;
export type AddMatricQuestionMutationFn = Apollo.MutationFunction<AddMatricQuestionMutation, AddMatricQuestionMutationVariables>;

/**
 * __useAddMatricQuestionMutation__
 *
 * To run a mutation, you first call `useAddMatricQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMatricQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMatricQuestionMutation, { data, loading, error }] = useAddMatricQuestionMutation({
 *   variables: {
 *      category_id: // value for 'category_id'
 *      contentEn: // value for 'contentEn'
 *      contentFr: // value for 'contentFr'
 *   },
 * });
 */
export function useAddMatricQuestionMutation(baseOptions?: Apollo.MutationHookOptions<AddMatricQuestionMutation, AddMatricQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMatricQuestionMutation, AddMatricQuestionMutationVariables>(AddMatricQuestionDocument, options);
      }
export type AddMatricQuestionMutationHookResult = ReturnType<typeof useAddMatricQuestionMutation>;
export type AddMatricQuestionMutationResult = Apollo.MutationResult<AddMatricQuestionMutation>;
export type AddMatricQuestionMutationOptions = Apollo.BaseMutationOptions<AddMatricQuestionMutation, AddMatricQuestionMutationVariables>;
export const AddMatricQuestionResponseDocument = gql`
    mutation AddMatricQuestionResponse($question_id: Int!, $point: numeric, $contentEn: String, $contentFr: String) {
  insert_matric_question_responses_one(
    object: {matric_question_id: $question_id, point: $point, content: {data: {translations: {data: [{translation: $contentEn, language_code: "en"}, {translation: $contentFr, language_code: "fr"}]}}}}
  ) {
    id
  }
}
    `;
export type AddMatricQuestionResponseMutationFn = Apollo.MutationFunction<AddMatricQuestionResponseMutation, AddMatricQuestionResponseMutationVariables>;

/**
 * __useAddMatricQuestionResponseMutation__
 *
 * To run a mutation, you first call `useAddMatricQuestionResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMatricQuestionResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMatricQuestionResponseMutation, { data, loading, error }] = useAddMatricQuestionResponseMutation({
 *   variables: {
 *      question_id: // value for 'question_id'
 *      point: // value for 'point'
 *      contentEn: // value for 'contentEn'
 *      contentFr: // value for 'contentFr'
 *   },
 * });
 */
export function useAddMatricQuestionResponseMutation(baseOptions?: Apollo.MutationHookOptions<AddMatricQuestionResponseMutation, AddMatricQuestionResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMatricQuestionResponseMutation, AddMatricQuestionResponseMutationVariables>(AddMatricQuestionResponseDocument, options);
      }
export type AddMatricQuestionResponseMutationHookResult = ReturnType<typeof useAddMatricQuestionResponseMutation>;
export type AddMatricQuestionResponseMutationResult = Apollo.MutationResult<AddMatricQuestionResponseMutation>;
export type AddMatricQuestionResponseMutationOptions = Apollo.BaseMutationOptions<AddMatricQuestionResponseMutation, AddMatricQuestionResponseMutationVariables>;
export const UpdateMatricQuestionResponseDocument = gql`
    mutation UpdateMatricQuestionResponse($id: Int!, $changes: matric_question_responses_set_input) {
  update_matric_question_responses_by_pk(pk_columns: {id: $id}, _set: $changes) {
    id
  }
}
    `;
export type UpdateMatricQuestionResponseMutationFn = Apollo.MutationFunction<UpdateMatricQuestionResponseMutation, UpdateMatricQuestionResponseMutationVariables>;

/**
 * __useUpdateMatricQuestionResponseMutation__
 *
 * To run a mutation, you first call `useUpdateMatricQuestionResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMatricQuestionResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMatricQuestionResponseMutation, { data, loading, error }] = useUpdateMatricQuestionResponseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateMatricQuestionResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMatricQuestionResponseMutation, UpdateMatricQuestionResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMatricQuestionResponseMutation, UpdateMatricQuestionResponseMutationVariables>(UpdateMatricQuestionResponseDocument, options);
      }
export type UpdateMatricQuestionResponseMutationHookResult = ReturnType<typeof useUpdateMatricQuestionResponseMutation>;
export type UpdateMatricQuestionResponseMutationResult = Apollo.MutationResult<UpdateMatricQuestionResponseMutation>;
export type UpdateMatricQuestionResponseMutationOptions = Apollo.BaseMutationOptions<UpdateMatricQuestionResponseMutation, UpdateMatricQuestionResponseMutationVariables>;
export const UpdateMatricQuestionByIdDocument = gql`
    mutation UpdateMatricQuestionById($id: Int!, $changes: matric_questions_set_input) {
  update_matric_questions_by_pk(pk_columns: {id: $id}, _set: $changes) {
    id
  }
}
    `;
export type UpdateMatricQuestionByIdMutationFn = Apollo.MutationFunction<UpdateMatricQuestionByIdMutation, UpdateMatricQuestionByIdMutationVariables>;

/**
 * __useUpdateMatricQuestionByIdMutation__
 *
 * To run a mutation, you first call `useUpdateMatricQuestionByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMatricQuestionByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMatricQuestionByIdMutation, { data, loading, error }] = useUpdateMatricQuestionByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateMatricQuestionByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMatricQuestionByIdMutation, UpdateMatricQuestionByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMatricQuestionByIdMutation, UpdateMatricQuestionByIdMutationVariables>(UpdateMatricQuestionByIdDocument, options);
      }
export type UpdateMatricQuestionByIdMutationHookResult = ReturnType<typeof useUpdateMatricQuestionByIdMutation>;
export type UpdateMatricQuestionByIdMutationResult = Apollo.MutationResult<UpdateMatricQuestionByIdMutation>;
export type UpdateMatricQuestionByIdMutationOptions = Apollo.BaseMutationOptions<UpdateMatricQuestionByIdMutation, UpdateMatricQuestionByIdMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken($refreshToken: String!) {
  refreshToken(input: {refresh_token: $refreshToken}) {
    token
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const AddTemplateQuestionDocument = gql`
    mutation AddTemplateQuestion($contentEn: String, $contentFr: String, $code: String) {
  insert_template_questions_one(
    object: {code: $code, content: {data: {translations: {data: [{translation: $contentEn, language_code: "en"}, {translation: $contentFr, language_code: "fr"}]}}}}
  ) {
    id
  }
}
    `;
export type AddTemplateQuestionMutationFn = Apollo.MutationFunction<AddTemplateQuestionMutation, AddTemplateQuestionMutationVariables>;

/**
 * __useAddTemplateQuestionMutation__
 *
 * To run a mutation, you first call `useAddTemplateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTemplateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTemplateQuestionMutation, { data, loading, error }] = useAddTemplateQuestionMutation({
 *   variables: {
 *      contentEn: // value for 'contentEn'
 *      contentFr: // value for 'contentFr'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAddTemplateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<AddTemplateQuestionMutation, AddTemplateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTemplateQuestionMutation, AddTemplateQuestionMutationVariables>(AddTemplateQuestionDocument, options);
      }
export type AddTemplateQuestionMutationHookResult = ReturnType<typeof useAddTemplateQuestionMutation>;
export type AddTemplateQuestionMutationResult = Apollo.MutationResult<AddTemplateQuestionMutation>;
export type AddTemplateQuestionMutationOptions = Apollo.BaseMutationOptions<AddTemplateQuestionMutation, AddTemplateQuestionMutationVariables>;
export const AddTemplateQuestionResponseDocument = gql`
    mutation AddTemplateQuestionResponse($template_question_id: Int!, $contentEn: String, $contentFr: String) {
  insert_template_question_responses_one(
    object: {template_question_id: $template_question_id, content: {data: {translations: {data: [{translation: $contentEn, language_code: "en"}, {translation: $contentFr, language_code: "fr"}]}}}}
  ) {
    id
  }
}
    `;
export type AddTemplateQuestionResponseMutationFn = Apollo.MutationFunction<AddTemplateQuestionResponseMutation, AddTemplateQuestionResponseMutationVariables>;

/**
 * __useAddTemplateQuestionResponseMutation__
 *
 * To run a mutation, you first call `useAddTemplateQuestionResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTemplateQuestionResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTemplateQuestionResponseMutation, { data, loading, error }] = useAddTemplateQuestionResponseMutation({
 *   variables: {
 *      template_question_id: // value for 'template_question_id'
 *      contentEn: // value for 'contentEn'
 *      contentFr: // value for 'contentFr'
 *   },
 * });
 */
export function useAddTemplateQuestionResponseMutation(baseOptions?: Apollo.MutationHookOptions<AddTemplateQuestionResponseMutation, AddTemplateQuestionResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTemplateQuestionResponseMutation, AddTemplateQuestionResponseMutationVariables>(AddTemplateQuestionResponseDocument, options);
      }
export type AddTemplateQuestionResponseMutationHookResult = ReturnType<typeof useAddTemplateQuestionResponseMutation>;
export type AddTemplateQuestionResponseMutationResult = Apollo.MutationResult<AddTemplateQuestionResponseMutation>;
export type AddTemplateQuestionResponseMutationOptions = Apollo.BaseMutationOptions<AddTemplateQuestionResponseMutation, AddTemplateQuestionResponseMutationVariables>;
export const UpdateTemplateQuestionResponseDocument = gql`
    mutation UpdateTemplateQuestionResponse($id: Int!, $changes: template_question_responses_set_input) {
  update_template_question_responses_by_pk(pk_columns: {id: $id}, _set: $changes) {
    id
  }
}
    `;
export type UpdateTemplateQuestionResponseMutationFn = Apollo.MutationFunction<UpdateTemplateQuestionResponseMutation, UpdateTemplateQuestionResponseMutationVariables>;

/**
 * __useUpdateTemplateQuestionResponseMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateQuestionResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateQuestionResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateQuestionResponseMutation, { data, loading, error }] = useUpdateTemplateQuestionResponseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateTemplateQuestionResponseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTemplateQuestionResponseMutation, UpdateTemplateQuestionResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTemplateQuestionResponseMutation, UpdateTemplateQuestionResponseMutationVariables>(UpdateTemplateQuestionResponseDocument, options);
      }
export type UpdateTemplateQuestionResponseMutationHookResult = ReturnType<typeof useUpdateTemplateQuestionResponseMutation>;
export type UpdateTemplateQuestionResponseMutationResult = Apollo.MutationResult<UpdateTemplateQuestionResponseMutation>;
export type UpdateTemplateQuestionResponseMutationOptions = Apollo.BaseMutationOptions<UpdateTemplateQuestionResponseMutation, UpdateTemplateQuestionResponseMutationVariables>;
export const UpdateTemplateQuestionByIdDocument = gql`
    mutation UpdateTemplateQuestionById($id: Int!, $changes: template_questions_set_input) {
  update_template_questions_by_pk(pk_columns: {id: $id}, _set: $changes) {
    id
  }
}
    `;
export type UpdateTemplateQuestionByIdMutationFn = Apollo.MutationFunction<UpdateTemplateQuestionByIdMutation, UpdateTemplateQuestionByIdMutationVariables>;

/**
 * __useUpdateTemplateQuestionByIdMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateQuestionByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateQuestionByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateQuestionByIdMutation, { data, loading, error }] = useUpdateTemplateQuestionByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateTemplateQuestionByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTemplateQuestionByIdMutation, UpdateTemplateQuestionByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTemplateQuestionByIdMutation, UpdateTemplateQuestionByIdMutationVariables>(UpdateTemplateQuestionByIdDocument, options);
      }
export type UpdateTemplateQuestionByIdMutationHookResult = ReturnType<typeof useUpdateTemplateQuestionByIdMutation>;
export type UpdateTemplateQuestionByIdMutationResult = Apollo.MutationResult<UpdateTemplateQuestionByIdMutation>;
export type UpdateTemplateQuestionByIdMutationOptions = Apollo.BaseMutationOptions<UpdateTemplateQuestionByIdMutation, UpdateTemplateQuestionByIdMutationVariables>;
export const UpdateTranslationDocument = gql`
    mutation UpdateTranslation($translation: String, $id: Int!) {
  update_translations_by_pk(
    pk_columns: {id: $id}
    _set: {translation: $translation}
  ) {
    translation
  }
}
    `;
export type UpdateTranslationMutationFn = Apollo.MutationFunction<UpdateTranslationMutation, UpdateTranslationMutationVariables>;

/**
 * __useUpdateTranslationMutation__
 *
 * To run a mutation, you first call `useUpdateTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTranslationMutation, { data, loading, error }] = useUpdateTranslationMutation({
 *   variables: {
 *      translation: // value for 'translation'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateTranslationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTranslationMutation, UpdateTranslationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTranslationMutation, UpdateTranslationMutationVariables>(UpdateTranslationDocument, options);
      }
export type UpdateTranslationMutationHookResult = ReturnType<typeof useUpdateTranslationMutation>;
export type UpdateTranslationMutationResult = Apollo.MutationResult<UpdateTranslationMutation>;
export type UpdateTranslationMutationOptions = Apollo.BaseMutationOptions<UpdateTranslationMutation, UpdateTranslationMutationVariables>;
export const GetAttachmentTestCategoriesDocument = gql`
    query GetAttachmentTestCategories($limit: Int, $offset: Int, $order_by: [attachment_test_categories_order_by!], $where: attachment_test_categories_bool_exp) {
  attachment_test_categories(
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: $where
  ) {
    id
    name {
      id
      translations {
        id
        translation
        language_code
      }
    }
    description {
      id
      translations {
        id
        translation
        language_code
      }
    }
    icon_url
    created_at
    updated_at
  }
  attachment_test_categories_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAttachmentTestCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAttachmentTestCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttachmentTestCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttachmentTestCategoriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAttachmentTestCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAttachmentTestCategoriesQuery, GetAttachmentTestCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttachmentTestCategoriesQuery, GetAttachmentTestCategoriesQueryVariables>(GetAttachmentTestCategoriesDocument, options);
      }
export function useGetAttachmentTestCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttachmentTestCategoriesQuery, GetAttachmentTestCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttachmentTestCategoriesQuery, GetAttachmentTestCategoriesQueryVariables>(GetAttachmentTestCategoriesDocument, options);
        }
export type GetAttachmentTestCategoriesQueryHookResult = ReturnType<typeof useGetAttachmentTestCategoriesQuery>;
export type GetAttachmentTestCategoriesLazyQueryHookResult = ReturnType<typeof useGetAttachmentTestCategoriesLazyQuery>;
export type GetAttachmentTestCategoriesQueryResult = Apollo.QueryResult<GetAttachmentTestCategoriesQuery, GetAttachmentTestCategoriesQueryVariables>;
export const GetAttachmentTestResponsesByQuestionIdDocument = gql`
    query GetAttachmentTestResponsesByQuestionId($where: attachment_test_responses_bool_exp) {
  attachment_test_responses(where: $where) {
    id
    attachment_test_id
    content {
      id
      translations {
        id
        translation
        language_code
      }
    }
    point
    created_at
    updated_at
  }
  attachment_test_responses_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAttachmentTestResponsesByQuestionIdQuery__
 *
 * To run a query within a React component, call `useGetAttachmentTestResponsesByQuestionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttachmentTestResponsesByQuestionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttachmentTestResponsesByQuestionIdQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAttachmentTestResponsesByQuestionIdQuery(baseOptions?: Apollo.QueryHookOptions<GetAttachmentTestResponsesByQuestionIdQuery, GetAttachmentTestResponsesByQuestionIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttachmentTestResponsesByQuestionIdQuery, GetAttachmentTestResponsesByQuestionIdQueryVariables>(GetAttachmentTestResponsesByQuestionIdDocument, options);
      }
export function useGetAttachmentTestResponsesByQuestionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttachmentTestResponsesByQuestionIdQuery, GetAttachmentTestResponsesByQuestionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttachmentTestResponsesByQuestionIdQuery, GetAttachmentTestResponsesByQuestionIdQueryVariables>(GetAttachmentTestResponsesByQuestionIdDocument, options);
        }
export type GetAttachmentTestResponsesByQuestionIdQueryHookResult = ReturnType<typeof useGetAttachmentTestResponsesByQuestionIdQuery>;
export type GetAttachmentTestResponsesByQuestionIdLazyQueryHookResult = ReturnType<typeof useGetAttachmentTestResponsesByQuestionIdLazyQuery>;
export type GetAttachmentTestResponsesByQuestionIdQueryResult = Apollo.QueryResult<GetAttachmentTestResponsesByQuestionIdQuery, GetAttachmentTestResponsesByQuestionIdQueryVariables>;
export const GetAttachmentTestsDocument = gql`
    query GetAttachmentTests($limit: Int, $offset: Int, $order_by: [attachment_tests_order_by!], $where: attachment_tests_bool_exp) {
  attachment_tests(
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: $where
  ) {
    id
    content {
      id
      translations {
        id
        translation
        language_code
      }
    }
    category {
      id
      name {
        translations {
          translation
          language_code
        }
      }
    }
    created_at
    updated_at
  }
  attachment_tests_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAttachmentTestsQuery__
 *
 * To run a query within a React component, call `useGetAttachmentTestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttachmentTestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttachmentTestsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAttachmentTestsQuery(baseOptions?: Apollo.QueryHookOptions<GetAttachmentTestsQuery, GetAttachmentTestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttachmentTestsQuery, GetAttachmentTestsQueryVariables>(GetAttachmentTestsDocument, options);
      }
export function useGetAttachmentTestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttachmentTestsQuery, GetAttachmentTestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttachmentTestsQuery, GetAttachmentTestsQueryVariables>(GetAttachmentTestsDocument, options);
        }
export type GetAttachmentTestsQueryHookResult = ReturnType<typeof useGetAttachmentTestsQuery>;
export type GetAttachmentTestsLazyQueryHookResult = ReturnType<typeof useGetAttachmentTestsLazyQuery>;
export type GetAttachmentTestsQueryResult = Apollo.QueryResult<GetAttachmentTestsQuery, GetAttachmentTestsQueryVariables>;
export const GetAvatarResultsDocument = gql`
    query GetAvatarResults($limit: Int, $offset: Int, $order_by: [avatar_results_order_by!], $where: avatar_results_bool_exp) {
  avatar_results(
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: $where
  ) {
    id
    code
    icon_url
    content {
      id
      translations {
        id
        translation
        language_code
      }
    }
    name {
      id
      translations {
        id
        translation
        language_code
      }
    }
    created_at
    updated_at
  }
  avatar_results_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAvatarResultsQuery__
 *
 * To run a query within a React component, call `useGetAvatarResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvatarResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvatarResultsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAvatarResultsQuery(baseOptions?: Apollo.QueryHookOptions<GetAvatarResultsQuery, GetAvatarResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvatarResultsQuery, GetAvatarResultsQueryVariables>(GetAvatarResultsDocument, options);
      }
export function useGetAvatarResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvatarResultsQuery, GetAvatarResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvatarResultsQuery, GetAvatarResultsQueryVariables>(GetAvatarResultsDocument, options);
        }
export type GetAvatarResultsQueryHookResult = ReturnType<typeof useGetAvatarResultsQuery>;
export type GetAvatarResultsLazyQueryHookResult = ReturnType<typeof useGetAvatarResultsLazyQuery>;
export type GetAvatarResultsQueryResult = Apollo.QueryResult<GetAvatarResultsQuery, GetAvatarResultsQueryVariables>;
export const GetAvatarTestResponsesDocument = gql`
    query GetAvatarTestResponses($where: avatar_test_responses_bool_exp) {
  avatar_test_responses(where: $where) {
    id
    content {
      id
      translations {
        id
        translation
        language_code
      }
    }
    avatar_result {
      id
      code
    }
    created_at
    updated_at
  }
  avatar_test_responses_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAvatarTestResponsesQuery__
 *
 * To run a query within a React component, call `useGetAvatarTestResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvatarTestResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvatarTestResponsesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAvatarTestResponsesQuery(baseOptions?: Apollo.QueryHookOptions<GetAvatarTestResponsesQuery, GetAvatarTestResponsesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvatarTestResponsesQuery, GetAvatarTestResponsesQueryVariables>(GetAvatarTestResponsesDocument, options);
      }
export function useGetAvatarTestResponsesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvatarTestResponsesQuery, GetAvatarTestResponsesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvatarTestResponsesQuery, GetAvatarTestResponsesQueryVariables>(GetAvatarTestResponsesDocument, options);
        }
export type GetAvatarTestResponsesQueryHookResult = ReturnType<typeof useGetAvatarTestResponsesQuery>;
export type GetAvatarTestResponsesLazyQueryHookResult = ReturnType<typeof useGetAvatarTestResponsesLazyQuery>;
export type GetAvatarTestResponsesQueryResult = Apollo.QueryResult<GetAvatarTestResponsesQuery, GetAvatarTestResponsesQueryVariables>;
export const GetAvatarTestsDocument = gql`
    query GetAvatarTests($limit: Int, $offset: Int, $order_by: [avatar_tests_order_by!], $where: avatar_tests_bool_exp) {
  avatar_tests(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    id
    category
    content {
      id
      translations {
        id
        translation
        language_code
      }
    }
    created_at
    updated_at
  }
  avatar_tests_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAvatarTestsQuery__
 *
 * To run a query within a React component, call `useGetAvatarTestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvatarTestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvatarTestsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAvatarTestsQuery(baseOptions?: Apollo.QueryHookOptions<GetAvatarTestsQuery, GetAvatarTestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvatarTestsQuery, GetAvatarTestsQueryVariables>(GetAvatarTestsDocument, options);
      }
export function useGetAvatarTestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvatarTestsQuery, GetAvatarTestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvatarTestsQuery, GetAvatarTestsQueryVariables>(GetAvatarTestsDocument, options);
        }
export type GetAvatarTestsQueryHookResult = ReturnType<typeof useGetAvatarTestsQuery>;
export type GetAvatarTestsLazyQueryHookResult = ReturnType<typeof useGetAvatarTestsLazyQuery>;
export type GetAvatarTestsQueryResult = Apollo.QueryResult<GetAvatarTestsQuery, GetAvatarTestsQueryVariables>;
export const GetOnboardingLinkDocument = gql`
    query GetOnboardingLink($input: GetStripeAccountLinkInput!) {
  getStripeAccountLink(input: $input) {
    link
  }
}
    `;

/**
 * __useGetOnboardingLinkQuery__
 *
 * To run a query within a React component, call `useGetOnboardingLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingLinkQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOnboardingLinkQuery(baseOptions: Apollo.QueryHookOptions<GetOnboardingLinkQuery, GetOnboardingLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOnboardingLinkQuery, GetOnboardingLinkQueryVariables>(GetOnboardingLinkDocument, options);
      }
export function useGetOnboardingLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOnboardingLinkQuery, GetOnboardingLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOnboardingLinkQuery, GetOnboardingLinkQueryVariables>(GetOnboardingLinkDocument, options);
        }
export type GetOnboardingLinkQueryHookResult = ReturnType<typeof useGetOnboardingLinkQuery>;
export type GetOnboardingLinkLazyQueryHookResult = ReturnType<typeof useGetOnboardingLinkLazyQuery>;
export type GetOnboardingLinkQueryResult = Apollo.QueryResult<GetOnboardingLinkQuery, GetOnboardingLinkQueryVariables>;
export const GetGendersDocument = gql`
    query GetGenders {
  genders {
    id
    code
    content {
      translations {
        id
        translation
        language_code
      }
    }
  }
}
    `;

/**
 * __useGetGendersQuery__
 *
 * To run a query within a React component, call `useGetGendersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGendersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGendersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGendersQuery(baseOptions?: Apollo.QueryHookOptions<GetGendersQuery, GetGendersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGendersQuery, GetGendersQueryVariables>(GetGendersDocument, options);
      }
export function useGetGendersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGendersQuery, GetGendersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGendersQuery, GetGendersQueryVariables>(GetGendersDocument, options);
        }
export type GetGendersQueryHookResult = ReturnType<typeof useGetGendersQuery>;
export type GetGendersLazyQueryHookResult = ReturnType<typeof useGetGendersLazyQuery>;
export type GetGendersQueryResult = Apollo.QueryResult<GetGendersQuery, GetGendersQueryVariables>;
export const GetAvailabilityTimeSlotsByCoachDocument = gql`
    query GetAvailabilityTimeSlotsByCoach($coach_id: Int!) {
  availability_time_slots(where: {coach_id: {_eq: $coach_id}}) {
    id
    coach_id
    availability_status
    end_time
    repeat_interval
    repeat_days
    start_time
  }
}
    `;

/**
 * __useGetAvailabilityTimeSlotsByCoachQuery__
 *
 * To run a query within a React component, call `useGetAvailabilityTimeSlotsByCoachQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailabilityTimeSlotsByCoachQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailabilityTimeSlotsByCoachQuery({
 *   variables: {
 *      coach_id: // value for 'coach_id'
 *   },
 * });
 */
export function useGetAvailabilityTimeSlotsByCoachQuery(baseOptions: Apollo.QueryHookOptions<GetAvailabilityTimeSlotsByCoachQuery, GetAvailabilityTimeSlotsByCoachQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailabilityTimeSlotsByCoachQuery, GetAvailabilityTimeSlotsByCoachQueryVariables>(GetAvailabilityTimeSlotsByCoachDocument, options);
      }
export function useGetAvailabilityTimeSlotsByCoachLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailabilityTimeSlotsByCoachQuery, GetAvailabilityTimeSlotsByCoachQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailabilityTimeSlotsByCoachQuery, GetAvailabilityTimeSlotsByCoachQueryVariables>(GetAvailabilityTimeSlotsByCoachDocument, options);
        }
export type GetAvailabilityTimeSlotsByCoachQueryHookResult = ReturnType<typeof useGetAvailabilityTimeSlotsByCoachQuery>;
export type GetAvailabilityTimeSlotsByCoachLazyQueryHookResult = ReturnType<typeof useGetAvailabilityTimeSlotsByCoachLazyQuery>;
export type GetAvailabilityTimeSlotsByCoachQueryResult = Apollo.QueryResult<GetAvailabilityTimeSlotsByCoachQuery, GetAvailabilityTimeSlotsByCoachQueryVariables>;
export const GetReservationsDocument = gql`
    query GetReservations($limit: Int, $offset: Int, $order_by: [reservations_order_by!], $where: reservations_bool_exp) {
  reservations(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    id
    user {
      id
      full_name
    }
    time_slots {
      start_time
      end_time
      slot_status
    }
    reservation_payments {
      payment_status
      payer_id
    }
    user_id
    coach_id
    coach {
      id
      full_name
    }
    amount
    reservation_status
    updated_at
    created_at
  }
  reservations_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetReservationsQuery__
 *
 * To run a query within a React component, call `useGetReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetReservationsQuery(baseOptions?: Apollo.QueryHookOptions<GetReservationsQuery, GetReservationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationsQuery, GetReservationsQueryVariables>(GetReservationsDocument, options);
      }
export function useGetReservationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationsQuery, GetReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationsQuery, GetReservationsQueryVariables>(GetReservationsDocument, options);
        }
export type GetReservationsQueryHookResult = ReturnType<typeof useGetReservationsQuery>;
export type GetReservationsLazyQueryHookResult = ReturnType<typeof useGetReservationsLazyQuery>;
export type GetReservationsQueryResult = Apollo.QueryResult<GetReservationsQuery, GetReservationsQueryVariables>;
export const GetUserByIdDocument = gql`
    query GetUserById($id: Int!) {
  users_by_pk(id: $id) {
    id
    role
    email
    full_name
    currency
  }
}
    `;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
      }
export function useGetUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
        }
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<GetUserByIdQuery, GetUserByIdQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers($limit: Int, $offset: Int, $order_by: [users_order_by!], $where: users_bool_exp) {
  users(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    id
    email
    full_name
    currency
    date_of_birth
    hourly_rate
    is_active
    role
    created_at
    stripe_account_id
    is_stripe_account_ready
    profile {
      city {
        id
        name
      }
      gender {
        id
        content {
          translations {
            translation
            language_code
          }
        }
      }
    }
  }
  users_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const SubCountUserDocument = gql`
    query SubCountUser {
  users_aggregate(where: {role: {_eq: "USER"}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useSubCountUserQuery__
 *
 * To run a query within a React component, call `useSubCountUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubCountUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubCountUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubCountUserQuery(baseOptions?: Apollo.QueryHookOptions<SubCountUserQuery, SubCountUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubCountUserQuery, SubCountUserQueryVariables>(SubCountUserDocument, options);
      }
export function useSubCountUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubCountUserQuery, SubCountUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubCountUserQuery, SubCountUserQueryVariables>(SubCountUserDocument, options);
        }
export type SubCountUserQueryHookResult = ReturnType<typeof useSubCountUserQuery>;
export type SubCountUserLazyQueryHookResult = ReturnType<typeof useSubCountUserLazyQuery>;
export type SubCountUserQueryResult = Apollo.QueryResult<SubCountUserQuery, SubCountUserQueryVariables>;
export const SubCountCoachDocument = gql`
    query SubCountCoach {
  users_aggregate(where: {role: {_eq: "COACH"}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useSubCountCoachQuery__
 *
 * To run a query within a React component, call `useSubCountCoachQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubCountCoachQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubCountCoachQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubCountCoachQuery(baseOptions?: Apollo.QueryHookOptions<SubCountCoachQuery, SubCountCoachQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubCountCoachQuery, SubCountCoachQueryVariables>(SubCountCoachDocument, options);
      }
export function useSubCountCoachLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubCountCoachQuery, SubCountCoachQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubCountCoachQuery, SubCountCoachQueryVariables>(SubCountCoachDocument, options);
        }
export type SubCountCoachQueryHookResult = ReturnType<typeof useSubCountCoachQuery>;
export type SubCountCoachLazyQueryHookResult = ReturnType<typeof useSubCountCoachLazyQuery>;
export type SubCountCoachQueryResult = Apollo.QueryResult<SubCountCoachQuery, SubCountCoachQueryVariables>;
export const SubCountSessionDocument = gql`
    query SubCountSession {
  reservations_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useSubCountSessionQuery__
 *
 * To run a query within a React component, call `useSubCountSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubCountSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubCountSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubCountSessionQuery(baseOptions?: Apollo.QueryHookOptions<SubCountSessionQuery, SubCountSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubCountSessionQuery, SubCountSessionQueryVariables>(SubCountSessionDocument, options);
      }
export function useSubCountSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubCountSessionQuery, SubCountSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubCountSessionQuery, SubCountSessionQueryVariables>(SubCountSessionDocument, options);
        }
export type SubCountSessionQueryHookResult = ReturnType<typeof useSubCountSessionQuery>;
export type SubCountSessionLazyQueryHookResult = ReturnType<typeof useSubCountSessionLazyQuery>;
export type SubCountSessionQueryResult = Apollo.QueryResult<SubCountSessionQuery, SubCountSessionQueryVariables>;
export const GetMatricQuestionResponsesByQuestionIdDocument = gql`
    query GetMatricQuestionResponsesByQuestionId($where: matric_question_responses_bool_exp) {
  matric_question_responses(where: $where) {
    id
    matric_question_id
    content {
      id
      translations {
        id
        translation
        language_code
      }
    }
    point
    created_at
    updated_at
  }
  matric_question_responses_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetMatricQuestionResponsesByQuestionIdQuery__
 *
 * To run a query within a React component, call `useGetMatricQuestionResponsesByQuestionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatricQuestionResponsesByQuestionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatricQuestionResponsesByQuestionIdQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetMatricQuestionResponsesByQuestionIdQuery(baseOptions?: Apollo.QueryHookOptions<GetMatricQuestionResponsesByQuestionIdQuery, GetMatricQuestionResponsesByQuestionIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMatricQuestionResponsesByQuestionIdQuery, GetMatricQuestionResponsesByQuestionIdQueryVariables>(GetMatricQuestionResponsesByQuestionIdDocument, options);
      }
export function useGetMatricQuestionResponsesByQuestionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMatricQuestionResponsesByQuestionIdQuery, GetMatricQuestionResponsesByQuestionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMatricQuestionResponsesByQuestionIdQuery, GetMatricQuestionResponsesByQuestionIdQueryVariables>(GetMatricQuestionResponsesByQuestionIdDocument, options);
        }
export type GetMatricQuestionResponsesByQuestionIdQueryHookResult = ReturnType<typeof useGetMatricQuestionResponsesByQuestionIdQuery>;
export type GetMatricQuestionResponsesByQuestionIdLazyQueryHookResult = ReturnType<typeof useGetMatricQuestionResponsesByQuestionIdLazyQuery>;
export type GetMatricQuestionResponsesByQuestionIdQueryResult = Apollo.QueryResult<GetMatricQuestionResponsesByQuestionIdQuery, GetMatricQuestionResponsesByQuestionIdQueryVariables>;
export const GetMatricQuestionCategoriesDocument = gql`
    query GetMatricQuestionCategories($limit: Int, $offset: Int, $order_by: [matric_question_categories_order_by!], $where: matric_question_categories_bool_exp) {
  matric_question_categories(
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: $where
  ) {
    id
    content {
      id
      translations {
        id
        translation
        language_code
      }
    }
    created_at
    updated_at
  }
  matric_question_categories_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetMatricQuestionCategoriesQuery__
 *
 * To run a query within a React component, call `useGetMatricQuestionCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatricQuestionCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatricQuestionCategoriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetMatricQuestionCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetMatricQuestionCategoriesQuery, GetMatricQuestionCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMatricQuestionCategoriesQuery, GetMatricQuestionCategoriesQueryVariables>(GetMatricQuestionCategoriesDocument, options);
      }
export function useGetMatricQuestionCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMatricQuestionCategoriesQuery, GetMatricQuestionCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMatricQuestionCategoriesQuery, GetMatricQuestionCategoriesQueryVariables>(GetMatricQuestionCategoriesDocument, options);
        }
export type GetMatricQuestionCategoriesQueryHookResult = ReturnType<typeof useGetMatricQuestionCategoriesQuery>;
export type GetMatricQuestionCategoriesLazyQueryHookResult = ReturnType<typeof useGetMatricQuestionCategoriesLazyQuery>;
export type GetMatricQuestionCategoriesQueryResult = Apollo.QueryResult<GetMatricQuestionCategoriesQuery, GetMatricQuestionCategoriesQueryVariables>;
export const GetMatricQuestionnairesDocument = gql`
    query GetMatricQuestionnaires($limit: Int, $offset: Int, $order_by: [matric_questions_order_by!], $where: matric_questions_bool_exp) {
  matric_questions(
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: $where
  ) {
    id
    content {
      id
      translations {
        id
        translation
        language_code
      }
    }
    matric_question_category {
      content {
        translations {
          translation
          language_code
        }
      }
    }
    created_at
    updated_at
  }
  matric_questions_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetMatricQuestionnairesQuery__
 *
 * To run a query within a React component, call `useGetMatricQuestionnairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatricQuestionnairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatricQuestionnairesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetMatricQuestionnairesQuery(baseOptions?: Apollo.QueryHookOptions<GetMatricQuestionnairesQuery, GetMatricQuestionnairesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMatricQuestionnairesQuery, GetMatricQuestionnairesQueryVariables>(GetMatricQuestionnairesDocument, options);
      }
export function useGetMatricQuestionnairesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMatricQuestionnairesQuery, GetMatricQuestionnairesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMatricQuestionnairesQuery, GetMatricQuestionnairesQueryVariables>(GetMatricQuestionnairesDocument, options);
        }
export type GetMatricQuestionnairesQueryHookResult = ReturnType<typeof useGetMatricQuestionnairesQuery>;
export type GetMatricQuestionnairesLazyQueryHookResult = ReturnType<typeof useGetMatricQuestionnairesLazyQuery>;
export type GetMatricQuestionnairesQueryResult = Apollo.QueryResult<GetMatricQuestionnairesQuery, GetMatricQuestionnairesQueryVariables>;
export const GetSettingsDocument = gql`
    query GetSettings {
  settings {
    id
    name
    value
  }
}
    `;

/**
 * __useGetSettingsQuery__
 *
 * To run a query within a React component, call `useGetSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetSettingsQuery, GetSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSettingsQuery, GetSettingsQueryVariables>(GetSettingsDocument, options);
      }
export function useGetSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSettingsQuery, GetSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSettingsQuery, GetSettingsQueryVariables>(GetSettingsDocument, options);
        }
export type GetSettingsQueryHookResult = ReturnType<typeof useGetSettingsQuery>;
export type GetSettingsLazyQueryHookResult = ReturnType<typeof useGetSettingsLazyQuery>;
export type GetSettingsQueryResult = Apollo.QueryResult<GetSettingsQuery, GetSettingsQueryVariables>;
export const UpdateSettingByNameDocument = gql`
    mutation UpdateSettingByName($where: settings_bool_exp!, $object: settings_set_input) {
  update_settings(where: $where, _set: $object) {
    affected_rows
  }
}
    `;
export type UpdateSettingByNameMutationFn = Apollo.MutationFunction<UpdateSettingByNameMutation, UpdateSettingByNameMutationVariables>;

/**
 * __useUpdateSettingByNameMutation__
 *
 * To run a mutation, you first call `useUpdateSettingByNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingByNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingByNameMutation, { data, loading, error }] = useUpdateSettingByNameMutation({
 *   variables: {
 *      where: // value for 'where'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateSettingByNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSettingByNameMutation, UpdateSettingByNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSettingByNameMutation, UpdateSettingByNameMutationVariables>(UpdateSettingByNameDocument, options);
      }
export type UpdateSettingByNameMutationHookResult = ReturnType<typeof useUpdateSettingByNameMutation>;
export type UpdateSettingByNameMutationResult = Apollo.MutationResult<UpdateSettingByNameMutation>;
export type UpdateSettingByNameMutationOptions = Apollo.BaseMutationOptions<UpdateSettingByNameMutation, UpdateSettingByNameMutationVariables>;
export const GetTemplateQuestionResponsesByQuestionIdDocument = gql`
    query GetTemplateQuestionResponsesByQuestionId($where: template_question_responses_bool_exp) {
  template_question_responses(where: $where) {
    id
    content {
      id
      translations {
        id
        translation
        language_code
      }
    }
    created_at
    updated_at
  }
  template_question_responses_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetTemplateQuestionResponsesByQuestionIdQuery__
 *
 * To run a query within a React component, call `useGetTemplateQuestionResponsesByQuestionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateQuestionResponsesByQuestionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateQuestionResponsesByQuestionIdQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTemplateQuestionResponsesByQuestionIdQuery(baseOptions?: Apollo.QueryHookOptions<GetTemplateQuestionResponsesByQuestionIdQuery, GetTemplateQuestionResponsesByQuestionIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplateQuestionResponsesByQuestionIdQuery, GetTemplateQuestionResponsesByQuestionIdQueryVariables>(GetTemplateQuestionResponsesByQuestionIdDocument, options);
      }
export function useGetTemplateQuestionResponsesByQuestionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateQuestionResponsesByQuestionIdQuery, GetTemplateQuestionResponsesByQuestionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplateQuestionResponsesByQuestionIdQuery, GetTemplateQuestionResponsesByQuestionIdQueryVariables>(GetTemplateQuestionResponsesByQuestionIdDocument, options);
        }
export type GetTemplateQuestionResponsesByQuestionIdQueryHookResult = ReturnType<typeof useGetTemplateQuestionResponsesByQuestionIdQuery>;
export type GetTemplateQuestionResponsesByQuestionIdLazyQueryHookResult = ReturnType<typeof useGetTemplateQuestionResponsesByQuestionIdLazyQuery>;
export type GetTemplateQuestionResponsesByQuestionIdQueryResult = Apollo.QueryResult<GetTemplateQuestionResponsesByQuestionIdQuery, GetTemplateQuestionResponsesByQuestionIdQueryVariables>;
export const GetTemplateQuestionsDocument = gql`
    query GetTemplateQuestions($limit: Int, $offset: Int, $order_by: [template_questions_order_by!], $where: template_questions_bool_exp) {
  template_questions(
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: $where
  ) {
    id
    code
    content {
      id
      translations {
        id
        translation
        language_code
      }
    }
    created_at
    updated_at
  }
  template_questions_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetTemplateQuestionsQuery__
 *
 * To run a query within a React component, call `useGetTemplateQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateQuestionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTemplateQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<GetTemplateQuestionsQuery, GetTemplateQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplateQuestionsQuery, GetTemplateQuestionsQueryVariables>(GetTemplateQuestionsDocument, options);
      }
export function useGetTemplateQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateQuestionsQuery, GetTemplateQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplateQuestionsQuery, GetTemplateQuestionsQueryVariables>(GetTemplateQuestionsDocument, options);
        }
export type GetTemplateQuestionsQueryHookResult = ReturnType<typeof useGetTemplateQuestionsQuery>;
export type GetTemplateQuestionsLazyQueryHookResult = ReturnType<typeof useGetTemplateQuestionsLazyQuery>;
export type GetTemplateQuestionsQueryResult = Apollo.QueryResult<GetTemplateQuestionsQuery, GetTemplateQuestionsQueryVariables>;