import React, { useState } from "react";
import DialogCommon, { IDialogProps } from "components/MDModal";

export type DialogOptions = Omit<IDialogProps, "onClose" | "open">;

export type ModalDialogType = {
  setOpen: (open: boolean, options: DialogOptions) => void;
  onClose: () => void;
};

export const ModalDialogContext = React.createContext<ModalDialogType>({
  setOpen: () => {},
  onClose: () => {},
});

export const ModalDialogProvider = (props: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<DialogOptions>();

  const setOpenImpl = (isOpen: boolean, dialogOptions: DialogOptions) => {
    setOptions(dialogOptions);
    setOpen(isOpen);
  };

  return (
    <ModalDialogContext.Provider value={{ setOpen: setOpenImpl, onClose: () => setOpen(false) }}>
      {props.children}
      <DialogCommon
        {...options}
        open={open}
        fullScreen={options?.fullScreen}
        title={options?.title!}
        onClose={() => setOpen(false)}
        body={options?.body!}
      />
    </ModalDialogContext.Provider>
  );
};
